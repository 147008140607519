<template>
  <v-container>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" v-if="capofila" />
    <v-row class="mb-4">
      <v-col>
        <h1>Dettaglio rendiconto di progetto</h1>
        <h4 class="grey--text">
          Lista dei
          <span v-if="this.capofila === 'istituzione'">corsi</span>
          <span v-else class="font-weight-bold">
            corsi in convenzione da <u>Capofila</u>
          </span>
          inclusi nel rendiconto di progetto - Anno Scolastico
          {{ this.anno }}/{{ this.anno + 1 }}
        </h4>
      </v-col>
    </v-row>

    <div>
      <v-card-text>
        <v-icon> mdi-information</v-icon>
        <span style="background: grey; color: white">
          <b>
            Dichiarazione del soggetto attuatore propedeutica alla presentazione
            del progetto.
          </b>
        </span></v-card-text
      >
      <v-btn
        outlined
        v-if="rendiconto"
        :disabled="!rendiconto.fileDocumentazioneFirmata"
        @click="scaricaFile()"
      >
        <v-icon> mdi-download </v-icon>
        Scarica
      </v-btn>
    </div>
    <v-row class="my-4">
      <v-col col="8">
        <v-text-field
          solo
          v-model="ricerca"
          append-icon="mdi-magnify"
          label="Cerca per N° id corso"
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <!--<v-row class="my-2">
      <v-col cols="2">
        <v-btn text class="blue--text"
          ><v-icon class="mx-2"> mdi-microsoft-excel </v-icon>
          Esporta Excel
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn text class="blue--text"
          ><v-icon class="mx-2"> mdi-file-pdf-box </v-icon>
          Esporta PDF
        </v-btn>
      </v-col>
      <v-col cols="4"></v-col>
      <v-col cols="4" class="d-flex"> </v-col>
    </v-row>-->
    <v-data-table
      :headers="headers"
      :items="corsi"
      :search="ricerca"
      :items-per-page="10"
      class="elevation-1"
      :loading="loadingDettaglio"
      loading-text="Caricamento dati in corso..."
      ><!-- eslint-disable -->
        <template v-slot:item.cup="{ item }">
          <span>{{ rendiconto.programma.cup }}</span>
        </template>
        <template v-slot:item.nomeIstituzione="{ item }">
          <span>{{ rendiconto.programma.nomeIstituzione }}</span>
        </template>
        <template v-slot:item.nomeScuola="{ item }">
          <span v-if="anno === 2022">
            >{{ item.corso.accordo.istituto.denominazioneScuola}}
          </span>
          <ul v-else>
            <li v-for="(accordo, index) in item.corso.accordi" :key="index">
              {{ accordo.istituto.denominazioneScuola }}
            </li>
          </ul>
        </template>
        <template v-slot:item.anno="{ item }">
          <span>{{item.corso.anno}}/{{item.corso.anno+1}}</span>
        </template>
        <template v-slot:item.dataInizio="{ item }">
          <span>{{item.corso.dataInizio | formatDate}}</span>
        </template>
        <template v-slot:item.dataFine="{ item }">
          <span>{{item.corso.dataFine | formatDate}}</span>
        </template>
        <template v-slot:item.studenti="{ item }">
          <span>{{item.corso.numeroStudenti}}</span>
        </template>
        <template v-slot:item.importo="{ item }">
          <span>{{item.importo | formatNumber}} €</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <router-link
              class="v-btn"
              :to="{
                    name: 'dettaglioCorso',
                    params: {
                      capofila: capofila,
                      idCorso: item.corso.id,
                      idRendiconto: idRendiconto,
                    },
                  }"
          >
            Dettaglio
            <v-icon></v-icon>
          </router-link>
        </template>
      <!-- eslint-enable -->
    </v-data-table>
    <div class="mt-4">
      <v-btn text class="blue--text" @click="$router.go(-1)">
        <v-icon>mdi-arrow-left-thin</v-icon>Torna a trasmissione rendiconto di
        progetto
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import rendicontiRepository from "@/api/istituto/RendicontiRepository";
import istitutoRepository from "@/api/istituto/IstitutoRepository";

export default {
  name: "dettaglioRendiconto",
  components: { BreadcrumbComponent },
  data: () => ({
    idUtente: 0,
    loadingDettaglio: false,
    isCapofila: false,
    corsi: [],
    ricerca: "",
    rendiconto: null,
    headers: [
      {
        text: "ID corso",
        align: "start",
        value: "corso.id",
      },
      { text: "CUP", value: "cup" },
      { text: "Università/AFAM", value: "nomeIstituzione" },
      { text: "Istituto Scolastico", value: "nomeScuola" },
      {
        text: "Anno Scolastico",
        value: "anno",
      },
      { text: "Data inizio", value: "dataInizio" },
      { text: "Data fine", value: "dataFine" },
      { text: "N° studenti", value: "studenti" },
      { text: "Costo del corso", value: "importoCorso" },
      { text: "Azioni", value: "actions", sortable: false },
    ],
  }),
  props: {
    capofila: {
      type: String,
      required: true,
    },
    idRendiconto: {
      type: [String, Number],
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["idStrutture", "anno", "user"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleConsulenza",
      "isRoleLegale",
    ]),
    loading: function () {
      return this.loadingDettaglio;
    },
    breadcrumbs: function () {
      if (this.capofila) {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania/${this.capofila}`,
            exact: true,
            link: true,
          },
          {
            text: "Creazione e trasmissione rendiconto di progetto",
            disabled: false,
            to: `/scrivania/${this.capofila}/rendiconti`,
            exact: true,
            link: true,
          },
          {
            text: "Dettaglio rendiconto di progetto",
            disabled: true,
            to: `/scrivania/${this.capofila}/rendiconti/dettaglioRendiconto`,
            exact: true,
            link: true,
          },
        ];
      }
      return [];
    },
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null && this.idRendiconto) {
        //this.getCorsiRendiconto();
        this.getRendiconto();
      }
    },
    /*async getCorsiRendiconto() {
      this.loadingDettaglio = true;
      this.corsi = await RendicontiRepository.getListaCorsiRendiconto(
        this.idStrutture,
        this.idRendiconto
      );
      this.loadingDettaglio = false;
    },*/
    async getRendiconto() {
      this.loadingDettaglio = true;
      this.rendiconto = await rendicontiRepository.getSingoloRendiconto(
        this.idStrutture,
        this.idRendiconto
      );
      if (this.rendiconto != null) {
        this.corsi = this.rendiconto.corsiRendiconto ?? [];
      }
      this.loadingDettaglio = false;
    },
    async scaricaFile() {
      if (this.rendiconto && this.rendiconto.fileDocumentazioneFirmata) {
        await istitutoRepository.downloadFile(
          this.idStrutture,
          this.rendiconto.fileDocumentazioneFirmata
        );
      }
    },
  },
};
</script>

<style scoped></style>
