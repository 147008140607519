<template>
  <v-card elevation="5">
    <v-card-title> Dettagli corso</v-card-title>
    <v-expansion-panels focusable hover v-if="corsiValidati.length">
      <v-expansion-panel v-for="(corso, index) in corsiValidati" :key="index">
        <v-expansion-panel-header>
          <v-row>
            <v-col class="align-start" cols="12"
              ><v-card-title>
                <h3 class="">ID corso {{ corso.id }}</h3>
              </v-card-title>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- Riepilogo corso-->
          <v-row class="pt-6">
            <v-col>
              <h3 class="blue--text">Riepilogo corso</h3>
            </v-col>
          </v-row>
          <!-- prima riga -->
          <v-row class="">
            <v-col cols="3">CUP </v-col>
            <v-col cols="3">ID Corso </v-col>
            <v-col cols="3">Data di avvio del corso </v-col>
            <v-col cols="3">Data conclusione del corso </v-col>
          </v-row>
          <v-row class="font-weight-bold pb-8">
            <v-col cols="3"
              >{{ corso.accordi[0].programmaOrientamento.cup }}
            </v-col>
            <v-col cols="3">{{ corso.id }} </v-col>
            <v-col cols="3">{{ corso.dataInizio | formatDate }} </v-col>
            <v-col cols="3">{{ corso.dataFine | formatDate }} </v-col>
          </v-row>
          <!-- seconda riga -->
          <v-row class="">
            <v-col cols="3">Istituzione </v-col>
            <v-col cols="3">Nome Legale rappresentante </v-col>
            <v-col cols="3">Cognome Legale rappresentante </v-col>
            <v-col cols="3">CF legale rappresentante </v-col>
          </v-row>
          <v-row class="font-weight-bold pb-8" v-if="legaleRappresentante">
            <v-col cols="3"
              >{{ corso.accordi[0].programmaOrientamento.nomeIstituzione }}
            </v-col>
            <v-col cols="3"
              >{{ legaleRappresentante.nomeRappresentante }}
            </v-col>
            <v-col cols="3"
              >{{ legaleRappresentante.cognomeRappresentante }}
            </v-col>
            <v-col cols="3"
              >{{ legaleRappresentante.codiceFiscaleRappresentante }}
            </v-col>
          </v-row>
          <!-- terza riga -->
          <v-row class="">
            <v-col cols="3">Nome referente di corso Università/AFAM </v-col>
            <v-col cols="3">Cognome referente di corso Università/AFAM </v-col>
            <v-col cols="3"></v-col>
            <v-col cols="3"></v-col>
          </v-row>
          <v-row class="font-weight-bold pb-8">
            <v-col cols="3">{{ corso.referente.anagrafica.nome }} </v-col>
            <v-col cols="3">{{ corso.referente.anagrafica.cognome }} </v-col>
            <v-col cols="3"> </v-col>
            <v-col cols="3"> </v-col>
          </v-row>
          <!-- quarta riga -->
          <div v-for="(accordo, index) in corso.accordi" :key="index">
            <v-row class="">
              <v-col cols="3">Istituto scolastico </v-col>
              <v-col cols="3">Tipologia istituto </v-col>
              <v-col cols="3">Regione istituto scolastico</v-col>
              <v-col cols="3">Città istituto scolastico</v-col>
            </v-row>
            <v-row class="font-weight-bold pb-8">
              <v-col cols="3"
                >{{ accordo.istituto.denominazioneScuola }}
              </v-col>
              <v-col cols="3"
                >{{ accordo.istituto.tipologiaGradoIstruzioneScuola }}
              </v-col>
              <v-col cols="3">{{ accordo.istituto.regione }} </v-col>
              <v-col cols="3">{{ accordo.istituto.descrizioneComune }} </v-col>
            </v-row>
          </div>
          <!-- Quinta riga -->
          <v-row class="">
            <v-col cols="3">Nome referente scolastico </v-col>
            <v-col cols="3">Cognome referente scolastico </v-col>
            <v-col cols="3"></v-col>
            <v-col cols="3"></v-col>
          </v-row>
          <v-row class="font-weight-bold pb-8">
            <v-col cols="3">{{ corso.nomeReferenteScolastico }} </v-col>
            <v-col cols="3">{{ corso.cognomeReferenteScolastico }} </v-col>
            <v-col cols="3"></v-col>
            <v-col cols="3"> </v-col>
          </v-row>
          <!-- Sesta riga -->
          <v-row class="">
            <v-col cols="3">N° studenti coinvolti</v-col>
            <v-col cols="3">N° studenti coinvolti con DSA </v-col>
            <v-col cols="3">N° docenti scuola</v-col>
            <v-col cols="3"></v-col>
          </v-row>
          <v-row class="font-weight-bold pb-8">
            <v-col cols="3">{{ corso.numeroStudenti }} </v-col>
            <v-col cols="3">{{ corso.numeroStudentiDsa }} </v-col>
            <v-col cols="3">{{ corso.numeroDocenti }}</v-col>
            <v-col cols="3"></v-col>
          </v-row>

          <v-divider class="my-4"></v-divider>
          <!-- Component elenco lezioni -->
          <v-row class="my-4">
            <v-col>
              <h3 class="blue--text">Elenco lezioni</h3>
            </v-col>
          </v-row>
          <ElencoLezioniComponent
            :id-corso="corso.id"
            :capofila="capofila"
          ></ElencoLezioniComponent>
          <v-row class="my-4">
            <v-col>
              <h3 class="blue--text">Elenco studenti con attestato</h3>
            </v-col>
          </v-row>
          <StudentiConCertificatoComponent
            :id-corso="corso.id"
          ></StudentiConCertificatoComponent>
          <!--SEZIONE ACCORDO E SOTTO ACCORDO -->
          <v-row class="my-4">
            <v-col>
              <h3 class="blue--text">Accordo</h3>
            </v-col>
          </v-row>
          <v-card v-for="(accordo, index) in corso.accordi" :key="index">
            <v-card-text>
              <v-row class="">
                <v-col cols="4">ID Accordo </v-col>
                <v-col cols="4">Data inserimento accordo </v-col>
                <v-col cols="4">Anno scolastico </v-col>
              </v-row>
              <v-row class="font-weight-bold">
                <v-col cols="4">{{ accordo.id }} </v-col>
                <v-col cols="4"
                  >{{ accordo.fileAccordo.dataInserimento | formatDate }}
                </v-col>
                <v-col cols="4">{{ corso.anno }}/{{ corso.anno + 1 }} </v-col>
              </v-row>
              <v-row class="">
                <v-col cols="4">ID Sotto accordo </v-col>
                <v-col cols="4">Data inserimento sotto accordo </v-col>
                <v-col cols="4"> </v-col>
              </v-row>
              <v-row class="font-weight-bold">
                <v-col cols="4"
                  ><span v-if="accordo.sottoAccordo">{{
                    accordo.sottoAccordo.id
                  }}</span>
                  <span v-else> Sotto-accordo non presente </span>
                </v-col>
                <v-col cols="4"
                  ><span v-if="accordo.sottoAccordo">{{
                    accordo.fileAccordo.dataInserimento | formatDate
                  }}</span>
                  <span v-else> -- </span>
                </v-col>
                <v-col cols="4"> </v-col>
              </v-row>
              <v-divider class="mt-4"></v-divider>
              <v-row class="font-weight-bold my-2">
                <v-col cols="6"
                  >{{ accordo.istituto.denominazioneScuola }}
                </v-col>
                <v-col cols="6" align="end">
                  <a
                    @click="
                      downloadPDF(accordo, accordo.fileAccordo.nomeOriginale)
                    "
                  >
                    Scarica accordo
                    <v-icon> mdi-tray-arrow-down </v-icon>
                  </a>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>
          </v-card>
          <FormatCorso :corso="corso" :capofila="capofila"></FormatCorso>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>
<script>
import ElencoLezioniComponent from "@/components/Rendiconti/ElencoLezioniComponent";
import StudentiConCertificatoComponent from "@/components/Rendiconti/StudentiConCertificato";
import FormatCorso from "@/components/Rendiconti/FormatCorso";
import CodiceFiscale from "codice-fiscale-js";
import RichiesteRepository from "@/api/istituto/RichiesteRepository";

export default {
  name: "documentazioneAttivitaRendicontoComponent",
  components: {
    ElencoLezioniComponent,
    StudentiConCertificatoComponent,
    FormatCorso,
  },
  props: {
    rendiconto: {
      type: Object,
      required: true,
    },
    capofila: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loadingPage: false,
    legaleRappresentante: null,
    corsiValidati: [],
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.corsiValidati = this.rendiconto.corsiRendiconto.map((element) => {
        return element.corso;
      });
      this.getLegaleRappresentanteAttivo();
      this.loadingPage = true;
    },
    async getLegaleRappresentanteAttivo() {
      const idStrutture = this.rendiconto.idStrutture;
      const idProgramma = this.rendiconto.programma.id;
      const legaleRappresentante =
        await RichiesteRepository.getLegaleRappresentante(
          idStrutture,
          idProgramma
        );
      if (legaleRappresentante) {
        this.getDatiLegaleRappresentante(legaleRappresentante);
      }
    },
    getDatiLegaleRappresentante(legaleRappresentante) {
      let datiMancantiLegale = null;
      try {
        datiMancantiLegale = new CodiceFiscale(
          legaleRappresentante.codiceFiscaleRappresentante
        );
        // eslint-disable-next-line no-empty
      } catch (err) {}

      if (datiMancantiLegale) {
        let comuneNascita = datiMancantiLegale.birthplace.nome;
        let dataNascita = datiMancantiLegale.birthday;
        this.legaleRappresentante = legaleRappresentante;
        this.legaleRappresentante.comuneNascita = comuneNascita;
        this.legaleRappresentante.dataNascita = new Date(
          dataNascita
        ).toLocaleDateString();
      } else {
        this.legaleRappresentante = legaleRappresentante;
      }
    },
  },
};
</script>
