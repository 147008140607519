<template>
  <v-container>
    <v-main>
      <StepRendicontazioni :numero-step="7" />
    </v-main>
    <v-main v-if="loadingPage">
      <!-- card riepilogo generale rendiconto -->
      <v-card elevation="4">
        <h2>Dichiarazione</h2>
        <RiepilogoGeneraleRendiconto class="mt-12" :rendiconto="rendiconto" />
        <DettaglioStep2 :rendiconto="rendiconto" />
      </v-card>
      <v-card elevation="4" class="mt-12">
        <h2>Documentazione attività svolta</h2>
        <DocumentazioneAttivitaRendicontoComponent
          class="mt-12"
          :rendiconto="rendiconto"
          :capofila="capofila"
        />
      </v-card>
      <!-- trasmissione del rendiconto  -->
      <v-card
        elevation="4"
        class="mt-12"
        v-if="
          rendiconto.programma.numeroAlunniCoinvolti + alunniIncremento >=
          totStudentiConCertificato
        "
      >
        <v-card-actions v-if="rendiconto.stato.id === 1">
          <v-btn class="primary" @click="dialogConferma = true">
            Trasmetti Rendiconto</v-btn
          >
          <!-- <v-alert type="info" width="500" class="ml-4">
            La chiusura del rendiconto sarà presto disponibile.</v-alert
          > -->
          <dialog-conferma
            @close-dialog="dialogConferma = false"
            :dialog.sync="dialogConferma"
            @callback="onSubmitTrasmissioneRendiconto"
            text-conferma="Si sta per trasmettere il rendiconto di progetto all’Organo di competenza del MUR. <br /> Sei sicuro/a di confermare quest''azione?"
          />
          <v-snackbar
            :color="snackbar.color"
            v-model="snackbar.value"
            max-width="200"
            max-height="200"
          >
            {{ snackbar.text }}</v-snackbar
          >
        </v-card-actions>
        <v-card-actions v-else>
          <v-alert
            type="info"
            v-html="'Il rendiconto è stato già trasmesso'"
            width="500"
          ></v-alert>
        </v-card-actions>
      </v-card>
      <v-card v-else class="mt-12">
        <v-alert type="warning">
          Impossibile procedere alla trasmissione del rendiconto. Il numero di
          studenti con attestati di frequenza supera il numero di alunni del
          target.
        </v-alert>
      </v-card>
    </v-main>
    <div v-else>
      <v-progress-linear color="blue" indeterminate></v-progress-linear>
    </div>
  </v-container>
</template>
<script>
import StepRendicontazioni from "@/components/Rendiconti/StepRendicontazioni";
import RendicontiRepository from "@/api/istituto/RendicontiRepository";
import { mapGetters, mapState } from "vuex";
import DialogConferma from "@/components/DialogConferma";
import RiepilogoGeneraleRendiconto from "@/components/Rendiconti/RiepilogoGeneraleRendiconto";
import DettaglioStep2 from "@/components/Rendiconti/DettaglioStep2";
import DocumentazioneAttivitaRendicontoComponent from "@/components/DocumentazioneAttivitaRendicontoComponent";
import ProgrammiRepository from "@/api/istituto/ProgrammiRepository";
/*import DialogConferma from "@/components/DialogConferma";*/

export default {
  name: "riepilogoTrasmissioneRendiconto",
  components: {
    DocumentazioneAttivitaRendicontoComponent,
    DialogConferma,
    StepRendicontazioni,
    RiepilogoGeneraleRendiconto,
    DettaglioStep2,
  },
  props: {
    capofila: {
      type: String,
      required: true,
    },
    operazione: {
      type: String,
      required: true,
      default: "modifica",
    },
  },
  computed: {
    ...mapState(["idStrutture", "anno", "user", "idRendiconto"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleConsulenza",
      "isRoleLegale",
    ]),
  },
  watch: {
    idStrutture() {
      return this.initialize();
    },
    anno() {
      return this.initialize();
    },
  },
  created() {
    this.initialize();
  },
  data: () => ({
    alunniIncremento: 0,
    targetIncrementato: null,
    rendiconto: null,
    loadingPage: false,
    dialogConferma: false,
    snackbar: { text: null, color: null, value: null },
    totStudentiConCertificato: null,
  }),
  methods: {
    initialize() {
      if (this.capofila && this.idStrutture && this.idRendiconto) {
        this.getRendiconto();
        this.getIncrementoTarget();
      }
    },
    async getRendiconto() {
      this.rendiconto = await RendicontiRepository.getSingoloRendiconto(
        this.idStrutture,
        this.idRendiconto
      );

      if (this.rendiconto) {
        await this.getNumeroStudentiConCertificato();
      }
    },
    async onSubmitTrasmissioneRendiconto() {
      await RendicontiRepository.modificaRendiconto(
        this.idStrutture,
        this.idRendiconto,
        7,
        null
      ).then((data) => {
        if (data) {
          this.setPropertySnackbar(true, "Rendiconto trasmesso con successo");
          setTimeout(() => this.goBackToHome(), 2000);
        }
      });
    },
    setPropertySnackbar(esito, testo) {
      this.snackbar.color = esito ? "success" : "red accent-2";
      this.snackbar.text = testo;
      this.snackbar.value = true;
    },
    goBackToHome() {
      this.$router.push({
        name: "RendicontiView",
        params: { capofila: this.capofila },
      });
    },
    /* funzione da cui estrapolo il numero totale di studenti con certificato,
     associati a corsi rendicontati ( incluso  quello in cui sono))
     */
    async getNumeroStudentiConCertificato() {
      // se sono in modifica il conteggio non includerà quelli del rendiconto che si sta modificando
      this.totStudentiConCertificato =
        await RendicontiRepository.getNumeroStudentiConCertificato(
          this.idStrutture,
          this.anno,
          this.capofila
        );
      //this.controlloNumeroAlunniOltreilTarget();
      this.loadingPage = true;
    },
    async getIncrementoTarget() {
      this.targetIncrementato = await ProgrammiRepository.incrementoTarget(
        this.idStrutture,
        this.anno
      );
      this.alunniIncremento = this.targetIncrementato
        ? this.targetIncrementato.alunniTarget
        : 0;
    },
    /*controlloNumeroAlunniOltreilTarget() {
      if (this.rendiconto && this.totStudentiConCertificato != null) {
        /*console.log(
          this.totStudentiConCertificato <=
            parseInt(this.rendiconto.programma.numeroAlunniCoinvolti)
        );
        return (
          this.totStudentiConCertificato <=
          parseInt(this.rendiconto.programma.numeroAlunniCoinvolti)
        );
      } else {
        return false;
      }
    },*/
  },
};
</script>
<style scoped>
h2 {
  color: #1a237e;
  margin-left: 1%;
}
</style>
