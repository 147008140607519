<template>
  <v-app>
    <v-card v-if="isPublicPage()">
      <v-main>
        <v-container fluid style="height: 100%">
          <router-view> <p v-html="$route.params.toString()" /> </router-view>
        </v-container>
      </v-main>
    </v-card>
    <v-card
      class="overflow-hidden o-card-drawer"
      v-else-if="
        $store.getters.isRoleLegale ||
        $store.getters.isRoleReferenteProgramma ||
        $store.getters.isRoleConsulenza ||
        $store.getters.isRoleMinistero ||
        $store.getters.isRoleIstituto ||
        $store.getters.isRoleReferenteIstituto ||
        $store.getters.isRoleReferenteCorsi
      "
    >
      <v-navigation-drawer absolute temporary v-model="drawer">
        <NavigationDrawer />
      </v-navigation-drawer>

      <v-app-bar color="#06c" dark>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>Orientamento 2026 - PNRR</v-toolbar-title>
        <v-img
          class="ml-3"
          max-width="170px"
          :src="require('@/assets/logo-orientamento-2026.png')"
        ></v-img>
        <v-spacer></v-spacer>
        <SelezionaStruttura
          v-if="
            $store.getters.isRoleMinistero ||
            $store.getters.isRoleConsulenza ||
            $store.getters.isRoleLegale ||
            $store.getters.isRoleLegaleProgramma ||
            $store.getters.isRoleLegaleCorso
          "
        />
        <SelezionaAnnoAccademico />
        <AbilitaScritturaConsulenza />
        <ChipRole />
        <InfoPersona />
      </v-app-bar>

      <v-main>
        <AlertIdStrutture class="mx-3 my-3" />
        <v-container fluid style="height: 100%">
          <router-view></router-view>
        </v-container>
      </v-main>

      <v-footer app>
        <footer-component />
      </v-footer>
    </v-card>
  </v-app>
</template>

<script>
import axios from "axios";
import NavigationDrawer from "@/components/NavigationDrawer";
import InfoPersona from "@/components/InfoPersona";
import SelezionaStruttura from "@/components/SelezionaStruttura";
import AlertIdStrutture from "@/components/AlertIdStrutture";
import FooterComponent from "@/components/Common/FooterComponent";
import ChipRole from "@/components/ChipRole";
import AbilitaScritturaConsulenza from "@/components/AbilitaScritturaConsulenza";
import SelezionaAnnoAccademico from "@/components/SelezionaAnnoAccademico";

export default {
  name: "app",
  components: {
    SelezionaAnnoAccademico,
    AbilitaScritturaConsulenza,
    ChipRole,
    SelezionaStruttura,
    InfoPersona,
    NavigationDrawer,
    AlertIdStrutture,
    FooterComponent,
  },
  props: ["keycloak"],
  data() {
    return {
      drawer: false,
    };
  },
  mounted() {
    this.getUserInfo();
    this.$store.dispatch("loadKeycloak", this.keycloak);
  },
  methods: {
    async getUserInfo() {
      const result = await axios.get(
        process.env.VUE_APP_API_URL + "/api/user/info"
      );
      console.log(parseInt(result.data.struttura[0]));
      await this.$store.dispatch("loadUser", result.data);
      await this.$store.dispatch(
        "loadIdStrutture",
        result.data.struttura.length ? parseInt(result.data.struttura[0]) : null
      );
    },
    isPublicPage() {
      const route = this.$route;
      return (
        route.path.includes("compilazione") &&
        route.path.includes("questionari")
      );
    },
  },
};
</script>

<style>
.o-card-drawer {
  height: 100%;
}
</style>
