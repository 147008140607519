<template>
  <v-container>
    <v-row class="my-4">
      <v-col col="8" class="px-8">
        <v-text-field
          solo
          v-model="ricerca"
          append-icon="mdi-magnify"
          label="Cerca per codice fiscale, nome o cognome"
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      v-model="studentiSelezionati"
      :items="studenti"
      :search="ricerca"
      class="elevation-1"
      :loading="loading"
      loading-text="Caricamento dati"
      loader-height="2"
      ><!-- eslint-disable -->
            <template v-slot:item.istituto="{ item }">
              {{ item.istituto ?? item.denominazioneScuola }}
            </template>
            <template v-slot:item.tipoIstituto="{ item }">
              {{ item.tipoIstituto ?? item.tipologiaGradoIstruzioneScuola}}
            </template>
            <template v-slot:item.regione="{ item }">
              {{ item.regione }}
            </template>
            <template v-slot:item.totOre="{ item }">
              {{ getTotOre(item.idStudente) }}
            </template>
            <template v-slot:item.percentualeOre="{ item }">
              <span style="color:red" v-if="getPercentualeOre(item.idStudente)<70" >
                {{ getPercentualeOre(item.idStudente) }} %
              </span>
              <span v-else>
                {{ getPercentualeOre(item.idStudente) }} %
              </span>
            </template>
            <template v-slot:item.seleziona="{ item }">
              <v-checkbox
                  v-if="[2, 4, 5, 7, 8, 9].includes(item.esito)"
                  @change="selezionaStudente(item)"
              >
              </v-checkbox>
            </template>
            <template v-slot:item.alertErrore="{ item }">
              <template v-if="item.esito === 1 && item.codiceErrore === '000'">
                <template v-if="item.autoconvalidato">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                          v-on="on"
                          large
                          color="success"
                      >mdi-lock-open-check</v-icon
                      >
                    </template>
                    <span> Studente autoconvalidato con sblocco manuale del codice fiscale</span>
                  </v-tooltip>
                </template>
              </template>
              <template v-else-if="item.esito === null">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                        v-on="on"
                        large
                        color="warning"
                    >mdi-alert-box</v-icon
                    >
                  </template>
                  <span> Codice fiscale in attesa di convalida</span>
                </v-tooltip>
              </template>
              <template v-else>
                <v-tooltip v-if="checkConvalida(item)" top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                        v-if="item.esito !== null"
                        v-on="on"
                        large
                        color="error"
                    >mdi-alert-box</v-icon
                    >
                  </template>
                  <span>{{descrizione(item)}}</span>
                </v-tooltip>
              </template>
            </template>
            <!-- eslint-enable -->
    </v-data-table>
    <template
      v-if="
        presenzaNonFrequentanti && (isRoleReferenteCorsi || isRoleConsulenza)
      "
    >
      <div v-if="finestraCompilazioneAttiva" class="px-4">
        <v-checkbox
          v-model="accetto"
          label="Si conferma di aver verificato con la Scuola la correttezza delle informazioni inserite nel portale 'Orientamento 2026'
           per gli alunni e le alunne selezionate e di aver dato indicazioni alla Scuola stessa di assicurare
            l'aggiornamento di quanto riportato nell'Anagrafe degli Studenti del MIM."
        >
        </v-checkbox>
      </div>
      <div v-if="finestraCompilazioneAttiva" class="pb-6">
        <v-btn
          class="primary"
          :disabled="!accetto && pulsanteBloccato"
          @click="dialogSalva = true"
          >Sblocca i codici fiscali
        </v-btn>
      </div>
    </template>
    <dialog-conferma
      @callback="sbloccaStudenti"
      :dialog.sync="dialogSalva"
      @close-dialog="dialogSalva = false"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import corsiRepository from "@/api/istituto/CorsiRepository";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";
import DialogConferma from "@/components/DialogConferma";
import CorsiRepository from "@/api/istituto/CorsiRepository";

export default {
  name: "studentiDettaglioComponent",
  props: {
    capofila: {
      type: String,
      required: true,
    },
    idCorso: {
      type: Number,
      required: true,
    },
    finestraCompilazioneAttiva: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    DialogConferma,
  },
  data: () => ({
    abilitazione: {
      idRuolo: null,
    },
    ricerca: null,
    inAttesa: false,
    sbloccoEffettuato: 0,
    presenzaNonFrequentanti: false,
    accetto: false,
    isMioCorso: false,
    validato: true,
    pulsanteBloccato: true,
    studentiSelezionati: [],
    loadingPage: true,
    firmato: 0,
    dialogSalva: null,
    dialogProsegui: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    certificati: null,
    lezioni: [],
    studenti: [],
    datiCorso: null,
    headers: [
      {
        text: "",
        sortable: false,
        value: "seleziona",
      },
      {
        text: "",
        sortable: false,
        value: "alertErrore",
      },
      {
        text: "Cognome",
        align: "start",
        value: "cognome",
      },
      { text: "Nome", value: "nome" },
      { text: "Codice fiscale", value: "cf" },
      { text: "Istituto scolastico", sortable: false, value: "istituto" },
      { text: "Tipologia istituto", sortable: false, value: "tipoIstituto" },
      { text: "Regione", sortable: false, value: "regione" },
      {
        text: "Tot ore freq.",
        sortable: false,
        align: "center",
        value: "totOre",
      },
      {
        text: "% ore freq.",
        sortable: false,
        align: "center",
        value: "percentualeOre",
      },
    ],
  }),
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["idStrutture", "anno", "user"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleConsulenza",
      "isRoleReferenteCorsi",
      "isRoleLegale",
      "isRoleLegaleCorso",
      "isRoleLegaleProgramma",
      "isRoleReferenteProgramma",
      "isRoleReferenteIstituto",
      "isRoleReferenteIstitutoCorso",
    ]),
    loading: function () {
      return this.loadingPage;
    },
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
    sbloccoEffettuato() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        //this.loadingPage = true;
        this.getLezioniCorso();
        this.getDatiCorso();
        this.getListaStudentiCorso();
        this.getAndamentoCertificati();
        this.getAbilitatoAllaFirma();
        //this.loadingPage = false;
      }
    },
    async getDatiCorso() {
      this.datiCorso = await corsiRepository.getCorsoOrientamento(
        this.idStrutture,
        this.anno,
        this.idCorso
      );
      this.isMioCorso = this.datiCorso.referente.id === this.user.id;
      if (
        (this.datiCorso.validato == null ||
          this.datiCorso.validato === false) &&
        this.isRoleReferenteIstituto
      ) {
        this.validato = false;
      }
    },
    async getListaStudentiCorso() {
      this.loadingPage = true;
      this.studenti = await corsiRepository.getStudentiCorso(
        this.idStrutture,
        this.idCorso
      );
      if (this.datiCorso.accordo) {
        let istituto =
          this.datiCorso.accordo.istituto.denominazioneIstitutoRiferimento ??
          null;
        let tipoIstituto =
          this.datiCorso.accordo.istituto.tipologiaGradoIstruzioneScuola ??
          null;
        let regione = this.datiCorso.accordo.istituto.regione ?? null;
        this.studenti.forEach(function (studente) {
          studente.istituto = istituto;
          studente.tipoIstituto = tipoIstituto;
          studente.regione = regione;
        });
      }
      this.inAttesa = this.studenti.some((item) => {
        if (item.esito == null) {
          return true;
        }
      });
      if (this.inAttesa) {
        this.$emit("inAttesa", this.inAttesa);
      }

      this.presenzaNonFrequentanti = this.studenti.some((p) =>
        [2, 4, 5, 7, 8, 9].includes(p.esito)
      );
      this.loadingPage = false;
    },
    async getLezioniCorso() {
      this.lezioni = await corsiRepository.getLezioniCorso(
        this.idStrutture,
        this.idCorso
      );
    },
    getTotOre(idStudente) {
      let totOre = 0;
      this.lezioni.forEach(function (lezione) {
        lezione.lezioniStudenti.forEach(function (lezioneStudente) {
          if (lezioneStudente.studente.id === idStudente) {
            totOre = totOre + lezioneStudente.oreSvolte;
          }
        });
      });
      return totOre;
    },
    getPercentualeOre(idStudente) {
      let totOre = this.getTotOre(idStudente);
      //let totOreCorso = 15;
      let totOreCorso = 0;
      this.lezioni.forEach(function (lezione) {
        totOreCorso = totOreCorso + lezione.oreErogate;
      });
      //calcolo del tasso percentuale: (oreStudente · 100)/TotOreCorso senza decimali
      return Math.trunc((totOre * 100) / totOreCorso);
    },
    async getAndamentoCertificati() {
      this.certificati = await corsiRepository.getNumeroCertificati(
        this.idStrutture,
        this.idCorso
      );
    },
    async getAbilitatoAllaFirma() {
      this.abilitazione = await IstitutoRepository.getAbilitaFirma(
        this.idStrutture,
        this.anno
      );
    },
    selezionaStudente(item) {
      if (this.studentiSelezionati.includes(item.idStudente)) {
        let index = this.studentiSelezionati.indexOf(item.idStudente);
        this.studentiSelezionati.splice(index, 1);
      } else {
        this.studentiSelezionati.push(item.idStudente);
      }
      this.pulsanteBloccato = this.studentiSelezionati.length >= 0;
    },
    async sbloccaStudenti() {
      let studentiSelezionati = { ...this.studentiSelezionati };
      const data = new FormData();
      data.append("studentiSelezionati", JSON.stringify(studentiSelezionati));
      try {
        await CorsiRepository.sbloccaConvalidaStudenti(
          this.idStrutture,
          this.idCorso,
          data
        );
        this.sbloccoEffettuato += 1;
        this.accetto = false;
        this.snackbarText =
          "Gli studenti selezionati sono stati autoconvalidati";
        this.snackbarColor = "success";
        this.snackbar = true;
      } catch (e) {
        console.log(e);
        if (e.response.status === 400) {
          this.snackbarText = "Selezionare gli studenti da convalidare";
          this.snackbarColor = "red accent-2";
          this.snackbar = true;
        } else {
          this.snackbarText =
            "Problema durante lo sblocco delle convalide, contattare il supporto se il problema persiste";
          this.snackbarColor = "red accent-2";
          this.snackbar = true;
        }
      }
    },
    checkConvalida(studente) {
      if (studente.codiceErrore !== "000" || parseInt(studente.esito) !== 1) {
        return true;
      }
      return false;
    },
    descrizione(studente) {
      if (studente.codiceErrore !== "000") {
        if (Number(studente.esito) > 1) {
          return "Verifica non ammissibile, " + studente.descrizioneErrore;
        }
        return studente.descrizioneErrore;
      }
      switch (studente.esito) {
        case 2:
          return "Non frequentante";
        case 3:
          return "Verifica non ammissibile";
        case 4:
          return "Frequenta la scuola ma un anno di corso diverso da quello specificato";
        case 5:
          return " L’alunno è stato frequentante la scuola (Interruzione di frequenza oppure è stato trasferito in altra scuola)";
        case 6:
          return "Codice fiscale non presente in anagrafica";
        case 7:
          return "Frequentante altro plesso dello stesso istituto principale";
        case 8:
          return "Frequentante altro plesso dello stesso istituto principale ma con anno di corso differente";
        case 9:
          return "Traferito/Interruzione di frequenza altro plesso stesso istituto principale";

        default:
          return "Non ammissibile al finanziamento";
      }
    },
  },
};
</script>

<style scoped></style>
