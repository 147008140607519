import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/HomeView.vue";
import GestioneUtenti from "@/views/GestioneUtenti";
import TargetAnnuale from "@/views/TargetAnnuale";
import ConvenzioniView from "@/views/ConvenzioniView";
import ProfiloUtenteView from "@/views/ProfiloUtenteView";
import ScrivaniaView from "@/views/ScrivaniaView";
import ProgrammaOrientamentoView from "@/views/ProgrammaOrientamentoView";
import ModificaProgrammaView from "@/views/ModificaProgrammaView";
import ScuoleAccordiView from "@/views/ScuoleAccordo/ScuoleAccordiView";
import AggiungiScuolaAccordiView from "@/views/ScuoleAccordo/AggiungiScuolaAccordiView";
import CorsiView from "@/views/CorsiView";
import CensimentoCorsiView from "@/views/CensimentoCorsiView";
import ModificaCensimentoCorsiView from "@/views/ModificaCensimentoCorsiView";
import ErogazioneCorsiView from "@/views/ErogazioneCorsiView";
import RegistroPresenzeView from "@/views/RegistroPresenzeView";
import RichiesteAnticipoSaldoView from "@/views/RichiestaAnticipoSaldo/RichiesteAnticipoSaldoView";
import InserisciRichiestaAnticipoSaldoView from "@/views/RichiestaAnticipoSaldo/InserisciRichiestaAnticipoSaldoView";
import CertificatiCorsiView from "@/views/CertificatiCorsiView";
import GestisciCertificatiCorsiView from "@/views/GestisciCertificatiCorsiView";
import AggiungiSottoAccordoView from "@/views/ScuoleAccordo/AggiungiSottoAccordoView.vue";
import TerminaCorsoView from "@/views/TerminaCorsoView";
import IndicatoriComuniView from "@/views/IndicatoriComuniView.vue";
import RendicontiView from "@/views/Rendiconti/RendicontiView";
import DettaglioRendicontoView from "@/views/Rendiconti/DettaglioRendicontoView";
import DettaglioCorsoView from "@/views/Rendiconti/DettaglioCorsoView";
import DettaglioIntegrazioniModificheView from "@/views/Rendiconti/DettaglioIntegrazioniModificheView";
import DettaglioVerbaliView from "@/views/Rendiconti/DettaglioVerbaliView";
import InserimentoRendiconto from "@/views/Rendiconti/InserimentoRendiconto";
import DichiarazioneRendiconto from "@/views/Rendiconti/DichiarazioneRendiconto";
import CheckListRendicontiView from "@/views/Rendiconti/CheckListRendicontiView";
import DsanDnshRendiconto from "@/views/Rendiconti/DsanDnshRendiconto";
import DocumentazioneAttivitaRendiconto from "@/views/Rendiconti/DocumentazioneAttivitaRendiconto";
import FirmaDocumentazioneRendicontoView from "@/views/Rendiconti/FirmaDocumentazioneRendicontoView";
import RiepilogoTrasmissioneRendiconto from "@/views/Rendiconti/RiepilogoTrasmissioneRendiconto";
import DownloadDettaglioVerbaliView from "@/views/Rendiconti/DownloadDettaglioVerbaliView";
import QuestionarioView from "@/views/Questionari/QuestionarioView.vue";
import DichiarazioniTracciabilitaView from "@/views/TracciabilitaFlussiFinanziari/DichiarazioniTracciabilitaView.vue";
import InserisciDichiarazioneTracciabilitaView from "@/views/TracciabilitaFlussiFinanziari/InserisciDichiarazioneTracciabilitaView.vue";
import FirmaDocumentazioneDichiarazioneView from "@/views/TracciabilitaFlussiFinanziari/FirmaDocumentazioneDichiarazioneView.vue";
import DettaglioContoView from "@/views/TracciabilitaFlussiFinanziari/DettaglioContoView.vue";
import ReportImmatricolazioniView from "@/views/ReportImmatricolazioniView.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/gestione-utenti",
    name: "gestione-utenti",
    component: GestioneUtenti,
  },
  {
    path: "/target-annuale",
    name: "target-annuale",
    component: TargetAnnuale,
  },
  {
    path: "/convenzioni",
    name: "convenzioni",
    component: ConvenzioniView,
  },
  {
    path: "/profilo-utente",
    name: "profilo-utente",
    component: ProfiloUtenteView,
  },
  {
    path: "/scrivania/:capofila",
    name: "scrivania",
    component: ScrivaniaView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/programma-orientamento",
    name: "programma-orientamento",
    component: ProgrammaOrientamentoView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/programma-orientamento/inserisci",
    name: "modifica-programma",
    component: ModificaProgrammaView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/scuole-accordi",
    name: "scuole-accordi",
    component: ScuoleAccordiView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/scuole-accordi/aggiungi",
    name: "aggiungi-scuola-accordi",
    component: AggiungiScuolaAccordiView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/corsi",
    name: "corsi",
    component: CorsiView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/corsi/censimento-corsi",
    name: "censimento-corsi",
    component: CensimentoCorsiView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/corsi/censimento-corsi/inserisci",
    name: "modifica-censimento-corsi",
    component: ModificaCensimentoCorsiView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/corsi/erogazione-corsi/",
    name: "erogazioneCorsi",
    component: ErogazioneCorsiView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/corsi/:idCorso/presenze",
    name: "registroPresenze",
    component: RegistroPresenzeView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/richieste-anticipo-saldo",
    name: "richieste-anticipo-saldo",
    component: RichiesteAnticipoSaldoView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/richieste-anticipo-saldo/inserisci",
    name: "inserisci-richiesta-anticipo-saldo",
    component: InserisciRichiestaAnticipoSaldoView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/corsi/certificati/",
    name: "certificati-corsi",
    component: CertificatiCorsiView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/corsi/certificati/gestisci",
    name: "gestisci-certificati-corsi",
    component: GestisciCertificatiCorsiView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/scuole-accordi/:idAccordo/sotto-accordi",
    name: "aggiungiSottoAccordo",
    component: AggiungiSottoAccordoView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/corsi-erogati/:idCorso/termina",
    name: "terminaCorso",
    component: TerminaCorsoView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/indicatori-comuni",
    name: "IndicatoriComuniView",
    component: IndicatoriComuniView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/rendiconti",
    name: "RendicontiView",
    component: RendicontiView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/rendiconti/:idRendiconto/dettaglioRendiconto",
    name: "dettaglioRendiconto",
    component: DettaglioRendicontoView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/rendiconti/:idRendiconto/dettaglioRendiconto/:idCorso/dettaglioCorso",
    name: "dettaglioCorso",
    component: DettaglioCorsoView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/rendiconti/:idRendiconto/dettaglioIntegrazioniModifiche",
    name: "dettaglioIntegrazioniModifiche",
    component: DettaglioIntegrazioniModificheView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/rendiconti/:idRendiconto/dettaglioVerbali",
    name: "dettaglioVerbali",
    component: DettaglioVerbaliView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/rendiconti/inserisci",
    name: "InserimentoRendiconto",
    component: InserimentoRendiconto,
    props: true,
  },
  {
    path: "/scrivania/:capofila/rendiconti/documentazione",
    name: "dichiarazioneRendiconto",
    component: DichiarazioneRendiconto,
    props: true,
  },
  {
    path: "/scrivania/:capofila/rendiconti/checklists",
    name: "checklistRendiconti",
    component: CheckListRendicontiView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/rendiconti/dsan-dnsh",
    name: "dsanDnshRendiconto",
    component: DsanDnshRendiconto,
    props: true,
  },
  {
    path: "/scrivania/:capofila/rendiconti/documentazione-attivita",
    name: "documentazioneAttivitaRendiconto",
    component: DocumentazioneAttivitaRendiconto,
    props: true,
  },
  {
    path: "/scrivania/:capofila/rendiconti/firme-documentazioni",
    name: "firmaDocumentazioneRendiconto",
    component: FirmaDocumentazioneRendicontoView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/rendiconti/riepilogo-rendiconto",
    name: "riepilogoTrasmissioneRendiconto",
    component: RiepilogoTrasmissioneRendiconto,
    props: true,
  },
  {
    path: "/scrivania/:capofila/rendiconti/:idRendiconto/downloadDettaglioVerbali",
    name: "downloadDettaglioVerbali",
    component: DownloadDettaglioVerbaliView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/corsi-orientamento/:idCorso/questionari",
    name: "questionarioView",
    component: QuestionarioView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/tracciabilita-flussi-finanziari",
    name: "DichiarazioniTracciabilitaView",
    component: DichiarazioniTracciabilitaView,
    props: true,
  },
  {
    path: "/scrivania/:capofila/tracciabilita-flussi-finanziari/inserisci-dichiarazione",
    name: "InserisciDichiarazioneTracciabilitaView",
    component: InserisciDichiarazioneTracciabilitaView,
    props: true,
  },
  {
    path: "scrivania/:capofila/tracciabilita-flussi-finanziari/firma-dichiarazione",
    name: "firmaDocumentazioneDichiarazioneView",
    component: FirmaDocumentazioneDichiarazioneView,
    props: true,
  },
  {
    path: "scrivania/:capofila/tracciabilita-flussi-finanziari/dettaglio-conto",
    name: "dettaglioContoView",
    component: DettaglioContoView,
    props: true,
  },
  {
    path: "/immatricolazioni",
    name: "immatricolazioni",
    component: ReportImmatricolazioniView,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
