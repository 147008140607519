import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    keycloak: null,
    user: null,
    idStrutture: null,
    anno: 2023,
    isPartecipanteProgramma: null,
    scrivaniaViewMode: null,
    consulenzaScritturaAbilitata: false,
    idRendiconto: null,
    quadrimestreRendiconti: null,
  },
  getters: {
    isRoleConsulenza(state) {
      return state.user?.roles?.includes("ROLE_CONSULENZA") ?? false;
    },
    isRoleMinistero(state) {
      return state.user?.roles?.includes("ROLE_MINISTERO") ?? false;
    },
    isRoleIstituto(state) {
      return state.user?.roles?.includes("ROLE_ISTITUTO") ?? false;
    },
    isRoleLegale(state) {
      return state.user?.roles?.includes("ROLE_LEGALE_RAPPRESENTANTE") ?? false;
    },
    isRoleReferenteProgramma(state) {
      return state.user?.roles?.includes("ROLE_REFERENTE_PROGRAMMA") ?? false;
    },
    isRoleReferenteIstituto(state) {
      return state.user?.roles?.includes("ROLE_ISTITUTO") ?? false;
    },
    isRoleReferenteCorsi(state) {
      return state.user?.roles?.includes("ROLE_REFERENTE_CORSI") ?? false;
    },
    isRoleReferenteIstitutoCorso(state) {
      return (
        (state.user?.roles?.includes("ROLE_ISTITUTO") ?? false) &&
        (state.user?.roles?.includes("ROLE_REFERENTE_CORSI") ?? false)
      );
    },
    isRoleLegaleCorso(state) {
      return (
        (state.user?.roles?.includes("ROLE_LEGALE_RAPPRESENTANTE") ?? false) &&
        (state.user?.roles?.includes("ROLE_REFERENTE_CORSI") ?? false)
      );
    },
    isRoleLegaleProgramma(state) {
      return (
        (state.user?.roles?.includes("ROLE_LEGALE_RAPPRESENTANTE") ?? false) &&
        (state.user?.roles?.includes("ROLE_REFERENTE_PROGRAMMA") ?? false)
      );
    },
    isUserLoaded(state) {
      return state.user ?? false;
    },
    hasPermessoGestioneUtenti(state) {
      return state.user?.permissions?.includes("ROLE_GESTIONE_UTENTI") ?? false;
    },
    hasPermessoScrittura(state) {
      return state.user?.permissions?.includes("ROLE_SCRITTURA") ?? false;
    },
    isReadOnly(state) {
      // Se sei consulenza
      if (
        (state.user?.roles?.includes("ROLE_CONSULENZA") ?? false) &&
        (state.user?.permissions?.includes("ROLE_SCRITTURA") ?? false)
      ) {
        return !state.consulenzaScritturaAbilitata;
      }
      // Se sei un referente corso
      if (state.user?.roles?.includes("ROLE_REFERENTE_CORSI") ?? false) {
        return false;
      }

      // Se sei un legale rappresentante
      if (state.user?.roles?.includes("ROLE_LEGALE_RAPPRESENTANTE") ?? false) {
        return false;
      }

      if (
        (state.user?.roles?.includes("ROLE_ISTITUTO") ?? false) &&
        (state.user?.permissions?.includes("ROLE_SCRITTURA") ?? false)
      ) {
        return false;
      }

      // utente istituzionale
      return (
        (!state.user?.roles?.includes("ROLE_ISTITUTO") ?? true) ||
        (!state.user?.permissions?.includes("ROLE_SCRITTURA") ?? false)
      );
    },
    isPartecipanteProgramma(state) {
      return state.isPartecipanteProgramma ?? false;
    },
    getUser(state) {
      return state.user;
    },
  },
  mutations: {
    setKeycloak(state, keycloak) {
      state.keycloak = keycloak;
    },
    setUser(state, user) {
      state.user = user;
    },
    setIdStrutture(state, idStrutture) {
      state.idStrutture = idStrutture;
    },
    setIsPartecipanteProgramma(state, isPartecipanteProgramma) {
      state.isPartecipanteProgramma = isPartecipanteProgramma;
    },
    setScrivaniaViewMode(state, scrivaniaViewMode) {
      state.scrivaniaViewMode = scrivaniaViewMode;
    },
    setConsulenzaScritturaAbilitata(state, consulenzaScritturaAbilitata) {
      state.consulenzaScritturaAbilitata = consulenzaScritturaAbilitata;
    },
    setIdRendiconto(state, idRendiconto) {
      state.idRendiconto = idRendiconto;
    },
    setAnnoSelezionato(state, annoSelezionato) {
      state.anno = annoSelezionato;
    },
    setQuadrimestreRendiconti(state, quadrimestreRendiconti) {
      state.quadrimestreRendiconti = quadrimestreRendiconti;
    },
  },
  actions: {
    async loadKeycloak({ commit }, keycloak) {
      commit("setKeycloak", keycloak);
    },
    async loadUser({ commit }, user) {
      commit("setUser", user);
    },
    async loadIdStrutture({ commit }, idStrutture) {
      commit("setIdStrutture", idStrutture);
    },
    async loadIsPartecipanteProgramma({ commit }, isPartecipanteProgramma) {
      commit("setIsPartecipanteProgramma", isPartecipanteProgramma);
    },
    async loadScrivaniaViewMode({ commit }, scrivaniaViewMode) {
      commit("setScrivaniaViewMode", scrivaniaViewMode);
    },
    async toggleConsulenzaScritturaAbilitata({ commit, state }) {
      commit(
        "setConsulenzaScritturaAbilitata",
        !state.consulenzaScritturaAbilitata
      );
    },
    async loadIdRendiconto({ commit, idRendiconto }) {
      commit("setIdRendiconto", idRendiconto);
    },
    async loadQuadrimestreRendiconti({ commit, quadrimestreRendiconti }) {
      commit("setQuadrimestreRendiconti", quadrimestreRendiconti);
    },
  },
  modules: {},
});
