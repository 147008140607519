<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="h1 justify-center"
          >Aggiungi {{ testoTipoFile }}</v-card-title
        >
        <v-card-text>
          <v-container>
            <v-form ref="form" lazy-validation v-model="validForm">
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    v-model="file"
                    :placeholder="`inserisci ${testoTipoFile}`"
                    :label="`inserisci ${testoTipoFile}`"
                    show-size
                    prepend-icon="mdi-paperclip"
                    accept=".pdf"
                    :rules="rulesForm.formFile"
                  >
                  </v-file-input>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-row>
          <v-col cols="7"></v-col>
          <v-col cols="2">
            <v-card-actions>
              <v-btn
                color="primary"
                @click="dialogSalva = true"
                :disabled="!validForm || activeOperation"
              >
                Carica
              </v-btn></v-card-actions
            >
            <dialog-conferma
              @callback="onSubmitUploadFile"
              :dialog.sync="dialogSalva"
              @close-dialog="dialogSalva = false"
            />
            <v-snackbar :color="snackbar.color" v-model="snackbar.value">
              {{ snackbar.text }}</v-snackbar
            >
          </v-col>
          <v-col cols="2">
            <v-card-actions>
              <v-btn color="primary" @click="dialog = false"> Annulla </v-btn>
            </v-card-actions></v-col
          >
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import DialogConferma from "@/components/DialogConferma.vue";
import { mapState } from "vuex";
import ProgrammiRepository from "@/api/istituto/ProgrammiRepository";

export default {
  name: "DialogAggiungiFileComponent",
  computed: {
    ...mapState(["anno"]),
    testoTipoFile() {
      switch (this.tipoFile) {
        case 1:
          return "Atto d'obbligo";
        case 2:
          return "assenza doppio finanziamento";
        case 3:
          return "comunicazione sulla titolarità effettiva";
      }
      return "";
    },
  },
  components: {
    DialogConferma,
  },
  data: () => ({
    dialog: false,
    file: null,
    dialogSalva: false,
    idOggetto: null,
    tipoFile: null,
    rulesForm: {
      formFile: [(v) => !!v || " E' obbligatoria la compilazione del campo"],
    },
    snackbar: {
      text: "",
      color: "",
      value: false,
    },
    validForm: false,
    activeOperation: false,
  }),
  methods: {
    openModal(id, tipoFile) {
      this.dialog = true;
      this.id = id;
      this.tipoFile = tipoFile;
    },
    async onSubmitUploadFile() {
      if (!this.$refs.form.validate()) {
        this.snackbar = {
          value: true,
          text: "Il file non è stato inserito. Impossibile procedere!",
          color: "red accent-2",
        };
      } else {
        this.activeOperation = true;
        const formBody = new FormData();
        formBody.append("file", this.file);
        let idStrutture = this.$store.state.idStrutture;
        let anno = this.$store.state.anno;
        let idProgramma = this.id;
        let idAssenzaDoppiFin = this.id;
        let idComunicazioneTitolarita = this.id;
        switch (this.tipoFile) {
          case 1:
            await ProgrammiRepository.uploadFileAllegato4(
              idStrutture,
              idProgramma,
              formBody
            )
              // eslint-disable-next-line no-unused-vars
              .then((data) => {
                this.activeOperation = false;
                this.$emit("upload-completato");
                this.file = null;
                this.dialog = false;
              })
              .catch((e) => {
                console.log(e);
                this.snackbar = {
                  text: "Un errore è stato riscontrato nella richiesta. Verificare che il formato del file sia pdf. In alternativa contattare il supporto per la segnalazione dell'errore ",
                  color: "red accent-2",
                  value: true,
                };
                this.activeOperation = false;
                this.file = null;
              });
            break;
          case 2:
            await ProgrammiRepository.uploadFileAllegato5(
              idStrutture,
              anno,
              idAssenzaDoppiFin,
              formBody
            )
              // eslint-disable-next-line no-unused-vars
              .then((data) => {
                this.activeOperation = false;
                this.$emit("upload-completato");
                this.file = null;
                this.dialog = false;
              })
              .catch((e) => {
                console.log(e);
                this.snackbar = {
                  text: "Un errore è stato riscontrato nella richiesta. Verificare che il formato del file sia pdf. In alternativa contattare il supporto per la segnalazione dell'errore ",
                  color: "red accent-2",
                  value: true,
                };
                this.activeOperation = false;
                this.file = null;
              });
            break;
          case 3:
            await ProgrammiRepository.uploadFileAllegato6(
              idStrutture,
              anno,
              idComunicazioneTitolarita,
              formBody
            )
              // eslint-disable-next-line no-unused-vars
              .then((data) => {
                this.activeOperation = false;
                this.$emit("upload-completato");
                this.file = null;
                this.dialog = false;
              })
              .catch((e) => {
                console.log(e);
                this.snackbar = {
                  text: "Un errore è stato riscontrato nella richiesta. Verificare che il formato del file sia pdf. In alternativa contattare il supporto per la segnalazione dell'errore ",
                  color: "red accent-2",
                  value: true,
                };
                this.activeOperation = false;
                this.file = null;
              });
            break;
        }
      }
    },
  },
};
</script>

<style scoped>
.alert-warning {
  font-size: 14px;
}
</style>
