<template>
  <v-list nav dense>
    <v-list dense nav>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        :to="item.routerUrl"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-list>
</template>

<script>
import IstitutoRepository from "@/api/istituto/IstitutoRepository";
import { mapState } from "vuex";

export default {
  name: "NavigationDrawer",
  data: () => ({
    loading: true,
    isPartecipanteProgramma: false,
    scrivaniaViewMode: null,
  }),
  computed: {
    items() {
      const item = [];
      item.push({ icon: "mdi-home", title: "Home", routerUrl: "/" });
      if (this.$store.getters.hasPermessoGestioneUtenti) {
        item.push({
          icon: "mdi-account-group",
          title: "Gestione Utenti",
          routerUrl: "/gestione-utenti",
        });
      }
      item.push({
        icon: "mdi-target",
        title: "Target Annuale",
        routerUrl: "/target-annuale",
      });
      if (this.isPartecipanteProgramma) {
        item.push({
          icon: "mdi-handshake",
          title: "Convenzioni",
          routerUrl: "/convenzioni",
        });
      }
      if (this.scrivaniaViewMode > 1) {
        item.push({
          icon: "mdi-desk",
          title: "Scrivania",
          routerUrl: "/profilo-utente",
        });
        item.push({
          icon: "mdi-chart-arc",
          title: "Immatricolazioni",
          routerUrl: "/immatricolazioni",
        });
      }
      return item;
    },
    ...mapState(["idStrutture", "anno"]),
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        this.loading = true;
        this.getIsPartecipanteProgramma();
        this.getTuttoInConvenzione();
        this.loading = false;
      }
    },
    async getIsPartecipanteProgramma() {
      this.isPartecipanteProgramma =
        await IstitutoRepository.isPartecipanteProgrammaOrientamento(
          this.idStrutture,
          this.anno
        );
      await this.$store.dispatch(
        "loadIsPartecipanteProgramma",
        this.isPartecipanteProgramma
      );
    },
    async getTuttoInConvenzione() {
      this.scrivaniaViewMode = await IstitutoRepository.scrivaniaViewMode(
        this.idStrutture,
        this.anno
      );
      await this.$store.dispatch(
        "loadScrivaniaViewMode",
        this.scrivaniaViewMode
      );
    },
  },
};
</script>

<style scoped></style>
