<template>
  <v-container>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" v-if="capofila" />
    <v-row class="mb-4">
      <v-col>
        <h1>Lista dei corsi censiti</h1>
      </v-col>
    </v-row>
    <div class="pa-4" style="background-color: gainsboro">
      <v-row class="pb-4">
        <v-col>
          <h4>Anno Scolastico {{ this.anno }}/{{ this.anno + 1 }}</h4>
        </v-col>
      </v-row>
      <v-card
        elevation="2"
        class="px-4 pb-4"
        v-if="idStrutture !== null"
        :loading="loading"
      >
        <v-row class="mt-2">
          <v-col>
            <h4 v-if="this.capofila === 'istituzione'">Corsi</h4>
            <h4 v-else class="font-weight-bold">
              Corsi in convenzione da <u>Capofila</u>
            </h4>
          </v-col>
        </v-row>

        <div class="mb-6">
          <v-row class="my-4">
            <v-col col="8">
              <v-text-field
                solo
                v-model="ricerca"
                append-icon="mdi-magnify"
                label="Cerca per ID Corso, istituto, stato corso..."
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                :items="corsi"
                :search="ricerca"
                :items-per-page="5"
                class="elevation-1"
                ><!-- eslint-disable -->
                <template v-slot:top>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5">
                        <b>Attenzione,</b> sei sicuro di voler cancellare il corso?  <br />La
                        cancellazione del corso prevede <br />l'eliminazione di tutti
                        i dati inseriti per il <br />corso: studenti, docenti, lezioni
                        e <br /> presenze inserite.
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete"
                          >Annulla</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItemConfirm"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
                <template v-slot:item.istitutoScolastico="{ item }">
                  <span v-html="item.istitutoScolastico"></span>
                </template>
                <template v-slot:item.cup="{ item }">
                  <span>{{item.accordo ? item.accordo.programmaOrientamento.cup : item.accordi[0].programmaOrientamento.cup}}</span>
                </template>
                <template v-slot:item.anno="{ item }">
                  <span>{{item.anno}} / {{item.anno+1}}</span>
                </template>
                <template v-slot:item.dataInizio="{ item }">
                  <span>{{item.dataInizio | formatDate}}</span>
                </template>
                <template v-slot:item.dataFine="{ item }">
                  <span>{{item.dataFine | formatDate}}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <router-link
                    :to="{
                      name: 'modifica-censimento-corsi',
                      params: { capofila: capofila, idCorsoOuter: item.id },
                    }"
                  >
                    <v-icon v-if="finestraCompilazione && checkRuolo()"
                      >mdi-square-edit-outline</v-icon
                    >
                    <v-icon v-else>mdi-eye</v-icon>
                  </router-link>
                </template>
                <template 
                v-if="isRoleReferenteCorsi || isRoleConsulenza"
                v-slot:item.eliminaCorso="{ item }">
                  <v-icon      
                  :disabled="item.terminato == true || !finestraCompilazione"
                   small class="mr-2" @click="deleteItem(item)">
                  mdi-delete
                  </v-icon>
                </template>
                <template v-slot:no-data> nessun risultato </template>
                <!-- eslint-enable -->
              </v-data-table>
              <dialog-message
                :dialog.sync="dialogMessage"
                :text.sync="dialogText"
                @close-dialog="dialogMessage = false"
              />
            </v-col>
          </v-row>
        </div>
        <div class="mt-6" v-if="this.inserimento && finestraCompilazione">
          <router-link
            class="v-btn primary pa-2"
            :to="`/scrivania/${capofila}/corsi/censimento-corsi/inserisci`"
          >
            <v-icon left class="white--text"> mdi-plus </v-icon> Inserisci Corso
          </router-link>
        </div>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";
import corsiRepository from "@/api/istituto/CorsiRepository";
import ProgrammiRepository from "@/api/istituto/ProgrammiRepository";
import DialogMessage from "@/components/DialogMessage.vue";
import CorsiRepository from "@/api/istituto/CorsiRepository";

export default {
  name: "CensimentoCorsiView",
  components: { BreadcrumbComponent, DialogMessage },
  data: () => ({
    ricerca: null,
    idUtente: 0,
    loadingProgrammi: false,
    loadingProgrammiDiCuiPartner: false,
    inserimento: true,
    isDisabled: true,
    isCapofila: false,
    corsi: [],
    programma: [],
    finestraCompilazione: null,
    corsiPartner: [],
    hasConvenzioni: false,
    dialogMessage: false,
    dialogText: "",
    dialogDelete: false,
    headers: [
      {
        text: "ID Corso",
        align: "start",
        sortable: true,
        value: "id",
      },
      { text: "CUP", value: "cup" },
      { text: "Anno Scolastico", value: "anno" },
      {
        text: "Istituto Scolastico",
        value: "istitutoScolastico",
      },
      { text: "Data inizio", value: "dataInizio" },
      { text: "Data fine", value: "dataFine" },
      { text: "Numero studenti", align: "center", value: "numeroStudenti" },
      { text: "Stato", value: "stato" },
      { text: "Azioni", value: "actions", sortable: false },
    ],
  }),
  mounted() {
    if (this.isRoleReferenteCorsi || this.isRoleConsulenza) {
      this.headers.push({
        text: "Elimina Corso",
        value: "eliminaCorso",
        sortable: false,
      });
    }
  },
  props: {
    capofila: {
      type: String,
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["idStrutture", "anno", "user"]),
    ...mapGetters([
      "isRoleReferenteCorsi",
      "isRoleReferenteIstituto",
      "isRoleReferenteIstitutoCorso",
      "isRoleConsulenza",
      "isRoleLegale",
      "isReadOnly",
    ]),
    loading: function () {
      return this.loadingProgrammi;
    },
    breadcrumbs: function () {
      if (this.capofila) {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania/${this.capofila}`,
            exact: true,
            link: true,
          },
          {
            text: "Censimento corsi, erogazione formazione e certificati",
            disabled: false,
            to: `/scrivania/${this.capofila}/corsi`,
            exact: true,
            link: true,
          },
          {
            text: "Censimento corsi",
            disabled: true,
            to: `/scrivania/${this.capofila}/corsi/censimento-corsi`,
            exact: true,
            link: true,
          },
        ];
      }
      return [];
    },
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        this.getCorsi();
        this.getFinestraCompilazione();
      }
    },
    async getCorsi() {
      this.loadingProgrammi = true;
      this.corsi = await corsiRepository.getCorsi(
        this.idStrutture,
        this.anno,
        this.capofila
      );
      this.corsi.map((c) => {
        // formattazione statoCorso
        let dataInizio = new Date(c.dataInizio);
        let dataFine = new Date(c.dataFine);
        const dateOdierna = new Date();
        if (c.terminato) {
          c.stato = "TERMINATO";
        } else if (dateOdierna < dataInizio) {
          c.stato = "DA EROGARE";
        } else if (dateOdierna >= dataInizio && dateOdierna <= dataFine) {
          c.stato = "IN EROGAZIONE";
        } else {
          c.stato = "EROGATO";
        }

        //formattazione IstititoScolastico
        if (c.accordi && c.accordi.length > 0) {
          let testo = "";
          c.accordi.forEach(({ istituto }) => {
            testo += `${istituto.denominazioneScuola} / <br />`;
          });
          c.istitutoScolastico = testo;
        } else {
          c.istitutoScolastico = c.accordo.istituto.denominazioneScuola;
        }
      });
      this.loadingProgrammi = false;
      //Recupero numero di corsi da programma e blocco inserimento quando arrivo a num max corsi
      this.programma = await ProgrammiRepository.getProgrammi(
        this.idStrutture,
        this.anno,
        this.capofila
      );
      if (this.programma.length > 0) {
        this.inserimento = !!(
          this.isRoleReferenteCorsi || this.isRoleConsulenza
        );
      }
    },
    async getFinestraCompilazione() {
      const finestraCompilazione =
        await IstitutoRepository.getFinestraCompilazione(
          this.idStrutture,
          this.anno,
          "corsi-orientamento-ist"
        );
      if (finestraCompilazione !== "") {
        this.finestraCompilazione = finestraCompilazione;
      }
    },
    checkRuolo() {
      if (this.isRoleConsulenza) return true;
      return !!this.isRoleReferenteCorsi;
    },
    async deleteCorso(editedItem) {
      try {
        await CorsiRepository.deleteCorso(this.idStrutture, editedItem.id);
      } catch (e) {
        console.log(e);
      }
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = this.corsi.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      await this.deleteCorso(this.editedItem);
      this.corsi.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    getNomeIstituto(corso) {
      if (corso.accordi && corso.accordi.length > 0) {
        let testo = "";
        corso.accordi.forEach((item) => {
          testo += item.istituto.denominazioneScuola + "/<br />";
        });
        return testo;
      } else {
        return corso.accordo.istituto.denominazioneScuola;
      }
    },
  },
};
</script>

<style scoped></style>
