<template>
  <div>
    <v-card
      v-if="loadingLegaleRappresentante"
      elevation="4"
      class="text-center"
    >
      <h4
        v-html="'Dati rappresentante legale'"
        class="font-weight-bold text-left sottotitolo"
      />
      <v-row>
        <v-col>
          <v-card-text><b>Nome </b> </v-card-text>
        </v-col>
        <v-col>
          <v-card-text> <b> Cognome </b> </v-card-text>
        </v-col>
        <v-col>
          <v-card-text> <b> Codice Fiscale</b> </v-card-text>
        </v-col>
        <v-col>
          <v-card-text> <b> Nato a </b> </v-card-text>
        </v-col>
        <v-col>
          <v-card-text> <b> Data di Nascita </b> </v-card-text></v-col
        >
      </v-row>
      <v-row>
        <v-col>
          <v-card-text>
            {{ legaleRappresentante.nomeRappresentante }}
          </v-card-text>
        </v-col>
        <v-col>
          <v-card-text>
            {{ legaleRappresentante.cognomeRappresentante }}
          </v-card-text></v-col
        >
        <v-col>
          <v-card-text
            >{{ legaleRappresentante.codiceFiscaleRappresentante }}
          </v-card-text>
        </v-col>
        <v-col>
          <v-card-text>
            {{
              legaleRappresentante.comuneNascita
                ? legaleRappresentante.comuneNascita
                : "Non Disponibile"
            }}
          </v-card-text>
        </v-col>
        <v-col>
          <v-card-text>
            {{
              legaleRappresentante.dataNascita
                ? legaleRappresentante.dataNascita
                : "Non Disponibile"
            }}
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import RichiesteRepository from "@/api/istituto/RichiesteRepository";
import CodiceFiscale from "codice-fiscale-js";

export default {
  name: "riepilogoLegaleRappresentante",
  components: {},
  props: {
    idProgramma: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    loadingPage: false,
    legaleRappresentante: null,
    loadingLegaleRappresentante: false,
  }),
  created() {
    this.getLegaleRappresentanteAttivo();
  },
  methods: {
    async getLegaleRappresentanteAttivo() {
      this.legaleRappresentante =
        await RichiesteRepository.getLegaleRappresentante(
          this.$store.state.idStrutture,
          this.idProgramma
        );
      if (this.legaleRappresentante) {
        this.getDatiLegaleRappresentante(this.legaleRappresentante);
      }
    },
    getDatiLegaleRappresentante(legaleRappresentante) {
      let datiMancantiLegale = null;
      try {
        datiMancantiLegale = new CodiceFiscale(
          legaleRappresentante.codiceFiscaleRappresentante
        );
        // eslint-disable-next-line no-empty
      } catch (err) {}

      if (datiMancantiLegale) {
        let comuneNascita = datiMancantiLegale.birthplace.nome;
        let dataNascita = datiMancantiLegale.birthday;
        this.legaleRappresentante.comuneNascita = comuneNascita;
        this.legaleRappresentante.dataNascita = new Date(
          dataNascita
        ).toLocaleDateString();
      } else {
        this.legaleRappresentante = legaleRappresentante;
      }
      this.loadingLegaleRappresentante = true;
    },
  },
};
</script>
<style scoped>
.sottotitolo {
  color: #1a237e;
  margin-left: 1%;
  font-weight: bold;
}
</style>
