import Repository from "../Repository";

export default {
  async getIstituzioniConvenzione(idStrutture, anno) {
    let url = `api/istituto/${idStrutture}/convenzioni/anno/${anno}/strutture-area-geografica`;
    return (await Repository.get(url)).data;
  },
  async getConvenzioni(idStrutture, anno, tipologia) {
    let url = `api/istituto/${idStrutture}/convenzioni/anno/${anno}/${tipologia}`;
    return (await Repository.get(url)).data;
  },
  async getConvenzioneTarget(idStrutture, anno) {
    let url = `api/istituto/${idStrutture}/convenzione-target/anno/${anno}`;
    return (await Repository.get(url)).data;
  },
  async getConvenzionePartner(idStrutture, anno) {
    let url = `api/istituto/${idStrutture}/convenzione-partner/anno/${anno}`;
    return (await Repository.get(url)).data;
  },
  async addConvenzionePartner(idStrutture, anno, data) {
    let url = `api/istituto/${idStrutture}/convenzione-partner/anno/${anno}`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  async createConvenzioneTarget(idStrutture, anno, data) {
    let url = `api/istituto/${idStrutture}/convenzione-target/anno/${anno}`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  async updateConvenzioneTarget(idStrutture, anno, data) {
    let url = `api/istituto/${idStrutture}/convenzione-target/${anno}`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.put(url, data, { headers })).data;
  },
  async deleteConvenzione(idStrutture, idConvenzione) {
    let url = `api/istituto/${idStrutture}/convenzione/${idConvenzione}`;
    return (await Repository.delete(url)).data;
  },
  async uploadConvenzione(idStrutture, anno, data) {
    let url = `api/istituto/${idStrutture}/convenzione/anno/${anno}/file-allegato`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  async downloadConvenzione(idStrutture, anno, nomeFileOriginale) {
    const url = `api/istituto/${idStrutture}/convenzione/anno/${anno}/file-allegato`;
    const res = await Repository.get(url, {
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = nomeFileOriginale;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
};
