<template>
  <v-card v-if="idStrutture !== null" :loading="loading" class="my-4">
    <v-container fluid>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="studenti"
            :items-per-page="5"
            class="elevation-1 table-custom"
            ><!-- eslint-disable -->
            <template v-slot:item.dataNascita="{ item }">
              <span>{{item.dataNascita | formatDate}}</span>
            </template>
            <template v-slot:item.istituto="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{item.denominazioneScuola ?? '--'}}</span>
                </template>
                <span v-if="item.tipologiaGradoIstruzioneScuola">{{ item.tipologiaGradoIstruzioneScuola ?? '--' }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.genereStudente="{ item }">
              <v-edit-dialog
                  :return-value.sync="item.genereStudente"
                  large
                  cancel-text="Annulla"
                  save-text="Salva"
                  persistent
                  @save="salvaGenere(newGenere,item.idStudente)"
              >
                <span>{{ item.genereStudente }}</span> <v-icon small>mdi-square-edit-outline</v-icon>
                <template v-slot:input>
                  <div class="mt-4 text-h6">
                    Modifica genere
                  </div>
                  <v-select
                      v-model="newGenere"
                      :items="genereStudente"
                      item-text="descrizione"
                      item-value="id"
                      label="Genere *"
                      :rules="validazioneCampoNecessario()"
                      :disabled="terminato"
                      required
                  ></v-select>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:item.actions="{ item }">
              <template v-if="terminato">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                        v-on="on"
                        medium
                    >mdi-delete-forever-outline</v-icon
                    >
                  </template>
                  <span>Non è possibile eliminare studenti di un corso terminato!</span>
                </v-tooltip>
              </template>
              <template v-else>
                <v-btn
                  class="mx-2"
                  fab
                  x-small
                  color="error"
                  @click="deleteStudente(item)"
                  :disabled="referenteIstituto || terminato || !finestraCompilazioneAttiva"
                  v-if="!readOnly"
                ><v-icon> mdi-delete-forever-outline </v-icon>
                </v-btn>
              </template>
            </template>
            <template v-slot:item.convalida="{ item }">
              <v-tooltip v-if="checkConvalida(item)" top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-if="item.esito !== null"
                    v-on="on"
                    large
                    color="error"
                    >mdi-close-octagon</v-icon
                  >
                </template>
                <span>{{descrizione(item)}}</span>
              </v-tooltip>
            </template>
            <!-- eslint-enable -->
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <dialog-elimina-studente
      :idStudente="idStudenteDelete"
      :idCorso="idCorso"
      :dialog.sync="dialogDelete"
      @close-dialog="dialogDelete = false"
      @studente-eliminato="keyStudenti += 1"
      @corso-terminato="messaggio()"
    />

    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      shaped
      :color="snackbarColor"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CorsiRepository from "@/api/istituto/CorsiRepository";
import DialogEliminaStudente from "@/components/DialogEliminaStudente";

export default {
  name: "StudentiDelCorsoComponent",
  components: { DialogEliminaStudente },
  props: {
    idCorso: {
      type: Number,
      required: true,
    },
    terminato: {
      type: Boolean,
      required: false,
    },
    finestraCompilazioneAttiva: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    referenteIstituto: false,
    newGenere: null,
    genereStudente: [
      { descrizione: "Maschio", id: "1" },
      { descrizione: "Femmina", id: "2" },
    ],
    generiStudente: [],
    keyStudenti: 0,
    idStudenteDelete: null,
    dialogDelete: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    loadingStudenti: false,
    studenti: [],
    headers: [
      { text: "", value: "convalida", sortable: false },
      { text: "Cognome", value: "cognome" },
      { text: "Nome", value: "nome" },
      { text: "Data di nascita", value: "dataNascita" },
      { text: "Comune di nascita", value: "nomeComuneNascita" },
      { text: "Comune di residenza", value: "nomeComuneResidenza" },
      { text: "Codice Fiscale", value: "cf", sortable: false },
      { text: "Classe", align: "center", value: "classe" },
      { text: "Istituto", align: "center", value: "istituto" },
      { text: "Genere", value: "genereStudente" },
      { text: "Elimina", value: "actions", sortable: false },
    ],
  }),
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["idStrutture", "anno"]),
    ...mapGetters(["isReadOnly", "isRoleIstituto", "isRoleReferenteCorsi"]),
    readOnly() {
      return !!this.isReadOnly;
    },
    loading: function () {
      return this.loadingStudenti;
    },
  },
  watch: {
    keyStudenti() {
      this.initialize();
      this.$emit("studente-eliminato");
    },
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        this.getStudenti();
        this.getGeneriStudente();
        if (this.isRoleIstituto && !this.isRoleReferenteCorsi) {
          this.referenteIstituto = true;
        }
      }
    },
    async getStudenti() {
      this.loadingStudenti = true;
      let idStrutture = this.$store.state.idStrutture;
      this.studenti = await CorsiRepository.getStudentiCorso(
        idStrutture,
        this.idCorso
      );
      this.loadingStudenti = false;
    },
    async deleteStudente(studente) {
      this.idStudenteDelete = studente.idStudente;
      this.dialogDelete = true;
    },
    checkConvalida(studente) {
      if (studente.codiceErrore !== "000" || parseInt(studente.esito) !== 1) {
        return true;
      }
      return false;
    },
    async getGeneriStudente() {
      this.generiStudente = await CorsiRepository.getGeneriStudente();
    },
    descrizione(studente) {
      if (studente.codiceErrore !== "000") {
        if (Number(studente.esito) > 1) {
          return "Verifica non ammissibile, " + studente.descrizioneErrore;
        }
        return studente.descrizioneErrore;
      }
      switch (studente.esito) {
        case 2:
          return "Non frequentante";
        case 3:
          return "Verifica non ammissibile";
        case 4:
          return "Frequenta la scuola ma un anno di corso diverso da quello specificato";
        case 5:
          return " L’alunno è stato frequentante la scuola (Interruzione di frequenza oppure è stato trasferito in altra scuola)";
        case 6:
          return "Codice fiscale non presente in anagrafica";
        case 7:
          return "Frequentante altro plesso dello stesso istituto principale";
        case 8:
          return "Frequentante altro plesso dello stesso istituto principale ma con anno di corso differente";
        case 9:
          return "Traferito/Interruzione di frequenza altro plesso stesso istituto principale";
        default:
          return "Non ammissibile al finanziamento";
      }
    },
    validazioneCampoNecessario() {
      return [(v) => !!v || "Campo richiesto"];
    },
    async salvaGenere(idGenere, idStudente) {
      await CorsiRepository.salvaGenereStudente(
        this.idStrutture,
        idGenere,
        idStudente
      );
      this.keyStudenti += 1;
      this.newGenere = true;
      this.snackbar = true;
      this.snackbarColor = "success";
      this.snackbarText = "Dati salvati";
    },
    messaggio() {
      this.snackbar = true;
      this.snackbarText =
        "ATTENZIONE: Non è possibile eliminare studenti di un corso terminato!";
      this.snackbarColor = "red accent-2";
    },
  },
};
</script>

<style scoped>
.table-custom {
  border-collapse: collapse;
  width: 100%;
}

.table-custom td,
.table-custom th {
  border: 1px solid #ddd;
  padding: 8px;
}

.table-custom tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table-custom tr:hover {
  background-color: #ddd;
}

.table-custom th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #ccc6c6;
  color: white;
}
</style>
