<template>
  <span
    v-if="
      $store.getters.isRoleConsulenza && $store.getters.hasPermessoScrittura
    "
  >
    <v-btn
      class="mx-2"
      fab
      dark
      small
      :color="color"
      @click="toggleScritturaConsulenza"
    >
      <v-icon dark> {{ icon }} </v-icon>
    </v-btn>
  </span>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AbilitaScritturaConsulenza",
  computed: {
    ...mapState(["consulenzaScritturaAbilitata"]),
    color() {
      return this.consulenzaScritturaAbilitata ? "success" : "red";
    },
    icon() {
      return this.consulenzaScritturaAbilitata
        ? "mdi-pencil"
        : "mdi-pencil-lock";
    },
  },
  methods: {
    toggleScritturaConsulenza() {
      this.$store.dispatch("toggleConsulenzaScritturaAbilitata");
    },
  },
};
</script>

<style scoped></style>
