<template>
  <div>
    <v-row class="mt-8">
      <v-col cols="6"><h3 class="blue--text">Format del corso</h3></v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-radio-group
          v-model="tipoFormazione"
          label="Tipologia di formazione erogata:"
          row
          :readonly="readOnly"
        >
          <v-radio
            v-for="tipo in tipiFormazione"
            :key="tipo.id"
            :label="tipo.descrizione"
            :value="tipo"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col>
        <v-radio-group
          v-model="tipoAttivita"
          label="Tipologia di attività:"
          row
          :readonly="readOnly"
        >
          <v-radio
            v-for="tipo in tipiAttivita"
            :key="tipo.id"
            :label="tipo.descrizione"
            :value="tipo"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row class="mt-4 pa-4">
      <v-textarea
        v-model="dettagliCorso"
        outlined
        :readonly="readOnly"
        class="mb-0"
        hint="Massimo 500 caratteri"
        name="dettagliCorso"
        label="Dettagli contenuto del corso"
        counter
      ></v-textarea>
    </v-row>
    <v-row class="mt-8 px-2">
      <v-col cols="6"
        ><h5 class="text-uppercase">Dettaglio del format</h5></v-col
      >
    </v-row>
    <v-row align="center" class="px-5 pt-0">
      <v-col cols="1"
        ><v-checkbox
          :value="dettaglioFormat1"
          v-model="dettaglioFormat1"
          hide-details
          class="shrink mr-2 mt-0"
          :readonly="readOnly"
        ></v-checkbox
      ></v-col>
      <v-col cols="9"
        ><span>
          Conoscere il contesto della formazione superiore e del suo valore in
          una società della conoscenza, informarsi sulle diverse proposte
          formative quali opportunità per la crescita personale e la
          realizzazione di società sostenibili e inclusive
        </span></v-col
      >
      <v-col cols="2"
        ><v-text-field
          v-model.number="valoreDettaglioFormat1"
          label="Rilevanza"
          suffix="%"
          :readonly="readOnly"
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row align="center" class="px-5 pt-0">
      <v-col cols="1"
        ><v-checkbox
          :value="dettaglioFormat2"
          v-model="dettaglioFormat2"
          hide-details
          class="shrink mr-2 mt-0"
          :readonly="readOnly"
        ></v-checkbox
      ></v-col>
      <v-col cols="9"
        ><span>
          Fare esperienza di didattica disciplinare attiva, partecipativa e
          laboratoriale, orientatadalla metodologia di apprendimento del metodo
          scientifico
        </span></v-col
      >
      <v-col cols="2"
        ><v-text-field
          v-model.number="valoreDettaglioFormat2"
          label="Rilevanza"
          suffix="%"
          :readonly="readOnly"
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row align="center" class="px-5 pt-0">
      <v-col cols="1"
        ><v-checkbox
          :value="dettaglioFormat3"
          v-model="dettaglioFormat3"
          hide-details
          class="shrink mr-2 mt-0"
          :readonly="readOnly"
        ></v-checkbox
      ></v-col>
      <v-col cols="9"
        ><span>
          Autovalutare, verificare e consolidare le proprie conoscenze per
          ridurre il divario tra quelle possedute e quelle richieste per il
          percorso di studio di interesse
        </span></v-col
      >
      <v-col cols="2"
        ><v-text-field
          v-model.number="valoreDettaglioFormat3"
          label="Rilevanza"
          suffix="%"
          :readonly="readOnly"
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row align="center" class="px-5 pt-0">
      <v-col cols="1"
        ><v-checkbox
          :value="dettaglioFormat4"
          v-model="dettaglioFormat4"
          hide-details
          class="shrink mr-2 mt-0"
          :readonly="readOnly"
        ></v-checkbox
      ></v-col>
      <v-col cols="9"
        ><span>
          Consolidare competenze riflessive e trasversali per la costruzione del
          progetto di sviluppo formativo e professionale
        </span></v-col
      >
      <v-col cols="2"
        ><v-text-field
          v-model.number="valoreDettaglioFormat4"
          label="Rilevanza"
          suffix="%"
          :readonly="readOnly"
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row align="center" class="px-5 pt-0">
      <v-col cols="1"
        ><v-checkbox
          :value="dettaglioFormat5"
          v-model="dettaglioFormat5"
          hide-details
          class="shrink mr-2 mt-0"
          :readonly="readOnly"
        ></v-checkbox
      ></v-col>
      <v-col cols="9"
        ><span>
          Conoscere i settori del lavoro, gli sbocchi occupazionali possibili
          nonché i lavori futuri sostenibili e inclusivi e il collegamento fra
          questi e le conseguenze e competenze acquisite
        </span></v-col
      >
      <v-col cols="2"
        ><v-text-field
          v-model.number="valoreDettaglioFormat5"
          label="Rilevanza"
          suffix="%"
          :readonly="readOnly"
        ></v-text-field
      ></v-col>
    </v-row>
  </div>
</template>
<script>
import CorsiRepository from "@/api/istituto/CorsiRepository";

export default {
  name: "FormatCorsoComponent",
  components: {},
  data: () => ({
    loadingDettaglio: false,
    tipoFormazione: null,
    tipiFormazione: [],
    tipiAttivita: [],
    tipoAttivita: {
      id: 0,
      descrizione: null,
    },
    dettagliCorso: null,
    dettaglioFormat1: 0,
    dettaglioFormat2: 0,
    dettaglioFormat3: 0,
    dettaglioFormat4: 0,
    dettaglioFormat5: 0,
    valoreDettaglioFormat1: 0,
    valoreDettaglioFormat2: 0,
    valoreDettaglioFormat3: 0,
    valoreDettaglioFormat4: 0,
    valoreDettaglioFormat5: 0,
  }),
  props: {
    capofila: {
      type: String,
      required: true,
    },
    corso: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    readOnly() {
      return true;
    },
  },
  methods: {
    initialize() {
      this.getTipiAttivita();
      this.getTipiFormazione();
      this.tipoFormazione = this.corso.tipoFormazione;
      this.tipoAttivita = this.corso.tipoAttivita;
      this.dettagliCorso = this.corso.dettagliCorso;
      this.dettaglioFormat1 = this.corso.dettaglioFormat1;
      this.dettaglioFormat2 = this.corso.dettaglioFormat2;
      this.dettaglioFormat3 = this.corso.dettaglioFormat3;
      this.dettaglioFormat4 = this.corso.dettaglioFormat4;
      this.dettaglioFormat5 = this.corso.dettaglioFormat5;
      this.valoreDettaglioFormat1 = this.corso.valoreDettaglioFormat1;
      this.valoreDettaglioFormat2 = this.corso.valoreDettaglioFormat2;
      this.valoreDettaglioFormat3 = this.corso.valoreDettaglioFormat3;
      this.valoreDettaglioFormat4 = this.corso.valoreDettaglioFormat4;
      this.valoreDettaglioFormat5 = this.corso.valoreDettaglioFormat5;
    },
    async getTipiAttivita() {
      this.tipiAttivita = await CorsiRepository.getTipiAttivita();
    },
    async getTipiFormazione() {
      this.tipiFormazione = await CorsiRepository.getTipiFormazione();
    },
  },
};
</script>

<style scoped></style>
