<template>
  <v-container v-if="scrivaniaViewMode > 1">
    <v-row class="mb-4 mt-2">
      <v-col>
        <h1>Allegati al programma di orientamento</h1>
      </v-col>
    </v-row>
    <div class="pa-0">
      <v-alert type="warning">
        <h3>Attenzione</h3>
        <p>
          Per l'assegnazione del finanziamento è necessario caricare nella
          presente sezione i documenti previsti dal decreto ministeriale del 29
          maggio 2024, n. 762, art. 5, comma 2. Per quanto riguarda la
          <b> dichiarazione di traccibilità dei flussi finanziari</b>, prevista
          anch'essa dal medesimo articolo e comma, occorre utilizzare l'apposità
          funzionalità del portale
        </p>
      </v-alert>

      <!-- ATTO d'obbligo. -->
      <v-row class="mt-6">
        <v-col>
          <h2 class="text-uppercase">
            atto d'obbligo connesso all' accettazione del finanziamento concesso
          </h2>
          <p class="font-italic">
            Sezione per il caricamento della dichiarazione relativa all’atto
            d’obbligo connesso all’accettazione del finanziamento concesso.
          </p>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col cols="8">
          <v-btn
            color="blue darken-1"
            text
            target="_blank"
            href="https://orientamento2026-backend.cineca.it/uploads/2024/istituto/All_4_accettazione.docx"
            ><span class="text-lowercase"
              >Template ALLEGATO 4 - atto d'obbligo connesso all' accettazione
              del finanziamento concesso</span
            >
            <v-icon color="blue" class="ml-2"
              >mdi-tray-arrow-down</v-icon
            ></v-btn
          >
        </v-col>
        <v-col cols="4"> </v-col>
      </v-row>
      <v-row class="mb-8">
        <v-col>
          <v-data-table
            :headers="headerAttoObbligo"
            :items="programmi"
            hide-default-footer
          >
            <!-- eslint-disable -->
            <template  v-slot:item.capofila="{ item }">
              <span>{{item.capofila ? 'CAPOFILA' : 'Università/AFAM'}}</span>
            </template>
            <template v-slot:item.dataFileAllegato="{ item }">
              <span> {{convertData(item.dataFileAllegato)}}</span>
            </template>
            <template  v-slot:item.azioni="{ item }">
              <v-row>
                <v-col cols="4">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" :disabled="!item.fileAllegato" text @click="getFileAllegato(item)">
                        <v-icon color="blue"> mdi-paperclip</v-icon>
                      </v-btn>
                    </template>
                    <span>Download allegato</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="4" v-if="editable && finestraCompilazione">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" :disabled="item.fileAllegato" text  @click="$refs.DialogAggiungiFileComponent.openModal(item.id, 1)">
                        <v-icon color="blue"> mdi-tray-arrow-up</v-icon>
                      </v-btn>
                      <DialogAggiungiFileComponent ref="DialogAggiungiFileComponent" @upload-completato="refreshComponent">
                      </DialogAggiungiFileComponent>
                    </template>
                    <span>Caricamento file</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="4" v-if="editable && finestraCompilazione">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" :disabled="!item.fileAllegato" text @click="eliminaFile(item,1)">
                        <v-icon color="red"> mdi-delete</v-icon></v-btn>
                    </template>
                    <span>Elimina file</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </template>
            <!-- eslint-enable -->
          </v-data-table>
        </v-col>
      </v-row>

      <!-- DOPPIO FINANZ. -->
      <v-row class="mt-8">
        <v-col>
          <h2 class="text-uppercase">assenza doppio finanziamento</h2>
          <p class="font-italic">
            Sezione per il caricamento della dichiarazione relativa all'assenza
            di doppio finanziamento. Nel caso di progetti promossi in
            convenzione, l'Istituzione Capofila dovrà inserire, oltre alla
            propria dichiarazione appositamente sottoscritta, anche le
            dichiarazioni sottoscritte individualmente da tutte le istituzioni
            partner.
          </p>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col cols="8">
          <v-btn
            color="blue darken-1"
            text
            target="_blank"
            href="https://orientamento2026-backend.cineca.it/uploads/2024/istituto/All_5_doppio_finanziamento.docx"
            ><span class="text-lowercase"
              >Template ALLEGATO 5 - Dichiarazione di assenza di doppio
              finanziamento</span
            >
            <v-icon color="blue" class="ml-2"
              >mdi-tray-arrow-down</v-icon
            ></v-btn
          >
        </v-col>
        <v-col cols="4"> </v-col>
      </v-row>
      <v-row class="mb-8">
        <v-col>
          <v-data-table
            :headers="headerDoppioFin"
            :items="programmi[0].assenzaDoppioFinanziamento"
            hide-default-footer
          >
            <!-- eslint-disable -->
            <template  v-slot:item.capofila="{ item }">
              <span v-if="item.capofila != null">{{item.capofila ? 'CAPOFILA' : 'Università/AFAM'}}</span>
              <span v-else>PARTNER</span>
            </template>
            <template v-slot:item.dataInserimento="{ item }">
              <span> {{convertData(item.dataInserimento)}}</span>
            </template>
            <template  v-slot:item.azioni="{ item }">
              <v-row>
                <v-col cols="4">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" :disabled="!item.fileAllegato" text @click="downloadAllegatiProgramma(item)">
                        <v-icon color="blue"> mdi-paperclip</v-icon>
                      </v-btn>
                    </template>
                    <span>Download allegato</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="4" v-if="editable && finestraCompilazione">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" :disabled="item.fileAllegato" text  @click="$refs.DialogAggiungiFileComponent.openModal(item.id, 2)">
                        <v-icon color="blue"> mdi-tray-arrow-up</v-icon>
                      </v-btn>
                      <DialogAggiungiFileComponent ref="DialogAggiungiFileComponent" @upload-completato="refreshComponent">
                      </DialogAggiungiFileComponent>
                    </template>
                    <span>Caricamento file</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="4" v-if="editable && finestraCompilazione">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" :disabled="!item.fileAllegato" text @click="eliminaFile(item,2)">
                        <v-icon color="red"> mdi-delete</v-icon></v-btn>
                    </template>
                    <span>Elimina file</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </template>
            <!-- eslint-enable -->
          </v-data-table>
        </v-col>
      </v-row>

      <!-- COMUNICAZIONE TITOLARITÀ EFFETTIVA. -->
      <v-row class="mt-6">
        <v-col>
          <h2 class="text-uppercase">
            comunicazione sulla titolarità effettiva
          </h2>
          <p class="font-italic">
            Sezione per il caricamento dei dati sulla titolarità effettiva. Il
            documento caricato in corrispondenza dell'apposito campo "carica
            Comunicazione sulla titolarità effettiva" dovrà contenere, oltre al
            template sulla comunicazione appositamente compilato e sottoscritto,
            anche copia della documentazione da cui è possibile evincere la/le
            titolarità effettiva/e e copia dei documenti di identità e dei
            codici fiscali del/i titolare/i effettivo/i.
          </p>
          <p class="font-italic">
            Nel caso di progetti promossi in convenzione, l'Istituzione Capofila
            dovrà inserire, oltre alla propria dichiarazione, anche le
            dichiarazioni sottoscritte individualmente da tutte le istituzioni
            partner, che al pari di quella della Capofila, dovrà contenere,
            oltre al template sulla comunicazione appositamente compilato e
            sottoscritto, anche copia della documentazione da cui è possibile
            evincere la/le titolarità effettiva/e e copia dei documenti di
            identità e dei codici fiscali del/i titolare/i effettivo/i.
          </p>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="8">
          <v-btn
            color="blue darken-1"
            text
            target="_blank"
            href="https://orientamento2026-backend.cineca.it/uploads/2024/istituto/All_6_titolarita.docx"
            ><span class="text-lowercase">Template </span> comunicazione sulla
            titolarità effettiva enti pubblici
            <v-icon color="blue" class="ml-2"
              >mdi-tray-arrow-down</v-icon
            ></v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            target="_blank"
            href="https://orientamento2026-backend.cineca.it/uploads/2024/istituto/All_6bis_titolarita.docx"
            ><span class="text-lowercase">Template </span> comunicazione sulla
            titolarità effettiva enti privati
            <v-icon color="blue" class="ml-2"
              >mdi-tray-arrow-down</v-icon
            ></v-btn
          >
        </v-col>
        <v-col cols="4"> </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col>
          <v-card
            elevation="2"
            class="mx-auto mb-5 pb-6"
            v-for="(item, index) in programmi[0]
              .comunicazioneTitolaritaEffettiva"
            :key="index"
          >
            <v-card-title
              >{{ item.nomeIstituzionePartner }} -
              <span v-if="item.capofila != null">{{
                item.capofila ? "CAPOFILA" : "Università/AFAM"
              }}</span>
              <span v-else>PARTNER</span></v-card-title
            >
            <v-card-text>
              <!--Azioni File Comunicaizone titolari-->
              <v-row>
                <v-col>
                  <p class="text-decoration-underline">
                    Carica documentazione sulla titolarità effettiva:
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="1">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        :disabled="!item.fileAllegato"
                        text
                        @click="downloadAllegatiProgramma(item)"
                      >
                        <v-icon color="blue"> mdi-paperclip</v-icon>
                      </v-btn>
                    </template>
                    <span>Download allegato</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="1" v-if="editable && finestraCompilazione">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        :disabled="item.fileAllegato"
                        text
                        @click="openModalForItem(item)"
                      >
                        <v-icon color="blue"> mdi-tray-arrow-up</v-icon>
                      </v-btn>
                      <DialogAggiungiFileComponent
                        :ref="`DialogAggiungiFileComponent${item.id}`"
                        @upload-completato="refreshComponent"
                      >
                      </DialogAggiungiFileComponent>
                    </template>
                    <span>Caricamento file</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="1" v-if="editable && finestraCompilazione">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        :disabled="!item.fileAllegato"
                        text
                        @click="eliminaFile(item, 3)"
                      >
                        <v-icon color="red"> mdi-delete</v-icon></v-btn
                      >
                    </template>
                    <span>Elimina file</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="2" v-if="item.dataInserimento">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{
                        convertData(item.dataInserimento)
                      }}</span>
                    </template>
                    <span>Data Trasmissione</span>
                  </v-tooltip>
                </v-col>
                <v-col v-if="editable && finestraCompilazione">
                  <DialogAggiungiTitolareEffettivoComponent
                    :id-comunicazione="item.id"
                    @titolare-aggiunto="refreshComponent"
                  ></DialogAggiungiTitolareEffettivoComponent>
                </v-col>
              </v-row>
              <!-- TITOLARI. -->
              <v-row class="mt-2">
                <v-col>
                  <v-data-table
                    :headers="headerTitolari"
                    :items="item.titolari"
                    hide-default-footer
                  >
                    <!-- eslint-disable -->
                    <template v-slot:item.dataInserimento="{ item }">
                      <span> {{convertData(item.dataInserimento)}}</span>
                    </template>
                    <template v-slot:item.action="{ item }">
                      <div v-if="editable && finestraCompilazione">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                text
                                @click="eliminaFile(item, 4)"
                            >
                              <v-icon color="red"> mdi-delete</v-icon></v-btn
                            >
                          </template>
                          <span>Rimuovi titolare</span>
                        </v-tooltip>
                      </div>
                    </template>
                    <!-- eslint-enable -->
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <div v-if="false">
        <v-row class="mb-0 mt-2">
          <v-col>
            <h3>Scarica documentazione</h3>
            <p class="pt-2">
              Funzionalità per scaricare tutti i documenti caricati in questa
              pagina in un file .zip
            </p>
          </v-col>
        </v-row>
        <v-row class="mb-4 mt-2">
          <v-col>
            <v-btn color="blue white--text">
              Scarica documentazione
              <v-icon color="white" class="ml-2">mdi-tray-arrow-down</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <DialogEliminaFile
      :id-programma="currentIdProgramma"
      :tipo-file="currentTipoFile"
      :dialog.sync="dialogEliminaFile"
      @close-dialog="dialogEliminaFile = false"
      @file-eliminato="refreshComponent"
    >
    </DialogEliminaFile>
    <v-snackbar :color="snackbar.color" v-model="snackbar.value">
      {{ snackbar.text }}</v-snackbar
    >
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ProgrammiRepository from "@/api/istituto/ProgrammiRepository";
import DialogAggiungiFileComponent from "@/components/Programmi/DialogAggiungiFileComponent.vue";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";
import DialogEliminaFile from "@/components/Programmi/DialogEliminaFileComponent.vue";
import DialogAggiungiTitolareEffettivoComponent from "@/components/Programmi/DialogAggiungiTitolareEffettivoComponent.vue";

export default {
  name: "AllegatiProgrammaComponet",
  components: {
    DialogAggiungiTitolareEffettivoComponent,
    DialogEliminaFile,
    DialogAggiungiFileComponent,
  },
  data: () => ({
    currentTipoFile: null,
    currentIdProgramma: null,
    snackbar: {
      text: "",
      color: "",
      value: false,
    },
    dialogEliminaFile: null,
    finestraCompilazione: null,
    isFinestraAttiva: null,
    isCapofila: false,
    attoObbligo: [],
    headerDoppioFin: [
      {
        text: "RUOLO",
        align: "start",
        sortable: false,
        value: "capofila",
      },
      {
        text: "NOME ISTITUZIONE",
        sortable: false,
        value: "nomeIstituzionePartner",
      },
      { text: "DATA TRASMISSIONE", sortable: false, value: "dataInserimento" },
      {
        text: "ASSENZA DOPPIO FINANZIAMENTO",
        sortable: false,
        value: "azioni",
      },
    ],
    headerAttoObbligo: [
      {
        text: "RUOLO",
        align: "start",
        sortable: false,
        value: "capofila",
      },
      {
        text: "NOME ISTITUZIONE",
        sortable: false,
        value: "nomeIstituzione",
      },
      { text: "DATA TRASMISSIONE", sortable: false, value: "dataFileAllegato" },
      { text: "ATTO D'OBBLIGO", sortable: false, value: "azioni" },
    ],
    headerComTitolarita: [
      {
        text: "RUOLO",
        align: "start",
        sortable: false,
        value: "capofila",
      },
      {
        text: "NOME ISTITUZIONE",
        sortable: false,
        value: "nomeIstituzionePartner",
      },
      { text: "DATA TRASMISSIONE", sortable: false, value: "" },
      {
        text: "ASSENZA DOPPIO FINANZIAMENTO",
        sortable: false,
        value: "azioni",
      },
    ],
    headerTitolari: [
      {
        text: "CODICE FISCALE",
        align: "start",
        sortable: false,
        value: "cf",
      },
      {
        text: "DATA TRASMISSIONE",
        sortable: false,
        value: "dataInserimento",
      },
      {
        text: "",
        sortable: false,
        value: "action",
      },
    ],
  }),
  props: {
    editable: {
      type: Boolean,
      required: true,
    },
    capofila: {
      type: String,
      required: true,
    },
    programmi: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["idStrutture", "anno", "scrivaniaViewMode"]),
    ...mapGetters(["isRoleLegale", "isRoleConsulenza", "isReadOnly"]),
    loading: function () {
      return this.loadingProgrammi;
    },
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        this.getFinestraCompilazione();
        //this.getProgramma();
      }
    },
    async getFinestraCompilazione() {
      this.finestraCompilazione = null;
      const finestraCompilazione =
        await IstitutoRepository.getFinestraCompilazione(
          this.idStrutture,
          this.anno,
          "programma-orientamento-ist"
        );
      if (finestraCompilazione !== "") {
        this.finestraCompilazione = finestraCompilazione ?? null;
      }
    },
    eliminaFile(item, tipoFile) {
      this.currentTipoFile = tipoFile;
      this.currentIdProgramma = item.id;
      this.dialogEliminaFile = true;
      //TODO gestire reset variabili current
    },
    async getFileAllegato(programma) {
      this.loadingProgrammi = true;
      await ProgrammiRepository.getFileAllegato(
        this.idStrutture,
        programma.id,
        this.anno,
        programma.fileAllegato.nomeOriginale
      );
      this.loadingProgrammi = false;
    },
    refreshComponent() {
      this.$emit("refresh");
    },
    convertData: function (data) {
      return data ? new Date(data).toLocaleDateString() : null;
    },
    openModalForItem(item) {
      this.$refs[`DialogAggiungiFileComponent${item.id}`][0].openModal(
        item.id,
        3
      );
    },
    async downloadAllegatiProgramma(item) {
      this.loadingProgrammi = true;
      await ProgrammiRepository.downloadAllegatiProgramma(
        this.idStrutture,
        item.fileAllegato.id,
        item.fileAllegato.nomeOriginale
      );

      this.loadingProgrammi = false;
    },
  },
};
</script>

<style scoped></style>
