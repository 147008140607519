<template>
  <v-container fluid>
    <v-card>
      <v-card-title> Caricare la convenzione </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col>
              <v-file-input
                label="Caricamento convenzione"
                @change="uploadFile($event)"
                @input="validateField"
              ></v-file-input>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="info"
                @click="downloadFileConvenzione"
                v-if="downloadFile"
              >
                <v-icon dark> mdi-file </v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                v-if="!readOnly || isRoleConsulenza"
                class="primary"
                @click="dialogSalva = true"
              >
                Carica Convenzione
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <dialog-conferma
        @callback="uploadConvenzione"
        :dialog.sync="dialogSalva"
        @close-dialog="dialogSalva = false"
      />

      <v-snackbar
        v-model="snackbar"
        :timeout="2000"
        shaped
        :color="snackbarColor"
      >
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";
import DialogConferma from "@/components/DialogConferma";
import ConvenzioniRepository from "@/api/istituto/ConvenzioniRepository";

export default {
  name: "UploadAllegato",
  components: { DialogConferma },
  props: {
    convenzione: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    finestraCompilazione: null,
    file: null,
    dialogSalva: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    validate: false,
    downloadFile: null,
  }),
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  computed: {
    ...mapState(["idStrutture", "anno"]),
    ...mapGetters(["isReadOnly", "isRoleIstituto", "isRoleConsulenza"]),
    readOnly() {
      if (this.isRoleIstituto) return true;
      if (this.isReadOnly) return true;
      else if (!this.finestraCompilazione) {
        return true;
      } else if (!this.finestraCompilazione.attiva) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        this.getFinestraCompilazione();
      }
      this.downloadFile = this.convenzione?.fileAllegato ?? null;
    },
    async getFinestraCompilazione() {
      const finestraCompilazione =
        await IstitutoRepository.getFinestraCompilazione(
          this.idStrutture,
          this.anno,
          "convenzione-file-allegato-ist"
        );
      if (finestraCompilazione !== "") {
        this.finestraCompilazione = finestraCompilazione;
      }
    },
    uploadFile(event) {
      this.file = event;
    },
    validateField() {
      this.validate = this.$refs.form.validate();
    },
    async uploadConvenzione() {
      this.validateField();
      if (this.validate) {
        // post file to server
        const formData = new FormData();
        formData.append("file", this.file);

        try {
          this.downloadFile = await ConvenzioniRepository.uploadConvenzione(
            this.idStrutture,
            this.anno,
            formData
          );
          this.snackbarText = "Convenzione caricata con successo";

          this.snackbarColor = "success";
          this.validate = true;
        } catch (e) {
          this.snackbarText =
            "Problema durante il caricamento, contattare il supporto se il problema persiste";
          this.snackbarColor = "red accent-2";
        }
        this.snackbar = true;
      }
    },
    async downloadFileConvenzione() {
      await ConvenzioniRepository.downloadConvenzione(
        this.idStrutture,
        this.anno,
        this.downloadFile.nomeOriginale
      );
    },
  },
};
</script>

<style scoped></style>
