import Repository from "../Repository";

export default {
  async createDichiarazione(idStrutture, anno, idProgramma, formData) {
    const url = `api/istituto/${idStrutture}/flussi-finaziari/${anno}/${idProgramma}/salva-dichiarazione`;
    const headers = { "Content-type": "multipart/form-data" };
    return (await Repository.post(url, formData, { headers })).data;
  },
  async getSingolaDichiarazione(idStrutture, idDichiarazione) {
    const url = `api/istituto/${idStrutture}/flussi-finaziari/dichiarazione/${idDichiarazione}`;
    return (await Repository.get(url)).data;
  },
  async downloadTemplatePdfDcoumentazioneDichiarazione(
    idStrutture,
    idDichiarazione,
    formBody
  ) {
    const url = `api/istituto/${idStrutture}/flussi-finaziari/${idDichiarazione}/documentazione-pdf`;
    const headers = { "Content-type": "multipart/form-data" };
    const res = await Repository.post(url, formBody, {
      responseType: "blob",
      headers,
    });

    if (res.status === 200) {
      const fileResponse = new Blob([res.data], {
        type: res.data.type,
      });
      const objectUrl = URL.createObjectURL(fileResponse);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      const fileName =
        "dichiarazione_tracciabilità_flussi_finanziari" +
        idDichiarazione +
        ".pdf";
      fileLink.download = fileName.trim();

      fileLink.click();
      URL.revokeObjectURL(fileResponse);
    }
  },
  async uploadFile(idStrutture, idDichiarazione, form) {
    const url = `api/istituto/${idStrutture}/flussi-finaziari/${idDichiarazione}/upload-dichiarazione-pdf`;
    const headers = { "Content-type": "multipart/form-data" };
    return (await Repository.post(url, form, { headers })).data;
  },
  async eliminaFileCaricato(idStrutture, idDichiarazione) {
    const url = `api/istituto/${idStrutture}/flussi-finaziari/${idDichiarazione}/documentazione-pdf`;
    return (await Repository.delete(url)).data;
  },
  async trasmettiDichiarazione(idStrutture, idDichiarazione) {
    const url = `api/istituto/${idStrutture}/flussi-finaziari/${idDichiarazione}/trasmetti-dichiarazione`;
    const headers = { "Content-type": "multipart/form-data" };
    return (await Repository.post(url, { headers })).data;
  },
  async getListaDichiarazioni(idStrutture, anno, idProgramma) {
    const url = `api/istituto/${idStrutture}/flussi-finaziari/${idProgramma}/anno/${anno}/dichiarazioni`;
    return (await Repository.get(url)).data;
  },
  async downloadFileRiepilogoDichiarazioni(
    idStrutture,
    idDichiarazione,
    tipoFile,
    formData
  ) {
    const url = `api/istituto/${idStrutture}/flussi-finaziari/${idDichiarazione}/riepilogo-dichiarazione/${tipoFile}`;
    const headers = { "Content-Type": "multipart/form-data" };
    const res = await Repository.post(url, formData, {
      headers,
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      const extension = tipoFile === 0 ? ".xlsx" : ".pdf";
      fileLink.download =
        "Riepilogo_dichiarazione_" + idDichiarazione + extension;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async downloadFileRiepilogoConto(idStrutture, idConto, tipoFile, formData) {
    const url = `api/istituto/${idStrutture}/flussi-finaziari/${idConto}/riepilogo-conto/${tipoFile}`;
    const headers = { "Content-Type": "multipart/form-data" };
    const res = await Repository.post(url, formData, {
      headers,
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      const extension = tipoFile === 0 ? ".xlsx" : ".pdf";
      fileLink.download = "Riepilogo_conto_" + idConto + extension;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async deleteDichiarazione(idStrutture, idDichiarazione) {
    const url = `api/istituto/${idStrutture}/flussi-finaziari/${idDichiarazione}/elimina-dichiarazione`;
    return (await Repository.delete(url)).data;
  },
  async getContiAttiviIstituto(idStrutture, anno) {
    const url = `api/istituto/${idStrutture}/flussi-finaziari/${anno}/conti-attivi`;
    return (await Repository.get(url)).data;
  },
};
