<template>
  <v-container>
    <v-main>
      <BreadcrumbComponent :breadcrumbs="breadcrumbs" v-if="capofila"
    /></v-main>
    <v-main>
      <h1>Indicatori Comuni UE</h1>
      <p>
        Sezione in cui il Rappresentante Legale può aggiornare, per il semestre
        di riferimento, il valore programmato di studenti sulla base dei target
        assegnati dal Decreto definitivo di assegnazione delle risorse e dei
        target. Il valore realizzato si alimenta in automatico sulla base delle
        informazioni inserite nella card di censimento, erogazione formazione e
        certificati.
      </p>
      <v-card elevation="4" v-if="disabilitazioneCard !== true">
        <v-card-title>
          14 - Numero di giovani di età compresa tra i 15 e i 29 anni che
          ricevono sostegno.</v-card-title
        >
        <v-expansion-panels v-if="menuSemestri" accordion>
          <v-expansion-panel
            v-for="itemSemestre in menuSemestri"
            :key="itemSemestre.id"
            :disabled="checkMenuDisabilitato(itemSemestre)"
          >
            <v-expansion-panel-header
              @click="settaggioValoriPreCompilati(itemSemestre)"
            >
              <v-card-text>
                {{ itemSemestre.tipologiaSemestre.descrizione }}
                {{ itemSemestre.anno }}
              </v-card-text>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form
                :readonly="!isRoleLegale && !isRoleConsulenza"
                ref="form"
                v-model="valid"
                lazy-validation
                :disabled="!itemSemestre.attivo"
              >
                <v-row>
                  <v-col>
                    <h4>
                      Valore Programmato
                      <v-tooltip min-width="700" max-width="700" top multiple>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="primary">
                            mdi-information
                          </v-icon>
                        </template>
                        <span> {{ tooltip[0].text }}</span>
                      </v-tooltip>
                    </h4>
                  </v-col>
                  <v-col></v-col>
                  <v-col></v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      label=" Valore Programmato Totale"
                      type="number"
                      v-model="formIndicatori.numeroTotaleStudentiProgrammati"
                    >
                    </v-text-field
                  ></v-col>
                  <v-col></v-col>
                  <v-col></v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Valore programmato studenti - Uomo"
                      type="number"
                      v-model="formIndicatori.numeroStudentiUomoProgrammati"
                      :rules="rulesForm.valueFormNumerici"
                    >
                      <template v-slot:append-outer>
                        <v-tooltip top min-width="500" max-width="500">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="primary">
                              mdi-information
                            </v-icon>
                          </template>
                          {{ tooltip[1].text }}
                        </v-tooltip>
                      </template>
                    </v-text-field></v-col
                  >
                  <v-col>
                    <v-text-field
                      label="Valore programmato studenti - Donna"
                      type="number"
                      v-model="formIndicatori.numeroStudentiDonnaProgrammati"
                      :rules="rulesForm.valueFormNumerici"
                    >
                      <template v-slot:append-outer>
                        <v-tooltip top min-width="500" max-width="500">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="primary">
                              mdi-information
                            </v-icon>
                          </template>
                          {{ tooltip[2].text }}
                        </v-tooltip>
                      </template>
                    </v-text-field></v-col
                  >
                </v-row>
                <v-row>
                  <v-col>
                    <h4>
                      Valore Realizzato
                      <v-tooltip min-width="700" max-width="700" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="primary">
                            mdi-information
                          </v-icon>
                        </template>
                        <span> {{ tooltip[3].text }}</span>
                      </v-tooltip>
                    </h4>
                  </v-col>
                  <v-col></v-col>
                  <v-col></v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      placeholder="0"
                      label="Valore realizzato di studenti"
                      type="number"
                      v-model="valoriTotali.numeroStudentiTotaleRealizzati"
                      readonly
                    >
                    </v-text-field
                  ></v-col>
                  <v-col></v-col>
                  <v-col></v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      placeholder="0"
                      label="Valore realizzato studenti - Uomo"
                      type="number"
                      readonly
                      v-model="valoriTotali.numeroStudentiUomoRealizzati"
                    >
                    </v-text-field
                  ></v-col>
                  <v-col>
                    <v-text-field
                      placeholder="0"
                      label="Valore realizzato studenti - Donna"
                      type="number"
                      readonly
                      v-model="valoriTotali.numeroStudentiDonnaRealizzati"
                    >
                    </v-text-field
                  ></v-col>
                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.value"
                    height="100"
                    width="500"
                    top
                  >
                    <p class="white--text">{{ snackbar.text }}</p>
                  </v-snackbar>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card-actions>
                      <v-btn
                        v-if="isRoleLegale || isRoleConsulenza"
                        color="primary"
                        :disabled="!itemSemestre.attivo || !valid"
                        @click="alertAttivo = true"
                      >
                        Salva
                      </v-btn>
                      <dialog-conferma
                        @callback="onSubmitIndicatori"
                        :dialog.sync="alertAttivo"
                        @close-dialog="alertAttivo = false"
                      />
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
      <v-card v-else>
        <AlertComponent
          testo="Impossibile procedere con l'inserimento
        dell'indicatore. <br />
        <b> Non è stato creato alcun Programma di orientamento!</b>"
        />
      </v-card>
    </v-main>
  </v-container>
</template>

<script>
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent.vue";
import DialogConferma from "@/components/DialogConferma.vue";
import { mapGetters, mapState } from "vuex";
import ProgrammiRepository from "@/api/istituto/ProgrammiRepository";
import MonitoraggiRepository from "@/api/istituto/MonitoraggiRepository";
import AlertComponent from "@/components/Common/AlertComponent.vue";

export default {
  name: "IndicatoriComuniView",
  components: { AlertComponent, BreadcrumbComponent, DialogConferma },
  props: {
    capofila: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(["idStrutture", "anno"]),
    ...mapGetters(["isReadOnly", "isRoleLegale", "isRoleConsulenza"]),
    breadcrumbs: function () {
      return [
        {
          text: "Scrivania",
          disabled: false,
          to: `/scrivania/${this.capofila}`,
          exact: true,
          link: true,
        },
        {
          text: "Indicatori omuni UE",
          disabled: true,
          to: `/scrivania/${this.capofila}/indicatori-comuni`,
          exact: true,
          link: true,
        },
      ];
    },
  },
  watch: {
    anno() {
      this.initialize();
    },
    idStrutture() {
      this.initialize();
    },
  },
  data: () => ({
    loadingPage: false,
    programma: null,
    alertAttivo: false,
    rulesForm: {
      valueFormNumerici: [
        (v) => !!v || " E' obbligatoria la compilazione del campo",
        (v) =>
          (!!v && v >= 0) ||
          "Il valore del campo deve essere un valore positivo",
      ],
    },
    valoriTotali: {
      numeroStudentiTotaleRealizzati: 0,
      numeroStudentiUomoRealizzati: null,
      numeroStudentiDonnaRealizzati: null,
    },
    formIndicatori: {
      numeroTotaleStudentiProgrammati: 0,
      numeroStudentiUomoProgrammati: 1,
      numeroStudentiDonnaProgrammati: 1,
    },
    snackbar: {
      text: null,
      color: null,
      value: false,
    },
    valid: true,
    indicatori: [],
    indicatoreSelezionato: null,
    operazione: null,
    menuSemestri: null,
    disabilitazioneCard: false,
    tooltip: [
      {
        value: false,
        text:
          "Il sistema propone in automatico il 50% del valore programmato di studenti nel programma di orientamento. " +
          "L'Istituzione può modificare il valore programmato totale, in modo da rappresentare la programmazione dei mesi oggetto " +
          "della rilevazione (novembre e dicembre 2022) e, di conseguenza, quello distinto per studenti - Uomo e studenti – Donna",
      },
      {
        value: false,
        text: " Il sistema propone in automatico un valore programmato con un peso del 50% rispetto al totale del valore programmato",
      },
      {
        value: false,
        text: " Il sistema propone in automatico un valore programmato con un peso del 50% rispetto al totale del valore programmato",
      },
      {
        value: false,
        text: "Il sistema riporta in automatico un valore realizzato corrispondente al numero di studenti partecipanti ai singoli corsi ovvero che hanno preso parte ad almeno un’ora di corso con il dettaglio per genere",
      },
    ],
    idSemestreSelezionato: null,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      let anno = this.anno;
      let idStrutture = this.idStrutture;
      this.getProgrammaOrientamento(idStrutture, anno);
    },
    async getProgrammaOrientamento(idStrutture, anno) {
      this.loadingPage = true;
      await ProgrammiRepository.getProgrammi(
        idStrutture,
        anno,
        this.capofila
      ).then((data) => {
        if (data && data.length > 0) {
          this.disabilitazioneCard = false;
          this.programma = data[0];
          this.getMenuSemestri();
          this.getIndicatoreAteneo(idStrutture, anno, this.capofila);
        } else {
          this.disabilitazioneCard = true;
          this.programma = null;
        }
      });
    },
    async settaggioValoriPreCompilati(itemSemestre) {
      if (!(this.idSemestreSelezionato === itemSemestre.id)) {
        this.idSemestreSelezionato = itemSemestre.id;
        await MonitoraggiRepository.getCampiStudentiAutomatici(
          this.idStrutture,
          this.anno,
          this.capofila,
          itemSemestre.id
        ).then((data) => {
          if (data) {
            this.valoriTotali.numeroStudentiUomoRealizzati =
              data["Studenti-Maschio"];
            this.valoriTotali.numeroStudentiDonnaRealizzati =
              data["Studenti-Femmina"];
            this.valoriTotali.numeroStudentiTotaleRealizzati =
              data["Studenti-Maschio"] + data["Studenti-Femmina"];
          }
        });

        let indicatore = this.getSingoloIndicatore(itemSemestre.id);

        if (indicatore && this.operazione === "modifica") {
          return (this.formIndicatori = indicatore);
        }

        // totale Studenti Programmati
        const totStudentiProgrammati = this.programma.numeroAlunniCoinvolti / 2;
        let totStudentiToShow = 0;
        if (totStudentiProgrammati % 2 > 0) {
          totStudentiToShow =
            itemSemestre.id === 1
              ? Math.ceil(totStudentiProgrammati) - 1
              : Math.ceil(totStudentiProgrammati);
        } else {
          totStudentiToShow = totStudentiProgrammati;
        }
        this.formIndicatori.numeroTotaleStudentiProgrammati = totStudentiToShow;

        // totale studenti uomo-donna programmati
        let totStudentiUomoProgrammati = 0;
        let totStudentiDonnaProgrammati = 0;
        if (totStudentiToShow % 2 > 0) {
          totStudentiDonnaProgrammati = Math.ceil(totStudentiToShow / 2);
          totStudentiUomoProgrammati = Math.ceil(totStudentiToShow / 2) - 1;
        } else {
          totStudentiDonnaProgrammati = totStudentiToShow / 2;
          totStudentiUomoProgrammati = totStudentiToShow / 2;
        }

        this.formIndicatori.numeroStudentiUomoProgrammati =
          totStudentiUomoProgrammati;
        this.formIndicatori.numeroStudentiDonnaProgrammati =
          totStudentiDonnaProgrammati;
        //this.formIndicatori.numeroStudentiBinariProgrammati = 0;
      } else {
        // il semestre selezionato è lo stesso, ho chiuso dunque l'expansions panel
      }
    },

    getSingoloIndicatore(idSemestre) {
      if (this.indicatori.length > 0) {
        const indexIndicatore = this.indicatori.findIndex(
          (element) => element.semestre.id === idSemestre
        );

        if (indexIndicatore >= 0) {
          this.indicatoreSelezionato = this.indicatori[indexIndicatore];
          this.operazione = "modifica";
          return this.indicatoreSelezionato;
        } else {
          this.operazione = "inserimento";
          return null;
        }
      } else {
        this.operazione = "inserimento";
        return null;
      }
    },
    async getMenuSemestri() {
      this.menuSemestri = await MonitoraggiRepository.getSemestri();
    },
    checkMenuDisabilitato(itemSemestre) {
      const dataOdierna = new Date();
      const annoPartenza = itemSemestre.anno;
      const dataInizio = new Date(itemSemestre.dataInizio);
      const dataFine = new Date(itemSemestre.dataFine);
      if (
        annoPartenza === dataOdierna.getFullYear() &&
        dataOdierna >= dataInizio &&
        dataOdierna <= dataFine
      ) {
        return false;
      } else return !(dataOdierna > dataInizio && dataOdierna > dataFine);
    },
    async onSubmitIndicatori() {
      const totStudentiProgrammati = parseInt(
        this.formIndicatori.numeroTotaleStudentiProgrammati
      );
      const totStudentiUomo = this.formIndicatori.numeroStudentiUomoProgrammati;
      const totStudentiDonna =
        this.formIndicatori.numeroStudentiDonnaProgrammati;

      let totale = parseInt(totStudentiUomo) + parseInt(totStudentiDonna);

      if (totStudentiProgrammati !== totale) {
        this.snackbar = {
          text: "Il valore di numero studenti totali programmati non può essere diversa dalla somma totale del numero di studenti programmato per genere.",
          color: "red darken-2",
          value: true,
        };
      } else {
        let idStrutture = this.idStrutture;
        let anno = this.anno;
        if (this.operazione === "inserimento") {
          await this.inserimentoIndicatore(idStrutture, anno, this.capofila);
        } else {
          await this.modificaIndicatore(idStrutture);
        }
      }
    },
    getIndicatoreAteneo(idStrutture, anno, capofila) {
      MonitoraggiRepository.getIndicatoreComuneAteneo(
        idStrutture,
        anno,
        capofila
      ).then((data) => {
        if (data && data.length) {
          this.indicatori = data.map((element) => {
            let semestre = element;
            semestre.attivo = false;
            return semestre;
          });
          //this.formIndicatori = data;
        } else {
          this.operazione = "inserimento";
        }
      });
    },
    async inserimentoIndicatore(idStrutture, anno, capofila) {
      this.formIndicatori.semestre = this.idSemestreSelezionato;
      const indicatore = JSON.stringify(this.formIndicatori);
      const formBody = new FormData();
      formBody.append("indicatoreComune", indicatore);
      MonitoraggiRepository.inserimentoIndicatori(
        idStrutture,
        anno,
        capofila,
        formBody
      ).then((data) => {
        if (data) {
          this.$router.push({
            name: "scrivania",
            params: { capofila: this.capofila },
          });
        }
      });
    },
    async modificaIndicatore(idStrutture) {
      delete this.formIndicatori.dataConferma;
      const indicatore = JSON.stringify(this.formIndicatori);
      const formBody = new FormData();
      formBody.append("indicatoreComune", indicatore);
      MonitoraggiRepository.modificaIndicatore(
        idStrutture,
        this.formIndicatori.id,
        formBody
      ).then((data) => {
        if (data) {
          this.$router.push({
            name: "scrivania",
            params: { capofila: this.capofila },
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.v-alert {
  text-align: center;
  margin: 0 auto;
}
</style>
