<template>
  <v-container>
    <BreadcrumbComponent
      :breadcrumbs="breadcrumbs"
      v-if="idRendiconto === null"
    />
    <div class="mt-4" v-if="idRendiconto">
      <v-btn text class="blue--text" @click="goToPreviousPage">
        <v-icon>mdi-arrow-left-thin</v-icon>Torna a dettaglio rendiconto di
        progetto
      </v-btn>
    </div>
    <DettaglioCorsoComponent
      :id-corso="idCorso"
      :capofila="capofila"
    ></DettaglioCorsoComponent>
    <div class="mt-4" v-if="idRendiconto">
      <v-btn text class="blue--text" @click="goToPreviousPage">
        <v-icon>mdi-arrow-left-thin</v-icon>Torna a dettaglio rendiconto di
        progetto
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import DettaglioCorsoComponent from "@/components/Rendiconti/DettaglioCorsoComponent";

export default {
  name: "dettaglioCorso",
  components: { BreadcrumbComponent, DettaglioCorsoComponent },
  props: {
    capofila: {
      type: String,
      required: true,
    },
    idRendiconto: {
      type: Number,
      required: false,
    },
    idCorso: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState(["idStrutture", "anno", "user", "quadrimestreRendiconti"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleConsulenza",
      "isRoleLegale",
    ]),
    breadcrumbs: function () {
      if (this.capofila) {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania/${this.capofila}`,
            exact: true,
            link: true,
          },
          {
            text: "Creazione e trasmissione rendiconto di progetto",
            disabled: false,
            to: `/scrivania/${this.capofila}/rendiconti`,
            exact: true,
            link: true,
          },
          {
            text: "Dettaglio rendiconto di progetto",
            disabled: false,
            to: `/scrivania/${this.capofila}/rendiconti/${this.idRendiconto}/dettaglioRendiconto`,
            exact: true,
            link: true,
          },
          {
            text: "Dettaglio corso",
            disabled: true,
            to: `/scrivania/${this.capofila}/rendiconti/dettaglioRendiconto`,
            exact: true,
            link: true,
          },
        ];
      }
      return [];
    },
  },
  methods: {
    goToPreviousPage() {
      this.$router.push({
        name: "InserimentoRendiconto",
        params: {
          capofila: this.capofila,
          quadrimestre: this.quadrimestreRendiconti,
          operazione: this.idRendiconto > 0 ? "modifica" : "inserimento",
        },
      });
    },
  },
};
</script>

<style scoped></style>
