//import Repository from "../Repository";
import Repository from "@/api/Repository";

export default {
  async caricaScuolaAccordo(idStrutture, anno, capofila, formScuolaAccordo) {
    const url = `api/istituto/${idStrutture}/scuole-accordi/${anno}/${capofila}`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, formScuolaAccordo, { headers })).data;
  },
  async getScuoleAccordi(idStrutture, anno, capofila) {
    let url = `api/istituto/${idStrutture}/scuole-accordi/${anno}/${capofila}`;
    return (await Repository.get(url)).data;
  },
  async downloadPDFAccordo(idStrutture, scuolaAccordo, nomeFileOriginale) {
    let url = `api/istituto/${idStrutture}/scuole-acccordi/${scuolaAccordo}/file-accordo`;
    const res = await Repository.get(url, {
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = nomeFileOriginale;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async getSingoloAccordo(idStrutture, idAccordo, anno) {
    let url = `api/istituto/${idStrutture}/scuole-accordi/${idAccordo}/anno/${anno}`;
    return (await Repository.get(url)).data;
  },
  async modificaAccordo(idStrutture, anno, idAccordo, formScuolaAccordo) {
    let url = `api/istituto/${idStrutture}/anno/${anno}/scuole-accordi/${idAccordo}`;
    const headers = { "Content-Type": "multipart/form-data" };
    return await Repository.post(url, formScuolaAccordo, { headers });
  },

  async caricaSottoAccordo(idStrutture, anno, idAccordo, bodySottoAccordo) {
    const url = `api/istituto/${idStrutture}/anno/${anno}/scuole-accordi/${idAccordo}/sotto-accordi`;
    const headers = { "Content-Type": "multipart/form-data" };
    return await Repository.post(url, bodySottoAccordo, { headers });
  },
  async getPdfSottoAccordo(
    idStrutture,
    idAccordo,
    idSottoAccordo,
    nomeFileOriginale
  ) {
    const url = `api/istituto/${idStrutture}/scuole-accordi/${idAccordo}/sotto-accordi/${idSottoAccordo}/pdf`;
    const res = await Repository.get(url, {
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = nomeFileOriginale;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async getSingoloSottoAccordo(idStrutture, idAccordo, idSottoAccordo) {
    const url = `api/istituto/${idStrutture}/scuole-accordi/${idAccordo}/sotto-accordi/${idSottoAccordo}`;
    return (await Repository.get(url)).data;
  },
  async modificaSottoAccordo(
    idStrutture,
    idAccordo,
    idSottoAccordo,
    anno,
    bodySottoAccordo
  ) {
    const url = `api/istituto/${idStrutture}/anno/${anno}/scuole-accordi/${idAccordo}/sotto-accordi/${idSottoAccordo}`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, bodySottoAccordo, { headers })).data;
  },
  async getSottoAccordoAttivo(idStrutture, anno, idAccordo, attivo) {
    const url = `api/istituto/${idStrutture}/anno/${anno}/scuole-accordi/${idAccordo}/sotto-accordi/${attivo}/`;
    return (await Repository.get(url)).data;
  },
  async getRivalidazioneCf(idStrutture, idAccordo) {
    const url = `api/istituto/${idStrutture}/scuole-accordi/${idAccordo}/rivalidazione-cf`;
    return (await Repository.put(url)).data;
  },
};
