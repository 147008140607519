<template>
  <v-container fluid>
    <StepRendicontazioni :numero-step="2" />
    <h1 class="sottotitolo">Dichiarazione Rendiconto</h1>
    <v-main v-if="!loadingPage">
      <v-card elevation="3" class="my-8">
        <RiepilogoGeneraleRendiconto :rendiconto="rendiconto" />
      </v-card>
      <!--    CARD DATI DEL RAPPRESENTANTE LEGALE  -->
      <v-card class="mt-3">
        <RiepilogoLegaleRappresentante
          :id-programma="rendiconto.programma.id"
        />
      </v-card>
      <!-- CARD CON I DATI DELLA STRUTTURA  -->
      <div class="my-8" v-if="contiStruttura.length < 1">
        <v-alert dense outlined elevation="2" type="error">
          Non è stata inserita la dichiarazione di flussi finanziari. Prima di
          procedere con la rendicontazione è necessario compilare e caricare la
          dichiarazione dei flussi finanziari nella apposita sezione.
        </v-alert>
      </div>

      <v-form ref="formRendiconto" lazy-validation>
        <v-card elevation="3" class="my-8">
          <h4 v-html="'Dati finanziari'" class="sottotitolo" />
          <v-card-text>
            <div class="text-center">
              <v-row>
                <v-col cols="2" class="w-25">
                  <b> Tipologia erogazione </b>
                </v-col>
                <v-col cols="2" class="w-25"> <b> Importo richiesto </b></v-col>
                <v-col cols="7" class="w-50">
                  <b> Iban Bancario/Conto di tesoreria </b></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="2" class="w-25"> Erogazione Intermedia </v-col>
                <v-col cols="2" class="w-25">
                  {{ rendiconto.importoRichiesto }} €</v-col
                >
                <v-col cols="7" class="">
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        label="Conto di Tesoreria"
                        class="text-center"
                        v-model="formDichiarazioneRendiconto.contoTesoreria"
                        readonly
                        outlined
                      />
                    </v-col>
                    <v-col cols="2" class="text-center"> oppure </v-col>
                    <v-col cols="4">
                      <!--<div v-if="contiStruttura && contiStruttura.length > 1">
                        <v-select
                          v-model="formDichiarazioneRendiconto.codiceIban"
                          :items="contiStruttura"
                          :item-text="getTextIbanAssociato"
                          item-value="ibanAssociato"
                          label="Conto corrente bancario"
                          :disabled="isStatale"
                          required
                          outlined
                          no-data-text="Nessun conto corrente disponibile"
                        ></v-select>
                      </div>-->
                      <v-text-field
                        label="IBAN bancario"
                        class="text-center"
                        v-model="formDichiarazioneRendiconto.codiceIban"
                        readonly
                        outlined
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            consapevole della responsabilità penale cui può andare incontro in
            caso di dichiarazione falsa o comunque non corrispondente al vero
            (art. 76 del D.P.R. n. 445 del 28/12/2000), ai sensi del D.P.R. n.
            445 del 28/12/2000 e ss.mm.ii. <br />
            <br />

            <h3 class="text-center"><b> Dichiara che </b></h3>
            <br />
            <br />

            in riferimento al Rendiconto di Progetto di cui alla sezione quanta
            segue:

            <v-row>
              <v-col> <b> Data presentazione </b></v-col>
              <v-col> <b>Numero rendiconto </b></v-col>
              <v-col> <b>Periodo di riferimento </b></v-col>
              <v-col> <b>Importo rendicontato </b></v-col>
            </v-row>
            <v-row>
              <v-col> <v-text-field readonly outlined /></v-col>
              <v-col>
                <v-text-field readonly outlined v-model="rendiconto.id"
              /></v-col>
              <v-col>
                <v-text-field
                  readonly
                  outlined
                  v-model="
                    rendiconto.quadrimestre.tipologiaQuadrimestre.descrizione
                  "
              /></v-col>
              <v-col>
                <v-text-field
                  readonly
                  outlined
                  v-model="sommaImportoRendicontato"
              /></v-col>
            </v-row>
            <ol>
              <li>
                I relativi corsi di orientamento, di cui all’elenco allegato al
                presente documento (All.1), sono stati progettati, programmati e
                realizzati secondo quanto previsto dai Decreti ministeriali e in
                coerenza con gli impegni assunti in sede di accettazione del
                finanziamento. In particolare, per i corsi costituenti il
                rendiconto in oggetto:
                <ol type="a">
                  <li>è stata rispettata la durata di 15 ore di lezione;</li>
                  <li>
                    la formazione è stata erogata con modalità curriculare o
                    extracurriculare da docenti adeguatamente selezionati in
                    relazione alle tematiche trattate;
                  </li>
                  <li>
                    le lezioni si sono svolte in presenza per almeno i 2/3 del
                    monte ore complessivo;
                  </li>
                  <li>
                    sono stati conseguiti tutti gli obiettivi programmati;
                  </li>
                  <li>
                    sono stati rilasciati gli attestati di frequenza
                    esclusivamente agli alunni che hanno raggiunto almeno il 70%
                    delle ore di formazione erogate.
                  </li>
                </ol>
              </li>
              <li>
                In riferimento ai costi maturati in esito allo svolgimento
                dell’attività formativa ed esposti nel Rendiconto di Progetto,
                ha svolto le verifiche:
                <ol type="a">
                  <li>
                    di regolarità amministrativo-contabile e i controlli interni
                    di gestione ordinari;
                  </li>
                  <li>ex-ante sul “titolare effettivo”;</li>
                  <li>ex-ante sull’assenza di conflitto di interessi;</li>
                  <li>
                    sull’assenza del doppio finanziamento previste dall’art. 9
                    del Reg. (UE) 2021/241;
                  </li>
                  <li>
                    sul rispetto delle condizionalità e di tutti gli ulteriori
                    requisiti connessi alla misura del PNRR a cui è associato il
                    progetto;
                  </li>
                  <li>
                    sul rispetto dei principi trasversali e della conformità
                    delle attività svolte alla pertinente normativa ambientale
                    comunitaria e nazionale; <br />
                    e a tal fine si allega alla presente la relativa Check-List
                    di autocontrollo debitamente compilata (All. 2) e la
                    dichiarazione di conformità al principio “DNSH” e alla
                    pertinente normativa ambientale (All.3).
                  </li>
                </ol>
              </li>
              <li>
                Le attività realizzate e i relativi costi maturati di cui al
                presente rendiconto sono stati imputati esclusivamente a valere
                su risorse PNRR, ovvero, ove previsto, per quota parte con
                risorse nazionali/regionali/locali/private;
              </li>
              <li>
                Le attività realizzate e i relativi costi maturati inseriti nel
                presente rendiconto sono stati imputati esclusivamente a valere
                del progetto finanziato e quindi è stato rispettato il divieto
                di duplice rimborso (stesso costo pagato due volte) a valere su
                fonti di finanziamento pubbliche, anche di diversa natura;
              </li>
              <li>
                Le attività realizzate e i relativi costi maturati inseriti nel
                presente rendiconto concorrono al conseguimento della
                performance oggetto della relativa Misura PNRR;
              </li>
              <li>
                In riferimento alle attività realizzate di cui al presente
                rendiconto di aver adempiuto agli obblighi previsti dall’art. 3
                della Legge 136/2010 e ss.mm.ii. in materia di tracciabilità dei
                flussi finanziari e di aver effettuato le dovute registrazioni
                contabili in conformità alle disposizioni di legge in materia
                fiscale, contabile e civilistica vigenti a livello europeo,
                nazionale e regionale;
              </li>
              <li>
                Tutti i documenti, titoli o atti sono copie conformi alla
                documentazione agli atti del Soggetto Attuatore e archiviati
                secondo le modalità indicate nei dispositivi attuativi e nelle
                “Linee guida per la rendicontazione destinate ai Soggetti
                Attuatori”;
              </li>
              <li>
                Di avere preso visione dell&rsquo;informativa sul trattamento
                dei dati personali ai sensi dell’art. 13 e 14 del Regolamento UE
                2016/679 fornita nella sezione
                <i>
                  Strumenti di attuazione del PNRR sottosezione
                  <a
                    href="https://www.mur.gov.it/it/pnrr/strumenti-di-attuazione/informative-privacy-i-soggetti-attuatori"
                    target="_blank"
                  >
                    Informative privacy per i soggetti attuatori
                  </a>
                </i>
                del sito
                <a href="https://www.mur.gov.it/it" target="_blank">
                  www.mur.gov.it </a
                >.
              </li>
              <li>
                Le modalità di calcolo del rendiconto sono coerenti con
                l’applicazione delle Opzioni di Semplificazione dei Costi,
                utilizzate in conformità a quanto previsto dal Regolamento (UE)
                2021/241, dall'art. 53 del Regolamento (UE) 2021/1060,
                dall’Avviso/Disciplinare e dalle indicazioni del Ministero,
                sulla base delle attività effettivamente realizzate e dei dati
                caricati e validati sulla Piattaforma “Orientamento 2026”.
              </li>
            </ol>
            <h3 class="text-left"><b> e chiede</b></h3>
            <br />
            il versamento dell’importo di euro
            <b>{{ rendiconto.importoRichiesto }} €</b>, a titolo di rimborso dei
            costi maturati in relazione alle attività formative svolte nel
            periodo di riferimento,
            <b v-if="formDichiarazioneRendiconto.contoTesoreria">
              sul conto di tesoreria n°
              {{ formDichiarazioneRendiconto.contoTesoreria }}
            </b>
            <b v-else>
              sul conto corrente bancario (nei casi in cui non si è assoggettati
              ai vincoli del regime di tesoreria unica)
              {{ formDichiarazioneRendiconto.codiceIban }}</b
            >

            dedicato alle transazioni relative al progetto in ottemperanza
            all’art. 9, comma 4, del Dlgs n. 77 del 31 maggio 2021 in materia di
            tracciabilità delle risorse finanziaria del PNRR.
          </v-card-text>
          <v-card-actions>
            <div class="d-flex mb-6 ml-8">
              <v-row>
                <v-col class="pa-2 mr-auto">
                  <v-btn @click="goOutRendicontioSection">
                    Torna al menù principale.
                  </v-btn>
                </v-col>
                <v-col class="pa-2" v-if="compilazioneRendicontoBloccata">
                  <v-alert type="warning" width="500">
                    Impossibile procedere alla compilazione del rendiconto.
                    L'IBAN bancario e/o il Conto di tesoreria associato
                    all'istituto specifico non è disponibile.
                  </v-alert>
                </v-col>
                <v-col v-else>
                  <v-btn
                    class="primary v-btn--outlined v-btn--tile white--text"
                    v-html="'Salva Bozza e Continua'"
                    @click="dialogConferma = true"
                    :disabled="disabilitaSalva()"
                  />
                  <dialog-conferma
                    :dialog.sync="dialogConferma"
                    @callback="salvaBozzaInserimentoRendiconto"
                    @close-dialog="dialogConferma = false"
                  />
                </v-col>
                <v-col>
                  <!-- disabilitato se non ho salvto le modifiche dello step corrente -->
                  <v-btn
                    v-html="'Continua senza salvare'"
                    :disabled="
                      rendiconto.step < 2 || compilazioneRendicontoBloccata
                    "
                    @click="goToNextStep"
                  />
                </v-col>
              </v-row>
              <v-snackbar
                :color="snackbar.color"
                v-model="snackbar.value"
                max-width="200"
                max-height="200"
              >
                {{ snackbar.text }}</v-snackbar
              >
            </div>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-main>
    <v-main v-else>
      <v-progress-linear indeterminate color="blue" />
    </v-main>
  </v-container>
</template>
<script>
import StepRendicontazioni from "@/components/Rendiconti/StepRendicontazioni";
import DialogConferma from "@/components/DialogConferma";
import { mapGetters, mapState } from "vuex";
import RendicontiRepository from "@/api/istituto/RendicontiRepository";
import RiepilogoGeneraleRendiconto from "@/components/Rendiconti/RiepilogoGeneraleRendiconto";
import RiepilogoLegaleRappresentante from "@/components/Rendiconti/RiepilogoLegaleRappresentanteComponent";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";
import DichiarazioneFlussiRepository from "@/api/istituto/DichiarazioneFlussiRepository";

export default {
  name: "dichiarazioneRendiconto",
  components: {
    RiepilogoGeneraleRendiconto,
    RiepilogoLegaleRappresentante,
    StepRendicontazioni,
    DialogConferma,
  },
  props: {
    capofila: {
      type: String,
      required: true,
    },
    operazione: {
      type: String,
      required: true,
      default: "modifica",
    },
  },
  computed: {
    ...mapState(["idStrutture", "anno", "user", "idRendiconto"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleConsulenza",
      "isRoleLegale",
    ]),
  },
  watch: {
    idStrutture() {
      return this.initialize();
    },
    anno() {
      return this.initialize();
    },
  },
  data: () => ({
    isStatale: false,
    contiStruttura: null,
    loadingPage: false,
    rendiconto: null,
    legaleRappresentante: null,
    formDichiarazioneRendiconto: {
      codiceIban: "",
      contoTesoreria: "",
      currentStep: 2,
    },
    snackbar: {
      color: null,
      value: false,
      text: null,
    },
    sommaImportoRendicontato: "0 €",
    dialogConferma: false,
    validForm: false,
    richiestaAnticipo: null,
    codiciContiStruttura: null,
    compilazioneRendicontoBloccata: false,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.idRendiconto && this.idStrutture && this.anno) {
        this.getSingoloRendiconto();
        this.getIsStatale();
      }
    },
    async getIsStatale() {
      let tipoStruttura = await IstitutoRepository.getIsStatale(
        this.idStrutture
      );
      //AFAM -> stesso comportamento di NON statali!
      this.isStatale = tipoStruttura["isStatale"] && !tipoStruttura["isAfam"];
      this.getContiAttiviIstituto();
    },
    async getContiAttiviIstituto() {
      this.contiStruttura =
        await DichiarazioneFlussiRepository.getContiAttiviIstituto(
          this.idStrutture,
          this.anno
        );
      if (!this.isStatale) {
        if (this.rendiconto && this.rendiconto.step >= 2) {
          this.formDichiarazioneRendiconto.codiceIban =
            this.rendiconto.ibanBancario;
        } else {
          this.formDichiarazioneRendiconto.codiceIban = this.contiStruttura
            ? this.contiStruttura[0].ibanAssociato
            : null;
        }
      }
      if (this.isStatale) {
        if (this.rendiconto && this.rendiconto.step >= 2) {
          this.formDichiarazioneRendiconto.contoTesoreria =
            this.rendiconto.contoTesoreria;
        } else {
          this.formDichiarazioneRendiconto.contoTesoreria =
            this.contiStruttura[0].contoTesoreriaPostale;
        }
      }
    },
    async getSingoloRendiconto() {
      this.loadingPage = true;
      this.rendiconto = await RendicontiRepository.getSingoloRendiconto(
        this.idStrutture,
        this.idRendiconto
      );
      /*if (this.rendiconto && this.rendiconto.step >= 2) {
        this.assignValueIbanContoTesoreria(this.rendiconto);
      } else {
        await this.getDatiBancariPreCompilati();
      }*/
      this.loadingPage = false;
    },

    /*async getDatiBancariPreCompilati() {
      this.codiciContiStruttura =
        await RichiesteRepository.getContiBancariStruttura(
          this.idStrutture,
          this.anno
        );
      if (this.codiciContiStruttura) {
        this.assignValueIbanContoTesoreria(this.codiciContiStruttura);
      } else {
        this.compilazioneRendicontoBloccata = true;
      }
    },*/
    /*assignValueIbanContoTesoreria(objectValue) {
      // viene passato l'oggetto rendiconto
      /*this.formDichiarazioneRendiconto.codiceIban =
        objectValue.ibanBancario ?? null;
      this.formDichiarazioneRendiconto.codiceIban =
        this.rendiconto.ibanBancario ?? null;
      this.formDichiarazioneRendiconto.contoTesoreria =
        objectValue.contoTesoreria ?? null;
    },*/

    async salvaBozzaInserimentoRendiconto() {
      const formRendiconto = this.formDichiarazioneRendiconto;
      const ibanTesoreria = formRendiconto.contoTesoreria;
      const ibanBancario = formRendiconto.codiceIban;

      if (ibanTesoreria && ibanBancario) {
        this.setPropertySnackbar(
          false,
          "E' possibile specificare solo uno fra CODICE IBAN e CONTO DI TESORERIA"
        );
      } else if (!ibanTesoreria && !ibanBancario) {
        this.setPropertySnackbar(
          false,
          "E' possibile specificare solo uno fra CODICE IBAN e CONTO DI TESORERIA"
        );
      } else {
        if (this.$refs.formRendiconto.validate()) {
          const formRendiconto = this.formDichiarazioneRendiconto;
          formRendiconto.currentStep = 2;

          const formData = new FormData();
          formData.append(
            "rendiconto",
            JSON.stringify(this.formDichiarazioneRendiconto)
          );

          RendicontiRepository.modificaRendiconto(
            this.idStrutture,
            this.idRendiconto,
            2,
            formData
          ).then((data) => {
            if (data) {
              this.goToNextStep();
            }
          });
        }
      }
    },
    getTextIbanAssociato(item) {
      return `${item.ibanAssociato} -- ${item.accesoPresso}`;
    },
    disabilitaSalva() {
      if (!this.isStatale && !this.formDichiarazioneRendiconto.codiceIban) {
        return true;
      } else if (
        this.isStatale &&
        !this.formDichiarazioneRendiconto.contoTesoreria
      ) {
        return true;
      }
    },
    goToNextStep() {
      this.$router.push({
        name: "checklistRendiconti",
        params: { capofila: this.capofila },
      });
    },
    goOutRendicontioSection() {
      this.$store.commit("setIdRendiconto", null);
      if (!this.idRendiconto) {
        this.$router.push({
          name: "RendicontiView",
          params: {
            capofila: this.capofila,
          },
        });
      }
    },
    setPropertySnackbar(esito, testo) {
      this.snackbar.color = esito ? "success" : "red accent-2";
      this.snackbar.text = testo;
      this.snackbar.value = true;
    },
  },
};
</script>
<style scoped>
.sottotitolo {
  color: #1a237e;
  margin-left: 1%;
  font-weight: bold;
}
.v-col {
  border: 3px solid black;
}
</style>
