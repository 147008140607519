<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <template v-slot:activator="{ on, attrs }">
        <v-row>
          <v-col cols="12" class="d-flex flex-row-reverse">
            <v-btn text class="blue--text" v-bind="attrs" v-on="on"
              ><v-icon class="mx-2"> mdi-loupe </v-icon>
              Aggiungi studenti da file
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <v-card>
        <v-card-title class="h1 justify-center"
          >Aggiungi studenti da File
        </v-card-title>

        <v-card-text>
          <v-main>
            <v-alert border="top" colored-border type="info" elevation="2">
              Prima di procedere con il caricamento del file assicurarsi che
              siano verificate le seguenti indicazioni:
              <ul>
                <li v-for="(info, index) in infoUpload" :key="index">
                  <span v-html="info" />
                </li>
              </ul>
              Le associazioni tra ID del comune di residenza e denominazione del
              comune sono presenti in questo file:
              <a
                target="_blank"
                href="https://orientamento2026-backend.cineca.it/uploads/2022/istituto/comuni_anpr_attivi.xlsx"
              >
                file ID</a
              >.
              <br />
              E' disponibile il template del file in formato
              <a
                v-if="anno === 2022"
                target="_blank"
                href="https://orientamento2026-backend.cineca.it/uploads/2022/istituto/template_inserimento_studenti.xlsx"
              >
                Excel
              </a>
              <a
                v-else
                target="_blank"
                href="https://orientamento2026-backend.cineca.it/uploads/2022/istituto/template_inserimento_studenti_aggiornato.xlsx"
              >
                Excel
              </a>
              e in formato
              <a
                v-if="anno === 2022"
                target="_blank"
                href="https://orientamento2026-backend.cineca.it/uploads/2022/istituto/template_inserimento_studenti.csv"
              >
                csv
              </a>
              <a
                v-else
                target="_blank"
                href="https://orientamento2026-backend.cineca.it/uploads/2022/istituto/template_inserimento_studenti_aggiornato.csv"
              >
                csv
              </a>
              con le intestazioni già create. <br />
              Al termine dell'operazione verrà scaricato un file di testo con
              l'esito del caricamento per singolo studente. In caso di errori di
              inserimento sarà necessario effettuare le opportune correzioni e
              reinserire il nominativo. <br />
              Gli studenti con residenza estera dovranno essere caricati
              singolarmente con il pulsante "Aggiungi studente".
            </v-alert>
          </v-main>
          <v-container
            ><div class="my-4">
              <small> I campi contrassegnati con * sono obbligatori </small>
            </div>
            <v-form ref="form" lazy-validation v-model="validForm">
              <v-row>
                <v-col cols="4">
                  <v-alert type="warning" max-width="300" class="alert-warning">
                    La procedura potrebbe richiedere alcuni secondi.
                  </v-alert></v-col
                >
                <v-col cols="8">
                  <v-file-input
                    v-model="formStudenti.fileStudenti"
                    placeholder="Upload your documents"
                    label="File Studenti"
                    show-size
                    prepend-icon="mdi-paperclip"
                    accept=".xlsx, .csv"
                    :rules="rulesForm.formFile"
                  >
                  </v-file-input>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-row>
          <v-col cols="7"></v-col>
          <v-col cols="2">
            <v-card-actions>
              <v-btn
                color="primary"
                @click="dialogSalva = true"
                :disabled="!validForm || activeOperation"
              >
                Invia
              </v-btn></v-card-actions
            >
            <dialog-conferma
              @callback="onSubmitAggiungiStudente"
              :dialog.sync="dialogSalva"
              @close-dialog="dialogSalva = false"
            />
            <v-snackbar :color="snackbar.color" v-model="snackbar.value">
              {{ snackbar.text }}</v-snackbar
            >
          </v-col>
          <v-col cols="2">
            <v-card-actions>
              <v-btn color="primary" @click="dialog = false"> Annulla </v-btn>
            </v-card-actions></v-col
          >
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import DialogConferma from "@/components/DialogConferma.vue";
import CorsiRepository from "@/api/istituto/CorsiRepository";
import { mapState } from "vuex";

export default {
  name: "DialogAggiungiStudenteDaFile",
  computed: {
    ...mapState(["anno"]),
  },
  components: {
    DialogConferma,
  },
  props: {
    idCorso: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    formStudenti: {
      idCorso: null,
      fileStudenti: null,
    },
    fileStudenti: null,
    dialogSalva: false,
    rulesForm: {
      formFile: [(v) => !!v || " E' obbligatoria la compilazione del campo"],
    },
    infoUpload: [
      "il file può contenere un massimo di 20 studenti;",
      "il file <b>NON</b> può contenere studenti con età inferiore ai 14 anni;",
      "possono essere caricati più file, uno per volta attraverso lo stesso pulsante;",
      "il file deve contenere queste informazioni: nome, cognome, codice fiscale, " +
        "<b>ID</b> del comune di residenza ( <b>campo numerico, fornito insieme alla descrizione del comune nel link in basso 'file ID' </b>) " +
        " la classe dello studente (<b> campo numerico compreso tra 1 e 5.</b>) e, dall' a.a. 2023/24 in poi, il <b>codice meccanografico</b> dell'istituto scolastico.",
    ],
    snackbar: {
      text: "",
      color: "",
      value: false,
    },
    validForm: false,
    activeOperation: false,
  }),
  methods: {
    async onSubmitAggiungiStudente() {
      if (!this.$refs.form.validate()) {
        this.snackbar = {
          value: true,
          text: "Il file non è stato caricato. Impossibile procedere!",
          color: "red accent-2",
        };
      } else {
        this.activeOperation = true;
        const idStrutture = this.$store.state.idStrutture;
        const formBody = new FormData();
        formBody.append("fileStudenti", this.formStudenti.fileStudenti);

        await CorsiRepository.aggiungiStudentiDaFile(
          idStrutture,
          this.idCorso,
          formBody
        )
          // eslint-disable-next-line no-unused-vars
          .then((data) => {
            this.activeOperation = false;
            this.$emit("studenti-da-file-aggiunti");
            this.formStudenti.fileStudenti = null;
            this.dialog = false;
          })
          .catch((reason) => {
            if (reason.response.status === 400) {
              this.snackbar = {
                text: reason.response.data[0],
                color: "red accent-2",
                value: true,
              };
            } else {
              this.snackbar = {
                text: "Un errore è stato riscontrato nella richiesta. Verificare che il formato del file sia text/csv. In alternativa contattare il supporto per la segnalazione dell'errore ",
                color: "red accent-2",
                value: true,
              };
            }
            this.activeOperation = false;
            this.formStudenti.fileStudenti = null;
          });
      }
    },
  },
};
</script>

<style scoped>
.alert-warning {
  font-size: 14px;
}
</style>
