<template>
  <div v-if="getLoadingComponent">
    <p style="margin-top: 2%">
      <b>
        Il/La sottoscritto/a
        {{ legaleRappresentante.nomeRappresentante }}
        {{ legaleRappresentante.cognomeRappresentante }}
      </b>

      nato/a

      <b v-if="legaleRappresentante.luogoNascita">
        {{ legaleRappresentante.luogoNascita }}
      </b>
      <b v-else> NON DISPONIBILE</b>
      , il
      <b v-if="legaleRappresentante.dataNascita">
        {{ legaleRappresentante.dataNascita }}
      </b>
      , C.F. <b> {{ legaleRappresentante.codiceFiscaleRappresentante }}</b
      >, Legale Rappresentante (ovvero delegato con procura e/o estratto
      autentico di atto comunque denominato n. _____ del ________) del Soggetto
      Attuatore <b> {{ programma.nomeIstituzione }} </b>
      , avente sede legale a
      <b>
        {{
          datiSoggettoAttuatore && datiSoggettoAttuatore.citta
            ? datiSoggettoAttuatore.citta
            : "_________________"
        }}
      </b>
      , in Via/Piazza
      <b>
        {{
          datiSoggettoAttuatore && datiSoggettoAttuatore.via
            ? datiSoggettoAttuatore.via
            : "_________________"
        }} </b
      >, CAP
      <b>
        {{
          datiSoggettoAttuatore && datiSoggettoAttuatore.cap
            ? datiSoggettoAttuatore.cap
            : "_________________"
        }} </b
      >, PEC
      <b>
        {{
          datiSoggettoAttuatore && datiSoggettoAttuatore.pec
            ? datiSoggettoAttuatore.pec
            : "_________________"
        }} </b
      >, a seguito della concessione del finanziamento di cui al Decreto
      Direttoriale <b> n. 1321 del 09-08-2023 </b> e al Decreto Direttoriale
      <b> n. 1853 del 07-11-2023</b>.
    </p>

    <h4 align="center" style="margin-bottom: 0cm; line-height: 100%">
      <font size="2" style="font-size: 12pt">CHIEDE</font>
    </h4>

    <p>
      Per l’avvio di operatività dell’intervento di cui al Decreto Direttoriale
      <b> n. 1321 del 09-08-2023</b> e al Decreto Direttoriale
      <b> n. 1853 del 07-11-2023</b>, al quale è associato il Codice Unico di
      Progetto (CUP)<b> {{ programma.cup }} </b>
    </p>

    <ul>
      <li>
        <p>
          L’erogazione della somma di euro
          <b> {{ richiestaAnticipo.importo }} € </b>, a titolo di anticipo, pari
          al 10 % delle risorse assegnate dal Decreto di concessione del
          finanziamento e pari a euro
          <b> {{ programma.assegnazioneFinanziaria }} €</b>;
        </p>
      </li>

      <li>
        <p>
          Il versamento dell’anzidetto anticipo
          <span
            v-if="
              richiestaAnticipo.ibanContoBancario &&
              richiestaAnticipo.ibanBancario !== ''
            "
          >
            sul conto corrente con IBAN
            <b> {{ richiestaAnticipo.ibanContoBancario }}</b>
          </span>
          <span v-else>
            sul conto di tesoreria n.<b>
              {{ richiestaAnticipo.ibanTesoreria }}</b
            >
          </span>
          ,intestato a
          <b> {{ programma.nomeIstituzione }} </b>, nel rispetto del principio
          di sana gestione finanziaria;
        </p>
      </li>
    </ul>

    <p>
      consapevole della responsabilità penale cui può andare incontro in caso di
      dichiarazione falsa o comunque non corrispondente al vero ai sensi del
      D.P.R. n. 445 del 28 dicembre 2000 e ss.mm.ii.,
    </p>
    <h4 align="center" style="margin-bottom: 0cm; line-height: 100%">
      <font size="2" style="font-size: 12pt">DICHIARA</font>
    </h4>

    <ul>
      <li>
        di rispettare quanto previsto dal Decreto Ministeriale n. 934 del 3
        agosto 2022 ed in particolare dall’art. 6, comma 7 in merito alla
        restituzione delle somme erogate e non utilizzate;
      </li>
      <li>
        di rispettare quanto sottoscritto con l’atto di accettazione dei
        finanziamenti e degli obblighi;
      </li>
      <li>
        di impegnarsi a comunicare tempestivamente ogni modifica ai dati di cui
        sopra;
      </li>
    </ul>
    <v-main style="margin-top: 5%">
      <i>
        Il/La sottoscritto/a dichiara altresì di essere informato/a, ai sensi
        del regolamento (UE) 2016/679 (regolamento europeo in materia di
        protezione dei dati personali, in sigla GDPR), che i dati personali
        raccolti saranno trattati secondo l'Informativa sul trattamento dei dati
        personali presente sul sistema Orientamento 2026.
      </i>
    </v-main>
  </div>
</template>
<script>
import IstitutoRepository from "@/api/istituto/IstitutoRepository";
export default {
  name: "attestazioneRichiestaAnticipo",
  components: {},
  props: {
    programma: {
      type: Object,
      required: true,
    },
    richiestaAnticipo: {
      type: Object,
      required: true,
    },
    legaleRappresentante: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    datiBnc: null,
    datiSoggettoAttuatore: null,
    loadingComponent: false,
    loadingDataSoggettoAttuatore: false,
    loadingDataBnc: false,
  }),
  created() {
    this.initializeComponent();
  },
  methods: {
    initializeComponent() {
      if (
        this.programma &&
        this.legaleRappresentante &&
        this.richiestaAnticipo
      ) {
        const idStrutture = this.programma.idStrutture;
        const anno = this.programma.anno;
        this.getDatiSoggettoAttuatore();
        this.getDatiBncSoggettoAttuatore(idStrutture, anno);
      }
    },
    async getDatiSoggettoAttuatore() {
      const idStrutture = this.programma.idStrutture;
      this.datiSoggettoAttuatore =
        await IstitutoRepository.getDatiSoggettoAttuatore(idStrutture);
      this.loadingDataSoggettoAttuatore = true;
    },
    async getDatiBncSoggettoAttuatore(idStrutture, anno) {
      this.datiBnc = await IstitutoRepository.getDatiBancariStruttura(
        idStrutture,
        anno
      );
      this.loadingDataBnc = true;
    },
    getLoadingComponent() {
      return !!(this.loadingDataSoggettoAttuatore && this.loadingDataBnc);
    },
  },
};
</script>
