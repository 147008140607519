<template>
  <div class="footer flex-container">
    <div class="supporto">
      Per richieste di accesso alla piattaforma, informazioni e comunicazioni:
      <a
        href="https://orientamento2026-backend.cineca.it/support.php?cmp=65434"
        class="txt2"
      >
        <strong>SUPPORTO</strong>
      </a>
    </div>
    <span class="privacy"
      ><a
        target="_blank"
        href="https://orientamento2026-backend.cineca.it/uploads/2022/istituto/Informativa_Privacy_Piattaforma_Orientamento_2026.pdf"
        class="txt2"
        ><strong>Informativa privacy</strong></a
      ></span
    >
    <div class="cineca">
      in collaborazione con
      <a href="https://www.cineca.it" class="txt2"><strong>CINECA</strong></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogoutComponent",
  data: () => ({
    url: null,
  }),
  methods: {},
};
</script>

<style scoped>
.footer {
  /*margin-top: auto;*/
  /*display: flex;*/
  align-items: center;
  justify-content: center;
  min-height: 64px;
  font-family: Ubuntu-Regular, sans-serif;
  /*font-size: 1rem;*/
}

.footer > .supporto {
  padding-left: 20px;
  padding-right: 20px;
}

.footer > .cineca {
  padding-right: 20px;
  padding-left: 20px;
}

.footer > .privacy {
  padding-right: 20px;
  padding-left: 40px;
}

.flex-container {
  min-height: 64px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.flex-container > span {
  flex-grow: 1;
}

footer > .cineca {
  border: 2px red solid;
  flex-grow: 0;
  align-items: flex-end;
}
</style>
