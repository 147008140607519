import Repository from "@/api/Repository";

export default {
  async getIstitutoScolasticoByCodice(codiceScuola) {
    let url = `api/generici/scuole-scolastiche/${codiceScuola}`;
    return (await Repository.get(url)).data;
  },
  async getIstituti() {
    const url = `api/generici/scuole`;
    return (await Repository.get(url)).data;
  },
};
