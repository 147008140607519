<template>
  <v-row justify="center">
    <v-dialog :value="dialog" persistent max-width="500">
      <v-card>
        <v-toolbar color="primary" dark class="text-h6">{{ title }}</v-toolbar>
        <v-card-text>
          <div class="pa-4">
            <p class="pt-6">
              Prosegui l'operazione <strong>aprendo il file</strong> appena
              scaricato.
            </p>
            <p>
              <strong>Firma</strong> i certificati attraverso l'applicativo
              Confirma e assicurati che l'esito dell'operazione sia positivo
            </p>
            <p>
              Al termine della procedura premere
              <strong>'Completato'</strong> (anche in caso di esito
              <strong>negativo</strong>)
            </p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="handleOk">
            Completato
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "DialogProsegui",
  props: {
    // editedId: Number,
    capofila: {
      type: String,
      required: true,
    },
    dialog: Boolean,
    title: {
      type: String,
      required: false,
      default: "Firma i certificati in Confirma",
    },
    response: {
      type: Object,
      required: false,
      default: null,
    },
  },
  watch: {
    response: {
      deep: true,
      handler: function () {
        //se cambia la response devo gestire la risposta
      },
    },
  },
  computed: {},
  data() {
    return {};
  },

  methods: {
    handleOk(event) {
      // Prevent modal from closing
      event.preventDefault();
      this.$emit("callback");
      /*this.$router.push({
        name: "certificati-corsi",
        params: { capofila: this.capofila },
      });*/
    },
    close() {
      this.$emit("close-dialog");
      this.$emit("callback");
    },
  },
};
</script>

<style scoped></style>
