<template>
  <div>
    <v-card
      elevation="8"
      class="mx-auto"
      max-width="800px"
      :loading="loadingTarget"
    >
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <h2>Target Proposto</h2>
          </v-col>
        </v-row>

        <v-form ref="form" v-if="target">
          <v-row>
            <v-col cols="4">
              <v-subheader>Numero studenti assegnati</v-subheader>
            </v-col>
            <v-col cols="4" class="field">
              <div v-if="!editMode">{{ target.numeroStudenti }}</div>
              <v-text-field
                v-else
                v-model.number="target.numeroStudenti"
                type="number"
                :rules="validazionePercentuale(targetOriginale.numeroStudenti)"
                @input="inputNumeroStudenti"
                @keyup="inputNumeroStudenti"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-subheader>Numero corsi assegnati</v-subheader>
            </v-col>
            <v-col cols="4" class="field">
              <div v-if="!editMode">{{ target.numeroCorsi }}</div>
              <div v-else>
                <v-text-field
                  v-model.number="target.numeroCorsi"
                  :rules="validazione(targetOriginale.numeroCorsi)"
                  type="number"
                  @input="validateField"
                  @keyup="validateField"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-subheader
                >Numero di accordi con le scuole per l’organizzazione dei
                corsi</v-subheader
              >
            </v-col>
            <v-col cols="4" class="field">
              <div v-if="!editMode">{{ target.numeroAccordi }}</div>
              <v-text-field
                v-else
                v-model.number="target.numeroAccordi"
                :rules="validazione(targetOriginale.numeroAccordi)"
                type="number"
                @input="validateField"
                @keyup="validateField"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-subheader> Assegnazione finanziaria</v-subheader>
            </v-col>
            <v-col cols="4" class="field">
              <div>{{ target.attribuzioneFinanziaria | formatNumber }} €</div>
            </v-col>
          </v-row>

          <template v-if="!loadingTarget">
            <v-row v-if="isInviata">
              <v-col cols="12">
                <v-alert
                  v-if="target.statoIstituzione.id === 1"
                  outlined
                  type="success"
                  text
                  >Il target è stato accettato in data:
                  {{ target.dataUltimaModifica | formatDatetimeLong }}
                </v-alert>
                <v-alert
                  v-if="target.statoIstituzione.id === 2"
                  outlined
                  type="error"
                  text
                  >Il target è stato rifiutato in data:
                  {{ target.dataUltimaModifica | formatDatetimeLong }}
                </v-alert>
                <v-alert
                  v-if="target.statoIstituzione.id === 3"
                  outlined
                  type="success"
                  text
                  >Il target è stato modificato in data:
                  {{ target.dataUltimaModifica | formatDatetimeLong }}.
                </v-alert>
                <v-btn
                  v-if="!readOnly"
                  color="info"
                  @click="dialogRiapri = true"
                >
                  Riapri
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-else-if="!readOnly" justify="space-between">
              <template v-if="editMode">
                <v-col>
                  <v-btn
                    :loading="loadingTarget"
                    :disabled="loadingTarget"
                    color="info"
                    @click="resetTarget()"
                  >
                    Annulla modifiche
                  </v-btn>
                  <v-btn
                    class="mx-3"
                    :loading="loadingTarget"
                    :disabled="loadingTarget || !validate"
                    color="success"
                    @click="dialogModifica = true"
                  >
                    Salva
                  </v-btn>
                </v-col>
              </template>
              <template v-else>
                <v-col cols="4">
                  <v-btn
                    :loading="loadingTarget"
                    :disabled="loadingTarget"
                    color="success"
                    @click="dialogAccetta = true"
                  >
                    Accetta
                  </v-btn>
                  <v-btn
                    class="mx-3"
                    v-if="mostraPulsanteModifica"
                    :loading="loadingTarget"
                    :disabled="loadingTarget"
                    color="primary"
                    @click="modificaTarget()"
                  >
                    Modifica
                  </v-btn>
                </v-col>
                <v-col cols="2"></v-col>
                <v-col cols="2">
                  <v-btn
                    :loading="loadingTarget"
                    :disabled="loadingTarget"
                    color="danger"
                    @click="dialogRifiuta = true"
                  >
                    Rifiuta
                  </v-btn>
                </v-col>
              </template>
            </v-row>
          </template>
        </v-form>
      </v-container>
    </v-card>
    <dialog-conferma
      @callback="accettaTarget"
      :dialog.sync="dialogAccetta"
      @close-dialog="dialogAccetta = false"
      text-conferma="Accettando il target confermi i dati proposti per l'attribuzione finanziaria prestabilita dal Ministero."
    />
    <dialog-conferma
      @callback="rifiutaTarget"
      :dialog.sync="dialogRifiuta"
      @close-dialog="dialogRifiuta = false"
      text-conferma="Il rifiuto del target corrisponde alla rinuncia al finanziamento."
    />
    <dialog-conferma
      @callback="updateTarget"
      :dialog.sync="dialogModifica"
      @close-dialog="dialogModifica = false"
    />
    <dialog-conferma
      @callback="riapriTarget"
      :dialog.sync="dialogRiapri"
      @close-dialog="dialogRiapri = false"
      :text-conferma.sync="confermaRiaperturaTesto"
    />
    <AssegnazioneFinanziariaDefinitiva v-if="isPartecipanteProgramma" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DialogConferma from "@/components/DialogConferma";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";
import TargetRepository from "@/api/istituto/TargetRepository";
import AssegnazioneFinanziariaDefinitiva from "@/components/AssegnazioneFinanziariaDefinitiva";
import ProgrammiRepository from "@/api/istituto/ProgrammiRepository";

export default {
  name: "TargetAnnuale",
  components: {
    DialogConferma,
    AssegnazioneFinanziariaDefinitiva,
  },
  data: () => ({
    espandiTarget: false,
    mostraPulsanteModifica: null,
    programma: [],
    certificati: [],
    loadingTarget: true,
    targetDefault: {
      numeroStudenti: null,
      numeroCorsi: null,
      numeroAccordi: null,
      attribuzioneFinanziaria: null,
    },
    editMode: false,
    targetOriginale: null,
    target: null,
    dialogAccetta: false,
    dialogRifiuta: false,
    dialogModifica: false,
    dialogRiapri: false,
    validate: true,
    finestraCompilazione: null,
  }),
  computed: {
    ...mapState(["idStrutture", "anno"]),
    ...mapGetters([
      "isReadOnly",
      "isPartecipanteProgramma",
      "isRoleIstituto",
      "isRoleReferenteCorsi",
      "isRoleLegaleCorso",
    ]),
    isInviata() {
      return this.target != null && "statoIstituzione" in this.target;
    },
    readOnly() {
      if (this.isRoleLegaleCorso) return false;
      else if (this.isRoleIstituto || this.isRoleReferenteCorsi) return true;
      return (
        this.isReadOnly ||
        this.finestraCompilazione === null ||
        this.finestraCompilazione === ""
      );
    },
    confermaRiaperturaTesto() {
      let data = this.finestraCompilazione?.dataFine ?? "";
      data = data != "" ? this.$options.filters.formatDateLong(data) : "";
      return `La modifica del target sarà consentita sino al ${data}`;
    },
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        this.loadingTarget = true;

        this.target = { ...this.targetDefault };
        this.getTargetOriginale();
        this.getTarget();
        this.getFinestraCompilazione();
        this.getCertificatiFirmatiAnnoPrecedente();

        this.loadingTarget = false;
      }
    },
    async getProgrammaAnnoPrecedente() {
      this.programma = await ProgrammiRepository.getProgrammi(
        this.idStrutture,
        this.anno - 1,
        "Istituzione"
      );
      if (
        parseInt(this.certificati) >
        parseInt(this.programma[0].numeroAlunniCoinvolti)
      ) {
        this.espandiTarget = true;
      }
    },
    async getCertificatiFirmatiAnnoPrecedente() {
      this.loadingTarget = true;
      this.certificati = await TargetRepository.getCertificatiFirmati(
        this.idStrutture,
        this.anno
      );
      console.log(this.certificati);
      if (this.certificati) {
        this.mostraPulsanteModifica = true;
      }
      this.getProgrammaAnnoPrecedente();
      this.loadingTarget = false;
    },
    async getFinestraCompilazione() {
      this.finestraCompilazione =
        await IstitutoRepository.getFinestraCompilazione(
          this.idStrutture,
          this.anno,
          "target-annuale-ist"
        );
    },
    async getTargetOriginale() {
      this.targetOriginale = this.mergeTarget(
        await TargetRepository.getTargetOriginale(this.idStrutture, this.anno)
      );
    },
    async getTarget() {
      this.target = this.mergeTarget(
        await TargetRepository.getTarget(this.idStrutture, this.anno)
      );
    },
    async accettaTarget() {
      this.loadingTarget = true;
      this.target = this.mergeTarget(
        await TargetRepository.accettaTarget(this.idStrutture, this.anno)
      );
      this.loadingTarget = false;
    },
    async rifiutaTarget() {
      this.loadingTarget = true;
      this.target = this.mergeTarget(
        await TargetRepository.rifiutaTarget(this.idStrutture, this.anno)
      );
      this.loadingTarget = false;
    },
    async riapriTarget() {
      this.loadingTarget = true;
      this.target = this.mergeTarget(
        await TargetRepository.riapriTarget(this.idStrutture, this.anno)
      );
      this.editMode = false;
      this.loadingTarget = false;
    },
    updateTarget() {
      const target = { ...this.target };
      target.dataUltimaModifica = null;
      target.esito = null;
      TargetRepository.updateTarget(this.idStrutture, this.anno, target)
        .then((result) => {
          this.target = this.mergeTarget(result);
          this.editMode = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    modificaTarget() {
      this.editMode = !this.editMode;
    },
    resetTarget() {
      this.editMode = false;
      this.getTarget();
    },
    mergeTarget(target) {
      return { ...this.targetDefault, ...target };
    },
    inputNumeroStudenti() {
      this.validateField();

      //250 è una costante del file excel
      if (this.target != null) {
        this.target.attribuzioneFinanziaria = this.target.numeroStudenti * 250;
      }
    },
    validateField() {
      this.validate = this.$refs.form.validate();
    },
    validazione(val) {
      return [
        (v) =>
          (v || "") >= val ||
          `Il valore inserito non può essere minore del valore di partenza: ${val} .`,
        (v) =>
          !(Number(v) === v && v % 1 !== 0) ||
          "Non è consentito inserire cifre decimali.",
      ];
    },
    validazionePercentuale(val) {
      if (this.espandiTarget) {
        return [
          ...this.validazione(val),
          ...[
            (v) =>
              v <= 1.4 * val ||
              `Il valore inserito non può essere superiore a:  
            ${Math.floor(1.4 * val)} (40% valore di partenza ${val}).`,
          ],
        ];
      } else {
        return [
          ...this.validazione(val),
          ...[
            (v) =>
              v <= 1.2 * val ||
              `Il valore inserito non può essere superiore a:  
            ${Math.floor(1.2 * val)} (20% valore di partenza ${val}).`,
          ],
        ];
      }
    },
  },
};
</script>

<style scoped>
v-text-field {
  padding-top: 0;
}
.field {
  align-items: center;
  display: flex;
}
</style>
