<template>
  <v-container>
    <v-card v-if="showCard" elevation="4">
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td style="border-right: floralwhite">
                <v-card-title> Conferma Dati di Monitoraggio</v-card-title>
                <br />
                <v-form>
                  <v-card-actions>
                    <template v-if="isRoleLegale || isRoleConsulenza">
                      <v-btn
                        color="primary"
                        :disabled="
                          bottoneDisabilitato.check1 ||
                          bottoneDisabilitato.check2
                        "
                        @click="dialogConferma = true"
                      >
                        Conferma Tutto
                      </v-btn>
                    </template>
                    <DialogConferma
                      @callback="onSubmitMonitoraggio"
                      :dialog.sync="dialogConferma"
                      :title="'Conferma dati a sistema per il monitoraggio'"
                      :text-conferma="'Stai per confermare che i dati inseriti nelle singole sezioni del sistema rispecchiano lo stato di avanzamento del progetto'"
                      @close-dialog="dialogConferma = false"
                    >
                    </DialogConferma>
                    <v-snackbar
                      :color="snackbar.color"
                      v-model="snackbar.value"
                      height="100"
                      width="500"
                      multi-line
                      right
                    >
                      {{ snackbar.text }}
                    </v-snackbar>
                  </v-card-actions>
                </v-form>
              </td>

              <td>
                <v-card-text>
                  Giorni Mancanti<br />
                  <br />
                  <h2>{{ giorniMancantiMonitoraggio }}</h2>
                </v-card-text>
              </td>
              <td>
                <v-card-text>
                  Dati di monitoraggio <br />
                  mensili al <br />
                  <h2>{{ dataMonitoraggioAl }}</h2></v-card-text
                >
              </td>

              <td>
                <v-card-text>
                  Data ultima conferma <br />
                  <br />
                  <h2 v-if="dataUltimaConferma">
                    {{ dataUltimaConferma }}
                  </h2>
                </v-card-text>
              </td>
              <td>
                <v-card-text>
                  Data termine <br />
                  <br />
                  <h2>
                    <b> {{ dataTermineMonitoraggio }}</b>
                  </h2>
                </v-card-text>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>
import DialogConferma from "@/components/DialogConferma.vue";
import ProgrammiRepository from "@/api/istituto/ProgrammiRepository";
import MonitoraggiRepository from "@/api/istituto/MonitoraggiRepository";
import { mapGetters, mapState } from "vuex";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";

export default {
  name: "ConfermaMonitoraggio",
  components: { DialogConferma },
  computed: {
    ...mapState(["idStrutture", "anno"]),
    ...mapGetters([
      "isRoleConsulenza",
      "isRoleIstituto",
      "isRoleLegale",
      "isReadOnly",
    ]),
  },
  watch: {
    anno() {
      this.initialize();
    },
    idStrutture() {
      this.initialize();
    },
  },
  data: () => ({
    giorniMancantiMonitoraggio: null,
    dataMonitoraggioAl: null,
    dataTermineMonitoraggio: null,
    bottoneDisabilitato: {
      check1: true,
      check2: true,
    },
    dialogConferma: false,
    showCard: false,
    dialogConfermaMonitoraggio: false,
    datiRiepilogo: [],
    ultimoMonitoraggio: null,
    dataUltimaConferma: null,
    programma: null,
    monitoraggi: null,
    snackbar: {
      text: null,
      color: "green",
      value: false,
    },
    finestraCompilazione: null,
    checkBtnDisabled1: true,
  }),
  props: {
    capofila: {
      type: String,
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      let idStrutture = this.$store.state.idStrutture;
      let anno = this.$store.state.anno;
      let capofila = this.capofila;
      this.getProgrammaOrientamento(idStrutture, anno, capofila);
    },
    async getProgrammaOrientamento(idStrutture, anno, capofila) {
      await ProgrammiRepository.getProgrammi(idStrutture, anno, capofila).then(
        (data) => {
          if (data && data.length > 0) {
            this.programma = data[0];
            this.getFinestraCompilazioneMonitoraggi(idStrutture, anno);
            this.getMonitoraggiProgramma(idStrutture);
            this.getDateToShow();
            this.showCard = true;
          } else {
            this.showCard = false;
          }
        }
      );
    },
    async getFinestraCompilazioneMonitoraggi(idStrutture, anno) {
      await IstitutoRepository.getFinestraCompilazione(
        idStrutture,
        anno,
        "monitoraggi-ist"
      ).then((data) => {
        if (data) {
          // la compilazione del monitoraggio è attiva
          this.giorniMancantiMonitoraggio = "Attivo";
          this.bottoneDisabilitato.check1 = false;
          this.finestraCompilazione = data;
        } else {
          // calcolo i giorni mancanti all'apertura
          const dateOdierna = new Date();
          const dataInizioMonitoraggio = new Date(
            dateOdierna.getFullYear(),
            dateOdierna.getMonth() + 1,
            1
          );
          const diffDay = Math.abs(dateOdierna - dataInizioMonitoraggio);
          this.giorniMancantiMonitoraggio = Math.ceil(
            diffDay / (1000 * 60 * 60 * 24)
          );
        }
      });
    },
    async getMonitoraggiProgramma(idStrutture) {
      this.monitoraggi = await MonitoraggiRepository.getMonitoraggiProgramma(
        idStrutture,
        this.programma.id
      );
      const lengthArray = this.monitoraggi.length;
      if (lengthArray) {
        const ultimoMonitoraggioConfermato = this.monitoraggi.findLast(
          (element) => element.dataConfermaMonitoraggio
        );

        if (ultimoMonitoraggioConfermato != null) {
          const dataUltimaConferma = new Date(
            ultimoMonitoraggioConfermato.dataConfermaMonitoraggio
          );
          this.dataUltimaConferma = dataUltimaConferma.toLocaleDateString();

          const meseCorrente = new Date().getMonth();
          this.bottoneDisabilitato.check2 =
            dataUltimaConferma.getMonth() === meseCorrente;
        } else {
          // non esistono monitoraggi
          this.bottoneDisabilitato.check2 = false;
        }
      } else {
        this.bottoneDisabilitato.check2 = false;
      }
    },
    getDateToShow() {
      const dateOdierna = new Date();

      this.dataTermineMonitoraggio = new Date(
        dateOdierna.getFullYear(),
        dateOdierna.getMonth(),
        10
      ).toLocaleDateString();

      // data da mostrare nella colonna data di monitoraggio al
      this.dataMonitoraggioAl = new Date(
        dateOdierna.getFullYear(),
        dateOdierna.getMonth() - 1,
        30
      ).toLocaleDateString();
    },

    onSubmitMonitoraggio() {
      if (!this.isRoleLegale && !this.isRoleConsulenza) {
        return (this.snackbar = {
          text: "Il monitoraggio può essere confermato solo dal Legale Rappresentante",
          color: "red accent-2",
          value: true,
        });
      } else {
        let idStrutture = this.idStrutture;
        let anno = this.anno;
        let capofila = this.capofila;
        MonitoraggiRepository.confermaMonitoraggioMensile(
          idStrutture,
          anno,
          capofila
        )
          .then((data) => {
            if (data) {
              this.datiRiepilogo[0] = data;
              if (
                this.datiRiepilogo.length > 0 &&
                !this.dialogConfermaMonitoraggio
              ) {
                this.dialogConfermaMonitoraggio = true;
                this.initialize();
                return (this.snackbar = {
                  text: "Monitoraggio avvenuto con successo. Tutti i dati fin qui inseriti sono stati storicizzati",
                  color: "green",
                  value: true,
                });
              }
            }
          })
          .catch((reason) => {
            console.log(reason);
            return (this.snackbar = {
              text: "Il monitoraggio non è andato a buon fine. Si prega di contattare il supporto.",
              color: "red accent-2",
              value: true,
            });
          });
      }
    },
  },
};
</script>
<style scoped></style>
