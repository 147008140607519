import Repository from "../Repository";

export default {
  async getUtenti(idStrutture, ruolo = null) {
    let url = `api/istituto/${idStrutture}/utenti`;
    if (ruolo) {
      url += `/ruolo/${ruolo}`;
    }
    return (await Repository.get(url)).data;
  },
  async creaUtente(idStrutture, data) {
    let url = `api/istituto/${idStrutture}/utente`;
    return (await Repository.post(url, data)).data;
  },
  async updateUtente(idStrutture, data) {
    let url = `api/istituto/${idStrutture}/utente`;
    return (await Repository.put(url, data)).data;
  },
  async deleteUtente(idStrutture, idUtente) {
    let url = `api/istituto/${idStrutture}/utente/${idUtente}`;
    return (await Repository.delete(url)).data;
  },
  async getUtenteById(id) {
    let url = `api/istituto/${id}/utente`;
    return (await Repository.get(url)).data;
  },
};
