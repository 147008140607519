var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('h3',[_vm._v("Elenco dei sotto accordi")]),(_vm.itemsSottoAccordi.length > 0)?_c('v-card',{attrs:{"elevation":"4"}},[_c('v-data-table',{attrs:{"headers":_vm.headersSottoAccordi,"items":_vm.itemsSottoAccordi},scopedSlots:_vm._u([{key:"item.pdfSottoAccordo",fn:function({ item }){return [(item.fileSottoAccordo !== null)?_c('span',[_c('v-list-item',{on:{"click":function($event){return _vm.downloadPdfSottoAccordo(item.id, item.fileSottoAccordo.nomeOriginale)}}},[_c('v-icon',[_vm._v(" mdi-paperclip")])],1)],1):_c('span',[_vm._v(" Nessun PDF è stato caricato ")])]}},{key:"item.azioni",fn:function({ item }){return [(_vm.finestraCompilazioneAttiva)?_c('div',[_c('router-link',{attrs:{"to":{
            name: 'aggiungiSottoAccordo',
            params: {
              capofila: _vm.capofila,
              idAccordo: _vm.idScuolaAccordo,
              operazione: 'modifica',
              idSottoAccordo: item.id
            },
          }}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1):_vm._e()]}}],null,false,56317307)})],1):_c('v-card',[_c('span',[_vm._v(" Nessun sotto-accordo con l'istituto scolastico corrispondente è stato creato. ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }