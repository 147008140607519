<template>
  <v-row justify="center">
    <v-dialog :value="dialog" persistent max-width="500">
      <v-card>
        <v-toolbar color="primary" dark class="text-h6">{{ title }}</v-toolbar>
        <v-card-text>
          <div class="pa-4">{{ text }}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="close"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "DialogMessage",
  props: {
    dialog: Boolean,
    title: {
      type: String,
      required: false,
      default: "Attenzione!",
    },
    text: {
      type: String,
      required: false,
      default: "Operazione conclusa con successo!",
    },
  },

  computed: {},
  data() {
    return {};
  },

  methods: {
    close() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style scoped></style>
