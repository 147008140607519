<template>
  <v-container>
    <div>
      <BreadcrumbComponent :breadcrumbs="breadcrumbs" v-if="capofila" />
      <br />
      <h2>Erogazione Corsi</h2>
      <br />
    </div>
    <div v-if="loading" class="mb-lg-10">
      <v-main class="pb-2">
        <v-alert border="top" colored-border type="info" elevation="2">
          Il pulsante 'termina corso' non sarà cliccabile se:
          <ul>
            <li>
              Non viene raggiunto 100% delle lezioni erogate per il corso
              specifico.
            </li>
            <li>
              Il file allegato accordo-scuola, di cui il corso specifico è
              compreso, non è stato caricato.
            </li>
          </ul>
          <br />
        </v-alert>
        <v-alert border="top" colored-border type="info" elevation="2">
          Il pulsante “Genera link” accessibile dal pulsante Azioni >
          Questionari di valutazione verrà generato dopo il censimento di almeno
          4 alunni/e nel corso. <br />
          <b>
            Si consiglia di somministrare il questionario agli alunni/e una
            volta svolto almeno il 70% delle attività.</b
          >
        </v-alert>
      </v-main>
      <v-row class="my-4">
        <v-col col="8">
          <v-text-field
            solo
            v-model="ricerca"
            append-icon="mdi-magnify"
            label="Cerca per ID Corso, istituto, stato corso..."
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-card elevation="1" v-if="corsi && corsi.length > 0">
        <v-data-table
          :headers="headers"
          :items="corsi"
          :search="ricerca"
          :item-key="corsi.id"
        >
          <!-- eslint-disable -->
          <template v-slot:item.istitutoScolastico="{ item }">
            <span v-html="item.istitutoScolastico"></span>
          </template>
          <template v-slot:item.dataInizio="{ item }">
            <span v-html="convertData(item.dataInizio)" />
          </template>
          <template v-slot:item.dataFine="{ item }">
            <span v-html="convertData(item.dataFine)" />
          </template>
          <template v-slot:item.avanzamentoCorso="{ item }">
            {{ Math.ceil(calcolaNumeroOreLezioni(item.lezioni)) }}%
          </template>
          <template v-slot:item.azioni="{ item }">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    class="v-icon"
                    v-bind="attrs"
                    v-on="on"
                    :icon="true"
                    :disabled="
                      checkOperazioniScrittura < 0 ||
                      (item.referente.id !== userId &&
                        isRoleReferenteIstitutoCorso)
                    "
                >
                  <v-icon> mdi-dots-vertical </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                    class="item-menu"
                    v-for="(itemMenu, i) in menuAction"
                    :key="i"
                    :disabled="checkAccessPermission(i, item)"
                >
                  <router-link
                      class="v-btn"
                      :to="{
                        name: itemMenu.nameComponent,
                        params: {
                          capofila: capofila,
                          idCorso: parseInt(item.id),
                        },
                      }"
                    >
                      {{ itemMenu.text }}
                      <v-icon> {{ itemMenu.icon }}</v-icon>
                    </router-link>
                  </v-list-item>
                </v-list>
              </v-menu>
          </template>
          <!-- eslint-enable -->
        </v-data-table>
      </v-card>
      <v-card v-else>
        <h2 v-html="stampaMessaggio" />
      </v-card>
    </div>
    <div v-else>
      <v-progress-linear indeterminate color="blue"></v-progress-linear>
    </div>
  </v-container>
</template>
<script>
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import { mapGetters, mapState } from "vuex";
import CorsiRepository from "@/api/istituto/CorsiRepository";

export default {
  name: "erogazioneCorsi",
  components: { BreadcrumbComponent },
  props: {
    capofila: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(["anno", "idStrutture", "user"]),
    ...mapGetters([
      "isRoleReferenteCorsi",
      "isRoleReferenteIstituto",
      "isRoleConsulenza",
      "isReadOnly",
      "isRoleReferenteIstitutoCorso",
      "getUser",
    ]),
    readOnly() {
      if (this.isReadOnly || this.isPartner) return true;
      else if (!this.finestraCompilazione) {
        return true;
      } else if (!this.finestraCompilazione.attiva) {
        return true;
      }
      return false;
    },
    loading() {
      return this.loadingPage;
    },
    breadcrumbs: function () {
      if (this.capofila) {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania/${this.capofila}`,
            exact: true,
            link: true,
          },
          {
            text: "Censimento, erogazione e validazione corsi",
            disabled: false,
            to: `/scrivania/${this.capofila}/corsi`,
            exact: true,
            link: true,
          },
          {
            text: "Erogazione corsi",
            disabled: false,
            to: `/scrivania/${this.capofila}/corsi/erogazione-corsi`,
            exact: true,
            link: true,
          },
        ];
      } else {
        return [];
      }
    },
    stampaMessaggio: function () {
      let message =
        this.roleUser === "ROLE_REFERENTE_CORSI"
          ? "Non esistono corsi da visualizzare, di cui sei referente."
          : "Non esistono corsi da visualizzare per l'istituto di appartenenza.";
      return message;
    },
  },
  watch: {
    anno() {
      return this.initializeView();
    },
    idStrutture() {
      return this.initializeView();
    },
    user() {
      return this.initializeView();
    },
  },
  created() {
    this.initializeView();
    this.findCorsiReferente();
  },
  data: () => ({
    menuAction: [
      {
        text: "Registra presenze",
        ambitoOperazione: "presenze",
        icon: null,
        nameComponent: "registroPresenze",
      },
      {
        text: "Termina il corso",
        ambitoOperazione: "termina",
        nameComponent: "terminaCorso",
        icon: null,
      },
      {
        text: "Questionari di valutazione",
        ambitoOperazione: "questionari",
        nameComponent: "questionarioView",
        icon: null,
      },
    ],
    headers: [
      { text: "ID Corso", value: "id" },
      {
        text: "Stato",
        align: "start",
        sortable: true,
        value: "stato",
      },
      { text: "Istituto Scolastico", value: "istitutoScolastico" },
      { text: "Data Inizio", value: "dataInizio" },
      { text: "Data Fine", value: "dataFine" },
      { text: "Numero studenti", value: "numeroStudenti" },
      { text: "% Avanzamento del corso", value: "avanzamentoCorso" },
      { text: "Azioni", sortable: false, value: "azioni" },
    ],
    ricerca: null,
    loadingPage: false,
    userId: null,
    corsi: null,
    checkOperazioniScrittura: 1,
    roleUser: null,
  }),
  methods: {
    initializeView() {
      this.checkPermessoOperazioni();
      this.findCorsiReferente();
    },
    checkPermessoOperazioni() {
      this.userId = this.user.id;
      if (this.readOnly) {
        this.checkOperazioniScrittura = 1;
        this.roleUser = "ROLE_CONSULENZA";
      } else if (this.isRoleReferenteIstitutoCorso) {
        // CASO IN CUI SONO ENTRAMBI I RUOLI
        this.checkOperazioniScrittura = 0;
        this.roleUser = "ROLE_REFERENTE_ISTITUTO";
      } else if (this.isRoleReferenteCorsi) {
        // CASO IN CUI SONO REFERENTE_CORSO
        this.roleUser = "ROLE_REFERENTE_CORSI";
        this.checkOperazioniScrittura = 1;
      } else {
        // per tutto gli altri casi simulo il role_referente_istituto con solo visualizzazione
        this.checkOperazioniScrittura = -1;
      }
    },
    async findCorsiReferente() {
      let idStrutture = this.$store.state.idStrutture;
      let anno = this.$store.state.anno;
      let capofila = this.capofila;

      this.corsi = await CorsiRepository.findCorsiReferenteCorsi(
        idStrutture,
        anno,
        capofila
      );

      this.corsi.map((c) => {
        // formattazione statoCorso
        let dataInizio = new Date(c.dataInizio);
        let dataFine = new Date(c.dataFine);
        const dateOdierna = new Date();
        if (c.terminato) {
          c.stato = "TERMINATO";
        } else if (dateOdierna < dataInizio) {
          c.stato = "DA EROGARE";
        } else if (dateOdierna >= dataInizio && dateOdierna <= dataFine) {
          c.stato = "IN EROGAZIONE";
        } else {
          c.stato = "EROGATO";
        }

        //formattazione IstititoScolastico
        if (c.accordi && c.accordi.length > 0) {
          let testo = "";
          c.accordi.forEach(({ istituto }) => {
            testo += `${istituto.denominazioneScuola} / <br />`;
          });
          c.istitutoScolastico = testo;
        } else {
          c.istitutoScolastico = c.accordo.istituto.denominazioneScuola;
        }
      });
      this.loadingPage = true;
    },
    convertData(data) {
      if (data) {
        return new Date(data).toLocaleDateString("nl-NL", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        });
      }
    },
    calcolaNumeroOreLezioni(lezioni) {
      if (lezioni && lezioni.length > 0) {
        const totalSum = lezioni.reduce((v, lezione) => {
          return v + lezione["oreErogate"];
        }, 0);
        return (totalSum / 15) * 100;
      } else {
        return 0;
      }
    },
    getStatoCorso(dataInizio, dataFine, terminato) {
      dataInizio = new Date(dataInizio);
      dataFine = new Date(dataFine);
      const dateOdierna = new Date();
      if (terminato) {
        return "TERMINATO";
      }
      if (dateOdierna < dataInizio) {
        return "DA EROGARE";
      } else if (dateOdierna >= dataInizio && dateOdierna <= dataFine) {
        return "IN EROGAZIONE";
      } else {
        return "EROGATO";
      }
    },
    checkAccessPermission(indexMenu, corsoScelto) {
      let access = true;
      switch (indexMenu) {
        case 0:
          access = false;
          break;
        case 1:
          access = !(
            (this.CheckFileAccordi(corsoScelto) &&
              this.calcolaNumeroOreLezioni(corsoScelto.lezioni) === 100 &&
              this.isRoleReferenteCorsi) ||
            this.isRoleConsulenza
          );
          break;
        case 2:
          access = !(
            (this.isRoleConsulenza ||
              (this.isRoleReferenteCorsi &&
                this.user.id === corsoScelto.referente.id)) &&
            this.anno > 2022
          );
          break;
        // eslint-disable-next-line no-fallthrough
        default:
          access = false;
          break;
      }
      return access;
    },
    getNomeIstituto(corso) {
      if (corso.accordi && corso.accordi.length > 0) {
        let testo = "";
        corso.accordi.forEach((item) => {
          testo += item.istituto.denominazioneScuola + "/<br />";
        });
        return testo;
      } else {
        return corso.accordo.istituto.denominazioneScuola;
      }
    },
    CheckFileAccordi(corso) {
      if (corso.accordi && corso.accordi.length > 0) {
        return !corso.accordi.some((item) => {
          if (item.fileAccordo == null) {
            return true;
          }
        });
      } else {
        return !!corso.accordo.fileAccordo;
      }
    },
  },
};
</script>

<style scoped>
.v-card {
  font-size: 14px;
  text-align: center;
}

.row-table {
  border-bottom: 1px solid #efe8e8;
  border-opacity: 0.7%;
}
.item-menu:hover {
  background-color: #c2b3b3;
}
</style>
