<template>
  <div v-if="rendiconto">
    <RiepilogoLegaleRappresentanteComponent
      :id-programma="rendiconto.programma.id"
    />
    <v-card v-if="accordo" elevation="4" class="text-center">
      <h4
        v-html="'Dati soggetto attuatore'"
        class="font-weight-bold text-left sottotitolo"
      />
      <v-row class="p-4">
        <v-col class="w-25">
          <v-card-text> <b>Denominazione Istituto</b> </v-card-text></v-col
        >
        <v-col class="w-25">
          <v-card-text> <b>Sede legale</b> </v-card-text></v-col
        >
        <v-col class="w-25"
          ><v-card-text> <b>Indirizzo</b> </v-card-text></v-col
        >
      </v-row>
      <v-row>
        <v-col class="w-50">
          <v-card-text>
            {{ rendiconto.programma.nomeIstituzione }}
          </v-card-text></v-col
        >
        <v-col class="w-25"
          ><v-card-text>
            {{ accordo.cittaStruttura }}
          </v-card-text>
        </v-col>
        <v-col class="w-25"
          ><v-card-text>
            {{ accordo.indirizzoStruttura }}
          </v-card-text>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="w-25">
          <v-card-text> <b> P.IVA</b> </v-card-text></v-col
        >
        <v-col class="w-25">
          <v-card-text> <b>CAP</b> </v-card-text></v-col
        >
        <v-col class="w-25">
          <v-card-text> <b>PEC</b> </v-card-text></v-col
        >
      </v-row>
      <v-row v-if="datiStruttura">
        <v-col class="w-25">
          <v-card-text
            ><p>{{ accordo.pIvaStruttura }}</p>
          </v-card-text></v-col
        >
        <v-col class="w-25">
          <v-card-text
            ><span v-if="datiStruttura.cap"> {{ datiStruttura.cap }}</span
            ><span v-else><b> Non disponibile </b></span></v-card-text
          ></v-col
        >
        <v-col class="w-25">
          <v-card-text>
            <span v-if="datiStruttura.pec"> {{ datiStruttura.pec }}</span
            ><span v-else><b> Non disponibile </b></span>
          </v-card-text></v-col
        >
      </v-row>
    </v-card>
    <v-card v-if="rendiconto" elevation="4" class="text-center">
      <h4 class="font-weight-bold text-left sottotitolo">Importo</h4>
      <v-row>
        <v-col>
          <p><b> Tipologia erogazione </b></p></v-col
        >
        <v-col> <b> Importo richiesto </b></v-col>
        <v-col> <b>Iban/Conto di Tesoreria </b> </v-col>
      </v-row>
      <v-row>
        <v-col><p>Erogazione Intermedia</p></v-col>
        <v-col>
          <p>{{ rendiconto.importoRichiesto }}</p>
        </v-col>
        <v-col>
          <b>
            {{
              rendiconto.ibanBancario
                ? rendiconto.ibanBancario
                : rendiconto.contoTesoreria
            }}</b
          ></v-col
        >
      </v-row>
    </v-card>
  </div>
</template>
<script>
import RichiesteRepository from "@/api/istituto/RichiesteRepository";
import ScuoleAccordoRepository from "@/api/istituto/ScuoleAccordoRepository";
import RiepilogoLegaleRappresentanteComponent from "@/components/Rendiconti/RiepilogoLegaleRappresentanteComponent";
import RendicontiRepository from "@/api/istituto/RendicontiRepository";

export default {
  name: "dettaglioStep2",
  components: { RiepilogoLegaleRappresentanteComponent },
  props: {
    rendiconto: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    datiStruttura: null,
    loadingPage: false,
    dialogConferma: false,
    accordo: null,
    struttura: null,
    richiestaAnticipo: null,
  }),
  created() {
    this.getAccordi();
    this.getRichiestaAnticipoSaldo();
    this.getDatiStruttura();
  },
  methods: {
    async getRichiestaAnticipoSaldo() {
      this.richiestaAnticipo =
        await RichiesteRepository.getRichiestaAnticipoPresentata(
          this.rendiconto.idStrutture,
          this.rendiconto.anno,
          this.rendiconto.capofila
        );
    },
    async getAccordi() {
      let accordi = await ScuoleAccordoRepository.getScuoleAccordi(
        this.rendiconto.idStrutture,
        this.rendiconto.anno,
        this.rendiconto.capofila
      );
      this.accordo = accordi[0];
      //this.soggettoAttuatore.cf = this.accordo.pIvaStruttura;
      //this.soggettoAttuatore.comuneSede = this.accordo.cittaStruttura;
    },
    async getDatiStruttura() {
      this.datiStruttura = await RendicontiRepository.getDatiStruttura(
        this.rendiconto.idStrutture
      );
    },
  },
};
</script>

<style scoped>
.sottotitolo {
  color: #1a237e;
  margin-left: 1%;
  font-weight: bold;
}
</style>
