import Repository from "../Repository";

export default {
  async getClassiStudente() {
    const url = `api/generici/classi-studente`;
    return (await Repository.get(url)).data;
  },
  async getGeneriStudente() {
    const url = `api/generici/generi-studente`;
    return (await Repository.get(url)).data;
  },
  async getListaComuniAttivi() {
    const url = `api/generici/lista-comuni-attivi`;
    return (await Repository.get(url)).data;
  },
  async getListaStatiAttivi() {
    const url = `api/generici/lista-stati-attivi`;
    return (await Repository.get(url)).data;
  },
  async getScuoleAccordi(idStrutture, anno) {
    const url = `api/istituto/${idStrutture}/anno/${anno}/scuole-accordi`;
    return (await Repository.get(url)).data;
  },
  async createStudente(idStrutture, data) {
    const url = `api/istituto/${idStrutture}/corsi/inserisci-studente`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  async createStudenteCorso(
    idStrutture,
    idStudente,
    idCorso,
    classe,
    istituto,
    data
  ) {
    const url = `api/istituto/${idStrutture}/corsi/${idCorso}/inserisci-studente-corso/${idStudente}/${classe}/${istituto}`;
    return (await Repository.post(url, data)).data;
  },
  async createCorsoOrientamento(idStrutture, anno, capofila, data) {
    const url = `api/istituto/${idStrutture}/corsi/inserisci-corso/${anno}/${capofila}`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  // utilizzo del metodo post per problemi lato backend, dovrebbe essere utilizzata una put
  async updateCorsoOrientamento(idStrutture, anno, capofila, idCorso, data) {
    const url = `api/istituto/${idStrutture}/corsi/aggiorna-corso/${anno}/${capofila}/${idCorso}`;
    return (await Repository.post(url, data)).data;
  },
  async getCorsi(idStrutture, anno, capofila) {
    const url = `api/istituto/${idStrutture}/corsi/anno/${anno}/${capofila}`;
    return (await Repository.get(url)).data;
  },
  async getTipiFormazione() {
    const url = `api/generici/tipi-formazione`;
    return (await Repository.get(url)).data;
  },
  async getTipiAttivita() {
    const url = `api/generici/tipi-attivita`;
    return (await Repository.get(url)).data;
  },
  async getCorsoOrientamento(idStrutture, anno, idCorso) {
    const url = `api/istituto/${idStrutture}/corsi/anno/${anno}/corso/${idCorso}`;
    return (await Repository.get(url)).data;
  },
  async getStudentiCorso(idStrutture, idCorso) {
    const url = `api/istituto/${idStrutture}/corsi/${idCorso}/lista-studenti-corso`;
    return (await Repository.get(url)).data;
  },
  async findCorsiReferenteCorsi(idStrutture, anno, capofila) {
    const url = `api/istituto/${idStrutture}/corsi/lezioni-associate/anno/${anno}/${capofila}`;
    return (await Repository.get(url)).data;
  },
  async getIstitutiScolasticiReferente(idStrutture, anno, capofila) {
    const url = `api/istituto/${idStrutture}/corsi/anno/${anno}/lista-istituti-scolastici/${capofila}`;
    return (await Repository.get(url)).data;
  },
  async deleteStudente(idStrutture, idCorso, idStudente) {
    let url = `api/istituto/${idStrutture}/corsi/${idCorso}/studente/${idStudente}/delete`;
    return (await Repository.delete(url)).data;
  },
  async deleteStudenteCorso(idStrutture, idCorso, idStudente) {
    let url = `api/istituto/${idStrutture}/corsi/${idCorso}/studente-corso/${idStudente}/delete`;
    return (await Repository.delete(url)).data;
  },
  async registraNuovaLezione(idStrutture, idCorso, bodyLezione) {
    let url = `api/istituto/${idStrutture}/corsi/${idCorso}/lezioni`;
    const headers = { "Content-Type": "multipart/form-data" };
    return await Repository.post(url, bodyLezione, { headers });
  },
  async getLezioniCorso(idStrutture, idCorso) {
    let url = `api/istituto/${idStrutture}/corsi/${idCorso}/lezioni`;
    return (await Repository.get(url)).data;
  },
  async modificaLezione(idStrutture, idCorso, idLezione, bodyLezione) {
    let url = `api/istituto/${idStrutture}/corsi/${idCorso}/lezioni/${idLezione}`;
    return await Repository.post(url, bodyLezione, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  async getRuoliDocente() {
    const url = `api/generici/ruoli-docente`;
    return (await Repository.get(url)).data;
  },
  async createDocente(idStrutture, data) {
    const url = `api/istituto/${idStrutture}/corsi/inserisci-docente`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  async createDocenteCorso(idStrutture, idDocente, idCorso, ruolo, data) {
    const url = `api/istituto/${idStrutture}/corsi/${idCorso}/inserisci-docente-corso/${idDocente}/${ruolo}`;
    return (await Repository.post(url, data)).data;
  },
  async getDocentiCorso(idStrutture, idCorso) {
    const url = `api/istituto/${idStrutture}/corsi/${idCorso}/lista-docenti-corso`;
    return (await Repository.get(url)).data;
  },
  async deleteDocenteCorso(idStrutture, idCorso, idDocente) {
    let url = `api/istituto/${idStrutture}/corsi/${idCorso}/docente-corso/${idDocente}/delete`;
    return (await Repository.delete(url)).data;
  },
  async salvaGenereStudente(idStrutture, idGenere, idStudente, data) {
    const url = `api/istituto/${idStrutture}/corsi/salva-genere-studente/${idGenere}/${idStudente}`;
    return (await Repository.post(url, data)).data;
  },
  async getListaSsdSad(idStrutture) {
    const url = `api/generici/${idStrutture}/lista-ssd-sad`;
    return (await Repository.get(url)).data;
  },
  async getNumeroCertificati(idStrutture, idCorso) {
    const url = `api/istituto/${idStrutture}/corsi/${idCorso}/certificati-ottenuti`;
    return (await Repository.get(url)).data;
  },
  async getFileCertificato(idStrutture, certificato, nomeFileOriginale) {
    const url = `api/istituto/${idStrutture}/corsi/${certificato}/file-certificato`;
    const res = await Repository.get(url, {
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = nomeFileOriginale;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async getAnagraficaStudente(idStrutture, cf) {
    const url = `api/istituto/${idStrutture}/corsi/${cf}/anagrafica-studente`;
    return (await Repository.get(url)).data;
  },
  async terminaCorso(idStrutture, idCorso) {
    const url = `api/istituto/${idStrutture}/corsi/${idCorso}/termina`;
    return await Repository.put(url, null);
  },
  async createAndFirmaCertificati(idStrutture, idCorso, data) {
    const url = `api/istituto/${idStrutture}/corsi/${idCorso}/crea-certificati`;
    const headers = { "Content-Type": "multipart/form-data" };
    const res = await Repository.post(url, data, {
      headers,
      responseType: "application/x-java-jnlp-file",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = "ConfirmaJWS.jnlp";
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async recuperaCertificatiConfirma(idStrutture, idCorso, data) {
    const url = `api/istituto/${idStrutture}/corsi/${idCorso}/recupera-certificati`;
    return (await Repository.post(url, data)).data;
  },
  async downloadTuttiCertificati(idStrutture, idCorso, nomeFile) {
    const url = `api/istituto/${idStrutture}/corsi/${idCorso}/download-certificati`;
    //const headers = { "Content-Type": "multipart/form-data" };
    const res = await Repository.get(url, {
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = nomeFile;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async getAllCorsi(idStrutture, anno, capofila) {
    const url = `api/istituto/${idStrutture}/corsi/anno/${anno}/${capofila}/tutti-corsi`;
    return (await Repository.get(url)).data;
  },
  async downloadTestFirma() {
    const url = `api/generici/confirma-test`;
    const headers = { "Content-Type": "multipart/form-data" };
    const res = await Repository.post(url, {
      headers,
      responseType: "application/x-java-jnlp-file",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = "ConfirmaJWS_TEST.jnlp";
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async validaCorsi(idStrutture, data) {
    const url = `api/istituto/${idStrutture}/corsi/valida-corsi`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  async getNumeriCertificati(idStrutture, anno, capofila) {
    const url = `api/istituto/${idStrutture}/corsi/${anno}/${capofila}/numeri-certificati`;
    return (await Repository.post(url)).data;
  },
  async aggiungiStudentiDaFile(idStrutture, idCorso, fileStudenti) {
    const url = `api/istituto/${idStrutture}/corsi/${idCorso}/studenti/da-file`;
    const headers = { "Content-Type": "multipart/form-data" };

    const res = await Repository.post(url, fileStudenti, { headers });

    if (res.status === 201) {
      const fileResponse = new Blob([res.data], { type: "text/plain" });
      const objectUrl = URL.createObjectURL(fileResponse);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = "esiti_inserimento_studenti.txt";
      fileLink.click();
      URL.revokeObjectURL(fileResponse);
    }
  },
  async getStudentiConvalidatiCorso(idStrutture, idCorso, anno) {
    let url = `api/istituto/${idStrutture}/corsi/${idCorso}/studenti-convalidati-corso/${anno}`;
    return (await Repository.get(url)).data;
  },
  async getReferentiCorsoStruttura(idStrutture) {
    const url = `api/istituto/${idStrutture}/corsi/referenti-corso`;
    return (await Repository.get(url)).data;
  },
  async updateReferenteCorso(idStrutture, idCorso, idUtente) {
    let url = `api/istituto/${idStrutture}/corsi/${idCorso}/sostituisci-referente/${idUtente}`;
    return (await Repository.put(url)).data;
  },

  async deleteLezione(idStrutture, idCorso, idLezione) {
    let url = `api/istituto/${idStrutture}/corsi/${idCorso}/lezioni/${idLezione}`;
    return (await Repository.delete(url)).data;
  },
  async deleteCorso(idStrutture, idCorso) {
    let url = `api/istituto/${idStrutture}/corsi/${idCorso}`;

    return (await Repository.delete(url)).data;
  },
  async deleteAllCertificatoErrati(idStrutture, idCorso) {
    let url = `api/istituto/${idStrutture}/corsi/${idCorso}/delete-certificati`;
    return (await Repository.delete(url)).data;
  },
  async deleteCertificatoStudente(idStrutture, idCorso, idCertificato) {
    let url = `api/istituto/${idStrutture}/corsi/${idCorso}/certificato-studente/${idCertificato}/delete`;
    return (await Repository.delete(url)).data;
  },
  async sbloccaConvalidaStudenti(idStrutture, idCorso, data) {
    let url = `api/istituto/${idStrutture}/corsi/${idCorso}/sblocca-convalida-studenti`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  async getRivalidazioneCfCorso(idStrutture, idCorso) {
    const url = `api/istituto/${idStrutture}/corsi/${idCorso}/rivalidazione-cf`;
    return (await Repository.put(url)).data;
  },
  // async getStudentiStruttura(idStrutture) {
  //   const url = `api/istituto/${idStrutture}/corsi/lista-studenti-corso-struttura`;
  //   return (await Repository.get(url)).data;
  // },
  async getRiepilogoStudentiCensiti(idStrutture, anno, capofila) {
    const url = `api/istituto/${idStrutture}/corsi/anno/${anno}/${capofila}/lista-studenti-corso-attestati`;
    return (await Repository.get(url)).data;
  },
};
