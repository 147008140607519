<template>
  <v-container v-if="showCard">
    <v-card color="transparent" outlined>
      <v-card-title>
        <v-icon class="mr-2">mdi-wallet</v-icon>
        Accordi scuola università
      </v-card-title>
      <v-simple-table>
        <template v-slot:default>
          <v-card-text class="text-center">
            <v-row>
              <v-col style="border-right: 2px solid darkgrey">
                <v-card-text style="padding: 40px; margin-top: -40px">
                  <h2>{{ scuoleAccordi.length }}</h2>
                  <br />
                  Numero Accordi sottoscritti
                </v-card-text>
              </v-col>
              <v-col>
                <v-card-text>
                  <h2>{{ programmaOrientamento.numeroScuole }}</h2>
                  <br />
                  Target
                </v-card-text>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ScuoleAccordoRepository from "@/api/istituto/ScuoleAccordoRepository";

export default {
  name: "AccordiScuolaUniversita",
  components: {},
  computed: {
    ...mapState(["idStrutture", "anno"]),
    ...mapGetters([
      "isRoleConsulenza",
      "isRoleIstituto",
      "isRoleLegale",
      "isReadOnly",
    ]),
  },
  watch: {
    anno() {
      this.initialize();
    },
    idStrutture() {
      this.initialize();
    },
  },
  data: () => ({
    scuoleAccordi: [],
    showCard: false,
  }),
  props: {
    capofila: {
      type: String,
      required: true,
    },
    programmaOrientamento: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.showCard = false;
      if (this.anno && this.idStrutture && this.capofila) {
        this.getScuoleAccordi(this.idStrutture, this.anno);
      }
    },
    async getScuoleAccordi(struttura, anno) {
      ScuoleAccordoRepository.getScuoleAccordi(
        struttura,
        anno,
        this.capofila
      ).then((data) => {
        this.scuoleAccordi = data;
        this.showCard = true;
      });
    },
  },
};
</script>
<style scoped></style>
