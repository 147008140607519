<template>
  <v-container>
    <BreadcrumbComponent
      :breadcrumbs="breadcrumbs"
      v-if="capofila && !isPartner"
    />
    <v-btn class="primary" @click="$router.go(-1)" v-else>
      <v-icon>mdi-arrow-left-thin</v-icon>Torna indietro
    </v-btn>
    <v-card elevation="8" class="mx-auto mt-8 px-6 pb-6" :loading="loadingForm">
      <div v-if="this.idProgramma">
        <br />
        <br />
        <v-alert
          v-if="legaliRappresentanti && mostraAlert"
          type="info"
          color="blue-grey"
          dense
        >
          {{ getTestoAlert() }}
          <br />
          <br />
          Pertanto, tutti i nuovi documenti generati dal sistema informatico
          ORIENTAMENTO 2026, relativi all’istituzione in questione, recepiranno
          la suddetta modifica con l’indicazione del nuovo responsabile LEGALE.
        </v-alert>
      </div>
      <!-- TODO da eliminare quando si avrà uno storico dei referenti di programma -->
      <div v-if="testoModificaRP">
        <v-alert type="info" color="blue-grey" class="ma-2 pa-2" dense>
          {{ testoModificaRP.testo }}
        </v-alert>
      </div>
      <!--<div v-if="anno === 2023">
        <v-alert
          v-if="idStrutture === 19"
          type="info"
          color="blue-grey"
          class="ma-2 pa-2"
          dense
        >
          <span
            v-html="
              getTestoModificaReferenteProgramma(
                'Prof. Laura Elisa Marcucci',
                'Prof. Michele Amato Padrone'
              )
            "
          />
        </v-alert>
        <v-alert
          v-if="idStrutture === 9"
          type="info"
          color="blue-grey"
          class="ma-2 pa-2"
          dense
        >
          <span
            v-html="
              getTestoModificaReferenteProgramma(
                'Prof. Antonio Grassi',
                'Prof. Ivana Ingrosso'
              )
            "
          />
        </v-alert>
        <v-alert
          v-if="idStrutture === 7301"
          type="info"
          color="blue-grey"
          dense
        >
          <span
            v-html="
              getTestoModificaReferenteProgramma(
                'Prof. Giosuè Grassia',
                'Prof. Giuseppe Ilario'
              )
            "
          />
        </v-alert>
      </div>-->
      <v-card-title class="h1"> Programma di orientamento </v-card-title>
      <div class="pa-4">
        <div class="my-4">
          <small> I campi contrassegnati con * sono obbligatori </small>
        </div>
        <v-form ref="form">
          <v-row>
            <v-col cols="4">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    label="CUP*"
                    v-model="programma.cup"
                    :readonly="readOnly"
                    :counter="15"
                    :rules="validazioneCUP()"
                    @input="validateField"
                    @change="validateField"
                    required
                  ></v-text-field>
                </template>
                <span>15 caratteri. Non inserire punti, virgole e spazi</span>
              </v-tooltip>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Anno scolastico di riferimento*"
                v-model="this.annoScolastico"
                disabled
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="4"></v-col>
          </v-row>
          <v-row class="mt-8">
            <v-col><h5 class="text-uppercase">Università/AFAM</h5></v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field
                label="Istituzione"
                v-model="programma.nomeIstituzione"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Nome legale rappresentante"
                v-model="programma.legaleRappresentante.nomeRappresentante"
                :readonly="readOnly"
                :rules="validazioneCampoNecessario()"
                @input="validateField"
                @change="validateField"
                :disabled="disabilitazioneCampiLegali"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Cognome legale rappresentante"
                v-model="programma.legaleRappresentante.cognomeRappresentante"
                :readonly="readOnly"
                :disabled="disabilitazioneCampiLegali"
                :rules="validazioneCampoNecessario()"
                @input="validateField"
                @change="validateField"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field
                label="CF Legale rappresentante*"
                v-model="
                  programma.legaleRappresentante.codiceFiscaleRappresentante
                "
                :readonly="readOnly"
                :disabled="disabilitazioneCampiLegali"
                required
                :counter="16"
                :rules="validazioneCF()"
                @keyup="uppercase()"
                @input="validateField"
                @change="validateField"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-8">
            <v-col><h5 class="text-uppercase">Programma</h5></v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field
                label="Nome referente università/AFAM"
                v-model="programma.nomeReferente"
                :readonly="readOnly"
                :rules="validazioneCampoNecessario()"
                @input="validateField"
                @change="validateField"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Cognome referente università/AFAM"
                v-model="programma.cognomeReferente"
                :readonly="readOnly"
                :rules="validazioneCampoNecessario()"
                @input="validateField"
                @change="validateField"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-radio-group
                v-model="programma.tipoReferente"
                :rules="validazioneCampoNecessario()"
                @input="validateField"
                @change="validateField"
              >
                <v-radio
                  v-for="tipo in tipiReferenti"
                  :key="tipo.id"
                  :label="tipo.descrizione"
                  :value="tipo"
                  :readonly="readOnly"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-text-field
                label="CF Referente*"
                v-model="programma.cfReferente"
                :readonly="readOnly"
                :counter="16"
                :rules="validazioneCF()"
                @keyup="uppercase()"
                @input="validateField"
                @change="validateField"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <!-- inserita validazione per email vuetify -->
              <v-text-field
                label="Email Referente*"
                v-model="programma.emailReferente"
                :readonly="readOnly"
                :rules="emailRules()"
                @input="validateField"
                @change="validateField"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Recapito telefonico del Referente"
                v-model="programma.telefonoReferente"
                :readonly="readOnly"
                :rules="validazioneCampoNecessario()"
                @input="validateField"
                @change="validateField"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-text-field
                label="Numero di corsi di orientamento programmato*"
                v-model.number="programma.numeroCorsi"
                disabled
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Ore di orientamento programmato*"
                v-model.number="programma.numeroOre"
                disabled
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Soglia minima per ottenere il certificato* (espresso in %)"
                v-model.number="programma.sogliaCertificato"
                disabled
                outlined
                suffix="%"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-text-field
                label="N° scuole coinvolte*"
                v-model.number="programma.numeroScuole"
                disabled
                outlined
              ></v-text-field>
              <!--<small class="mt-0 red--text" v-if="!isNumeroScuoleValido"
                >la somma dei campi non può essere diversa dal totale delle
                scuole</small-->
            </v-col>
            <!--<v-col class="align-self-center" cols="1">
              <span>di cui</span>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Licei"
                v-model.number="programma.numeroLicei"
                :readonly="readOnly"
                @keyup="calcolatoreScuole"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Istituti tecnici"
                v-model.number="programma.numeroIstTecnici"
                :readonly="readOnly"
                @keyup="calcolatoreScuole"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Istituti professionali"
                v-model.number="programma.numeroIstProfessionali"
                :readonly="readOnly"
                @keyup="calcolatoreScuole"
              ></v-text-field>
            </v-col>-->
            <!--</v-row>

          <v-row>-->
            <v-col cols="4">
              <v-text-field
                label="N° alunni coinvolti*"
                v-model.number="programma.numeroAlunniCoinvolti"
                disabled
                outlined
              ></v-text-field>
              <!--<small class="mt-0 red--text" v-if="!isNumeroStudentiValido"
                >la somma dei campi non può essere diversa dal totale degli
                alunni
              </small>-->
            </v-col>
            <!--<v-col class="align-self-center" cols="1">
              <span>di cui</span>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Alunni di III"
                v-model.number="programma.numeroAlunniIII"
                :readonly="readOnly"
                @keyup="calcolatoreStudenti"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Alunni di IV"
                v-model.number="programma.numeroAlunniIV"
                :readonly="readOnly"
                @keyup="calcolatoreStudenti"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Alunni di V "
                v-model.number="programma.numeroAlunniV"
                :readonly="readOnly"
                @keyup="calcolatoreStudenti"
              ></v-text-field>
            </v-col>-->
            <!--</v-row>

          <v-row>-->
            <v-col cols="4">
              <v-text-field
                label="Assegnazione  Finanziaria"
                disabled
                outlined
                v-model="programma.assegnazioneFinanziaria"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field
                label="Data di avvio programma"
                prepend-icon="mdi-calendar"
                disabled
                outlined
                v-model="this.dataInizio"
              ></v-text-field>
            </v-col>
            <v-col cols="4"
              ><v-text-field
                label="Data di conclusione programma"
                prepend-icon="mdi-calendar"
                disabled
                outlined
                v-model="this.dataFine"
              ></v-text-field>
            </v-col>
            <v-col cols="4"></v-col>
          </v-row>
          <v-row v-if="this.idProgramma">
            <v-col>
              <v-alert
                v-if="targetIncrementato"
                type="info"
                color="blue-grey"
                dense
              >
                <p>
                  A seguito dell’incremento del Target secondo
                  <span v-if="anno === 2022">il d.d. 1050/2023</span
                  ><span v-else
                    >il d.d. 2121 del 18-12-23 e secondo il d.d. 1187 del
                    07-08-2024</span
                  >, il numero di alunni per il programma di orientamento
                  {{ annoScolastico }} è aumentato di
                  <b>{{ targetIncrementato.alunniTarget }}</b
                  >, con una assegnazione finanziaria aggiuntiva di
                  <b>{{
                    new Intl.NumberFormat("de-DE", {
                      style: "currency",
                      currency: "EUR",
                    }).format(targetIncrementato.assegnazione)
                  }}</b>
                  rispetto al target accettato.
                </p>
              </v-alert>
            </v-col>
          </v-row>
          <div v-if="capofila === 'capofila' && !isPartner" class="my-6">
            <lista-component tipologia="capofila" />
          </div>
          <!--<v-row class="mt-8">
            <v-col
              ><h4 class="">
                Allegato 4 – Schema tipo di atto di accettazione dei
                finanziamenti e degli obblighi
              </h4></v-col
            >
          </v-row>
          <v-row>
            <v-col>
              <v-file-input
                label="Caricamento allegato 4"
                @change="uploadFile($event)"
                :rules="validazioneUploadFile()"
                @input="validateField"
                :required="idProgramma == null"
              ></v-file-input>
            </v-col>
          </v-row>-->
          <v-row v-if="salvataggioInCorso">
            <v-col class="d-flex flex-row-reverse">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col class="d-flex flex-row-reverse">
              <v-btn
                class="primary"
                @click="dialogSalva = true"
                v-if="!readOnly"
              >
                Salva
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
      <dialog-conferma
        @callback="updateProgramma"
        :dialog.sync="dialogSalva"
        @close-dialog="dialogSalva = false"
      />

      <v-snackbar
        v-model="snackbar"
        :timeout="2000"
        shaped
        :color="snackbarColor"
      >
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ListaComponent from "@/components/Convenzioni/ListaComponent";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";
import DialogConferma from "@/components/DialogConferma";
import ProgrammiRepository from "@/api/istituto/ProgrammiRepository";
import targetRepository from "@/api/istituto/TargetRepository";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import ConvenzioniRepository from "@/api/istituto/ConvenzioniRepository";
import convenzioniRepository from "@/api/istituto/ConvenzioniRepository";

export default {
  name: "ModificaProgrammaView",
  components: { DialogConferma, BreadcrumbComponent, ListaComponent },
  props: {
    capofila: {
      type: String,
      required: true,
    },
    idProgramma: {
      type: Number,
      required: false,
    },
    isPartner: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    salvataggioInCorso: false,
    targetIncrementato: null,
    mostraAlert: true,
    tipiReferenti: null,
    loadingForm: true,
    dialogSalva: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    validate: false,
    importiDiCuiInConvenzione: [],
    convenzioni: [],
    istituzione: [],
    annoScolastico: null,
    targetAnnuali: null,
    convenzioniTarget: null,
    convenzioniPartner: null,
    sommaScuole: 0,
    sommaStudenti: 0,
    //isNumeroScuoleValido: false,
    //isNumeroStudentiValido: false,
    dataFine: null,
    dataInizio: null,
    finestraCompilazione: null,
    valoriAssegnazione: {
      importoAssegnato: 0,
      inConvenzione: 0,
    },
    programma: {
      cup: null,
      annoScolastico: 0,
      nomeIstituzione: null,
      legaleRappresentante: {
        nomeRappresentante: null,
        cognomeRappresentante: null,
        codiceFiscaleRappresentante: null,
        id: null,
      },
      nomeReferente: null,
      cognomeReferente: null,
      numeroOre: 0,
      numeroCorsi: 0,
      sogliaCertificato: 0,
      numeroScuole: 0,
      numeroLicei: 0,
      numeroIstTecnici: 0,
      numeroIstProfessionali: 0,
      numeroAlunniCoinvolti: 0,
      numeroAlunniIII: 0,
      numeroAlunniIV: 0,
      numeroAlunniV: 0,
      assegnazioneFinanziaria: 0,
      dataInizioProgramma: null,
      dataFineProgramma: null,
      cfReferente: null,
      tipoReferente: 0,
      emailReferente: null,
      telefonoReferente: null,
    },
    legaliRappresentanti: null,
    disabilitazioneCampiLegali: false,
    testoModificaRP: null,
  }),
  computed: {
    ...mapState(["idStrutture", "anno", "scrivaniaViewMode"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleIstituto",
      "isRoleLegaleCorso",
    ]),
    readOnly() {
      if (this.isRoleLegaleCorso) return false;
      else if (this.isRoleReferenteCorsi || this.isRoleIstituto) return true;
      else if (this.isReadOnly || this.isPartner) return true;
      else if (this.programma && this.programma.trasmesso) return true;
      else if (!this.finestraCompilazione) {
        return true;
      } else if (!this.finestraCompilazione.attiva) {
        return true;
      }
      return false;
    },
    breadcrumbs: function () {
      if (this.capofila) {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania/${this.capofila}`,
            exact: true,
            link: true,
          },
          {
            text: "Programma Orientamento",
            disabled: false,
            to: `/scrivania/${this.capofila}/programma-orientamento`,
            exact: true,
            link: true,
          },
          {
            text: "Inserimento/Modifica",
            disabled: true,
            to: `/scrivania/${this.capofila}/programma-orientamento/inserisci`,
            exact: true,
            link: true,
          },
        ];
      }
      return [];
    },
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  mounted() {
    this.initialize();
    this.annoScolastico =
      this.anno === 2024
        ? this.anno + "/" + (this.anno + 2)
        : this.anno + "/" + (this.anno + 1);
    this.programma.sogliaCertificato = 70;
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        this.loadingForm = true;
        if (this.idProgramma) {
          this.getProgramma();
          this.getLegaliRappresentanti();
          this.getIncrementoTarget();
        } else {
          this.getIstituzione();
          this.getDateProgramma();
          this.getNumeriPrecompilati();
          this.getAssegnazioneFinanziariaDefinitiva();
        }
        this.getProgrammiTipoReferente();
        this.getFinestraCompilazione();
        this.getTestoModificaReferenteProgramma();
        this.loadingForm = false;
      }
    },
    validateField() {
      this.validate = this.$refs.form.validate();
      //this.isNumeroScuoleValido &&
      //this.isNumeroStudentiValido;
    },
    validazioneCampoNecessario() {
      return [(v) => !!v || "Campo richiesto"];
    },
    validazioneCF() {
      return [
        (v) =>
          (!!v && v.length === 16) ||
          "Il campo è obbligatorio e il formato del codice fiscale deve essere valido",
      ];
    },
    validazioneCUP() {
      return [
        (v) =>
          (!!v && v.length === 15) ||
          "Il campo è obbligatorio e il formato del codice CUP deve essere valido",
      ];
    },
    uppercase() {
      if (this.programma.legaleRappresentante.codiceFiscaleRappresentante) {
        this.programma.legaleRappresentante.codiceFiscaleRappresentante =
          this.programma.legaleRappresentante.codiceFiscaleRappresentante.toUpperCase();
      }
      if (this.programma.cfReferente) {
        this.programma.cfReferente = this.programma.cfReferente.toUpperCase();
      }
    },
    emailRules() {
      return [
        (v) => !!v || "Il campo E-mail è obbligatorio",
        (v) => /.+@.+/.test(v) || "Inserire un indirizzo E-mail valido",
      ];
    },
    validazioneUploadFile() {
      if (this.idProgramma == null) {
        return [(v) => !!v || "Campo richiesto"];
      }
      return [];
    },
    validazione() {
      return [
        (v) =>
          !(Number(v) === v && v % 1 !== 0) ||
          "Non è consentito inserire cifre decimali.",
      ];
    },
    uploadFile(event) {
      this.file = event;
    },
    async updateProgramma() {
      this.validateField();
      if (this.validate) {
        let programma = { ...this.programma };

        if (this.idProgramma) {
          //delete programma.fileAllegato;
          delete programma.dataInizioProgramma;
          delete programma.dataFineProgramma;
        }

        // post file to server
        const formData = new FormData();
        formData.append("programma", JSON.stringify(programma));
        //formData.append("file", this.file);

        try {
          if (this.idProgramma == null) {
            this.salvataggioInCorso = true;
            await ProgrammiRepository.createProgrammaOrientamento(
              this.idStrutture,
              this.anno,
              this.capofila,
              formData
            );
            this.snackbarText = "Programma aggiunto con successo";
            setTimeout(() => this.goToAllegati(), 2000);
          } else {
            this.salvataggioInCorso = true;
            await ProgrammiRepository.updateProgrammaOrientamento(
              this.idStrutture,
              this.anno,
              this.capofila,
              formData
            );
            this.salvataggioInCorso = false;
            this.snackbarText = "Programma aggiornato con successo";
          }
          this.snackbarColor = "success";
          this.validate = true;
        } catch (e) {
          console.log(e);
          this.salvataggioInCorso = false;
          this.snackbarText =
            "Problema durante il salvataggio, contattare il supporto se il problema persiste";
          this.snackbarColor = "red accent-2";
        }
        this.snackbar = true;
      }
    },
    async getProgramma() {
      this.loadingForm = true;
      this.programma = await ProgrammiRepository.getProgramma(
        this.idStrutture,
        this.anno,
        this.idProgramma
      );
      this.dataInizio = new Date(
        this.programma.dataInizioProgramma
      ).toLocaleDateString("it-IT");
      this.dataFine = new Date(
        this.programma.dataFineProgramma
      ).toLocaleDateString("it-IT");
      this.calcolatoreScuole();
      this.calcolatoreStudenti();
      this.loadingForm = false;
    },
    async getIstituzione() {
      this.loadingForm = true;
      this.istituzione = await ProgrammiRepository.getIstituzione(
        this.idStrutture
      );

      const legaleRappresentante =
        await IstitutoRepository.getUltimoLegaleRappresentanteInseritoAttivo(
          this.idStrutture
        );

      if (legaleRappresentante) {
        this.programma.legaleRappresentante.nomeRappresentante =
          legaleRappresentante.nomeRappresentante;
        this.programma.legaleRappresentante.cognomeRappresentante =
          legaleRappresentante.cognomeRappresentante;
        this.programma.legaleRappresentante.codiceFiscaleRappresentante =
          legaleRappresentante.codiceFiscaleRappresentante;
        this.programma.legaleRappresentante.id = legaleRappresentante.id;
        this.disabilitazioneCampiLegali = true;
      } else {
        this.programma.legaleRappresentante.nomeRappresentante =
          this.istituzione[0]["legRapp"].split(" ")[0];
        this.programma.legaleRappresentante.cognomeRappresentante =
          this.istituzione[0]["legRapp"].replace(
            this.programma.legaleRappresentante.nomeRappresentante,
            ""
          );
        this.programma.legaleRappresentante.cognomeRappresentante =
          this.programma.legaleRappresentante.cognomeRappresentante.replace(
            /[a-z]/g,
            ""
          );
        this.programma.legaleRappresentante.codiceFiscaleRappresentante =
          this.istituzione[0]["legRappCf"];
        this.disabilitazioneCampiLegali = false;
      }
      this.programma.nomeIstituzione = this.istituzione[0]["name"];
      this.loadingForm = false;
    },
    /*calcolatoreScuole() {
      this.sommaScuole = Number(
        (this.programma.numeroLicei ? Number(this.programma.numeroLicei) : 0) +
          (this.programma.numeroIstTecnici
            ? Number(this.programma.numeroIstTecnici)
            : 0) +
          (this.programma.numeroIstProfessionali
            ? Number(this.programma.numeroIstProfessionali)
            : 0)
      ).toFixed(0);
      this.isNumeroScuoleValido =
        Number(this.sommaScuole) === Number(this.programma.numeroScuole);
    },
    calcolatoreStudenti() {
      this.sommaStudenti = (
        (this.programma.numeroAlunniIII
          ? Number(this.programma.numeroAlunniIII)
          : 0) +
        (this.programma.numeroAlunniIV
          ? Number(this.programma.numeroAlunniIV)
          : 0) +
        (this.programma.numeroAlunniV
          ? Number(this.programma.numeroAlunniV)
          : 0)
      ).toFixed(0);
      this.isNumeroStudentiValido =
        Number(this.sommaStudenti) ===
        Number(this.programma.numeroAlunniCoinvolti);
    },*/
    getDateProgramma() {
      if (this.anno === 2024) {
        this.dataFine = new Date(this.anno + 2, 3, 30).toLocaleDateString(
          "it-IT"
        );
      } else {
        this.dataFine = new Date(this.anno + 1, 7, 31).toLocaleDateString(
          "it-IT"
        );
      }

      if (this.anno === 2022) {
        this.dataInizio = new Date(this.anno, 10, 1).toLocaleDateString(
          "it-IT"
        );
      } else {
        this.dataInizio = new Date(this.anno, 8, 1).toLocaleDateString("it-IT");
      }
    },
    async getNumeriPrecompilati() {
      this.loadingForm = true;
      this.targetAnnuali = await targetRepository.getTarget(
        this.idStrutture,
        this.anno
      );
      this.loadingForm = false;
      if (this.scrivaniaViewMode === 2) {
        this.convenzioniPartner = await convenzioniRepository.getConvenzioni(
          this.idStrutture,
          this.anno,
          "partner"
        );
        //se profilo uni-afam: nr.corsi = nrCorsiTarget - nrCorsiPropriInConvenzione

        let totaleCorsiPartner = 0;
        let totaleAlunniPartner = 0;
        let totaleAccordiPartner = 0;
        this.convenzioniPartner.forEach(function (convenzione) {
          totaleCorsiPartner =
            totaleCorsiPartner + convenzione.numeroCorsiPartner;
          totaleAlunniPartner =
            totaleAlunniPartner + convenzione.numeroStudentiPartner;
          totaleAccordiPartner =
            totaleAccordiPartner + convenzione.numeroAccordiPartner;
        });
        this.programma.numeroCorsi =
          this.targetAnnuali.numeroCorsi -
          (totaleCorsiPartner ? totaleCorsiPartner : 0);
        this.programma.numeroOre = this.programma.numeroCorsi * 15;
        this.programma.numeroAlunniCoinvolti =
          this.targetAnnuali.numeroStudenti -
          (totaleAlunniPartner ? totaleAlunniPartner : 0);
        this.programma.numeroScuole =
          this.targetAnnuali.numeroAccordi -
          (totaleAccordiPartner ? totaleAccordiPartner : 0);
      } else {
        this.convenzioniTarget =
          await convenzioniRepository.getConvenzioneTarget(
            this.idStrutture,
            this.anno
          );
        //se profilo uni-afam: nr.corsi = nrCorsiTarget - nrCorsiPropriInConvenzione
        this.programma.numeroCorsi =
          this.targetAnnuali.numeroCorsi -
          (this.convenzioniTarget.numeroCorsi
            ? this.convenzioniTarget.numeroCorsi
            : 0);
        this.programma.numeroOre = this.programma.numeroCorsi * 15;
        this.programma.numeroAlunniCoinvolti =
          this.targetAnnuali.numeroStudenti -
          (this.convenzioniTarget.numeroStudenti
            ? this.convenzioniTarget.numeroStudenti
            : 0);
        this.programma.numeroScuole =
          this.targetAnnuali.numeroAccordi -
          (this.convenzioniTarget.numeroAccordi
            ? this.convenzioniTarget.numeroAccordi
            : 0);
      }

      //se profilo capofila: nrCorsi = nrCorsiPropriInConv + nrCorsiInConvPartner
      if (this.capofila === "capofila") {
        this.loadingForm = true;
        this.convenzioniTarget =
          await convenzioniRepository.getConvenzioneTarget(
            this.idStrutture,
            this.anno
          );
        this.convenzioni = await ConvenzioniRepository.getConvenzioni(
          this.idStrutture,
          this.anno,
          "capofila"
        );
        let totaleCorsi = 0;
        let totaleAlunni = 0;
        let totaleAccordi = 0;
        this.convenzioni.forEach(function (convenzione) {
          totaleCorsi = totaleCorsi + convenzione.numeroCorsi;
          totaleAlunni = totaleAlunni + convenzione.numeroStudenti;
          totaleAccordi = totaleAccordi + convenzione.numeroAccordi;
        });
        this.programma.numeroCorsi =
          (this.convenzioniTarget.numeroCorsi
            ? this.convenzioniTarget.numeroCorsi
            : 0) + totaleCorsi;
        this.programma.numeroOre = this.programma.numeroCorsi * 15;
        this.programma.numeroAlunniCoinvolti =
          (this.convenzioniTarget.numeroStudenti
            ? this.convenzioniTarget.numeroStudenti
            : 0) + totaleAlunni;
        this.programma.numeroScuole =
          (this.convenzioniTarget.numeroAccordi
            ? this.convenzioniTarget.numeroAccordi
            : 0) + totaleAccordi;
        this.loadingForm = false;
      }
    },
    async getAssegnazioneFinanziariaDefinitiva() {
      this.valoriAssegnazione =
        await IstitutoRepository.getAssegnazioneFinanziariaDefinitiva(
          this.idStrutture,
          this.anno
        );
      if (this.valoriAssegnazione) {
        if (this.capofila === "capofila") {
          this.importiDiCuiInConvenzione =
            await ProgrammiRepository.getDiCuiInConvenzionePartner(
              this.idStrutture,
              this.anno
            );
          let totaleImportoInConvenzione = 0;
          this.importiDiCuiInConvenzione.forEach(function (importo) {
            totaleImportoInConvenzione =
              totaleImportoInConvenzione + importo.inConvenzione;
          });
          this.programma.assegnazioneFinanziaria =
            this.valoriAssegnazione.inConvenzione + totaleImportoInConvenzione;
        } else {
          this.programma.assegnazioneFinanziaria =
            this.valoriAssegnazione.importoAssegnato -
            this.valoriAssegnazione.inConvenzione;
        }
      }
    },
    async getFinestraCompilazione() {
      const finestraCompilazione =
        await IstitutoRepository.getFinestraCompilazione(
          this.idStrutture,
          this.anno,
          "programma-orientamento-ist"
        );
      if (finestraCompilazione !== "") {
        this.finestraCompilazione = finestraCompilazione;
      }
    },
    async getProgrammiTipoReferente() {
      this.tipiReferenti =
        await ProgrammiRepository.getProgrammiTipoReferente();
    },
    async getLegaliRappresentanti() {
      await ProgrammiRepository.getLegaliRappresentantiByProgramma(
        this.idStrutture,
        this.idProgramma
      ).then((data) => {
        this.legaliRappresentanti = data;
      });
      this.disabilitazioneCampiLegali = true;
    },
    getTestoAlert() {
      const rappresentanteAttivo = this.legaliRappresentanti.find(
        (element) => true === element.attivo
      );
      const rappresentanteNonAttivo = this.legaliRappresentanti.findLast(
        (element) => false === element.attivo
      );

      let text = "";
      if (rappresentanteNonAttivo) {
        text =
          "Si procede con la modifica a sistema del precedente rappresentante legale: Prof. " +
          rappresentanteNonAttivo.legale.nomeRappresentante +
          " " +
          rappresentanteNonAttivo.legale.cognomeRappresentante +
          " con il nuovo in carica: Rettore Prof. " +
          rappresentanteAttivo.legale.nomeRappresentante +
          " " +
          rappresentanteAttivo.legale.cognomeRappresentante +
          ", come da comunicazione inoltrata al CINECA.";
      } else {
        this.mostraAlert = false;
      }
      return text;
    },
    async getIncrementoTarget() {
      this.targetIncrementato = await ProgrammiRepository.incrementoTarget(
        this.idStrutture,
        this.anno
      );
    },
    async getTestoModificaReferenteProgramma() {
      this.testoModificaRP =
        await IstitutoRepository.getTestoModificaReferenteProgramma(
          this.idStrutture,
          this.anno
        );
    },
    goToAllegati() {
      this.$router.push({
        path: `/scrivania/${this.capofila}/programma-orientamento`,
      });
    },
    /*getTestoModificaReferenteProgramma(referenteVecchio, referenteNuovo) {
      return (
        "Si procede con la modifica a sistema del precedente\n" +
        "            <b>referente di programma: " +
        referenteVecchio +
        "</b> con il" +
        "            nuovo nuovo in carica: <b>" +
        referenteNuovo +
        "</b>, come da" +
        "            comunicazione inoltrata al CINECA.<br />Pertanto, tutti i nuovi documenti" +
        "            generati dal sistema informatico ORIENTAMENTO 2026, relativi" +
        "            all’istituzione in questione,recepiranno la suddetta modifica con" +
        "            l’indicazione del nuovo <b>referente di programma </b>."
      );
    },*/
  },
};
</script>

<style scoped></style>
