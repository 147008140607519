<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Report Immatricolazioni</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>
          In questa sezione è possibile visualizzare il numero di studenti
          censiti sulla piattaforma Orientamento 2026 in relazione al numero di
          studenti immatricolati nell’anno successivo allo svolgimento del corso
          di orientamento.
        </p>
      </v-col>
    </v-row>
    <v-row v-if="scrivaniaViewMode">
      <v-col cols="12" v-if="true">
        <v-card
          elevation="8"
          class="mx-auto mb-5 pb-6"
          :loading="loadingCensiti"
          loader-height="8"
        >
          <v-card-title
            class="white--text mb-6"
            style="background-color: #0066cc"
            >Report studenti censiti
          </v-card-title>
          <v-card-text v-if="!loadingCensiti">
            <v-row>
              <v-col>
                <ReportStudentiCensitiChart :studenti="studentiCensiti" />
              </v-col>
            </v-row>
            <v-card-actions>
              <v-row class="mt-2 d-flex flex-row-reverse">
                <v-col cols="2" class="">
                  <div v-if="!loadingDownload">
                    <v-btn text class="blue--text" @click="esportaExcel()"
                      ><v-icon class="mr-2"> mdi-microsoft-excel </v-icon>
                      Esporta Excel
                    </v-btn>
                  </div>
                  <div v-else>
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular
                    ><span class="blue--text ml-4">Download in corso</span>
                  </div>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="true">
        <v-card
          elevation="8"
          class="mx-auto mb-5 pb-6"
          :loading="loadingImmatricolati"
          loader-height="8"
        >
          <v-card-title
            class="white--text mb-6"
            style="background-color: #0066cc"
            >Report studenti immatricolati
          </v-card-title>
          <v-card-text v-if="!loadingImmatricolati">
            <v-row>
              <v-col>
                <ReportStudentiImmatricolatiChart
                  :studenti="studentiImmatricolati"
                />
              </v-col>
            </v-row>
            <!--<v-card-actions>
              <v-row class="mt-2 d-flex flex-row-reverse">
                <v-col cols="2" class="">
                  <v-btn text class="blue--text"
                    ><v-icon class="mr-2"> mdi-microsoft-excel </v-icon>
                    Esporta Excel
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>-->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      shaped
      :color="snackbarColor"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import ReportStudentiCensitiChart from "@/components/Immatricolazioni/ReportStudentiCensitiChart.vue";
import ReportStudentiImmatricolatiChart from "@/components/Immatricolazioni/ReportStudentiImmatricolatiChart.vue";
import ReportImmatricolazioniRepository from "@/api/istituto/ReportImmatricolazioniRepository";

export default {
  name: "ReportImmatricolazioniView",
  components: { ReportStudentiImmatricolatiChart, ReportStudentiCensitiChart },
  computed: {
    ...mapState(["scrivaniaViewMode", "idStrutture", "anno"]),
  },
  data: () => ({
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    loadingDownload: false,
    studentiCensitiAltro: null,
    loadingCensiti: true,
    loadingImmatricolati: true,
    studentiCensiti: {
      censiti: 0,
      immatricolati: 0,
      immatricolatiAltra: 0,
      nonImmatricolati: 0,
    },
    studentiImmatricolati: {
      immatricolati: 0,
      censiti: 0,
      censitiAltra: 0,
      nonCensiti: 0,
    },
  }),
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        this.getStudentiCensiti();
        this.getStudentiImmatricolati();
      }
    },
    async getStudentiCensiti() {
      let studenti = await ReportImmatricolazioniRepository.getStudentiCensiti(
        this.idStrutture,
        this.anno
      );
      this.studentiCensiti.censiti = studenti.length;
      this.studentiCensiti.immatricolati = studenti.filter(
        (item) => item.immatricolatoStesso === true
      ).length;
      this.studentiCensiti.immatricolatiAltra = studenti.filter(
        (item) => item.immatricolatoAltro === true
      ).length;
      this.studentiCensiti.nonImmatricolati =
        studenti.length -
        studenti.filter((item) => item.noImmatricolato === true).length;
      this.loadingCensiti = false;
    },
    async getStudentiImmatricolati() {
      let studenti =
        await ReportImmatricolazioniRepository.getStudentiImmatricolati(
          this.idStrutture,
          this.anno
        );
      this.studentiImmatricolati.immatricolati = studenti.immatricolati;
      this.studentiImmatricolati.censiti = studenti.immatricolatoStesso;
      this.studentiImmatricolati.censitiAltra = studenti.immatricolatoAltro;
      this.studentiImmatricolati.nonCensiti = studenti.noImmatricolato;
      this.loadingImmatricolati = false;
    },
    async esportaExcel() {
      try {
        this.loadingDownload = true;
        await ReportImmatricolazioniRepository.esportaExcel(
          this.idStrutture,
          this.anno
        );
        this.loadingDownload = false;
      } catch (e) {
        console.log(e);
        this.snackbarText = "Problema durante il download del report";
        this.snackbarColor = "red accent-2";
        this.snackbar = true;
        this.loadingDownload = false;
      }
    },
  },
};
</script>

<style scoped></style>
