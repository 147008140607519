<template>
  <v-container fluid>
    <v-main> <BreadcrumbComponent :breadcrumbs="breadcrumbs" /> </v-main>
    <v-main>
      <h1><v-card-title>Questionario di Valutazione</v-card-title></h1>
      <v-card-text>
        Il questionario è somministrato agli alunni e alle alunne partecipanti
        ai corsi di orientamento. Il questionario è impostato per essere anonimo
        a tutti gli effetti. <br />
        Ciascun’istituzione, e in particolare il referente di corso, dovrà
        generare il link al questionario tramite l'apposito pulsante e
        condividere il link con gli alunni e le alunne partecipanti al corso. In
        questa stessa pagina potranno essere visualizzati i dati aggregati
        relativi alle risposte fornite.
      </v-card-text>
    </v-main>
    <v-main class="ma-2 pa-2">
      <v-btn
        color="primary"
        @click="generaLinkQuestionario()"
        v-if="!isDisabilitatoGenerazioneLink"
        :disabled="isDisabilitatoGenerazioneLink"
      >
        Genera link</v-btn
      >
      <div v-else>
        <!-- caso in cui il link è stato già generato  -->
        <v-alert v-if="infoLinkQuestionario" type="info">
          E' stato già generato un link al questionario per il corso specifico.
          <br />
          <b> Link questionario: </b>
          <a
            :href="infoLinkQuestionario.linkQuestionario"
            target="_blank"
            style="color: azure"
          >
            {{ infoLinkQuestionario.linkQuestionario }}
          </a>
          <br />
          <b> Data Scadenza: </b> {{ getDataScadenzaLink() }}
        </v-alert>
        <v-alert
          type="warning"
          v-if="
            infoGeneraliQuestionariCorso &&
            infoGeneraliQuestionariCorso.numeroStudentiCorso < 4
          "
        >
          Impossibile generare il link per il questionario. Il numero di
          studenti associati censiti per il corso è minore rispetto al minimo
          consentito(4) per poter far svolgere il questionario.
        </v-alert>
      </div>
    </v-main>

    <v-main>
      <v-card elevation="5" v-if="loadingPage">
        <div class="pa-2 ma-2" v-if="infoGeneraliQuestionariCorso">
          <v-progress-linear
            v-model="percentualeCompilazioneQuestionari"
            height="40"
            class="progress-percentuale-questionari"
          >
            <strong>
              {{ percentualeCompilazioneQuestionari }} %
            </strong></v-progress-linear
          >
          <br />
          <v-card-text>
            Studenti su totale
            <b
              >{{ infoGeneraliQuestionariCorso.numeroQuestionari }} /
              {{ infoGeneraliQuestionariCorso.numeroStudentiCorso }}</b
            ></v-card-text
          >
          <VisualizzazioneDatiQuestionario
            class="pa-4 ma-4"
            :id-corso="idCorso"
            v-if="infoGeneraliQuestionariCorso.numeroQuestionari > 0"
          />
        </div>
        <v-progress-circular
          v-else
          indeterminate
          color="blue"
        ></v-progress-circular>
      </v-card>
      <v-snackbar :color="snackbar.color" v-model="snackbar.value">
        {{ snackbar.text }}
      </v-snackbar>
    </v-main>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent.vue";
import VisualizzazioneDatiQuestionario from "@/components/Questionari/VisualizzazioneDatiQuestionarioComponent.vue";
import CorsiRepository from "@/api/istituto/CorsiRepository";
import QuestionariRepository from "@/api/istituto/QuestionariRepository";

export default {
  name: "questionarioView",
  components: {
    VisualizzazioneDatiQuestionario,
    /*FormQuestionario,*/
    BreadcrumbComponent,
  },
  props: {
    idCorso: {
      type: [Number, String],
      required: true,
    },
    capofila: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loadingPage: false,
    corso: null,
    percentualeCompilazioneQuestionari: 0,
    generazioneLinkInCorso: false,
    infoLinkQuestionario: null,
    btnGenerazioneLinkDisattivo: false,
    infoGeneraliQuestionariCorso: null,
    snackbar: {
      color: null,
      text: null,
      value: false,
    },
  }),
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["anno", "idStrutture"]),
    breadcrumbs: function () {
      return [
        {
          text: "Scrivania",
          disabled: false,
          to: `/scrivania/${this.capofila}`,
          exact: true,
          link: true,
        },
        {
          text: "Censimento, erogazione e validazione corsi",
          disabled: false,
          to: `/scrivania/${this.capofila}/corsi`,
          exact: true,
          link: true,
        },
        {
          text: "Erogazione corsi",
          disabled: false,
          to: `/scrivania/${this.capofila}/corsi/erogazione-corsi`,
          exact: true,
          link: true,
        },
        {
          text: "Questionari di valutazione",
          disabled: true,
          to: `/scrivania/${this.capofila}/corsi-orientamento/${this.idCorso}/questionari`,
          exact: true,
          link: true,
        },
      ];
    },
    isDisabilitatoGenerazioneLink() {
      return (
        this.infoLinkQuestionario ||
        (this.infoGeneraliQuestionariCorso != null &&
          this.infoGeneraliQuestionariCorso.numeroStudentiCorso <= 3)
      );
    },
  },
  watch: {
    anno() {
      this.initialize();
    },
    idStrutture() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.idCorso && this.capofila) {
        this.loadingPage = true;
        this.getCorsoOrientamento();
        this.getInfoLinkQuestionarioCorso();
        this.getInformazioniGeneraliQuestionari();
      }
    },
    async generaLinkQuestionario() {
      this.generazioneLinkInCorso = true;
      this.linkQuestionario =
        await QuestionariRepository.generazioneLinkQuestionario(
          this.idStrutture,
          parseInt(this.idCorso)
        )
          .then((data) => {
            if (data) {
              this.loadingPage = false;
              this.setValueSnackbar(
                "Il link del questionario è stato generato",
                "success"
              );
              this.initialize();
            }
          })
          .catch((reason) => {
            const resData =
              reason.response.status === 400 || reason.response.status === 403
                ? reason.response.data[0]
                : "Un'errore generico è stato riscontrato. Contattare il supporto se il problema persiste.";
            this.setValueSnackbar(resData, "red accent-2");
          });
    },
    async getCorsoOrientamento() {
      const idStrutture = this.idStrutture;
      const anno = this.anno;
      const idCorso = parseInt(this.idCorso);
      this.corso = await CorsiRepository.getCorsoOrientamento(
        idStrutture,
        anno,
        idCorso
      );
    },
    async getInfoLinkQuestionarioCorso() {
      this.infoLinkQuestionario =
        await QuestionariRepository.verificaCreazioneLinkQuestionarioCorso(
          this.idStrutture,
          this.idCorso
        );
    },
    getDataScadenzaLink() {
      const dataScadenza = new Date(this.infoLinkQuestionario.dataScadenza);
      return dataScadenza.toLocaleString();
    },
    async getInformazioniGeneraliQuestionari() {
      const idStrutture = this.idStrutture;
      const idCorso = parseInt(this.idCorso);
      this.infoGeneraliQuestionariCorso =
        await QuestionariRepository.getInformazioniGeneraliQuestionari(
          idStrutture,
          idCorso
        );
      if (this.infoGeneraliQuestionariCorso) {
        this.calcolaPercentualeAvanzamento();
      }
    },
    calcolaPercentualeAvanzamento() {
      const numeroStudenti =
        this.infoGeneraliQuestionariCorso.numeroStudentiCorso;
      const numeroQuestionari =
        this.infoGeneraliQuestionariCorso.numeroQuestionari;
      this.percentualeCompilazioneQuestionari =
        numeroStudenti > 0
          ? parseFloat((numeroQuestionari / numeroStudenti) * 100).toFixed(2)
          : 0;
    },
    setValueSnackbar(text, color) {
      this.snackbar.color = color;
      this.snackbar.text = text;
      this.snackbar.value = true;
    },
  },
};
</script>
<style scoped>
.progress-percentuale-questionari {
  border: 3px solid black;
}
</style>
