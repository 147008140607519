<template>
  <Doughnut
    id="AndamentoStudentiCertificati"
    :options="chartOptions"
    :data="chartData"
    v-if="certificati"
  />
</template>

<script>
import { Doughnut } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "AndamentoStudentiCertificatiChart",
  components: { Doughnut },
  props: {
    certificati: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
    },
  }),
  computed: {
    chartData() {
      return {
        labels: [
          "Studenti che hanno ottenuto l'attestato",
          "Studenti che NON hanno ottenuto l'attestato",
        ],
        datasets: [
          {
            backgroundColor: ["#00D8FF", "#DD1B16"],
            data: [this.certificati.ottenuti, this.certificati.nonOttenuti],
          },
        ],
      };
    },
  },
};
</script>
