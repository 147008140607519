<template>
  <span>
    <v-chip
      class="ma-2"
      color="pink"
      label
      text-color="white"
      v-if="$store.getters.isRoleConsulenza"
    >
      <v-icon left> mdi-label </v-icon>
      Consulenza
    </v-chip>
    <v-chip
      class="ma-2"
      color="orange"
      label
      text-color="white"
      v-if="$store.getters.isRoleMinistero"
    >
      <v-icon left> mdi-label </v-icon>
      Ministero
    </v-chip>
  </span>
</template>

<script>
export default {
  name: "ChipRole",
};
</script>

<style scoped></style>
