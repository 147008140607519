<template>
  <v-container fluid>
    <v-card :loading="loading">
      <v-card-title v-if="partner">
        Seleziona l'istituzione Partner
      </v-card-title>
      <v-card-title v-else>
        Inserisci un numero di Studenti, Corsi e Accordi in Convenzione
        appartenenti al tuo target
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-if="convenzione">
          <v-row v-if="partner">
            <v-col>
              <v-autocomplete
                v-model="convenzione.idStrutturePartner"
                :items="istituzioni"
                flat
                hide-details
                item-text="nome"
                item-value="idStrutture"
                label="Seleziona istituto..."
                :rules="validazione()"
                @input="validateField"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Studenti"
                v-model.number="convenzione.numeroStudenti"
                type="number"
                :rules="validazione()"
                @input="validateField"
                @keyup="validateField"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Corsi"
                v-model.number="convenzione.numeroCorsi"
                type="number"
                :rules="validazione()"
                @input="validateField"
                @keyup="validateField"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Accordi"
                v-model.number="convenzione.numeroAccordi"
                type="number"
                :rules="validazione()"
                @input="validateField"
                @keyup="validateField"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="!loading">
            <v-col>
              <v-btn
                v-if="!readOnly"
                class="primary"
                :loading="loading"
                :disabled="loading"
                @click="dialogSalva = true"
              >
                {{ buttonSaveText }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <dialog-conferma
        @callback="updateConvenzione"
        :dialog.sync="dialogSalva"
        @close-dialog="dialogSalva = false"
      />

      <v-snackbar
        v-model="snackbar"
        :timeout="2000"
        shaped
        :color="snackbarColor"
      >
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DialogConferma from "@/components/DialogConferma";
import ConvenzioniRepository from "@/api/istituto/ConvenzioniRepository";

export default {
  name: "ConvenzioniTargetComponent",
  components: { DialogConferma },
  props: {
    partner: {
      type: Boolean,
    },
    finestraCompilazione: {
      type: Object,
    },
  },
  data() {
    return {
      dialogSalva: null,
      snackbar: false,
      snackbarText: "",
      snackbarColor: "",
      validate: false,
      istituzioni: [],
      loadingIstituzioni: false,
      loadingConvenzione: false,
      convenzioneTargetPresente: false,
      convenzioneDefault: {
        idStrutture: null,
        idStrutturePartner: null,
        numeroStudenti: 0,
        numeroCorsi: 0,
        numeroAccordi: 0,
      },
      convenzione: {
        idStrutture: null,
        idStrutturePartner: null,
        numeroStudenti: 0,
        numeroCorsi: 0,
        numeroAccordi: 0,
      },
    };
  },
  computed: {
    ...mapState(["idStrutture", "anno"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleIstituto",
      "isRoleReferenteCorsi",
      "isRoleLegaleCorso",
      "isRoleLegale",
    ]),
    readOnly() {
      if (this.isRoleLegaleCorso || this.isRoleLegale) return false;
      else if (
        this.isReadOnly ||
        this.isRoleIstituto ||
        this.isRoleReferenteCorsi
      )
        return true;
      else if (!this.finestraCompilazione) {
        return true;
      } else if (!this.finestraCompilazione.attiva) {
        return true;
      }
      return false;
    },
    buttonSaveText() {
      return this.partner ? "Aggiungi Partner" : "Salva";
    },
    loading: function () {
      return this.partner
        ? this.loadingIstituzioni && this.loadingConvenzione
        : this.loadingConvenzione;
    },
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        if (this.partner) {
          this.getIstituzioni();
          this.getConvenzionePartner();
        } else {
          delete this.convenzione.idStrutturePartner;
          delete this.convenzioneDefault.idStrutturePartner;
          this.getConvenzioneTarget();
        }
      }
    },
    validateField() {
      this.validate = this.$refs.form.validate();
    },
    validazione() {
      return [
        (v) =>
          (v || "") >= 1 ||
          `Il campo è obbligatorio e non può essere uguale a 0.`,
        (v) =>
          !(Number(v) === v && v % 1 !== 0) ||
          "Non è consentito inserire cifre decimali.",
      ];
    },
    mergeConvenzione(convenzione) {
      this.convenzioneTargetPresente = convenzione?.id ?? false;
      return { ...this.convenzioneDefault, ...convenzione };
    },
    async updateConvenzione() {
      this.validateField();
      if (this.validate) {
        const convenzione = { ...this.convenzione };

        // post file to server
        const formData = new FormData();
        formData.append("convenzione", JSON.stringify(convenzione));

        try {
          if (this.partner) {
            await ConvenzioniRepository.addConvenzionePartner(
              this.idStrutture,
              this.anno,
              formData
            );

            this.convenzione = { ...this.convenzioneDefault };
            this.snackbarText = "Convenzione aggiunta con successo";
            this.$emit("add-convenzione");
          } else {
            if (this.convenzioneTargetPresente) {
              await ConvenzioniRepository.updateConvenzioneTarget(
                this.idStrutture,
                this.anno,
                formData
              );
              this.snackbarText = "Valori aggiornati con successo";
            } else {
              await ConvenzioniRepository.createConvenzioneTarget(
                this.idStrutture,
                this.anno,
                formData
              );
              this.snackbarText = "Valori salvati con successo";
            }
          }

          this.snackbarColor = "success";
          this.validate = true;
        } catch (e) {
          console.log(e);
          this.snackbarText =
            "Problema durante il salvataggio, contattare il supporto se il problema persiste";
          this.snackbarColor = "red accent-2";
        }
        this.snackbar = true;
      }
    },
    async getIstituzioni() {
      this.loadingIstituzioni = true;
      this.istituzioni = await ConvenzioniRepository.getIstituzioniConvenzione(
        this.idStrutture,
        this.anno
      );
      this.loadingIstituzioni = false;
    },
    async getConvenzioneTarget() {
      this.loadingConvenzione = true;
      this.convenzione = this.mergeConvenzione(
        await ConvenzioniRepository.getConvenzioneTarget(
          this.idStrutture,
          this.anno
        )
      );
      if (
        this.convenzione &&
        this.convenzione.numeroAccordi !== 0 &&
        this.convenzione.numeroStudenti !== 0 &&
        this.convenzione.numeroCorsi !== 0
      ) {
        this.$emit("convenzione", this.convenzione);
      } else {
        this.$emit("convenzione", false);
      }
      this.loadingConvenzione = false;
    },
    async getConvenzionePartner() {
      this.loadingConvenzione = true;
      this.convenzione = this.mergeConvenzione(
        await ConvenzioniRepository.getConvenzionePartner(
          this.idStrutture,
          this.anno
        )
      );
      this.loadingConvenzione = false;
    },
  },
};
</script>

<style scoped></style>
