<template>
  <div v-if="idStrutture && idCorso">
    <v-row class="mb-4 mt-2">
      <v-col>
        <h1>Dettaglio corso</h1>
        <h3 class="blue--text">
          Riepilogo
          <span v-if="this.capofila === 'istituzione'">corso</span>
          <span v-else class="font-weight-bold">
            in convenzione da <u>Capofila</u>
          </span>
        </h3>
      </v-col>
    </v-row>
    <v-main v-if="corso">
      <v-row class="my-2">
        <v-col cols="4">
          <v-text-field
            v-model="nominativoReferente"
            label="Referente"
            :readonly="true"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="corso.dataInizio"
            label="Data inizio "
            type="date"
            :readonly="true"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="corso.dataFine"
            label="Data fine "
            type="date"
            :readonly="true"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="my-2">
        <v-col cols="4">
          <v-text-field
            v-model="corso.accordi[0].istituto.denominazioneIstitutoRiferimento"
            label="Istituto scolastico"
            :readonly="true"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="corso.accordi[0].istituto.tipologiaGradoIstruzioneScuola"
            label="Tipologia istituto "
            :readonly="true"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="corso.regione"
            label="Regione "
            :readonly="true"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <v-col>
          <h3 class="blue--text">Elenco studenti con attestato</h3>
        </v-col>
      </v-row>
      <StudentiConCertificato :id-corso="idCorso"></StudentiConCertificato>
      <FormatCorso :corso="corso" :capofila="capofila"></FormatCorso>
    </v-main>
    <v-progress-circular v-else indeterminate color="primary" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CorsiRepository from "@/api/istituto/CorsiRepository";
import StudentiConCertificato from "@/components/Rendiconti/StudentiConCertificato";
import FormatCorso from "@/components/Rendiconti/FormatCorso";

export default {
  name: "dettaglioCorsoComponent",
  components: { StudentiConCertificato, FormatCorso },
  data: () => ({
    loadingDettaglio: false,
    corso: null,
    nominativoReferente: null,
  }),
  props: {
    capofila: {
      type: String,
      required: true,
    },
    idCorso: {
      type: [String, Number],
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["idStrutture", "anno", "user"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleConsulenza",
      "isRoleLegale",
    ]),
    loading: function () {
      return this.loadingDettaglio;
    },
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        this.getCorso();
      }
    },
    async getCorso() {
      this.loadingDettaglio = true;
      this.corso = await CorsiRepository.getCorsoOrientamento(
        this.idStrutture,
        this.anno,
        this.idCorso
      );
      this.nominativoReferente = `${this.corso.referente.anagrafica.cognome} ${this.corso.referente.anagrafica.nome}`;
      let dataInizioFormattata;
      dataInizioFormattata = this.corso.dataInizio.split("T");
      this.corso.dataInizio = dataInizioFormattata[0];
      let dataFineFormattata;
      dataFineFormattata = this.corso.dataFine.split("T");
      this.corso.dataFine = dataFineFormattata[0];
      this.loadingDettaglio = false;
      console.log(this.corso);
    },
  },
};
</script>

<style scoped></style>
