<template>
  <v-container>
    <StepRendicontazioni :numero-step="6" />
    <h1 class="mb-4 sottotitolo">Firma Documentazione</h1>
    <v-alert type="warning" class="pt-2 mt-2" outlined dense>
      Nel caso in cui i corsi inseriti nel rendiconto e/o le successive sezioni
      per la compilazione del rendiconto siano state modificate, è necessario
      generare un pdf di dichiarazione che includa le modifiche e caricarlo
      prima di procedere alla trasmissione.
    </v-alert>
    <v-form ref="form" lazy-validation>
      <v-card v-if="loadingPage" flat outlined>
        <div class="d-flex">
          <v-main class="pa-2 order-1">
            <v-row>
              <span>
                <v-icon size="150" color="blue-grey lighten-5">
                  mdi-check-circle
                </v-icon>
                Doc_da_firmare
              </span>
            </v-row>
            <v-row>
              <v-card-text>
                <h3 v-html="'Documento'" />
              </v-card-text>
              <v-card-text> Scarica la documentazione </v-card-text>
              <v-card-actions>
                <v-btn
                  class="primary"
                  v-html="'Scarica il Documento'"
                  @click="downloadTemplatePdf()"
                  :disabled="downloadInCorso"
                />
              </v-card-actions>
            </v-row>
            <br />
            <alert-component
              tipo-alert="warning"
              :testo="'La procedura potrebbe richiedere qualche minuto, dato il possibile numero elevato di corsi.'"
              :width="400"
            />
          </v-main>
          <v-main class="pa-2 order-2">
            <v-row>
              <span>
                <v-icon
                  size="150"
                  :color="
                    rendiconto.fileDocumentazioneFirmata
                      ? 'blue lighten-3'
                      : 'blue-grey lighten-5'
                  "
                >
                  mdi-check-circle
                </v-icon>
                {{
                  rendiconto.fileDocumentazioneFirmata
                    ? "Documento caricato: " +
                      rendiconto.fileDocumentazioneFirmata.nomeOriginale
                    : "Documento da caricare"
                }}
              </span>
            </v-row>
            <v-row>
              <v-card-text>
                <h3>
                  {{
                    rendiconto.fileDocumentazioneFirmata
                      ? "Documento Caricato"
                      : "Documento Firmato"
                  }}
                </h3>
              </v-card-text>
              <v-card-text>
                Carica il documento firmato, in formato
                <b>.p7m</b>
                per ultimare la procedura.
              </v-card-text>
              <v-card-text v-if="!rendiconto.fileDocumentazioneFirmata">
                <v-file-input
                  v-model="form.uploadedFiles"
                  placeholder="Effettua l'upload del tuo documento firmato"
                  label="Documentazione rendiconto firmato"
                  :rules="rules.fieldRequired"
                  accept=".p7m"
                />
                <v-btn
                  class="primary v-btn--outlined v-btn--tile white--text"
                  v-html="'Carica il documento'"
                  @click="dialogConferma = true"
                />
                <dialog-conferma
                  :dialog.sync="dialogConferma"
                  @callback="salvaBozzaInserimentoRendiconto"
                  @close-dialog="dialogConferma = false"
                />
              </v-card-text>
              <v-card-text v-else>
                <v-btn
                  v-if="rendiconto.fileDocumentazioneFirmata"
                  @click="dialogDeleteFile = true"
                  class="border-danger"
                >
                  <v-icon> mdi-delete</v-icon> Rimuovi il file</v-btn
                >
                <dialog-conferma
                  :dialog.sync="dialogDeleteFile"
                  @callback="eliminaFileCaricato"
                  @close-dialog="dialogDeleteFile = false"
                />
              </v-card-text>
            </v-row>
          </v-main>
          <v-main class="pa-2 order-4">
            <v-card-text
              v-if="rendiconto.fileDocumentazioneFirmato"
              v-html="
                'File caricato: <br />' +
                rendiconto.fileDocumentazioneFirmato.nomeOriginale
              "
            >
            </v-card-text>
          </v-main>
        </div>
        <div class="d-flex mt-15">
          <v-col cols="4">
            <v-btn @click="goOutRendicontioSection">
              Torna al menù principale.
            </v-btn>
          </v-col>
          <v-col v-if="!rendiconto.fileDocumentazioneFirmata" cols="6">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="grey" dark v-bind="attrs" v-on="on">
                  Continua
                </v-btn>
              </template>
              <span
                >Non e' possibile procedere alla trasmissione dei dati del
                rendiconto, <br />in quanto il documento firmato dal Legale
                Rappresentante non è stato caricato.</span
              >
            </v-tooltip>
          </v-col>
          <v-col v-else cols="6">
            <v-btn
              v-html="'Continua'"
              class="primary"
              @click="dialogNextStep = true"
            />
          </v-col>
          <dialog-conferma
            text-conferma="Nel caso in cui i corsi inseriti nel rendiconto e/o le successive
        sezioni per la compilazione del rendiconto siano state modificate, è
        necessario generare un nuovo pdf di Dichiarazione e caricarlo prima di
        procedere alla trasmissione."
            @callback="goToNextStep"
            @close-dialog="dialogNextStep = false"
            :dialog.sync="dialogNextStep"
          />
          <v-snackbar
            :color="snackbar.color"
            v-model="snackbar.value"
            max-width="800"
            max-height="800"
          >
            {{ snackbar.text }}</v-snackbar
          >
        </div>
      </v-card>
    </v-form>
  </v-container>
</template>
<script>
import StepRendicontazioni from "@/components/Rendiconti/StepRendicontazioni";
import { mapGetters, mapState } from "vuex";
import RendicontiRepository from "@/api/istituto/RendicontiRepository";
import DialogConferma from "@/components/DialogConferma";
import AlertComponent from "@/components/Common/AlertComponent";

export default {
  name: "documentazioneAttivitaRendiconto",
  components: { AlertComponent, StepRendicontazioni, DialogConferma },
  props: {
    capofila: {
      type: String,
      required: true,
    },
    operazione: {
      type: String,
      required: true,
      default: "modifica",
    },
  },
  computed: {
    ...mapState(["idStrutture", "anno", "user", "idRendiconto"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleConsulenza",
      "isRoleLegale",
    ]),
  },
  watch: {
    idStrutture() {
      return this.initialize();
    },
    anno() {
      return this.initialize();
    },
  },
  data: () => ({
    loadingPage: false,
    rendiconto: null,
    aggiornamentoFile: false,
    statoVerificaCaricamentoPdf: 0,
    form: {
      uploadedFiles: null,
      currentStep: 6,
    },
    rules: {
      fieldRequired: [
        (v) =>
          !!v ||
          "E' richiesto necessariamente l'upload del documento in formato .p7m e dunque firmato",
      ],
    },
    downloadedFiles: null,
    snackbar: { text: null, color: null, value: null },
    dialogConferma: false,
    dialogDeleteFile: false,
    dialogNextStep: false,
    downloadInCorso: false,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.capofila) {
        this.getRendiconto();
      }
    },
    async getRendiconto() {
      const idStrutture = this.idStrutture;
      this.rendiconto = await RendicontiRepository.getSingoloRendiconto(
        idStrutture,
        this.idRendiconto
      );
      if (this.rendiconto) {
        this.rendiconto.checklist.sort((a, b) => {
          return a.id - b.id;
        });
        this.loadingPage = true;
      }
    },
    async downloadTemplatePdf() {
      this.downloadInCorso = true;
      const formBody = new FormData();
      formBody.append("rendiconto", JSON.stringify(this.rendiconto));
      await RendicontiRepository.downloadTemplatePdfDcoumentazioneRendiconto(
        this.idStrutture,
        this.idRendiconto,
        formBody
      )
        .then((data) => {
          this.downloadedFiles = data;
          this.downloadInCorso = false;
        })
        .catch(() => {
          this.downloadInCorso = false;
        });
    },
    async salvaBozzaInserimentoRendiconto() {
      if (this.$refs.form.validate()) {
        const formBody = new FormData();
        formBody.append("documentazioneRendiconto", this.form.uploadedFiles);

        try {
          await RendicontiRepository.modificaRendiconto(
            this.idStrutture,
            this.idRendiconto,
            6,
            formBody
          );
          // intercetto l'evento con cui verifico che l'utente ha caricato un template pdf
          this.aggiornamentoFile = true;
          this.loadingPage = false;
          this.initialize();
        } catch (reason) {
          if (reason.response.status === 415) {
            this.setPropertySnackbar(false, reason.response.data[0]);
          } else if (reason.response.status === 400) {
            this.setPropertySnackbar(
              false,
              "Non è stato caricato alcun file. Errore nella richiesta"
            );
          }
        }
      } else {
        this.setPropertySnackbar(
          false,
          "Verificare la compilazione dei campi richiesti"
        );
      }
    },
    goToNextStep() {
      this.$router.push({
        name: "riepilogoTrasmissioneRendiconto",
        params: { operazione: this.operazione, capofila: this.capofila },
      });
    },
    goOutRendicontioSection() {
      this.$store.commit("setIdRendiconto", null);
      if (!this.idRendiconto) {
        this.$router.push({
          name: "RendicontiView",
          params: {
            capofila: this.capofila,
          },
        });
      }
    },
    setPropertySnackbar(esito, testo) {
      this.snackbar.color = esito ? "success" : "red accent-2";
      this.snackbar.text = testo;
      this.snackbar.value = true;
    },
    async eliminaFileCaricato() {
      await RendicontiRepository.eliminaFileCaricato(
        this.idStrutture,
        this.idRendiconto
      ).then(() => {
        this.form.uploadedFiles = null;
        this.initialize();
      });
    },
    /*isDisablePulsanteNextStep() {
      if (!this.form.uploadedFiles) {
        // file non presente pulsante continua disbailitato a prescindere
        return 0;
      }

      if (!this.aggiornamentoFile && this.rendiconto.step >= 6) {
        return 1;
      }

      if (this.form.uploadedFiles && this.aggiornamentoFile) {
        return 2;
      }

      /*else if (
        !this.aggiornamentoFile &&
        this.rendiconto.step >= 6 &&
        this.rendiconto.fileDocumentazioneFirmata
      ) {
        // quando  non hanno aggiornato il file ed ho già salvato in precedenza il file
        // in questa casistica deve apparire l'alert in cui devono
        return 1;
      } else {
        /**
         * this.aggiornamentoFile = true
         * this.step > 6 o this.step < 6;
         * ho comunque aggiornato il file e lho caricato
        
        return 2;
      }
    },*/
  },
};
</script>
<style scoped>
.v-file-input {
  width: 60%;
}
.sottotitolo {
  color: #1a237e;
  margin-left: 1%;
  font-weight: bold;
}
</style>
