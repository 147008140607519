import Repository from "../Repository";

export default {
  async inserimentoIndicatori(
    idStrutture,
    anno,
    capofila,
    bodyIndicatoriComuni
  ) {
    const url = `api/istituto/${idStrutture}/indicatori/anno/${anno}/capofila/${capofila}/indicatori-comuni`;
    const headers = { "Content-Type": "multipart/form-data" };

    return (await Repository.post(url, bodyIndicatoriComuni, { headers })).data;
  },
  async getIndicatoreComuneAteneo(idStrutture, anno, capofila) {
    const url = `api/istituto/${idStrutture}/indicatori/anno/${anno}/capofila/${capofila}/indicatori-comuni`;
    return (await Repository.get(url)).data;
  },

  async modificaIndicatore(idStrutture, idIndicatore, bodyIndicatoriComuni) {
    const url = `api/istituto/${idStrutture}/indicatori/indicatori-comuni/${idIndicatore}`;
    const headers = { "Content-Type": "multipart/form-data" };

    return (await Repository.post(url, bodyIndicatoriComuni, { headers })).data;
  },

  async confermaMonitoraggioMensile(idStrutture, anno, capofila) {
    const url = `api/istituto/${idStrutture}/monitoraggio/anno/${anno}/capofila/${capofila}/monitoraggi`;
    return (await Repository.post(url, null)).data;
  },
  async getMonitoraggiProgramma(idStrutture, idProgramma) {
    const url = `api/istituto/${idStrutture}/monitoraggio/programmi-orientamento/${idProgramma}/monitoraggi`;

    return (await Repository.get(url)).data;
  },

  async getCampiStudentiAutomatici(idStrutture, anno, capofila, idSemestre) {
    const url = `api/istituto/${idStrutture}/indicatori/anno/${anno}/capofila/${capofila}/indicatori-comuni/${idSemestre}/dati-studenti`;
    return (await Repository.get(url)).data;
  },
  async getSemestri() {
    const url = `api/generici/semestri`;
    return (await Repository.get(url)).data;
  },
};
