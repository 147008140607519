import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Keycloak from "keycloak-js";
import VueLogger from "vuejs-logger";
import vuetify from "@/plugins/vuetify";
import axios from "axios";
import filtersNumber from "@/filters/filtersNumber";
import filtersDate from "@/filters/filtersDate";

filtersNumber.forEach((f) => {
  Vue.filter(f.name, f.execute);
});

filtersDate.forEach((f) => {
  Vue.filter(f.name, f.execute);
});

const isProduction = process.env.NODE_ENV === "production";

Vue.config.productionTip = false;

const loggerOptions = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

Vue.use(VueLogger, loggerOptions);

let keycloakOptions = {
  url: process.env.VUE_APP_OIDC_URL + "/auth/",
  realm: "Orientamento26",
  clientId: "vueapp",
  onLoad: "login-required",
};

let keycloak = new Keycloak(keycloakOptions);

keycloak
  .init({ onLoad: keycloakOptions.onLoad })
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      Vue.$log.info("Authenticated");

      axios.interceptors.request.use(
        function (config) {
          // Do something before request is sent
          config.headers.common["Authorization"] = `Bearer ${keycloak.idToken}`;
          return config;
        },
        function (error) {
          // Do something with request error
          return Promise.reject(error);
        }
      );

      new Vue({
        router,
        store,
        vuetify,
        render: (h) =>
          h(App, {
            props: { keycloak: keycloak },
          }),
      }).$mount("#app");
    }

    //Token Refresh
    setInterval(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            Vue.$log.info("Token refreshed" + refreshed);
          } else {
            Vue.$log.warn(
              "Token not refreshed, valid for " +
                Math.round(
                  keycloak.tokenParsed.exp +
                    keycloak.timeSkew -
                    new Date().getTime() / 1000
                ) +
                " seconds"
            );
          }
        })
        .catch(() => {
          Vue.$log.error("Failed to refresh token");
        });
    }, 6000);
  })
  .catch(() => {
    Vue.$log.error("Authenticated Failed");
  });
