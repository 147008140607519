<template>
  <v-alert dense outlined elevation="2" v-if="showAlert" type="error">
    Sono presenti una o più richieste di integrazioni/modifiche in attesa di
    risposta per i rendiconti:
    {{ listaRendiconti }}. Per visualizzarle e rispondere andare alla voce
    'Dettagli integrazioni' del rendiconto interessato
  </v-alert>
</template>

<script>
import { mapState } from "vuex";
import RendicontiRepository from "@/api/istituto/RendicontiRepository";

export default {
  name: "AlertRichiesteRendiconti",
  computed: {
    ...mapState(["anno", "idStrutture"]),
  },
  data: () => ({
    showAlert: false,
    listaRendiconti: [],
  }),
  mounted() {
    this.initialize();
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      this.studentiConvalidati();
    },
    async studentiConvalidati() {
      this.listaRendiconti =
        await RendicontiRepository.getRichiesteIntegrazioni(
          this.idStrutture,
          this.anno
        );
      this.showAlert = this.listaRendiconti.length > 0;
    },
  },
};
</script>

<style scoped></style>
