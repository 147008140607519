var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[(_vm.capofila)?_c('BreadcrumbComponent',{attrs:{"breadcrumbs":_vm.breadcrumbs}}):_vm._e(),_c('br'),_c('div',[_c('h1',{domProps:{"innerHTML":_vm._s('Elenco scuole-accordi')}}),_c('p',[_vm._v(" Si ricorda che è obbligatorio compilare la sezione \"Inserimento scuole e accordi\" prima della chiusura di ciascun corso. ")])]),(_vm.loadingPage)?_c('v-card',{attrs:{"elevation":"4"}},[_c('v-card-title',[_vm._v(" Elenco accordi-scuole per anno accademico "+_vm._s(this.anno)+"/"+_vm._s(this.anno + 1)+" ")]),(_vm.programmaOrientamento)?_c('v-card-subtitle',[_vm._v(" CUP del programma orientamento associato: "),_c('b',[_vm._v(_vm._s(_vm.programmaOrientamento.cup))])]):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"mx-4"},[(
            this.programmaOrientamento != null &&
            _vm.permessiOperazione > 0 &&
            _vm.finestraCompilazione
          )?_c('router-link',{staticClass:"v-btn primary pa-2",attrs:{"to":{
            name: 'aggiungi-scuola-accordi',
            params: {
              capofila: _vm.capofila,
              idAccordo: null,
              operazione: 'inserimento',
            },
          }}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v(" Inserisci un nuovo Accordo-Scuola ")],1):_c('span',[(_vm.programmaOrientamento == null)?_c('v-alert',{staticClass:"alert",attrs:{"width":"800","type":"warning"},domProps:{"innerHTML":_vm._s(
              'Non è possibile aggiungere un accordo-scuola se non hai creato un <b> PROGRAMMA DI ORIENTAMENTO</b>.'
            )}}):_vm._e()],1)],1)],1),_c('v-card-text',[(_vm.scuoleAccordi.length > 0)?_c('div',[_c('v-row',{staticClass:"my-4"},[_c('v-col',{attrs:{"col":"8"}},[_c('v-text-field',{attrs:{"solo":"","append-icon":"mdi-magnify","label":"Cerca per ID Accordo, codice  o nome istituto","hide-details":""},model:{value:(_vm.ricerca),callback:function ($$v) {_vm.ricerca=$$v},expression:"ricerca"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableScuoleAccordi,"items":_vm.scuoleAccordi,"search":_vm.ricerca,"loading":!_vm.permessiOperazione,"show-expand":"","single-expand":""},scopedSlots:_vm._u([{key:"item.cup",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm.programmaOrientamento.cup))])]}},(_vm.anno > 2023)?{key:"item.dataFineAccordo",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.dataFineAccordo)))])]}}:null,(_vm.anno > 2023)?{key:"item.dataFirmaAccordo",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.dataFirmaAccordo)))])]}}:null,(_vm.anno < 2024)?{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('ElencoSottoAccordi',{attrs:{"capofila":_vm.capofila,"itemsSottoAccordi":item.sottoAccordiScuola,"idScuolaAccordo":item.id,"finestra-compilazione-attiva":_vm.finestraCompilazioneAttiva}})],1)]}}:null,{key:"item.fileAccordo",fn:function({ item }){return [(item.fileAccordo != null)?_c('span',[_c('a',{on:{"click":function($event){return _vm.downloadPDF(item, item.fileAccordo.nomeOriginale)}}},[_c('v-icon',[_vm._v("mdi-paperclip")])],1)]):_c('span',{domProps:{"innerHTML":_vm._s('Pdf non caricato')}})]}},{key:"item.azioni",fn:function({ item }){return [(_vm.permessiOperazione > 0 && _vm.checkControlloCorsiTerminati(item, 1) && _vm.finestraCompilazione)?_c('router-link',{attrs:{"to":{
                  name: 'aggiungi-scuola-accordi',
                  params: {
                    capofila: _vm.capofila,
                    idAccordo: item.id,
                    operazione: 'modifica',
                  },
                }}},[_c('span',[_c('v-icon',[_vm._v(" mdi-square-edit-outline ")])],1)]):_c('router-link',{attrs:{"to":{
                  name: 'aggiungi-scuola-accordi',
                  params: {
                    capofila: _vm.capofila,
                    idAccordo: item.id,
                    operazione: 'lettura',
                  },
                }}},[_c('span',[_c('v-icon',[_vm._v(" mdi-eye ")])],1)])]}},(_vm.anno <= 2023)?{key:"item.integra",fn:function({ item }){return [(_vm.checkControlloCorsiTerminati(item, 2) && _vm.finestraCompilazione)?_c('v-list-item',{attrs:{"to":{
                         name: 'aggiungiSottoAccordo',
                         params: {
                           capofila: _vm.capofila,
                           idAccordo: item.id,
                           operazione: 'inserimento',
                         },
                    }}},[_c('span',[_c('v-icon',[_vm._v(" mdi-square-edit-outline ")])],1)]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-square-edit-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(" L’inserimento dell’integrazione sarà possibile solo se la somma tra il "),_c('br'),_vm._v(" valore del campo 'numero_corsi' nell'accordo-scuola specifico e "),_c('br'),_vm._v(" i valori del campo omonimo di tutti i sotto-accordi associati è uguale "),_c('br'),_vm._v(" al numero dei corsi terminati totali, in riferimento all'accordo-scuola specifico. ")])])]}}:null],null,true)})],1):_c('div',[_c('span',[_vm._v(" Non esistono accordi al momento per l'istituto scelto ")])])])],1):_c('v-card',[(_vm.programmaOrientamento == null)?_c('v-alert',{staticClass:"alert",attrs:{"width":"800","type":"warning"},domProps:{"innerHTML":_vm._s(
        'Non è possibile aggiungere un accordo-scuola se non hai creato un <b> PROGRAMMA DI ORIENTAMENTO</b>.'
      )}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }