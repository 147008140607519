<template>
  <v-container>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" v-if="capofila" />
    <v-btn class="primary" @click="$router.go(-1)" v-else>
      <v-icon>mdi-arrow-left-thin</v-icon>Torna indietro
    </v-btn>
    <h1 class="mt-8">Gestisci Attestati di frequenza</h1>
    <template v-if="abilitazione">
      <v-alert
        dense
        type="warning"
        v-if="!abilitazione.idRuolo"
        density="compact"
        class="mt-2"
      >
        ATTENZIONE: Il legale rappresentante non ha abilitato nessun ruolo alla
        firma degli attestati. <br />Abilitare uno dei due ruoli nella sezione
        "Gestione utenti" prima di procedere.
      </v-alert>
      <v-alert dense type="info" density="compact" v-else class="mt-2">
        Il legale rappresentante ha abilitato alla firma gli utenti con il ruolo
        di
        <span v-if="abilitazione.idRuolo === 5">REFERENTE DI CORSO</span>
        <span v-else>REFERENTE DI PROGRAMMA</span>
      </v-alert>
    </template>
    <template v-else>
      <v-alert dense type="warning" density="compact" class="mt-2">
        ATTENZIONE: Il legale rappresentante non ha abilitato nessun ruolo alla
        firma degli attestati. <br />Abilitare uno dei due ruoli nella sezione
        "Gestione utenti" prima di procedere.
      </v-alert>
    </template>
    <v-card elevation="4" class="mx-auto mt-8 pa-6">
      <v-row>
        <v-col cols="4">
          <v-icon large>mdi-account-multiple</v-icon>
          <h2>Andamento Studenti</h2>
          <p class="mt-4">
            Riepilogo della percentuale di studenti che hanno ottenuto
            l'attestato sul totale degli studenti iscritti al corso e la
            percentuale di studenti che non ha ottenuto l'attestato
          </p>
        </v-col>
        <v-col cols="8">
          <template>
            <AndamentoStudentiCertificatiChart
              :certificati="certificati"
              v-if="certificati"
            />
          </template>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="mt-3 pt-3" elevation="2">
      <DatiStudentiCorso
        v-if="loadingComponentStudentiCorso"
        :corso="datiCorso"
        :lezioni-corso="lezioni"
        :studenti-corso="studenti"
      />
    </v-card>
    <v-row>
      <v-col>
        <h2 class="mt-8">Esegui un test di firma</h2>
        <p>
          Si consiglia a tutti i firmatari di eseguire questo test prima di
          iniziare la procedura di firma degli attestati.
        </p>
        <v-btn class="error" @click="getTestFirma">
          Scarica test
          <v-icon right class="white--text"> mdi-tray-arrow-down </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <h1 class="mt-8">Firma e scarica gli attestati di frequenza</h1>
    <ul>
      <li>
        Prima di procedere con la firma, assicurarsi che nella sezione "Gestione
        utenti" sia inserito il <b>codice fiscale corretto</b> del firmatario
      </li>
      <li>
        È possibile
        <b>selezionare</b> solo gli studenti che hanno raggiunto
        <b>il 70%</b> di presenza
      </li>
      <li>
        È possibile
        <b>selezionare e firmare MAX 25 studenti</b> alla volta
      </li>
      <li>
        Cliccando il pulsante <b>"Firma attestati"</b> il sistema genera il
        <b>download</b> di un file.
        <b>L'operazione potrebbe richiedere diversi secondi</b>, si prega di
        attendere e di non chiudere la pagina.
      </li>
      <li>
        Procedi al <b>download dell'attestato</b> per gli studenti che hanno
        raggiunto il minimo di ore di partecipazione previsto dal format
        cliccando sull'apposita <b>icona</b> dall'elenco seguente.
      </li>
      <li>
        Per il download di tutti gli attestati <b><u>firmati</u></b> premere il
        pulsante "scarica tutti".
      </li>
    </ul>
    <v-row class="mt-4">
      <v-col>
        <template v-if="erroriCertificato">
          <div>
            <v-alert prominent type="error">
              <v-row align="center">
                <v-col class="grow">
                  ATTENZIONE: alcuni attestati risultano firmati non
                  correttamente. Premere il pulsante 'Riprova' per sbloccare la
                  selezione e ripetere la procedura di firma per quegli
                  attestati che risultano non conformi. (Operazione concessa
                  solo al firmatario abilitato)
                </v-col>
                <v-col class="shrink">
                  <v-btn
                    v-if="abilitatoFirma || isRoleConsulenza"
                    @click="deleteCertificatiErrati"
                    >Riprova</v-btn
                  >
                </v-col>
              </v-row>
            </v-alert>
          </div>
        </template>
      </v-col>
    </v-row>
    <v-row v-if="!erroriCertificato" class="mt-4">
      <v-col class="d-flex flex-row-reverse">
        <v-btn class="primary" @click="getTuttiCertificati">
          Scarica tutti
          <v-icon right class="white--text"> mdi-tray-arrow-down </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <template>
          <v-row class="my-4">
            <v-col col="8" class="">
              <v-text-field
                solo
                v-model="ricerca"
                append-icon="mdi-magnify"
                label="Cerca per ID Corso"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            dense
            :headers="headers"
            v-model="studentiSelezionati"
            :items="studenti"
            :search="ricerca"
            class="elevation-1"
            :loading="loading"
            loading-text="Caricamento dati"
            loader-height="2"
            ><!-- eslint-disable -->
            <template v-slot:item.regione="{ item }">
              {{ item.regione }}
            </template>
            <template v-slot:item.totOre="{ item }">
              {{ getTotOre(item.idStudente) }}
            </template>
            <template v-slot:item.percentualeOre="{ item }">
              <span style="color:red" v-if="getPercentualeOre(item.idStudente)<70" >
                {{ getPercentualeOre(item.idStudente) }} %
              </span>
              <span v-else>
                {{ getPercentualeOre(item.idStudente) }} %
              </span>
            </template>
            <template v-slot:item.firmaRef="{ item }">
              <template v-if="!item.dataFirmaAttestato">
                <v-tooltip v-if="!item.dataFirmaAttestato && item.certificatoReferenteIstituto" top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <v-checkbox
                          :value="item.certificatoReferenteIstituto"
                          v-model="item.certificatoReferenteIstituto"
                          :readonly="true"
                          color="error"
                      ></v-checkbox>
                    </span>
                  </template>
                  <span>Errore nel processo di firma</span>
                </v-tooltip>
              </template>
              <template v-else>
                <v-checkbox
                    :value="item.certificatoReferenteIstituto"
                    v-model="item.certificatoReferenteIstituto"
                    :readonly="true"
                    color="success"
                ></v-checkbox>
              </template>
            </template>
            <template v-slot:item.scarica="{ item }">
              <a v-if="item.certificatoReferenteIstituto && item.dataFirmaAttestato" @click="getCertificato(item)">
                <v-icon> mdi-tray-arrow-down
                </v-icon>
              </a>
            </template>
            <template v-slot:item.seleziona="{ item }">
              <template v-if="item.esito === 1 && item.codiceErrore === '000'">
                <v-checkbox
                    :disabled="getPercentualeOre(item.idStudente) < 70 ||
                  !abilitatoFirma"
                    :readonly="!!item.certificatoReferenteIstituto"
                    @change="selezionaStudente(item)"
                ></v-checkbox>
              </template>
              <template v-else-if="item.esito === null">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                        v-on="on"
                        large
                        color="warning"
                    >mdi-alert-box</v-icon
                    >
                  </template>
                  <span> Codice fiscale in attesa di convalida</span>
                </v-tooltip>
              </template>
              <template v-else>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                        v-on="on"
                        large
                        color="error"
                    >mdi-alert-box</v-icon
                    >
                  </template>
                  <span v-if="item.esito > 1"> Codice fiscale in fase di verifica. <br/>Possibile incongruità codice meccanografico <br/>scuola - Frequenza studente</span>
                  <span v-else> {{ item.descrizioneErrore }}</span>
                </v-tooltip>
              </template>
            </template>
            <!-- eslint-enable -->
          </v-data-table>
        </template>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex flex-row-reverse">
        <v-btn
          v-if="abilitatoFirma && finestraCompilazione"
          :loading="loading"
          class="primary"
          @click="dialogSalva = true"
          :disabled="pulsanteBloccato"
        >
          Firma gli attestati
        </v-btn>
      </v-col>
    </v-row>
    <dialog-conferma
      @callback="firmaCertificati"
      :dialog.sync="dialogSalva"
      @close-dialog="dialogSalva = false"
    />
    <DialogProsegui
      @callback="recuperaCertificati"
      :capofila="capofila"
      :dialog.sync="dialogProsegui"
      @close-dialog="dialogProsegui = false"
    ></DialogProsegui>
    <v-snackbar v-model="snackbar" shaped :color="snackbarColor">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import corsiRepository from "@/api/istituto/CorsiRepository";
import AndamentoStudentiCertificatiChart from "@/components/AndamentoStudentiCertificatiChart";
import CorsiRepository from "@/api/istituto/CorsiRepository";
import DialogConferma from "@/components/DialogConferma";
import DialogProsegui from "@/components/DialogProsegui";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";
import DatiStudentiCorso from "@/components/DatiStudentiCorsoComponent.vue";

export default {
  name: "GesticiCertificatiCorsiView",
  components: {
    DatiStudentiCorso,
    AndamentoStudentiCertificatiChart,
    BreadcrumbComponent,
    DialogConferma,
    DialogProsegui,
  },
  props: {
    capofila: {
      type: String,
      required: true,
    },
    idCorso: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    abilitazione: {
      idRuolo: null,
    },
    ricerca: null,
    finestraCompilazione: null,
    erroriCertificato: false,
    isMioCorso: false,
    validato: true,
    pulsanteBloccato: true,
    studentiSelezionati: [],
    loadingPage: true,
    firmato: 0,
    idCertificatoDaEliminare: null,
    dialogSalva: null,
    dialogElimina: null,
    dialogProsegui: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    certificati: null,
    lezioni: [],
    studenti: [],
    datiCorso: null,
    loadingLezioni: false,
    loadingStudenti: false,
    loadingCorso: false,
  }),
  computed: {
    ...mapState(["idStrutture", "anno", "user"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleConsulenza",
      "isRoleReferenteCorsi",
      "isRoleLegale",
      "isRoleLegaleCorso",
      "isRoleLegaleProgramma",
      "isRoleReferenteProgramma",
      "isRoleReferenteIstituto",
      "isRoleReferenteIstitutoCorso",
    ]),
    headers() {
      return [
        {
          text: "Seleziona (MAX 25 Studenti)",
          sortable: false,
          value: "seleziona",
        },
        {
          text: "Cognome",
          align: "start",
          value: "cognome",
        },
        { text: "Nome", value: "nome" },
        { text: "Codice fiscale", value: "cf" },
        {
          text: "Istituto scolastico",
          sortable: true,
          value: this.anno === 2022 ? "istituto" : "denominazioneScuola",
        },
        {
          text: "Tipologia istituto",
          sortable: true,
          value:
            this.anno === 2022
              ? "tipoIstituto"
              : "tipologiaGradoIstruzioneScuola",
        },
        { text: "Regione", sortable: false, value: "regione" },
        {
          text: "Tot ore freq.",
          align: "center",
          sortable: false,
          value: "totOre",
        },
        {
          text: "% ore freq.",
          align: "center",
          sortable: false,
          value: "percentualeOre",
        },
        { text: "Attestato Firmato ", sortable: false, value: "firmaRef" },
        { text: "Scarica attestato", sortable: false, value: "scarica" },
      ];
    },
    loading: function () {
      return this.loadingPage;
    },
    loadingComponentStudentiCorso() {
      return this.loadingStudenti && this.loadingLezioni && this.datiCorso;
    },
    //nuova gestione permessi di firma che varia in base a scelta del legale rappr.
    abilitatoFirma() {
      if (
        this.abilitazione.idRuolo === 5 &&
        (this.isRoleReferenteCorsi || this.isRoleLegaleCorso)
      ) {
        return this.isMioCorso;
      } else if (
        this.abilitazione.idRuolo === 6 &&
        (this.isRoleReferenteProgramma || this.isRoleLegaleProgramma)
      ) {
        return true;
      }
      return false;
    },
    //vecchia gestione dei permessi di firma che consente solo a ref corso e ref programma di firmare
    permessiFirma() {
      if (this.isRoleLegaleCorso && this.isMioCorso) {
        return true;
      }
      if (this.isRoleLegaleProgramma) {
        return true;
      }
      if (this.isRoleLegale) {
        return false;
      }
      if (this.isRoleReferenteProgramma || this.isRoleReferenteCorsi) {
        return true;
      }
      return false;
    },
    breadcrumbs: function () {
      if (this.capofila) {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania/${this.capofila}`,
            exact: true,
            link: true,
          },
          {
            text: "Censimento, erogazione e attestati di frequenza",
            disabled: false,
            to: `/scrivania/${this.capofila}/corsi`,
            exact: true,
            link: true,
          },
          {
            text: "Attestati di frequenza",
            disabled: false,
            to: `/scrivania/${this.capofila}/corsi/certificati`,
            exact: true,
            link: true,
          },
          {
            text: "Gestisci",
            disabled: true,
            to: `/scrivania/${this.capofila}/corsi/certificati/gestisci`,
            exact: true,
            link: true,
          },
        ];
      }
      return [];
    },
  },
  watch: {
    firmato() {
      this.initialize();
    },
    user() {
      this.initialize();
    },
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  mounted() {
    this.initialize();
    this.annoScolastico = this.anno + "/" + (this.anno + 1);
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        //this.loadingPage = true;
        this.getLezioniCorso();
        this.getDatiCorso();
        this.getListaStudentiCorso();
        this.getAndamentoCertificati();
        this.getAbilitatoAllaFirma();
        this.getFinestraCompilazione();
        //this.loadingPage = false;
      }
    },
    async getFinestraCompilazione() {
      const finestraCompilazione =
        await IstitutoRepository.getFinestraCompilazione(
          this.idStrutture,
          this.anno,
          "corsi-orientamento-ist"
        );
      if (finestraCompilazione !== "") {
        this.finestraCompilazione = finestraCompilazione;
      }
    },
    async getDatiCorso() {
      this.datiCorso = await corsiRepository.getCorsoOrientamento(
        this.idStrutture,
        this.anno,
        this.idCorso
      );
      this.isMioCorso = this.datiCorso.referente.id === this.user.id;
      if (
        (this.datiCorso.validato == null ||
          this.datiCorso.validato === false) &&
        this.isRoleReferenteIstituto
      ) {
        this.validato = false;
      }
    },
    async getListaStudentiCorso() {
      this.loadingPage = true;
      this.studenti = await corsiRepository.getStudentiCorso(
        this.idStrutture,
        this.idCorso
      );
      this.loadingStudenti = true;
      if (this.datiCorso.accordo) {
        let istituto =
          this.datiCorso.accordo.istituto.denominazioneIstitutoRiferimento;
        let tipoIstituto =
          this.datiCorso.accordo.istituto.tipologiaGradoIstruzioneScuola;
        let regione = this.datiCorso.accordo.istituto.regione;
        this.studenti.forEach(function (studente) {
          studente.istituto = istituto;
          studente.tipoIstituto = tipoIstituto;
          studente.regione = regione;
        });
      }
      this.erroriCertificato = this.studenti.some((item) => {
        if (item.certificatoReferenteIstituto && !item.dataFirmaAttestato) {
          return true;
        }
      });
      this.loadingPage = false;
    },
    async getLezioniCorso() {
      this.lezioni = await corsiRepository.getLezioniCorso(
        this.idStrutture,
        this.idCorso
      );
      this.loadingLezioni = true;
    },
    getTotOre(idStudente) {
      let totOre = 0;
      this.lezioni.forEach(function (lezione) {
        lezione.lezioniStudenti.forEach(function (lezioneStudente) {
          if (lezioneStudente.studente.id === idStudente) {
            totOre = totOre + lezioneStudente.oreSvolte;
          }
        });
      });
      return totOre;
    },
    getPercentualeOre(idStudente) {
      let totOre = this.getTotOre(idStudente);
      //let totOreCorso = 15;
      let totOreCorso = 0;
      this.lezioni.forEach(function (lezione) {
        totOreCorso = totOreCorso + lezione.oreErogate;
      });
      //calcolo del tasso percentuale: (oreStudente · 100)/TotOreCorso senza decimali
      return Math.trunc((totOre * 100) / totOreCorso);
    },
    async getAndamentoCertificati() {
      this.certificati = await corsiRepository.getNumeroCertificati(
        this.idStrutture,
        this.idCorso
      );
    },
    async getCertificato(certificato) {
      await CorsiRepository.getFileCertificato(
        this.idStrutture,
        certificato.idCertificato,
        certificato.certificatoReferenteIstituto.nomeOriginale +
          certificato.idStudente +
          "-" +
          certificato.cognome
      );
    },
    selezionaStudente(item) {
      if (this.studentiSelezionati.includes(item.idStudente)) {
        let index = this.studentiSelezionati.indexOf(item.idStudente);
        this.studentiSelezionati.splice(index, 1);
      } else {
        this.studentiSelezionati.push(item.idStudente);
      }
      //this.pulsanteBloccato = false;
      this.pulsanteBloccato = this.studentiSelezionati.length > 25;
    },
    async firmaCertificati() {
      let studentiSelezionati = { ...this.studentiSelezionati };
      const data = new FormData();
      data.append("studentiSelezionati", JSON.stringify(studentiSelezionati));
      try {
        this.loadingPage = true;
        await CorsiRepository.createAndFirmaCertificati(
          this.idStrutture,
          this.idCorso,
          data
        );
        this.loadingPage = false;
        this.dialogProsegui = true;
      } catch (e) {
        console.log(e);
        if (e.response.status === 400) {
          this.snackbarText =
            "Errore generazione file jnlp: Controllare il codice fiscale del firmatario in gestione utenti. Se il problema persiste contattare il supporto";
          this.snackbarColor = "red accent-2";
        } else {
          this.snackbarText =
            "Problema durante il salvataggio, contattare il supporto se il problema persiste";
          this.snackbarColor = "red accent-2";
        }
        this.snackbar = true;
      }
    },
    async recuperaCertificati() {
      let studentiSelezionati = { ...this.studentiSelezionati };
      const data = new FormData();
      data.append("studentiSelezionati", JSON.stringify(studentiSelezionati));
      try {
        await CorsiRepository.recuperaCertificatiConfirma(
          this.idStrutture,
          this.idCorso,
          data
        );
        this.dialogProsegui = false;
        this.studentiSelezionati = [];
        this.firmato += 1;
        this.$router.push({
          name: "certificati-corsi",
          params: { capofila: this.capofila },
        });
      } catch (e) {
        console.log(e);
        if (e.response.status === 400) {
          this.snackbarText = "Transazione NON firmata: errore nella firma.";
          this.snackbarColor = "red accent-2";
        }
        if (e.response.status === 507) {
          this.snackbarText =
            "Transazione NON firmata: tornare alla pagina precedente e firmare i documenti.";
          this.snackbarColor = "red accent-2";
        } else {
          this.snackbarText =
            "Problema durante il recupero degli attestati, tornare alla pagina precedente e ripetere il processo di firma";
          this.snackbarColor = "red accent-2";
        }
        this.snackbar = true;
      }
    },
    async getTuttiCertificati() {
      try {
        await CorsiRepository.downloadTuttiCertificati(
          this.idStrutture,
          this.idCorso,
          "Certificati_corso_" + this.idCorso + ".zip"
        );
      } catch (e) {
        console.log(e);
        if (e.response.status === 401) {
          this.snackbarText = "ATTENZIONE: Non ci sono attestati firmati";
          this.snackbarColor = "red accent-2";
        } else {
          this.snackbarText =
            "Problema durante il download degli attestati, contattare il supporto se il problema persiste";
          this.snackbarColor = "red accent-2";
        }
        this.snackbar = true;
      }
    },
    async getTestFirma() {
      await CorsiRepository.downloadTestFirma();
    },
    async getAbilitatoAllaFirma() {
      this.abilitazione = await IstitutoRepository.getAbilitaFirma(
        this.idStrutture,
        this.anno
      );
    },
    async deleteAttestato(idCertificato) {
      this.idCertificatoDaEliminare = idCertificato;
      this.dialogElimina = true;
    },
    messaggio() {
      this.snackbar = true;
      this.snackbarText =
        "ATTENZIONE: Non è possibile eliminare certificati di un corso già validato!";
      this.snackbarColor = "red accent-2";
    },
    async deleteCertificatiErrati() {
      try {
        await CorsiRepository.deleteAllCertificatoErrati(
          this.idStrutture,
          this.idCorso
        );
        this.initialize();
      } catch (e) {
        console.log(e);
        this.snackbarText =
          "Problema durante la rimozione degli attestati errati, contattare il supporto se il problema persiste";
        this.snackbarColor = "red accent-2";
        this.snackbar = true;
      }
    },
  },
};
</script>

<style scoped></style>
