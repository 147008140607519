<template>
  <v-container v-if="showCard">
    <v-card color="transparent" outlined>
      <v-card-title>
        <v-icon class="mr-2">mdi-clipboard-check-outline</v-icon>
        Corsi orientamento
      </v-card-title>
      <v-simple-table>
        <template v-slot:default>
          <v-card-text>
            <v-row>
              <v-col style="border-right: 2px solid darkgrey">
                <v-card-text>
                  <b style="font-size: 20px">{{
                    numeroCorsiTotaliDaErogare
                  }}</b>
                  Da Erogare
                </v-card-text>
                <v-card-text>
                  <b style="font-size: 20px">{{
                    numeroCorsiTotaliInErogazione
                  }}</b>
                  In erogazione
                </v-card-text>
                <v-card-text>
                  <b style="font-size: 20px">
                    {{ numeroCorsiTotaliErogati }}
                  </b>
                  Erogati
                </v-card-text>
                <v-card-text>
                  <b style="font-size: 20px">
                    {{ corsiTerminati.length }}
                  </b>
                  Terminati
                </v-card-text>
              </v-col>
              <v-col>
                <v-card-text class="text-center">
                  <b style="font-size: 20px">
                    {{ corsi.length }}
                  </b>
                  <br />
                  Totale corsi di orientamento
                </v-card-text>
                <v-card-text class="text-center">
                  <b style="font-size: 20px">
                    {{ programmaOrientamento.numeroCorsi }}
                  </b>
                  <br />
                  Target
                </v-card-text>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CorsiRepository from "@/api/istituto/CorsiRepository";

export default {
  name: "CorsiOrientamento",
  components: {},
  computed: {
    ...mapState(["idStrutture", "anno"]),
    ...mapGetters([
      "isRoleConsulenza",
      "isRoleIstituto",
      "isRoleLegale",
      "isReadOnly",
    ]),
  },
  watch: {
    anno() {
      this.initialize();
    },
    idStrutture() {
      this.initialize();
    },
  },
  data: () => ({
    numeroCorsiTotaliTerminati: 0,
    numeroCorsiTotaliDaErogare: 0,
    numeroCorsiTotaliErogati: 0,
    numeroCorsiTotaliInErogazione: 0,
    corsiTerminati: [],
    corsi: [],
    programma: [],
    showCard: false,
  }),
  props: {
    capofila: {
      type: String,
      required: true,
    },
    programmaOrientamento: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.showCard = false;
      if (this.idStrutture && this.anno != null) {
        this.getCorsi();
      }
    },
    async getCorsi() {
      this.corsi = await CorsiRepository.getCorsi(
        this.idStrutture,
        this.anno,
        this.capofila
      );
      let terminato = true;
      if (terminato === true) {
        //let corsiTer = 0;
        this.corsiTerminati = this.corsi.filter((element) => {
          return element.terminato === true;
        });
        this.numeroCorsiTotaliTerminati = this.corsiTerminati.length;
      }
      // let corsiTer = 0;
      // this.numeroCorsiTotaliTerminati = corsiTer;
      const dateOdierna = new Date().getTime();
      let corsiDaErogare = 0;
      let corsiInErogazione = 0;
      let corsiErogati = 0;

      this.corsi.forEach((element) => {
        let dataInizio = new Date(element.dataInizio).getTime();
        let dataFine = new Date(element.dataFine).getTime();
        //corsiTer += element.terminato;

        if (dateOdierna < dataInizio) {
          corsiDaErogare += element.terminato !== true;
        }
        if (dateOdierna >= dataInizio && dateOdierna <= dataFine) {
          corsiInErogazione += element.terminato !== true;
        }
        if (dateOdierna > dataInizio && dateOdierna >= dataFine) {
          corsiErogati += element.terminato !== true;
        }
      });

      this.numeroCorsiTotaliDaErogare = corsiDaErogare;
      this.numeroCorsiTotaliInErogazione = corsiInErogazione;
      this.numeroCorsiTotaliErogati = corsiErogati;

      this.showCard = true;
    },
  },
};
</script>
<style scoped></style>
