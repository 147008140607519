<template>
  <v-container>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" v-if="capofila" />
    <br />
    <v-main v-if="this.loading">
      <h1>
        Integra l'accordo inserito con l'istituto
        {{ accordoScuola.istituto.codiceScuola }} -
        {{ accordoScuola.istituto.denominazioneScuola }}
      </h1>

      <v-card elevation="3">
        <v-form @submit="submitSottoAccordo" ref="form" v-model="valid">
          <v-row class="mt-10">
            <v-col>
              <v-text-field
                label="CUP *"
                placeholder="CUP *"
                v-model="accordoScuola.programmaOrientamento.cup"
                outlined
                disabled
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Anno"
                v-model="this.anno"
                outlined
                disabled
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                type="number"
                min="1"
                label="Numero corsi *"
                v-model="sottoAccordo.numeroCorsi"
                :rules="checkValueForm.valueRequired"
              />
            </v-col>
            <v-col>
              <v-text-field
                type="number"
                min="1"
                placeholder="Numero alunni"
                label="Numero alunni *"
                v-model="sottoAccordo.numeroAlunni"
                :rules="checkValueForm.valueRequired"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                type="number"
                min="1"
                label="Numero docenti da coinvolgere *"
                v-model="sottoAccordo.numeroDocenti"
                :rules="checkValueForm.valueRequired"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                label="Nome Legale rappresentante"
                disabled
                v-model="legaleRappresentante.nome"
                outlined
              ></v-text-field
            ></v-col>
            <v-col>
              <v-text-field
                label="Cognome Legale rappresentante"
                disabled
                v-model="legaleRappresentante.cognome"
                outlined
              ></v-text-field
            ></v-col>
            <v-col>
              <v-text-field
                label="CF Legale rappresentante"
                v-model="legaleRappresentante.codiceFiscale"
                outlined
                disabled
              ></v-text-field
            ></v-col>
          </v-row>
          <h3 v-html="'Integra accordo'" />
          <v-row>
            <v-col>
              <v-text-field
                readonly
                :value="accordoScuola.istituto.denominazioneScuola"
              />
            </v-col>
            <v-col>
              <v-file-input
                accept="application/pdf"
                :placeholder="placeholderInputFile"
                v-model="fileSottoAccordo"
                label="PDF Sotto-accordo"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-alert type="warning" v-if="!fileSottoAccordo" width="900">
                'Attenzione! <br />
                Non è stato selezionato alcun PDF per il sotto-accordo scuola
                specifico.
              </v-alert>
              <v-alert v-else type="success" width="900">
                Il PDF è
                {{
                  operazione === "modifica"
                    ? "già stato inserito. E’ possibile modificarlo fino al termine del primo corso censito. "
                    : "memorizzato correttamente."
                }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                color="#06c"
                @click="dialogSalva = true"
                dark
                v-html="'Conferma'"
              />
            </v-col>
          </v-row>
        </v-form>
        <dialog-conferma
          @callback="submitSottoAccordo"
          :dialog.sync="dialogSalva"
          @close-dialog="dialogSalva = false"
        />
      </v-card>
    </v-main>
    <v-main v-else>
      <v-progress-linear color="blue"></v-progress-linear>
    </v-main>
  </v-container>
</template>
<script>
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent.vue";
import { mapState } from "vuex";
import ScuoleAccordoRepository from "@/api/istituto/ScuoleAccordoRepository";
import DialogConferma from "@/components/DialogConferma.vue";
import RichiesteRepository from "@/api/istituto/RichiesteRepository";
export default {
  name: "aggiungiSottoAccordo",
  components: { BreadcrumbComponent, DialogConferma },
  props: {
    capofila: {
      type: String,
      required: true,
    },
    idAccordo: {
      type: Number,
      required: true,
    },
    operazione: {
      type: String,
      required: true,
      default: "inserimento",
    },
    idSottoAccordo: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapState(["idStrutture", "anno"]),
    loading: function () {
      return this.loadingPage;
    },
    breadcrumbs: function () {
      if (this.capofila) {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania/${this.capofila}`,
            exact: true,
            link: true,
          },
          {
            text: "Scuole-Accordi",
            disabled: false,
            to: `/scrivania/${this.capofila}/scuole-accordi`,
            exact: true,
            link: true,
          },
          {
            text: "Sotto-Accordi",
            disabled: true,
            to: `/scrivania/${this.capofila}/scuole-accordi/${this.idAccordo}/sotto-accordi`,
            exact: true,
            link: true,
          },
        ];
      }
      return [];
    },
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      return this.initialize();
    },
  },
  mounted() {
    this.intialize();
  },
  data: () => ({
    accordoScuola: null,
    loadingPage: false,
    checkPermessoInserimento: null,
    sottoAccordo: {
      numeroCorsi: 1,
      numeroAlunni: 1,
      numeroDocenti: 1,
    },
    fileSottoAccordo: null,
    placeholderInputFile: "Selezionare il file dell'accordo",
    valid: true,
    checkValueForm: {
      valueRequired: [
        (v) => !!v || "Il valore del campo seguente è obbligatorio",
        (v) =>
          (!!v && v >= 1) ||
          "Il valore del campo deve essere un valore positivo",
      ],
    },
    dialogSalva: false,
    legaleRappresentante: {
      nome: null,
      cognome: null,
      codiceFiscale: null,
    },
  }),
  methods: {
    async intialize() {
      let idStrutture = this.$store.state.idStrutture;
      let anno = this.$store.state.anno;

      if (this.idAccordo) {
        await this.getAccordo(idStrutture, anno, this.idAccordo);
        if (this.operazione === "modifica") {
          await this.getSottoAccordo(this.idSottoAccordo);
        }
        this.loadingPage = true;
      }
    },
    async getAccordo(idStrutture, anno, idAccordo) {
      await ScuoleAccordoRepository.getSingoloAccordo(
        idStrutture,
        idAccordo,
        anno
      ).then((data) => {
        this.accordoScuola = data;
        let idProgramma = this.accordoScuola.programmaOrientamento.id;
        this.getLegaleRappresentanteAttivo(idStrutture, idProgramma);
      });
    },
    async submitSottoAccordo() {
      if (this.$refs.form.validate()) {
        let anno = this.$store.state.anno;
        let idStrutture = this.$store.state.idStrutture;
        const formData = new FormData();
        formData.append("sottoAccordo", JSON.stringify(this.sottoAccordo));
        formData.append("pdfSottoAccordo", this.fileSottoAccordo);

        this.operazione === "inserimento"
          ? this.aggiungiSottoAccordo(anno, idStrutture, formData)
          : this.modificaSottoAccordo(anno, idStrutture, formData);
      }
    },
    async getSottoAccordo(idSottoAccordo) {
      let idStrutture = this.idStrutture;
      ScuoleAccordoRepository.getSingoloSottoAccordo(
        idStrutture,
        this.idAccordo,
        idSottoAccordo
      ).then((data) => {
        this.sottoAccordo.numeroAlunni = data.numeroAlunni;
        this.sottoAccordo.numeroCorsi = data.numeroCorsi;
        this.sottoAccordo.numeroDocenti = data.numeroDocenti;
        if (data.fileSottoAccordo) {
          this.fileSottoAccordo = data.fileSottoAccordo;
          this.placeholderInputFile = data.fileSottoAccordo.nomeOriginale;
        }
      });
    },
    async aggiungiSottoAccordo(anno, idStrutture, formData) {
      ScuoleAccordoRepository.caricaSottoAccordo(
        idStrutture,
        anno,
        this.accordoScuola.id,
        formData
      ).then((data) => {
        if (data) {
          this.$router.push({
            name: "scuole-accordi",
            params: { capofila: this.capofila },
          });
        }
      });
    },
    async getSottoAccordoAttivo(idStrutture, anno, idAccordo) {
      ScuoleAccordoRepository.getSottoAccordoAttivo(
        idStrutture,
        anno,
        idAccordo,
        true
      ).then((data) => {
        if (!data) {
          this.checkPermessoInserimento = true;
        } else {
          let numeroCorsiSottoAccordo = data.sottoAccordoAttivo.numeroCorsi;
          let totCorsiTerminati = data.totCorsiSottoAccordo;

          totCorsiTerminati === numeroCorsiSottoAccordo
            ? (this.checkPermessoInserimento = true)
            : (this.checkPermessoInserimento = false);
        }
      });
    },
    async modificaSottoAccordo(anno, idStrutture, formData) {
      ScuoleAccordoRepository.modificaSottoAccordo(
        idStrutture,
        this.idAccordo,
        this.idSottoAccordo,
        anno,
        formData
      ).then((data) => {
        if (data) {
          this.$router.push({
            name: "scuole-accordi",
            params: { capofila: this.capofila },
          });
        }
      });
    },
    async getLegaleRappresentanteAttivo(idStrutture, idProgramma) {
      RichiesteRepository.getLegaleRappresentante(
        idStrutture,
        idProgramma
      ).then((data) => {
        if (data) {
          this.legaleRappresentante.nome = data.nomeRappresentante;
          this.legaleRappresentante.cognome = data.cognomeRappresentante;
          this.legaleRappresentante.codiceFiscale =
            data.codiceFiscaleRappresentante;
        }
      });
    },
  },
};
</script>
