<template>
  <v-container>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" v-if="capofila" />
    <div v-if="conto">
      <v-row class="my-4">
        <v-col>
          <h1>Dettaglio conto</h1>
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <v-col>
          <v-card>
            <v-card-title class="pb-8">
              <h4>Dati conto corrente o di tesoreria</h4>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="3" class="">
                  <p>
                    N° conto corrente
                    <br />
                    <br />
                    <b>{{ conto.contoTesoreriaPostale }}</b>
                  </p>
                </v-col>
                <v-col cols="3">
                  <p>
                    IBAN
                    <br />
                    <br />
                    <b>{{ conto.ibanAssociato }}</b>
                  </p>
                </v-col>
                <v-col cols="3">
                  <p>
                    Acceso presso
                    <br />
                    <br />
                    <b>{{ conto.accesoPresso }}</b>
                  </p>
                </v-col>
                <v-col cols="3">
                  <p>
                    Intestato a
                    <br />
                    <br />
                    <b>{{ conto.intestatario }}</b>
                  </p>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col cols="12">
                  <h4 class="text-uppercase">
                    Persone delegate ad operare sul suddetto conto:
                  </h4>
                </v-col>
              </v-row>
            </v-card-text>
            <v-row class="mt-2">
              <v-col cols="2">
                <v-btn
                  text
                  class="blue--text"
                  @click="getFileRiepilogoContoDelegato(0, conto)"
                  ><v-icon class="mr-2"> mdi-microsoft-excel </v-icon>
                  Esporta Excel
                </v-btn>
              </v-col>
              <v-col cols="2">
                <v-btn
                  text
                  class="blue--text"
                  @click="getFileRiepilogoContoDelegato(1, conto)"
                  ><v-icon class="mr-2"> mdi-file-pdf-box </v-icon>
                  Esporta PDF
                </v-btn>
              </v-col>
              <v-col cols="4"></v-col>
            </v-row>
            <v-row class="pa-4">
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="conto.delegati"
                  :items-per-page="10"
                  class="elevation-1"
                  loading-text="Caricamento dati in corso..."
                  ><!-- eslint-disable -->
                  <template v-slot:item.dataNascita="{ item }">
                    {{item.dataNascita | formatDate}}
                  </template>
                  <!-- eslint-enable -->
                </v-data-table>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div class="mt-4">
      <v-btn text class="blue--text" @click="goToPreviousPage">
        <v-icon>mdi-arrow-left-thin</v-icon>Torna alle dichiarazioni
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import DichiarazioneFlussiRepository from "@/api/istituto/DichiarazioneFlussiRepository";

export default {
  name: "dettaglioContoView",
  components: { BreadcrumbComponent },
  data: () => ({
    headers: [
      {
        text: "Nome",
        value: "nome",
      },
      { text: "Cognome", value: "cognome", sortable: true },
      { text: "Qualifica", value: "qualifica", sortable: false },
      { text: "Luogo di nascita", value: "luogoNascita", sortable: false },
      { text: "Data di nascita", value: "dataNascita", sortable: false },
      { text: "Codice Fiscale", value: "cf", sortable: false },
    ],
  }),
  props: {
    capofila: {
      type: String,
      required: true,
    },
    conto: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(["idStrutture", "anno", "user"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleConsulenza",
      "isRoleLegale",
    ]),
    breadcrumbs: function () {
      if (this.capofila) {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania/${this.capofila}`,
            exact: true,
            link: true,
          },
          {
            text: "Dichiarazione di tracciabilità dei flussi finanziari",
            disabled: false,
            to: `/scrivania/${this.capofila}/tracciabilita-flussi-finanziari`,
            exact: true,
            link: true,
          },
          {
            text: "Dettaglio conto",
            disabled: true,
            to: `/scrivania/${this.capofila}/rendiconti/${this.idRendiconto}/dettaglioRendiconto`,
            exact: true,
            link: true,
          },
        ];
      }
      return [];
    },
  },
  methods: {
    goToPreviousPage() {
      this.$router.push({
        name: "DichiarazioniTracciabilitaView",
        params: {
          capofila: this.capofila,
        },
      });
    },
    async getFileRiepilogoContoDelegato(tipoFile, conto) {
      const formData = new FormData();
      formData.append("conto", JSON.stringify(conto));
      await DichiarazioneFlussiRepository.downloadFileRiepilogoConto(
        this.idStrutture,
        conto.id,
        tipoFile,
        formData
      );
    },
  },
};
</script>

<style scoped></style>
