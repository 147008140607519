import Repository from "../Repository";

export default {
  async getTargetOriginale(idStrutture, anno) {
    let url = `api/istituto/${idStrutture}/targetOriginale/anno/${anno}`;
    return (await Repository.get(url)).data;
  },
  async getTarget(idStrutture, anno) {
    let url = `api/istituto/${idStrutture}/target/anno/${anno}`;
    return (await Repository.get(url)).data;
  },
  async accettaTarget(idStrutture, anno) {
    let url = `api/istituto/${idStrutture}/target/anno/${anno}/accetta`;
    return (await Repository.post(url)).data;
  },
  async rifiutaTarget(idStrutture, anno) {
    let url = `api/istituto/${idStrutture}/target/anno/${anno}/rifiuta`;
    return (await Repository.post(url)).data;
  },
  async riapriTarget(idStrutture, anno) {
    let url = `api/istituto/${idStrutture}/target/anno/${anno}/riapri`;
    return (await Repository.post(url)).data;
  },
  async updateTarget(idStrutture, anno, data) {
    let url = `api/istituto/${idStrutture}/target/anno/${anno}`;
    return (await Repository.put(url, data)).data;
  },
  async getCertificatiFirmati(idStrutture, anno) {
    let url = `api/istituto/${idStrutture}/target/anno/${anno}/certificati`;
    return (await Repository.get(url)).data;
  },
};
