import Repository from "../Repository";

export default {
  async getListaRendiconti(idStrutture, anno, capofila) {
    const url = `api/istituto/${idStrutture}/rendiconti/anno/${anno}/${capofila}/lista-rendiconti`;
    return (await Repository.get(url)).data;
  },
  async getListaCorsiRendiconto(idStrutture, idRendiconto) {
    const url = `api/istituto/${idStrutture}/rendiconti/${idRendiconto}/lista-corsi`;
    return (await Repository.get(url)).data;
  },
  async getQuadrimestreAttivo(anno, attivo) {
    const url = `api/generici/quadrimestre/anno/${anno}?attivo=${attivo}`;
    return (await Repository.get(url)).data;
  },
  async salvaBozzaRendiconto(
    idStrutture,
    anno,
    capofila,
    esistente,
    rendiconto
  ) {
    const url = `api/istituto/${idStrutture}/rendiconti/anno/${anno}/${capofila}?esistente=${esistente}`;
    const headers = { "Content-type": "multipart/formData" };
    return (await Repository.post(url, rendiconto, { headers })).data;
  },
  async downloadFileCorsiValidati(idStrutture, tipologiaFile, formBody) {
    const url = `api/istituto/${idStrutture}/rendiconti/corsi-validati/da-file/${tipologiaFile}`;
    //const headers = { "Content-type": "multipart/form-data" };

    const res = await Repository.post(url, formBody, { responseType: "blob" });

    if (res.status === 200) {
      const fileResponse = new Blob([res.data], { type: res.data.type });
      const objectUrl = URL.createObjectURL(fileResponse);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = "corsi-validati." + tipologiaFile;
      fileLink.click();
      URL.revokeObjectURL(fileResponse);
    }
  },
  async getSingoloRendiconto(idStrutture, idRendiconto) {
    const url = `api/istituto/${idStrutture}/rendiconti/${idRendiconto}`;
    return (await Repository.get(url)).data;
  },
  async modificaRendiconto(
    idStrutture,
    idRendiconto,
    currentStep,
    formRendiconto
  ) {
    const url = `api/istituto/${idStrutture}/rendiconti/${idRendiconto}?step=${currentStep}`;
    const headers = { "Content-type": "multipart/form-data" };
    return (await Repository.post(url, formRendiconto, { headers })).data;
  },
  async deleteRendiconto(idStrutture, idRendiconto) {
    const url = `api/istituto/${idStrutture}/rendiconti/${idRendiconto}`;
    return (await Repository.delete(url)).data;
  },

  async getTipologieCheckListRendiconto() {
    const url = `api/generici/checklist-rendiconti`;
    return (await Repository.get(url)).data;
  },
  async getCorsiValidati(
    idStrutture,
    anno,
    capofila,
    dataFineQuadrimestre,
    dataInizioQuadrimestre,
    idRendiconto
  ) {
    const url =
      idRendiconto !== null
        ? `api/istituto/${idStrutture}/rendiconti/anno/${anno}/${capofila}/corsi-validati?dataTermine=${dataFineQuadrimestre}&dataInizio=${dataInizioQuadrimestre}&rendiconto=${idRendiconto}`
        : `api/istituto/${idStrutture}/rendiconti/anno/${anno}/${capofila}/corsi-validati?dataTermine=${dataFineQuadrimestre}&dataInizio=${dataInizioQuadrimestre}`;
    return (await Repository.get(url)).data;
  },
  async downloadTemplatePdfDcoumentazioneRendiconto(
    idStrutture,
    idRendiconto,
    formBody
  ) {
    const url = `api/istituto/${idStrutture}/rendiconti/${idRendiconto}/documentazione-pdf`;
    const headers = { "Content-type": "multipart/form-data" };
    const res = await Repository.post(url, formBody, {
      responseType: "blob",
      headers,
    });

    if (res.status === 200) {
      const fileResponse = new Blob([res.data], {
        type: res.data.type,
      });
      const objectUrl = URL.createObjectURL(fileResponse);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      const fileName = "documentazione_rendiconto_" + idRendiconto + ".pdf";
      fileLink.download = fileName.trim();

      fileLink.click();
      URL.revokeObjectURL(fileResponse);
    }
  },
  async eliminaFileCaricato(idStrutture, idRendiconto) {
    const url = `api/istituto/${idStrutture}/rendiconti/${idRendiconto}/documentazione-pdf`;
    return (await Repository.delete(url)).data;
  },
  async getDatiSedeAfam(idStrutture) {
    const url = `api/generici/${idStrutture}/dati-afam`;
    return (await Repository.get(url)).data;
  },
  async getDatiStruttura(idStrutture) {
    const url = `api/generici/${idStrutture}/dati-struttura`;
    return (await Repository.get(url)).data;
  },
  async getNumeroStudentiConCertificato(
    idStrutture,
    anno,
    capofila,
    idRendiconto
  ) {
    // se rendiconto è settato, il conteggio non includerà il rendiconto specificato
    // viceversa verranno ottenuti gli studenti con certificato associati a tutti i corsi rendicontati
    const url =
      idRendiconto != null
        ? `api/istituto/${idStrutture}/rendiconti/anno/${anno}/${capofila}/studenti-con-certificato/conteggio?rendiconto=${idRendiconto}`
        : `api/istituto/${idStrutture}/rendiconti/anno/${anno}/${capofila}/studenti-con-certificato/conteggio`;
    return (await Repository.get(url)).data;
  },
  async getRichiesteIntegrazioni(idStrutture, anno) {
    const url = `api/istituto/${idStrutture}/rendiconti/${anno}/richieste-integrazioni`;
    return (await Repository.get(url)).data;
  },
  async updateRichiestaIntegrazioni(idStrutture, anno, idRichiesta, data) {
    const url = `api/istituto/${idStrutture}/rendiconti/${anno}/update-richiesta-integrazioni/${idRichiesta}`;
    const headers = { "Content-type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  async getFileRispostaRichiesta(
    idStrutture,
    richiesta,
    nomeFileOriginale,
    tipoAllegato
  ) {
    const url = `api/generici/${idStrutture}/rendiconti/${richiesta}/file-allegato?tipoAllegato=${tipoAllegato}`;
    const res = await Repository.get(url, {
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = nomeFileOriginale;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async getFileEistoVerifica(idStrutture, verifica, nomeFileOriginale) {
    const url = `api/generici/${idStrutture}/rendiconti/${verifica}/file-esito-verifica`;
    const res = await Repository.get(url, {
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = nomeFileOriginale;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async getLastQuadrimestreAttivo(anno) {
    const url = `api/generici/quadrimestre/anno/${anno}/ultimo-attivo`;
    return (await Repository.get(url)).data;
  },
};
