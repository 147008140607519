<template>
  <v-container>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" v-if="capofila" />
    <v-row class="mb-4">
      <v-col>
        <h1>Dettaglio verbali</h1>
        <h4 class="grey--text">
          Qui è possibile visualizzare i
          <span v-if="this.capofila === 'istituzione'"> verbali</span>
          <span v-else class="font-weight-bold">
            verbali in convenzione da <u>Capofila</u>
          </span>
          di DG e UDM e fornire eventuali controdeduzioni - Anno Scolastico
          {{ this.anno }}/{{ this.anno + 1 }}
        </h4>
      </v-col>
    </v-row>
    <v-row class="my-0">
      <v-col class="my-0 d-flex flex-row-reverse"
        ><h4>Vedi verbali di:</h4></v-col
      >
    </v-row>
    <v-row class="my-0">
      <v-col class="my-0">
        <v-card :loading="loadingDettaglio">
          <v-tabs right v-model="tab">
            <v-tab key="dg"> DG </v-tab>
            <v-tab key="udm"> UDM </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item key="dg">
              <v-card> </v-card>
            </v-tab-item>
            <v-tab-item key="udm">
              <v-card flat> </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="mt-4">
          <v-btn text class="blue--text" @click="$router.go(-1)">
            <v-icon>mdi-arrow-left-thin</v-icon>Torna a trasmissione rendiconto
            di progetto
          </v-btn>
        </div>
      </v-col>
      <v-col>
        <v-col class="d-flex flex-row-reverse">
          <v-btn class="primary"> Accetta </v-btn>
          <v-btn text class="blue--text"
            ><v-icon class="mx-2"> mdi-loupe </v-icon>
            Fornisci controdeduzioni
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import RendicontiRepository from "@/api/istituto/RendicontiRepository";

export default {
  name: "dettaglioVerbali",
  components: { BreadcrumbComponent },
  data: () => ({
    idUtente: 0,
    loadingDettaglio: false,
    tab: null,
    ricerca: "",
    headers: [
      {
        text: "ID corso",
        align: "start",
        value: "corso.id",
      },
      { text: "CUP", value: "cup" },
      { text: "Università/AFAM", value: "nomeIstituzione" },
      { text: "Istituto Scolastico", value: "nomeScuola" },
      {
        text: "Anno Scolastico",
        value: "anno",
      },
      { text: "Data inizio", value: "dataInizio" },
      { text: "Data fine", value: "dataFine" },
      { text: "N° studenti", value: "studenti" },
      { text: "Costo del corso", value: "" },
      { text: "Azioni", value: "actions", sortable: false },
    ],
  }),
  props: {
    capofila: {
      type: String,
      required: true,
    },
    idRendiconto: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["idStrutture", "anno", "user"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleConsulenza",
      "isRoleLegale",
    ]),
    loading: function () {
      return this.loadingDettaglio;
    },
    breadcrumbs: function () {
      if (this.capofila) {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania/${this.capofila}`,
            exact: true,
            link: true,
          },
          {
            text: "Creazione e trasmissione rendiconto di progetto",
            disabled: false,
            to: `/scrivania/${this.capofila}/rendiconti`,
            exact: true,
            link: true,
          },
          {
            text: "Dettaglio rendiconto di progetto",
            disabled: true,
            to: `/scrivania/${this.capofila}/rendiconti/dettaglioRendiconto`,
            exact: true,
            link: true,
          },
        ];
      }
      return [];
    },
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        this.getCorsiRendiconto();
      }
    },
    async getCorsiRendiconto() {
      this.loadingDettaglio = true;
      this.corsi = await RendicontiRepository.getListaCorsiRendiconto(
        this.idStrutture,
        this.idRendiconto
      );
      this.loadingDettaglio = false;
    },
  },
};
</script>

<style scoped></style>
