<template>
  <v-container fluid>
    <v-main class="pa-6" v-if="finestraCompilazione">
      <h2 class="py-6">
        Inserisci dichiarazione dei flussi finanziari e dati conto di tesoreria
        o bancario/postale
      </h2>
      <div class="py-6">
        <StepDichiarazioneFlussiFinanziari :numero-step="1" />
      </div>
      <v-progress-linear v-if="!loading()" indeterminate color="blue" />
      <v-row class="pt-6">
        <v-col>
          <h3 class="py-6">
            Dichiarazione di tracciabilità dei flussi finanziari di cui agli
            artt. 3ss. della legge n°136 del 13 Agosto 2010 e SS.MM.II
          </h3>
        </v-col>
      </v-row>
      <!--    SEZIONE CON I DATI DEL RAPPRESENTANTE LEGALE  -->
      <v-form ref="form">
        <div v-if="legaleRappresentante">
          <v-row>
            <v-col><p class="testo">La/Il sottoscritta/o</p></v-col>
          </v-row>
          <v-row>
            <v-col> Nome </v-col>
            <v-col> Cognome </v-col>
            <v-col> Codice fiscale </v-col>
            <v-col> Data di nascita</v-col>
            <v-col> Luogo di nascita </v-col>
          </v-row>
          <v-row>
            <v-col>
              <b> {{ legaleRappresentante.nomeRappresentante }}</b></v-col
            >
            <v-col>
              <b> {{ legaleRappresentante.cognomeRappresentante }}</b></v-col
            >
            <v-col
              ><b> {{ legaleRappresentante.codiceFiscaleRappresentante }}</b>
            </v-col>
            <v-col>
              <b>
                {{
                  legaleRappresentante.dataNascita
                    ? legaleRappresentante.dataNascita
                    : "Non Disponibile"
                }}
              </b>
            </v-col>
            <v-col>
              <b>
                {{
                  legaleRappresentante.comuneNascita
                    ? legaleRappresentante.comuneNascita
                    : "Non Disponibile"
                }}
              </b>
            </v-col>
          </v-row>
          <v-row class="py-4">
            <v-col><p class="testo">Legale rappresentante del</p></v-col>
          </v-row>

          <div>
            <!-- SEZIONE CON I DATI DELLA STRUTTURA  -->
            <v-row>
              <v-col> Soggetto attuatore</v-col>
              <v-col> Codice fiscale/p.iva </v-col>
              <v-col> Luogo sede legale</v-col>
              <v-col> Comune sede legale</v-col>
              <v-col> Provincia sede legale</v-col>
            </v-row>
            <v-row>
              <v-col>
                <b v-if="programma"> {{ programma.nomeIstituzione }}</b></v-col
              >
              <v-col>
                <b v-if="accordo"> {{ accordo.pIvaStruttura }}</b>
                <b
                  v-else-if="
                    datiStruttura &&
                    (datiStruttura.partitaIva || datiStruttura.cfEnte)
                  "
                >
                  {{ datiStruttura.partitaIva ?? datiStruttura.cfEnte }}
                </b>
                <b v-else> -- </b>
              </v-col>
              <v-col
                ><b v-if="accordo"> {{ accordo.indirizzoStruttura }}</b>
                <b v-else-if="datiStruttura && datiStruttura.via">
                  {{ datiStruttura.via }}
                </b>
                <b v-else> --</b>
              </v-col>
              <v-col
                ><b v-if="accordo"> {{ accordo.cittaStruttura }}</b>
                <b v-else-if="datiStruttura && datiStruttura.citta">
                  {{ datiStruttura.citta }}
                </b>
                <b v-else> --</b>
              </v-col>
              <v-col
                ><b v-if="accordo"> {{ accordo.provinciaStruttura }}</b>
                <b
                  v-else-if="datiStruttura && datiStruttura.provincia"
                  v-html="
                    typeof datiStruttura.provincia === 'object'
                      ? datiStruttura.provincia.prov
                      : datiStruttura.provincia
                  "
                />
                <b v-else> --</b>
              </v-col>
            </v-row>
            <!-- SEZIONE CON I DATI DELLA STRUTTURA  SECONDA RIGA-->
            <v-row>
              <v-col> Cap sede legale </v-col>
              <v-col> PEC </v-col>
              <v-col> </v-col>
              <v-col> </v-col>
              <v-col> </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span v-if="datiStruttura && datiStruttura.cap"
                  ><b> {{ datiStruttura.cap }}</b></span
                >
                <span v-else><b> -- </b></span></v-col
              >
              <v-col>
                <span v-if="datiStruttura && datiStruttura.pec"
                  ><b> {{ datiStruttura.pec }}</b></span
                >
                <span v-else><b> -- </b></span></v-col
              >
              <v-col> </v-col>
              <v-col> </v-col>
              <v-col> </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col>
              <p class="mt-6">
                consapevole della responsabilità penale cui può andare incontro
                in caso di dichiarazione falsa o comunque non corrispondente al
                vero (art. 76 del D.P.R. n. 445 del 28/12/2000), ai sensi del
                D.P.R. n. 445 del 28/12/2000 e ss.mm.ii.
              </p>
            </v-col>
          </v-row>
          <v-row class="mb-4 mt-2">
            <v-col cols="12">
              <h4 class="text-center">DICHIARA</h4>
            </v-col>
          </v-row>
          <v-row class="mb-4 mt-2">
            <v-col cols="12">
              <ul>
                <li>
                  di assumersi tutti gli obblighi relativi alla tracciabilità
                  dei flussi finanziari di cui agli artt. 3 ss. della legge 13
                  agosto 2010 n.136 e ss.mm.ii.;
                </li>
                <li v-if="isStatale">
                  che il conto di tesoreria dedicato ai movimenti finanziari,
                  destinati all'investimento 1.6 è il seguente:
                </li>
                <li v-else>
                  che il conto bancario/postale dedicato ai movimenti
                  finanziari, destinati all'investimento 1.6 è il seguente:
                </li>
              </ul>
            </v-col>
          </v-row>
          <div>
            <v-row>
              <v-col cols="3" class="w-25">
                <v-text-field
                  :label="
                    isStatale
                      ? 'N° conto di tesoreria *'
                      : 'N° conto corrente bancario/postale *'
                  "
                  class="text-center"
                  v-model="formContoBancario.contoTesoreria"
                  :readonly="isStatale"
                  :rules="validazioneCampoNecessario()"
                  @change="validateField"
                  outlined
                />
              </v-col>
              <v-col cols="3" class="w-25">
                <v-text-field
                  label="Associato al codice IBAN *"
                  class="text-center"
                  v-model="formContoBancario.codiceIban"
                  :counter="27"
                  :rules="validazioneIBAN()"
                  @change="validateField"
                  @keyup="uppercaseIBAN()"
                  :readonly="!isStatale"
                  outlined
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Acceso presso *"
                  class="text-center"
                  v-model="formContoBancario.accesoPresso"
                  :rules="validazioneCampoNecessario()"
                  @change="validateField"
                  :readonly="isStatale"
                  outlined
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Intestato a *"
                  class="text-center"
                  v-model="formContoBancario.intestatario"
                  :rules="validazioneCampoNecessario()"
                  @change="validateField"
                  :readonly="isStatale"
                  outlined
                />
              </v-col>
            </v-row>
            <v-row class="mb-4 mt-2">
              <v-col cols="12">
                <ul>
                  <li>
                    che le persone delegate ad operare sul suddetto conto
                    corrente sono:
                  </li>
                </ul>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="w-25">
                <v-text-field
                  label="Nome *"
                  class="text-center"
                  v-model="formDelegato.nome"
                  outlined
                  :rules="validazioneCampoNecessario()"
                  @change="validateField"
                />
              </v-col>
              <v-col cols="3" class="w-25">
                <v-text-field
                  label="Cognome *"
                  class="text-center"
                  v-model="formDelegato.cognome"
                  outlined
                  :rules="validazioneCampoNecessario()"
                  @change="validateField"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Codice fiscale *"
                  v-model="formDelegato.cf"
                  class="text-center"
                  :counter="16"
                  :rules="validazioneCF()"
                  @change="validateField"
                  @keyup="uppercase()"
                  @blur="datiFromCF(formDelegato.cf)"
                  required
                  outlined
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Luogo di nascita * (autocompilato da cf)"
                  class="text-center"
                  v-model="formDelegato.luogoNascita"
                  readonly
                  :rules="validazioneCampoNecessario()"
                  @change="validateField"
                  outlined
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="w-25">
                <v-text-field
                  label="Data di nascita * (autocompilato da cf)"
                  type="date"
                  v-model="formDelegato.dataNascita"
                  class="text-center"
                  outlined
                  readonly
                  :rules="validazioneCampoNecessario()"
                  @change="validateField"
                />
              </v-col>
              <v-col cols="3" class="w-25">
                <v-text-field
                  label="Nella qualità di *"
                  v-model="formDelegato.qualifica"
                  class="text-center"
                  :rules="validazioneCampoNecessario()"
                  @change="validateField"
                  outlined
                />
              </v-col>
              <v-col cols="3"> </v-col>
              <v-col cols="3"> </v-col>
            </v-row>
          </div>
          <!-- array di delegati aggiuntivi -->
          <div v-if="arrayDelegati.length > 0">
            <div v-for="(delegato, index) in arrayDelegati" :key="index">
              <hr />
              <v-row class="mt-4">
                <v-col cols="3" class="w-25">
                  <h3>Delegato n° {{ index + 2 }}</h3>
                </v-col>
                <v-col cols="3" class="w-25">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        large
                        color="error"
                        class="mr-2"
                        @click="rimuoviDelegato(delegato)"
                      >
                        mdi-trash-can-outline
                      </v-icon>
                    </template>
                    <span>Rimuovi delegato </span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="w-25">
                  <v-text-field
                    label="Nome *"
                    class="text-center"
                    v-model="delegato.nome"
                    outlined
                    readonly
                  />
                </v-col>
                <v-col cols="3" class="w-25">
                  <v-text-field
                    label="Cognome *"
                    class="text-center"
                    v-model="delegato.cognome"
                    outlined
                    readonly
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Nella qualità di *"
                    v-model="delegato.qualifica"
                    class="text-center"
                    readonly
                    outlined
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Luogo di nascita *"
                    class="text-center"
                    v-model="delegato.luogoNascita"
                    readonly
                    outlined
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="w-25">
                  <v-text-field
                    label="Data di nascita *"
                    v-model="delegato.dataNascita"
                    class="text-center"
                    outlined
                    readonly
                  />
                </v-col>
                <v-col cols="3" class="w-25">
                  <v-text-field
                    label="Codice fiscale *"
                    v-model="delegato.cf"
                    class="text-center"
                    readonly
                    outlined
                  />
                </v-col>
                <v-col cols="3"> </v-col>
                <v-col cols="3"> </v-col>
              </v-row>
            </div>
          </div>

          <v-row>
            <v-col cols="12" class="pl-6">
              <DialogAggiungiDelegatoComponent
                :primoDelegato="validate"
                @delegato-aggiunto="aggiungiDelegato"
              />
            </v-col>
          </v-row>
          <!-- CHECKBOX -->
          <v-row class="py-4">
            <v-col cols="12" class="">
              <v-checkbox
                :disabled="arrayContiBancari.length > 0"
                :label="testoCheckbox()"
                v-model="valueCheckbox"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row v-if="!valueCheckbox" class="pb-4">
            <v-col>
              <ul>
                <li>
                  che il conto corrente bancario/postale dedicato ai movimenti
                  finanziari per l'investimento 1.6 è il seguente:
                </li>
              </ul>
            </v-col>
          </v-row>
          <!-- ULTERIORI CONTI BANCARI E DELEGATI ASSOCIATI -->
          <div v-if="arrayContiBancari.length > 0" class="mb-4">
            <div v-for="(conto, index) in arrayContiBancari" :key="index">
              <hr />
              <v-row class="mt-4">
                <v-col cols="3" class="w-25">
                  <h3>Conto Bancario n° {{ index + 2 }}</h3>
                </v-col>
                <v-col cols="3" class="w-25">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        large
                        color="error"
                        class="mr-2"
                        @click="rimuoviConto(conto)"
                      >
                        mdi-trash-can-outline
                      </v-icon>
                    </template>
                    <span>Rimuovi conto bancario e delegato associato </span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="w-25">
                  <v-text-field
                    :label="
                      isStatale
                        ? 'N° conto di tesoreria *'
                        : 'N° conto corrente bancario/postale *'
                    "
                    class="text-center"
                    v-model="conto.contoTesoreria"
                    readonly
                    outlined
                  />
                </v-col>
                <v-col cols="3" class="w-25">
                  <v-text-field
                    label="Associato al codice IBAN *"
                    class="text-center"
                    v-model="conto.codiceIban"
                    readonly
                    outlined
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Acceso presso *"
                    class="text-center"
                    v-model="conto.accesoPresso"
                    readonly
                    outlined
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Intestato a *"
                    class="text-center"
                    v-model="conto.intestatario"
                    readonly
                    outlined
                  />
                </v-col>
              </v-row>
              <v-row class="mt-4">
                <v-col cols="3" class="w-25">
                  <h3>Delegati associati al conto:</h3>
                </v-col>
              </v-row>
              <div
                v-for="(delegato, index) in conto.formDelegatoAggintivo"
                :key="index"
              >
                <v-row>
                  <v-col cols="3" class="w-25">
                    <v-text-field
                      label="Nome *"
                      class="text-center"
                      v-model="delegato.nome"
                      outlined
                      readonly
                    />
                  </v-col>
                  <v-col cols="3" class="w-25">
                    <v-text-field
                      label="Cognome *"
                      class="text-center"
                      v-model="delegato.cognome"
                      outlined
                      readonly
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      label="Nella qualità di *"
                      v-model="delegato.qualifica"
                      class="text-center"
                      readonly
                      outlined
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      label="Luogo di nascita *"
                      class="text-center"
                      v-model="delegato.luogoNascita"
                      readonly
                      outlined
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" class="w-25">
                    <v-text-field
                      label="Data di nascita *"
                      v-model="delegato.dataNascita"
                      class="text-center"
                      outlined
                      readonly
                    />
                  </v-col>
                  <v-col cols="3" class="w-25">
                    <v-text-field
                      label="Codice fiscale *"
                      v-model="delegato.cf"
                      class="text-center"
                      readonly
                      outlined
                    />
                  </v-col>
                  <v-col cols="3"> </v-col>
                  <v-col cols="3"> </v-col>
                </v-row>
              </div>
              <!-- Aggiungi delegati a conto aggiuntivo -->
              <v-row class="pb-8">
                <v-col cols="12" class="pl-6">
                  <DialogAggiungiDelegatoComponent
                    :primoDelegato="arePropertiesSetDelegato()"
                    :contoAggiuntivo="index"
                    @delegato-aggiunto="aggiungiDelegato"
                    @ulteriore-delegato="aggiungiDelegatoUlterioreConto"
                  />
                </v-col>
              </v-row>
              <v-row class="pb-8">
                <v-col cols="12">
                  <hr />
                </v-col>
              </v-row>
            </div>
          </div>
          <v-row v-if="!valueCheckbox">
            <v-row>
              <v-col cols="12" class="pl-8">
                <DialogAggiungiContoBancarioComponent
                  :isStatale="isStatale"
                  :primoDelegato="arePropertiesSetConto()"
                  @conto-aggiunto="aggiungiConto"
                />
              </v-col>
            </v-row>
          </v-row>

          <v-row class="mb-4 mt-2">
            <v-col cols="12">
              <h4 class="text-center">DICHIARA INOLTRE</h4>
            </v-col>
          </v-row>
          <v-row class="mb-4 mt-2">
            <v-col cols="12">
              <ul>
                <li>
                  di impegnarsi a comunicare ogni modifica relativa ai dati
                  trasmessi
                </li>
              </ul>
            </v-col>
          </v-row>
          <v-row class="mb-4 mt-2">
            <v-col cols="12">
              <p class="font-italic">
                Il/la sottoscritto/a dichiara altresì di essere informato/a, ai
                sensi del regolamento (UE) 2016/679 (regolamento europeo in
                materia di protezione dei dati personali, in sigla GDPR), che i
                dati personali raccolti saranno trattati secondo l'informativa
                sul trattamento dei dati personali peresente sul sistema
                Orientamento 2026.
              </p>
            </v-col>
          </v-row>
        </div>
      </v-form>
      <v-row>
        <v-col>
          <v-btn
            text
            class="blue--text"
            @click="
              $router.push(
                `/scrivania/${capofila}/tracciabilita-flussi-finanziari`
              )
            "
          >
            <v-icon>mdi-arrow-left-thin</v-icon>Torna indietro
          </v-btn>
        </v-col>
        <v-col class="d-flex flex-row-reverse">
          <v-btn
            v-if="isRoleLegale || isRoleConsulenza"
            class="primary"
            text
            @click="dialogSalva = true"
          >
            Salva e Firma
          </v-btn>
        </v-col>
      </v-row>
      <dialog-conferma
        @callback="SalvaDichiarazione"
        text-conferma="I dati verranno salvati e non sarà più possibile modificare la dichiarazione."
        :dialog.sync="dialogSalva"
        @close-dialog="dialogSalva = false"
      />

      <v-snackbar
        v-model="snackbar"
        :timeout="4000"
        shaped
        :color="snackbarColor"
      >
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
    <v-main v-else> </v-main>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import StepDichiarazioneFlussiFinanziari from "@/components/TracciabilitaFlussiFinanziari/StepDichiarazioneFlussiFinanziari.vue";
import RichiesteRepository from "@/api/istituto/RichiesteRepository";
import CodiceFiscale from "codice-fiscale-js";
import ProgrammiRepository from "@/api/istituto/ProgrammiRepository";
import ScuoleAccordoRepository from "@/api/istituto/ScuoleAccordoRepository";
import RendicontiRepository from "@/api/istituto/RendicontiRepository";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";
import DialogAggiungiDelegatoComponent from "@/components/TracciabilitaFlussiFinanziari/DialogAggiungiDelegatoComponent.vue";
import DialogConferma from "@/components/DialogConferma.vue";
import DialogAggiungiContoBancarioComponent from "@/components/TracciabilitaFlussiFinanziari/DialogAggiungiContoBancarioComponent.vue";
import DichiarazioneFlussiRepository from "@/api/istituto/DichiarazioneFlussiRepository";
import CorsiRepository from "@/api/istituto/CorsiRepository";
export default {
  name: "InserimentoRendiconto",
  props: {
    capofila: {
      type: String,
      required: true,
    },
  },
  components: {
    DialogConferma,
    StepDichiarazioneFlussiFinanziari,
    DialogAggiungiDelegatoComponent,
    DialogAggiungiContoBancarioComponent,
  },
  data: () => ({
    finestraCompilazione: true,
    loadingPage: true,
    isStatale: false,
    programma: null,
    accordo: null,
    validate: false,
    datiStruttura: null,
    valueCheckbox: 0,
    legaleRappresentante: null,
    dialogConferma: false,
    arrayContiBancari: [],
    arrayDelegati: [],
    formContoBancario: {
      codiceIban: "",
      contoTesoreria: "",
      accesoPresso: "",
      intestatario: "",
    },
    formDelegato: {
      nome: "",
      cognome: "",
      qualifica: "",
      luogoNascita: "",
      dataNascita: "",
      cf: "",
    },
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    dialogSalva: null,
  }),
  computed: {
    ...mapState(["idStrutture", "anno", "user"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleConsulenza",
      "isRoleLegale",
    ]),
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
    capofila() {
      this.initialize();
    },
    formDelegato() {
      this.validateField();
    },
    formContoBancario() {
      this.validateField();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loadingPage = true;
      if (this.capofila && this.idStrutture && this.anno) {
        this.getProgrammaOrientamento();
        this.getAccordi();
        this.getDatiStruttura();
        this.getIsStatale();
        this.getDatiBancariPreCompilati();
        this.loadingPage = false;
      }
    },
    async getProgrammaOrientamento() {
      let programma = await ProgrammiRepository.getProgrammi(
        this.idStrutture,
        this.anno,
        this.capofila
      );
      this.programma = programma ? programma[0] : null;
      this.getLegaleRappresentanteAttivo();
      this.formContoBancario.intestatario = this.programma.nomeIstituzione;
    },
    async getLegaleRappresentanteAttivo() {
      const idProgrammaOrientamento = parseInt(this.programma.id);
      const legaleRappresentante =
        await RichiesteRepository.getLegaleRappresentante(
          this.idStrutture,
          idProgrammaOrientamento
        );
      if (legaleRappresentante) {
        this.getDatiLegaleRappresentante(legaleRappresentante);
      }
    },
    getDatiLegaleRappresentante(legaleRappresentante) {
      let datiMancantiLegale = null;
      try {
        datiMancantiLegale = new CodiceFiscale(
          legaleRappresentante.codiceFiscaleRappresentante
        );
        // eslint-disable-next-line no-empty
      } catch (err) {}

      if (datiMancantiLegale) {
        let comuneNascita = datiMancantiLegale.birthplace.nome;
        let dataNascita = datiMancantiLegale.birthday;
        this.legaleRappresentante = legaleRappresentante;
        this.legaleRappresentante.comuneNascita = comuneNascita;
        this.legaleRappresentante.dataNascita = new Date(
          dataNascita
        ).toLocaleDateString();
      } else {
        this.legaleRappresentante = legaleRappresentante;
      }
    },
    async getAccordi() {
      let accordi = await ScuoleAccordoRepository.getScuoleAccordi(
        this.idStrutture,
        this.anno,
        this.capofila
      );
      this.accordo = accordi[0];
    },
    async getDatiStruttura() {
      let datiSruttura = await RendicontiRepository.getDatiStruttura(
        this.idStrutture
      );
      if (Array.isArray(datiSruttura)) {
        if (datiSruttura.length) {
          this.datiStruttura = datiSruttura[0];
        }
      } else {
        this.datiStruttura = datiSruttura;
      }
    },
    async getIsStatale() {
      let tipoStruttura = await IstitutoRepository.getIsStatale(
        this.idStrutture
      );
      //AFAM -> stesso comportamento di NON statali!
      this.isStatale = tipoStruttura["isStatale"] && !tipoStruttura["isAfam"];
      this.formContoBancario.accesoPresso =
        this.isStatale === true ? "Banca d'Italia" : "";
    },
    loading() {
      return this.legaleRappresentante && this.accordo && this.datiStruttura;
    },
    async getDatiBancariPreCompilati() {
      this.codiciContiStruttura =
        await RichiesteRepository.getContiBancariStruttura(
          this.idStrutture,
          this.anno
        );
      if (this.codiciContiStruttura) {
        this.assignValueIbanContoTesoreria(this.codiciContiStruttura);
      } else {
        this.compilazioneRendicontoBloccata = true;
      }
    },
    assignValueIbanContoTesoreria(objectValue) {
      this.formContoBancario.codiceIban = objectValue.ibanBancario ?? null;

      this.formContoBancario.contoTesoreria =
        objectValue.contoTesoreria ?? null;
    },
    validazioneCF() {
      return [
        (v) =>
          (!!v && v.length === 16) ||
          "Il campo è obbligatorio e il formato del codice fiscale deve essere valido",
      ];
    },
    validazioneIBAN() {
      return [
        (v) =>
          (!!v && v.length === 27) ||
          "Il campo è obbligatorio e il formato del codice IBAN deve essere valido",
      ];
    },
    validazioneCampoNecessario() {
      return [(v) => !!v || "Campo richiesto"];
    },
    uppercase() {
      this.formDelegato.cf = this.formDelegato.cf.toUpperCase();
    },
    arePropertiesSetDelegato() {
      return Object.values(this.formDelegato).every(
        (value) => value !== undefined && value !== null && value !== ""
      );
    },
    arePropertiesSetConto() {
      return Object.values(this.formContoBancario).every(
        (value) => value !== undefined && value !== null && value !== ""
      );
    },
    validateField() {
      this.validate = this.$refs.form.validate();
    },
    uppercaseIBAN() {
      this.formContoBancario.codiceIban =
        this.formContoBancario.codiceIban.toUpperCase();
    },
    async datiFromCF(cf) {
      let anagrafica = await CorsiRepository.getAnagraficaStudente(
        this.idStrutture,
        cf
      );
      let data = anagrafica.subject.birthDate.date;
      let dataNascitaFormattata = data.split(" ");
      this.formDelegato.dataNascita = dataNascitaFormattata[0];
      if (anagrafica.isEstero) {
        this.formDelegato.luogoNascita = anagrafica.comune.name_it;
      } else {
        this.formDelegato.luogoNascita = anagrafica.comune.name_it;
      }
    },
    testoCheckbox() {
      return this.isStatale
        ? `Dichiaro che il conto di tesoreria n. ${this.formContoBancario.contoTesoreria},
      associato al codice IBAN ${this.formContoBancario.codiceIban} acceso presso ${this.formContoBancario.accesoPresso} 
      e intestato a ${this.formContoBancario.intestatario} è il medesimo per tutti i movimenti finanziari per l'investimento 1.6`
        : `Dichiaro che il conto corrente bancario/postale n. ${this.formContoBancario.contoTesoreria},
      associato al codice IBAN ${this.formContoBancario.codiceIban} acceso presso ${this.formContoBancario.accesoPresso} 
      e intestato a ${this.formContoBancario.intestatario} è il medesimo per tutti i movimenti finanziari per l'investimento 1.6`;
    },
    aggiungiDelegato(delegato) {
      this.arrayDelegati.push({ ...delegato });
    },
    rimuoviDelegato(delegato) {
      this.arrayDelegati = this.arrayDelegati.filter(
        (item) => item !== delegato
      );
    },
    aggiungiConto(conto) {
      this.arrayContiBancari.push({ ...conto });
    },
    rimuoviConto(conto) {
      this.arrayContiBancari = this.arrayContiBancari.filter(
        (item) => item !== conto
      );
    },
    validazioneCheckbox() {
      //se il checkbox è settato non ci possono essere ulteriori conti inseriti
      if (this.valueCheckbox && this.arrayContiBancari.length === 0) {
        return true;
      } else if (!this.valueCheckbox && this.arrayContiBancari.length > 0) {
        return true;
      }
    },
    async SalvaDichiarazione() {
      this.validateField();
      if (!this.validazioneCheckbox()) {
        this.snackbarText =
          "ATTENZIONE: è necessario aggiungere un conto o dichiarare il conto inserito come medesimo per tutti gli investimenti";
        this.snackbarColor = "red accent-2";
        this.snackbar = true;
      }
      if (this.validate && this.validazioneCheckbox()) {
        let arrayDelegati = [...this.arrayDelegati];
        arrayDelegati.unshift({ ...this.formDelegato });
        this.formContoBancario.formDelegatoAggintivo = [...arrayDelegati];
        let arrayConti = [...this.arrayContiBancari];
        arrayConti.unshift({ ...this.formContoBancario });

        let dati = {
          valueCheckbox: this.valueCheckbox,
          contiBancari: arrayConti,
        };

        const formData = new FormData();
        formData.append("dichiarazione", JSON.stringify(dati));

        try {
          let idDichiarazione =
            await DichiarazioneFlussiRepository.createDichiarazione(
              this.idStrutture,
              this.anno,
              this.programma.id,
              formData
            );
          this.snackbarText =
            "Dichiarazione salvata con successo. Attendere il secondo step...";
          this.snackbarColor = "success";
          this.snackbar = true;
          setTimeout(() => this.goToNextStep(idDichiarazione), 4000);
        } catch (e) {
          console.log(e);
          this.snackbarText =
            "Problema durante il salvataggio, controllare tutti i dati inseriti";
          this.snackbarColor = "red accent-2";
          this.snackbar = true;
        }
      }
    },
    goToNextStep(idDichiarazione) {
      this.$router.push({
        name: "firmaDocumentazioneDichiarazioneView",
        params: { idDichiarazione: idDichiarazione, capofila: this.capofila },
      });
    },
    aggiungiDelegatoUlterioreConto(delegato, idConto) {
      this.arrayContiBancari[idConto].formDelegatoAggintivo.push({
        ...delegato,
      });
    },
  },
};
</script>
