<template>
  <v-container v-if="showCard">
    <v-card color="transparent" outlined>
      <v-card-title>
        <v-icon class="mr-2">mdi-account-group</v-icon>
        Alunni con attestato di frequenza
      </v-card-title>
      <v-simple-table>
        <template v-slot:default>
          <v-card-text class="text-center">
            <v-row>
              <v-col style="border-right: 2px solid darkgrey">
                <v-card-text class="text-left">
                  <h2>
                    <b>
                      {{ riepilogoStudenti.totaleStudentiConCertificato }}
                    </b>
                  </h2>
                  <br />
                  Alunni con attestato <br />
                </v-card-text>
                <v-card-text class="text-left">
                  <h2>
                    <b>{{ riepilogoStudenti.totaleStudentiCensiti }}</b>
                  </h2>
                  <br />
                  Alunni censiti
                </v-card-text>
              </v-col>
              <v-col>
                <v-card-text>
                  <h2>
                    <b>
                      {{
                        targetIncrementato
                          ? programmaOrientamento.numeroAlunniCoinvolti +
                            targetIncrementato.alunniTarget
                          : programmaOrientamento.numeroAlunniCoinvolti
                      }}
                    </b>
                  </h2>
                  <br />
                  Target
                </v-card-text>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
//import CorsiRepository from "@/api/istituto/CorsiRepository";
import corsiRepository from "@/api/istituto/CorsiRepository";
import ProgrammiRepository from "@/api/istituto/ProgrammiRepository";

export default {
  name: "AlunniProgrammiOrientamento",
  components: {},
  props: {
    capofila: {
      type: String,
      required: true,
    },
    programmaOrientamento: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    riepilogoStudenti: null,
    targetIncrementato: null,
    showCard: false,
  }),
  computed: {
    ...mapState(["idStrutture", "anno", "scrivaniaViewMode"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleIstituto",
      "isRoleLegaleCorso",
    ]),
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.showCard = false;
      if (this.idStrutture != null && this.anno != null) {
        this.getRiepilogoStudenti();
        this.getIncrementoTarget();
      }
    },
    async getRiepilogoStudenti() {
      this.riepilogoStudenti =
        await corsiRepository.getRiepilogoStudentiCensiti(
          this.idStrutture,
          this.anno,
          this.capofila
        );
      this.showCard = true;
    },
    async getIncrementoTarget() {
      this.targetIncrementato = await ProgrammiRepository.incrementoTarget(
        this.idStrutture,
        this.anno
      );
    },
  },
};
</script>

<style scoped></style>
