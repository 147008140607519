<template>
  <v-container fluid>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" v-if="capofila" />
    <v-row class="mb-4">
      <v-col>
        <h1>Dichiarazione di tracciabilità dei flussi finanziari</h1>
      </v-col>
    </v-row>
    <v-row v-if="loadingData" class="mb-4">
      <v-col>
        <v-progress-linear v-if="loadingData" indeterminate color="blue" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-alert border="top" colored-border type="info" elevation="2">
          Inserire la dichiarazione di tracciabilità dei flussi finanziari e
          indicare i conti dedicati ai movimenti finanziari destinati
          all'investimento 1.6 con indicazione delle persone delegate ad operare
          sul conto
        </v-alert>
      </v-col>
    </v-row>
    <div v-if="dichiarazioni.length">
      <v-expansion-panels focusable hover v-if="dichiarazioni.length">
        <v-expansion-panel
          v-for="(dichiarazione, index) in dichiarazioni"
          :key="index"
        >
          <v-expansion-panel-header
            :color="getColorHeader(dichiarazione)"
            class="white--text"
          >
            <v-row>
              <v-col cols="3">
                <p class="">
                  ID dichiarazione
                  <br />
                  <br />
                  <b>
                    {{ dichiarazione.id }}
                  </b>
                </p>
              </v-col>
              <v-col cols="3">
                <p class="">
                  CUP
                  <br />
                  <br />
                  <b>
                    {{ dichiarazione.programma.cup }}
                  </b>
                </p>
              </v-col>
              <v-col cols="3">
                <p class="">
                  Istituzione
                  <br />
                  <br />
                  <b>
                    {{ dichiarazione.programma.nomeIstituzione }}
                  </b>
                </p>
              </v-col>
              <v-col cols="3">
                <p class="">
                  Data dichiarazione
                  <br />
                  <br />
                  <b>
                    <span v-if="dichiarazione.dataTrasmissione">
                      {{ dichiarazione.dataTrasmissione | formatDate }}</span
                    >
                    <span v-else> --</span>
                  </b>
                </p>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-if="dichiarazione.trasmesso">
              <v-row class="my-2">
                <v-col cols="2">
                  <v-btn
                    text
                    class="blue--text"
                    @click="
                      getFileRiepilogoDichiarazioneConti(0, dichiarazione)
                    "
                    ><v-icon class="mr-2"> mdi-microsoft-excel </v-icon>
                    Esporta Excel
                  </v-btn>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    text
                    class="blue--text"
                    @click="
                      getFileRiepilogoDichiarazioneConti(1, dichiarazione)
                    "
                    ><v-icon class="mr-2"> mdi-file-pdf-box </v-icon>
                    Esporta PDF
                  </v-btn>
                </v-col>
                <v-col cols="4"></v-col>
              </v-row>
              <v-data-table
                :headers="headers"
                :items="dichiarazione.conti"
                :items-per-page="10"
                class="elevation-1"
                loading-text="Caricamento dati in corso..."
                ><!-- eslint-disable -->
                <template v-slot:item.statoConto="{ item }">
                  <v-chip outlined pill :color="colorState(item.stato)">
                    {{ item.stato.descrizione }}
                  </v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                  <router-link
                      class="v-btn"
                      :to="{
                          name: 'dettaglioContoView',
                          params: {
                            capofila: capofila,
                            conto: item,
                          },
                        }"
                  >
                    Dettagli
                  </router-link>
                </template>
                <!-- eslint-enable -->
              </v-data-table>
              <v-row class="my-2">
                <v-col cols="4">
                  <h3>Allegati</h3>
                </v-col>
              </v-row>
              <v-row class="my-2">
                <v-col cols="4">
                  <p>
                    Dichiarazione di tracciabilità dei flussi finanziari
                    <b>{{
                      dichiarazione.fileDocumentazioneFirmata.nomeOriginale
                    }}</b>
                  </p>
                  <div>
                    <a @click="getFileDichiarazione(dichiarazione)">
                      <v-icon>mdi-file-download-outline</v-icon> Scarica il file
                    </a>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div
              v-else-if="
                dichiarazione.fileDocumentazioneFirmata &&
                dichiarazione.dataFirma
              "
              class="mt-6"
            >
              <v-row>
                <v-col>
                  <v-alert prominent type="error">
                    <v-row align="center">
                      <v-col class="grow"> Dichiarazione NON trasmessa! </v-col>
                      <v-col class="shrink">
                        <v-btn
                          v-if="isRoleLegale || isRoleConsulenza"
                          class="primary"
                          text
                          @click="goToFirmaStep(dichiarazione.id)"
                        >
                          Procedi con la trasmissione
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
              </v-row>
            </div>
            <div v-else class="mt-6">
              <v-row>
                <v-col>
                  <v-alert prominent type="error">
                    <v-row align="center">
                      <v-col class="grow"> Dichiarazione NON firmata! </v-col>
                      <v-col class="shrink">
                        <v-btn
                          v-if="isRoleLegale || isRoleConsulenza"
                          @click="deleteDichiarazione(dichiarazione.id)"
                        >
                          <v-icon class="mr-2"> mdi-delete</v-icon>Elimina
                          dichiarazione
                        </v-btn>
                      </v-col>
                      <v-col class="shrink">
                        <v-btn
                          v-if="isRoleLegale || isRoleConsulenza"
                          class="primary"
                          text
                          @click="goToFirmaStep(dichiarazione.id)"
                        >
                          <v-icon class="mr-2"> mdi-draw-pen</v-icon>Procedi con
                          la firma
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
              </v-row>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <v-row class="mt-6">
      <v-col>
        <v-btn
          text
          class="blue--text"
          @click="$router.push(`/scrivania/${capofila}`)"
        >
          <v-icon>mdi-arrow-left-thin</v-icon>Torna alla scrivania
        </v-btn>
      </v-col>
      <v-col class="d-flex flex-row-reverse">
        <router-link
          v-if="isRoleLegale || isRoleConsulenza"
          class="v-btn primary pa-2"
          :to="`/scrivania/${capofila}/tracciabilita-flussi-finanziari/inserisci-dichiarazione`"
        >
          Inserisci dichiarazione e conti
        </router-link>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      shaped
      :color="snackbarColor"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import DichiarazioneFlussiRepository from "@/api/istituto/DichiarazioneFlussiRepository";
import ProgrammiRepository from "@/api/istituto/ProgrammiRepository";
import istitutoRepository from "@/api/istituto/IstitutoRepository";

export default {
  name: "DichiarazioniTracciabilitaView",
  components: {
    BreadcrumbComponent,
  },
  data: () => ({
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    finestraCompilazione: null,
    dichiarazioni: [],
    loadingData: true,
    headers: [
      {
        text: "ID Conto Bancario",
        align: "start",
        value: "id",
      },
      { text: "IBAN", value: "ibanAssociato", sortable: false },
      { text: "Acceso presso", value: "accesoPresso", sortable: false },
      { text: "intestato a", value: "intestatario", sortable: false },
      { text: "Stato", value: "statoConto", sortable: false },
      { text: "Azioni", value: "actions", sortable: false },
    ],
  }),
  props: {
    capofila: {
      type: String,
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["idStrutture", "anno", "user"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleConsulenza",
      "isRoleLegale",
    ]),
    breadcrumbs: function () {
      if (this.capofila) {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania/${this.capofila}`,
            exact: true,
            link: true,
          },
          {
            text: "Dichiarazione di tracciabilità dei flussi finanziari",
            disabled: true,
            to: `/scrivania/${this.capofila}/tracciabilita-flussi-finanziari`,
            exact: true,
            link: true,
          },
        ];
      }
      return [];
    },
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (
        this.idStrutture != null &&
        this.anno != null &&
        this.capofila != null
      ) {
        this.loadingData = true;
        this.getProgrammaOrientamento();
        this.loadingData = false;
      }
    },
    async getProgrammaOrientamento() {
      let programma = await ProgrammiRepository.getProgrammi(
        this.idStrutture,
        this.anno,
        this.capofila
      );
      this.programma = programma ? programma[0] : null;
      this.getDichiarazioni();
    },
    async getDichiarazioni() {
      this.dichiarazioni =
        await DichiarazioneFlussiRepository.getListaDichiarazioni(
          this.idStrutture,
          this.anno,
          parseInt(this.programma.id)
        );
      console.log(this.dichiarazioni);
    },
    colorState(item) {
      switch (item.id) {
        case 1:
          return "warning";
        case 2:
          return "success";
        case 3:
          return "danger";
        default:
          return "";
      }
    },
    getColorHeader(item) {
      return item.trasmesso ? "blue" : "warning";
    },
    goToFirmaStep(idDichiarazione) {
      this.$router.push({
        name: "firmaDocumentazioneDichiarazioneView",
        params: { idDichiarazione: idDichiarazione, capofila: this.capofila },
      });
    },
    async getFileDichiarazione(dichiarazione) {
      await istitutoRepository.downloadFile(
        this.idStrutture,
        dichiarazione.fileDocumentazioneFirmata
      );
    },
    async getFileRiepilogoDichiarazioneConti(tipoFile, dichiarazione) {
      const formData = new FormData();
      formData.append("dichiarazione", JSON.stringify(dichiarazione));
      await DichiarazioneFlussiRepository.downloadFileRiepilogoDichiarazioni(
        this.idStrutture,
        dichiarazione.id,
        tipoFile,
        formData
      );
    },
    async deleteDichiarazione(idDichiarazione) {
      try {
        await DichiarazioneFlussiRepository.deleteDichiarazione(
          this.idStrutture,
          idDichiarazione
        );
        this.snackbarText =
          "Dichiarazione di tracciabilità eliminata correttamente";
        this.snackbarColor = "success";
        setTimeout(() => this.initialize(), 2000);
      } catch (e) {
        console.log(e);
        this.snackbarText =
          "Problema durante la procedura di eliminazione, contattare il supporto se il problema persiste";
        this.snackbarColor = "red accent-2";
      }
      this.snackbar = true;
    },
  },
};
</script>

<style scoped>
.item-menu:hover {
  background-color: #c2b3b3;
}
</style>
