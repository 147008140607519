<template>
  <v-container>
    <h3>Elenco dei sotto accordi</h3>
    <v-card v-if="itemsSottoAccordi.length > 0" elevation="4">
      <v-data-table :headers="headersSottoAccordi" :items="itemsSottoAccordi">
        <!-- eslint-disable -->
        <template v-slot:item.pdfSottoAccordo="{ item }">
          <span v-if="item.fileSottoAccordo !== null">
            <v-list-item @click="downloadPdfSottoAccordo(item.id, item.fileSottoAccordo.nomeOriginale)"> <v-icon> mdi-paperclip</v-icon></v-list-item>
          </span>
          <span v-else>
            Nessun PDF  è stato caricato
          </span>
        </template>
        <template  v-slot:item.azioni="{ item }">
          <div v-if="finestraCompilazioneAttiva">
            <router-link
                :to="{
              name: 'aggiungiSottoAccordo',
              params: {
                capofila: capofila,
                idAccordo: idScuolaAccordo,
                operazione: 'modifica',
                idSottoAccordo: item.id
              },
            }"
            > <v-icon>  mdi-pencil </v-icon>
            </router-link>
          </div>
        </template>
     <!-- eslint-enable -->
      </v-data-table>
    </v-card>
    <v-card v-else>
      <span>
        Nessun sotto-accordo con l'istituto scolastico corrispondente è stato
        creato.
      </span>
    </v-card>
  </v-container>
</template>

<script>
import ScuoleAccordoRepository from "@/api/istituto/ScuoleAccordoRepository";

export default {
  name: "elencoSottoAccordi",
  props: {
    idScuolaAccordo: {
      type: Number,
      required: true,
    },
    itemsSottoAccordi: {
      type: Array,
      required: true,
    },
    capofila: {
      type: String,
      required: true,
    },
    finestraCompilazioneAttiva: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    sottoAccordi: [],
    headersSottoAccordi: [
      { text: "NUMERO ALUNNI", value: "numeroAlunni" },
      { text: "NUMERO CORSI", value: "numeroCorsi" },
      { text: "NUMERO DOCENTI", value: "numeroDocenti" },
      { text: "PDF SOTTO-ACCORDO", value: "pdfSottoAccordo" },
      { text: "MODIFICA/VISUALIZZA", value: "azioni" },
    ],
  }),
  methods: {
    async downloadPdfSottoAccordo(idSottoAccordo, nomeFileOriginale) {
      let idStrutture = this.$store.state.idStrutture;
      return ScuoleAccordoRepository.getPdfSottoAccordo(
        idStrutture,
        this.idScuolaAccordo,
        idSottoAccordo,
        nomeFileOriginale
      );
    },
  },
};
</script>
