<template>
  <v-autocomplete
    v-if="strutture.length > 2"
    v-model="strutturaSelezionata"
    :items="strutture"
    class="mx-4"
    flat
    hide-details
    item-text="name"
    item-value="idStrutture"
    label="Seleziona istituto..."
    solo-inverted
    @change="cambioStruttura()"
  ></v-autocomplete>
</template>

<script>
import ConsulenzaRepository from "@/api/ConsulenzaRepository";
import MinisteroRepository from "@/api/MinisteroRepository";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";

export default {
  name: "SelezionaStruttura",
  data() {
    return {
      strutturaSelezionata: this.$store.state.idStrutture,
      strutture: [],
    };
  },
  mounted() {
    this.getStrutture();
  },
  methods: {
    async getStrutture() {
      let result = null;
      if (
        this.$store.getters.isRoleLegaleProgramma ||
        this.$store.getters.isRoleLegaleCorso ||
        this.$store.getters.isRoleLegale
      ) {
        result = await IstitutoRepository.getStruttureUtente();
      } else if (this.$store.getters.isRoleConsulenza) {
        result = await ConsulenzaRepository.getStruttureRaggruppate();
      } else {
        result = await MinisteroRepository.getStruttureRaggruppate();
      }
      this.strutture = result;
    },
    cambioStruttura() {
      this.$store.dispatch(
        "loadIdStrutture",
        parseInt(this.strutturaSelezionata)
      );
    },
  },
};
</script>

<style scoped></style>
