<template>
  <v-container fluid>
    <template v-if="isRoleLegale || isRoleMinistero || isRoleConsulenza">
      <ListaUtenti ruolo="ROLE_LEGALE_RAPPRESENTANTE" />
      <ListaUtenti
        ruolo="ROLE_REFERENTE_PROGRAMMA"
        :key="keyAbilitato"
        @check-abilitato="keyAbilitato += 1"
      />
    </template>
    <ListaUtenti
      ruolo="ROLE_REFERENTE_CORSI"
      :key="keyAbilitato"
      @check-abilitato="keyAbilitato += 1"
    />
    <ListaUtenti class="mb-5" ruolo="ROLE_ISTITUTO" />
    <template
      v-if="
        isRoleReferenteProgramma ||
        isRoleMinistero ||
        isRoleConsulenza ||
        isRoleLegale
      "
    >
    </template>
  </v-container>
</template>

<script>
import ListaUtenti from "@/components/Utenti/ListaUtenti";
import { mapGetters, mapState } from "vuex";

export default {
  name: "GestioneUtenti",
  components: { ListaUtenti },
  data: function () {
    return {
      keyAbilitato: 0,
    };
  },
  computed: {
    ...mapState(["idStrutture", "user"]),
    ...mapGetters([
      "isRoleConsulenza",
      "isRoleMinistero",
      "isRoleLegale",
      "isRoleReferenteProgramma",
    ]),
  },
};
</script>
