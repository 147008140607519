import Repository from "../Repository";

export default {
  async getProgramma(idStrutture, anno, idProgramma) {
    const url = `api/istituto/${idStrutture}/programmi/anno/${anno}/programma/${idProgramma}`;
    return (await Repository.get(url)).data;
  },
  async createProgrammaOrientamento(idStrutture, anno, capofila, data) {
    const url = `api/istituto/${idStrutture}/programmi/inserisci-programma/${anno}/${capofila}`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  // utilizzo del metodo post per problemi lato backend, dovrebbe essere utilizzata una put
  async updateProgrammaOrientamento(idStrutture, anno, capofila, data) {
    const url = `api/istituto/${idStrutture}/programmi/aggiorna-programma/${anno}/${capofila}`;
    return (await Repository.post(url, data)).data;
  },
  async getIstituzione(idStrutture) {
    const url = `api/istituto/${idStrutture}/programmi/get-istituzione`;
    return (await Repository.get(url)).data;
  },
  async getProgrammi(idStrutture, anno, capofila) {
    const url = `api/istituto/${idStrutture}/programmi/anno/${anno}/${capofila}`;
    return (await Repository.get(url)).data;
  },
  async getFileAllegato(idStrutture, idProgramma, anno, nomeFileOriginale) {
    const url = `api/istituto/${idStrutture}/programmi/${idProgramma}/${anno}/file-allegato`;
    const res = await Repository.get(url, {
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = nomeFileOriginale;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async getConvenzioniProgrammi(idStrutture, anno, tipologia) {
    let url = `api/istituto/${idStrutture}/programmi/programmi-convenzioni/anno/${anno}/${tipologia}`;
    return (await Repository.get(url)).data;
  },
  async getProgrammiDiCuiPartner(idStrutture, anno) {
    const url = `api/istituto/${idStrutture}/programmi/anno/${anno}/programmi-partner`;
    return (await Repository.get(url)).data;
  },
  async getDiCuiInConvenzionePartner(idStrutture, anno) {
    const url = `api/istituto/${idStrutture}/programmi/anno/${anno}/assegnazioni-in-convenzione-partner`;
    return (await Repository.get(url)).data;
  },
  async getProgrammiTipoReferente() {
    const url = `api/generici/programmi-tipo-referente`;
    return (await Repository.get(url)).data;
  },
  async getLegaliRappresentantiByProgramma(idStrutture, idProgramma) {
    const url = `api/istituto/${idStrutture}/programmi/${idProgramma}`;
    return (await Repository.get(url)).data;
  },
  async incrementoTarget(idStrutture, anno) {
    const url = `api/istituto/${idStrutture}/programmi/${anno}/incremento-target`;
    return (await Repository.get(url)).data;
  },
  async deleteFileAllegato4Programma(idStrutture, idProgramma) {
    let url = `api/istituto/${idStrutture}/programmi/${idProgramma}/delete-file-programma`;
    return (await Repository.delete(url)).data;
  },
  async uploadFileAllegato4(idStrutture, idProgramma, data) {
    const url = `api/istituto/${idStrutture}/programmi/${idProgramma}/upload-allegato-programma`;
    return (await Repository.post(url, data)).data;
  },
  async uploadFileAllegato5(idStrutture, anno, idAssenzaDoppioFin, data) {
    const url = `api/istituto/${idStrutture}/programmi/${idAssenzaDoppioFin}/anno/${anno}/upload-assenza-doppio-finanziamento`;
    return (await Repository.post(url, data)).data;
  },
  async downloadAllegatiProgramma(idStrutture, idFile, nomeFileOriginale) {
    const url = `api/istituto/${idStrutture}/programmi/${idFile}/file-allegati-programma`;
    const res = await Repository.get(url, {
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = nomeFileOriginale;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async deleteFileAllegato5Programma(idStrutture, idAssenzaDoppioFin) {
    let url = `api/istituto/${idStrutture}/programmi/${idAssenzaDoppioFin}/delete-file-doppio-finanziamento`;
    return (await Repository.delete(url)).data;
  },
  async uploadFileAllegato6(
    idStrutture,
    anno,
    idComunicazioneTitolarita,
    data
  ) {
    const url = `api/istituto/${idStrutture}/programmi/${idComunicazioneTitolarita}/anno/${anno}/upload-comunicazione-titolarita`;
    return (await Repository.post(url, data)).data;
  },
  async deleteFileAllegato6Programma(idStrutture, idComunicazioneTitolarita) {
    let url = `api/istituto/${idStrutture}/programmi/${idComunicazioneTitolarita}/delete-file-comunicazione-titolarita`;
    return (await Repository.delete(url)).data;
  },
  async addTitolareEffettivo(idStrutture, idComunicazioneTitolarita, data) {
    const url = `api/istituto/${idStrutture}/programmi/${idComunicazioneTitolarita}/insert-titolare-effettivo`;
    return (await Repository.post(url, data)).data;
  },
  async deleteTitolareEffettivo(idStrutture, idTitolare) {
    let url = `api/istituto/${idStrutture}/programmi/${idTitolare}/delete-titolare-effettivo`;
    return (await Repository.delete(url)).data;
  },
  async trasmettiProgramma(idStrutture, anno, idProgramma) {
    const url = `api/istituto/${idStrutture}/programmi/anno/${anno}/trasmetti-programma/${idProgramma}`;
    return (await Repository.put(url)).data;
  },
};
