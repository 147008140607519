<template>
  <v-card v-if="idStrutture !== null" :loading="loading" class="my-4">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-simple-table class="mt-4 table-custom">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" rowspan="2">Nome e Cognome</th>
                  <th class="text-left">Codice fiscale</th>
                  <th class="text-left">Ruolo</th>
                  <th class="text-left">SSD/SDA</th>
                  <th class="text-center">Elimina</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="docenti.length === 0">
                  <td colspan="9">
                    <h4>Nessun docente trovato.</h4>
                  </td>
                </tr>
                <tr v-else v-for="item in docenti" :key="item.id">
                  <td>{{ item.nome }} {{ item.cognome }}</td>
                  <td>{{ item.cf }}</td>
                  <td>{{ item.ruolo }}</td>
                  <td>
                    {{ item.ssdSad }}
                  </td>
                  <td align="center">
                    <template v-if="terminato">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" medium
                            >mdi-delete-forever-outline</v-icon
                          >
                        </template>
                        <span
                          >Non è possibile eliminare i docenti di un corso
                          terminato!</span
                        >
                      </v-tooltip>
                    </template>
                    <template v-else>
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="error"
                        @click="deleteDocente(item)"
                        :disabled="
                          referenteIstituto || !finestraCompilazioneAttiva
                        "
                        v-if="!readOnly"
                        ><v-icon> mdi-delete-forever-outline </v-icon>
                      </v-btn>
                    </template>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
    <dialog-elimina-docente
      :idDocente="idDocenteDelete"
      :idCorso="idCorso"
      :dialog.sync="dialogDelete"
      @close-dialog="dialogDelete = false"
      @docente-eliminato="keyDocenti += 1"
    />

    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      shaped
      :color="snackbarColor"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CorsiRepository from "@/api/istituto/CorsiRepository";
import DialogEliminaDocente from "@/components/DialogEliminaDocente";

export default {
  name: "DocentiDelCorsoComponent",
  components: { DialogEliminaDocente },
  props: {
    idCorso: {
      type: Number,
      required: true,
    },
    terminato: {
      type: Boolean,
      required: false,
    },
    finestraCompilazioneAttiva: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    referenteIstituto: false,
    keyDocenti: 0,
    idDocenteDelete: null,
    dialogDelete: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    loadingDocenti: false,
    docenti: [],
  }),
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["idStrutture", "anno"]),
    ...mapGetters(["isReadOnly", "isRoleIstituto", "isRoleReferenteCorsi"]),
    readOnly() {
      return !!this.isReadOnly;
    },
    loading: function () {
      return this.loadingDocenti;
    },
  },
  watch: {
    keyDocenti() {
      this.initialize();
    },
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        this.getDocenti();
        if (this.isRoleIstituto && !this.isRoleReferenteCorsi) {
          this.referenteIstituto = true;
        }
      }
    },
    async getDocenti() {
      this.loadingDocenti = true;
      let idStrutture = this.$store.state.idStrutture;
      this.docenti = await CorsiRepository.getDocentiCorso(
        idStrutture,
        this.idCorso
      );
      this.loadingDocenti = false;
    },
    async deleteDocente(docente) {
      this.idDocenteDelete = docente.idDocente;
      this.dialogDelete = true;
    },
  },
};
</script>

<style scoped>
.table-custom {
  border-collapse: collapse;
  width: 100%;
}

.table-custom td,
.table-custom th {
  border: 1px solid #ddd;
  padding: 8px;
}

.table-custom tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table-custom tr:hover {
  background-color: #ddd;
}

.table-custom th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #ccc6c6;
  color: white;
}
</style>
