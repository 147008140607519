<template>
  <v-container fluid>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" v-if="capofila" />
    <div class="mt-8">
      <AlertRichiesteRendiconti />
    </div>
    <v-row class="mb-4">
      <v-col>
        <h1>Creazione e trasmissione rendiconto di progetto</h1>
        <h4 class="grey--text">
          Lista dei
          <span v-if="this.capofila === 'istituzione'">Rendiconti</span>
          <span v-else class="font-weight-bold">
            Rendiconti in convenzione da <u>Capofila</u>
          </span>
          Anno Scolastico {{ this.anno }}/{{ this.anno + 1 }}
        </h4>
      </v-col>
    </v-row>
    <v-row class="my-4">
      <v-col col="8">
        <v-text-field
          solo
          v-model="ricerca"
          append-icon="mdi-magnify"
          label="Cerca per N° rendiconto"
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <br />
    <alert-component tipo-alert="info" :testo="getTestoAlertRendiconto()" />
    <v-row class="my-2">
      <v-col cols="2">
        <v-btn text class="blue--text d-none"
          ><v-icon class="mx-2"> mdi-microsoft-excel </v-icon>
          Esporta Excel
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn text class="blue--text d-none"
          ><v-icon class="mx-2"> mdi-file-pdf-box </v-icon>
          Esporta PDF
        </v-btn>
      </v-col>
      <v-col cols="4"></v-col>
      <v-col cols="4" class="d-flex">
        <template v-if="finestraCompilazione">
          <div
            class="ml-auto"
            v-if="!controlloAccessoCompilazioneRendiconto(1, null)"
          >
            <router-link
              v-if="isRoleLegale || isRoleConsulenza"
              class="v-btn primary pa-2"
              :to="{
                name: 'InserimentoRendiconto',
                params: {
                  capofila: capofila,
                  operazione: 'inserimento',
                  quadrimestre: finestraCompilazione,
                },
              }"
            >
              <v-icon left class="white--text"> mdi-plus </v-icon> Nuovo
              rendiconto
            </router-link>
            <span v-else>
              <v-alert
                type="info"
                v-html="'Operazione consentita solo ai legali rappresentanti.'"
              />
            </span>
          </div>
          <div v-else>
            <v-alert
              type="info"
              v-html="
                'È possibile inserire un solo rendiconto per periodo di riferimento.'
              "
            />
          </div>
        </template>
        <template v-else>
          <small class="red--text"
            >La compilazione dei rendinconti è attualmente chiusa</small
          >
        </template>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="rendiconti"
      :search="ricerca"
      :items-per-page="10"
      class="elevation-1"
      :loading="loadingRendiconti"
      loading-text="Caricamento dati in corso..."
      ><!-- eslint-disable -->
        <template v-slot:item.stato="{ item }">
          <v-chip outlined pill :color="colorState(item)">
            {{ item.stato.descrizione }}
          </v-chip>
        </template>
        <template v-slot:item.quadrimestre="{ item }">
           {{ item.quadrimestre.tipologiaQuadrimestre.descrizione }}
        </template>
        <template v-slot:item.controlli="{ item }">
          <span v-if="item.avanzamentoControllo"> {{item.avanzamentoControllo.id}}/4 - {{ item.avanzamentoControllo.descrizione }}</span>
        </template>
        <template v-slot:item.dataTrasmissione="{ item }">
          <span>{{item.dataTrasmissione | formatDate}}</span>
        </template>
        <template v-slot:item.importoRichiesto="{ item }">
          <span>{{item.importoRichiesto | formatNumber}} €</span>
        </template>
      <template v-slot:item.invioRegis="{ item }">
        <span v-if="item.inviatoRegis === 1">Si</span>
        <span v-else>No</span>
      </template>
      <template v-slot:item.importoAmmesso="{ item }">
        <span v-if="item.importoAmmesso"> {{ item.importoAmmesso }} €</span>
      </template>
        <template v-slot:item.actions="{ item }">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="v-icon"
                  v-bind="attrs"
                  v-on="on"
                  :icon="true"
              >
                <v-icon> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                  class="item-menu"
                  v-for="(itemMenu, i) in menuAction"
                  :key="i"
                  :disabled="
                    controlloDisabilitazione(
                      i,
                      item
                    )
                  "
              >
                <router-link
                    class="v-btn"
                    :to="{
                          name: itemMenu.nameComponent,
                          params: {
                            capofila: capofila,
                            idRendiconto: item.id,
                          },
                        }"
                >
                  {{ itemMenu.text }}
                  <v-icon> {{ itemMenu.icon }}</v-icon>
                </router-link>
              </v-list-item>
            </v-list>
          </v-menu>
            <v-icon
                v-if="isRoleLegale || isRoleConsulenza"
              small
              class="mr-2"
              @click="goToRendicontoView(item.id)"
              :disabled="controlloAccessoCompilazioneRendiconto(2, item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                v-if="isRoleLegale || isRoleConsulenza"
              small
              class="mr-2"
              :disabled="controlloAccessoCompilazioneRendiconto(2, item)"
              @click="showDialogDeleteRendiconto(item)"
             > mdi-delete </v-icon>
        </template>
      <!-- eslint-enable -->
    </v-data-table>
    <dialog-conferma
      :dialog.sync="dialogDeleteRendiconto"
      @callback="deleteRendiconto(rendicontoToDelete.id)"
      @close-dialog="closeDialogDeleteRendiconto()"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import RendicontiRepository from "@/api/istituto/RendicontiRepository";
import DialogConferma from "@/components/DialogConferma";
import AlertComponent from "@/components/Common/AlertComponent";
import AlertRichiesteRendiconti from "@/components/AlertRichiesteRendiconti";

export default {
  name: "RendicontiView",
  components: {
    AlertComponent,
    DialogConferma,
    BreadcrumbComponent,
    AlertRichiesteRendiconti,
  },
  data: () => ({
    idUtente: 0,
    loadingRendiconti: false,
    isDisabled: true,
    isCapofila: false,
    rendiconti: [],
    finestraCompilazione: null,
    menuAction: [
      {
        text: "Dettaglio rendiconto",
        ambitoOperazione: "dettaglioRendiconto",
        icon: null,
        nameComponent: "dettaglioRendiconto",
      },
      {
        text: "Dettaglio integrazioni e modifica",
        ambitoOperazione: "dettaglioIntegrazioniModifiche",
        icon: null,
        nameComponent: "dettaglioIntegrazioniModifiche",
      },
      {
        text: "Dettaglio verbali",
        ambitoOperazione: "dettaglioVerbali",
        icon: null,
        nameComponent: "downloadDettaglioVerbali",
      } /*
      {
        text: "Dettaglio checklist",
        ambitoOperazione: "checklist",
        icon: null,
        nameComponent: "dettagliochecklist",
      },
      {
        text: "Invio a ReGIS",
        ambitoOperazione: "regis",
        nameComponent: "invioRegis",
        icon: null,
      },*/,
    ],
    ricerca: "",
    headers: [
      {
        text: "ID rendiconto",
        align: "start",
        value: "id",
      },
      { text: "Periodo di riferimento", value: "quadrimestre" },
      { text: "Importo richiesto", value: "importoRichiesto" },
      { text: "Data trasmissione", value: "dataTrasmissione" },
      { text: "Stato", value: "stato", sortable: false },
      { text: "Inviato a ReGIS", value: "invioRegis" },
      { text: "Importo ammesso", value: "importoAmmesso" },
      { text: "Azioni", value: "actions", sortable: false },
    ],
    dialogDeleteRendiconto: false,
    idStruttureConCompilazioneAttiva: [],
    rendicontoToDelete: null,
  }),
  props: {
    capofila: {
      type: String,
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["idStrutture", "anno", "user"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleConsulenza",
      "isRoleLegale",
    ]),
    loading: function () {
      return this.loadingRendiconti;
    },
    breadcrumbs: function () {
      if (this.capofila) {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania/${this.capofila}`,
            exact: true,
            link: true,
          },
          {
            text: "Creazione e trasmissione rendiconto di progetto",
            disabled: true,
            to: `/scrivania/${this.capofila}/rendiconti`,
            exact: true,
            link: true,
          },
        ];
      }
      return [];
    },
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      // ogni volto che entro in questa pagina, resetto l'idRendiconto a null
      this.$store.commit("setIdRendiconto", null);
      if (this.idStrutture != null && this.anno != null) {
        this.getRendiconti();
        this.getQuadrimestreAttivoInCompilazione();
      }
    },
    async getRendiconti() {
      this.loadingRendiconti = true;
      this.rendiconti = await RendicontiRepository.getListaRendiconti(
        this.idStrutture,
        this.anno,
        this.capofila
      );
      this.loadingRendiconti = false;
    },
    async getQuadrimestreAttivoInCompilazione() {
      const finestraCompilazione =
        await RendicontiRepository.getQuadrimestreAttivo(this.anno, true);
      if (finestraCompilazione) {
        this.finestraCompilazione = finestraCompilazione;
        this.$store.commit("setQuadrimestreRendiconti", finestraCompilazione);
      } else if (
        this.idStruttureConCompilazioneAttiva.includes(this.idStrutture)
      ) {
        this.finestraCompilazione =
          await RendicontiRepository.getLastQuadrimestreAttivo(this.anno);
      } else {
        this.finestraCompilazione = null;
      }
    },
    colorState(item) {
      switch (item.id) {
        case 1:
          return "primary";
        case 3:
          return "primary";
        case 4:
          return "primary";
        case 5:
          return "warning";
        case 2:
          return "success";
        case 6:
          return "success";
        case 7:
          return "success";
        case 8:
          return "danger";
        default:
          return "";
      }
    },
    goToRendicontoView(idRendiconto) {
      this.$store.commit("setIdRendiconto", idRendiconto);
      if (this.$store.state.idRendiconto) {
        this.$router.push({
          name: "InserimentoRendiconto",
          params: {
            capofila: this.capofila,
            operazione: "modifica",
            quadrimestre: this.finestraCompilazione
              ? this.finestraCompilazione
              : null,
          },
        });
      }
    },
    async deleteRendiconto(idRendiconto) {
      const idStrutture = this.idStrutture;
      await RendicontiRepository.deleteRendiconto(
        idStrutture,
        idRendiconto
      ).then(() => {
        this.initialize();
      });
    },
    controlloAccessoCompilazioneRendiconto(
      tipoOperazione,
      rendicontoSelezionato
    ) {
      switch (tipoOperazione) {
        case 1: // operazione inserimento
          if (
            this.idStrutture === 12 ||
            this.idStrutture === 16 ||
            this.idStrutture === 6
          ) {
            return false;
          }
          if (this.rendiconti && this.rendiconti.length) {
            const rendicontiFiltratiPerQuadrimestre = this.rendiconti.filter(
              (element) =>
                element.quadrimestre.id === this.finestraCompilazione.id
            );
            if (this.idStrutture === 7212 && this.anno === 2022) {
              return rendicontiFiltratiPerQuadrimestre.length > 1;
            }
            return rendicontiFiltratiPerQuadrimestre.length > 0;
          } else {
            return false;
          }

        case 2: // operazione modifica
          return (
            !this.finestraCompilazione || rendicontoSelezionato.stato.id !== 1
          );
      }
    },
    getTestoAlertRendiconto() {
      return (
        "Al fine di consentire " +
        "il completamento dei rendiconti da parte di tutte le istituzioni interessate, " +
        "si fa presente che la relativa procedura resterà disponibile fino a venerdì 28 giugno, ore 17:00."
      );
    },
    controlloDisabilitazione(voceMenu, rendiconto) {
      let disabilitato = true;
      switch (voceMenu) {
        case 0: // dettaglio rendiconto
          disabilitato = false;
          break;
        case 1: // dettagli integrazioni e modifiche
          if (rendiconto.richiesteIntegrazioniModifiche.length > 0) {
            disabilitato = false;
          }
          break;
        case 2: // dettaglio verbali
          if (
            rendiconto.verificaRendiconto &&
            rendiconto.verificaRendiconto.stepVerifica === 5 &&
            rendiconto.verificaRendiconto.fileVerificaRendiconto
          ) {
            disabilitato = false;
          }
          break;
      }
      return disabilitato;
    },
    showDialogDeleteRendiconto(rendiconto) {
      this.rendicontoToDelete = rendiconto;
      this.dialogDeleteRendiconto = true;
    },
    closeDialogDeleteRendiconto() {
      this.rendicontoToDelete = null;
      this.dialogDeleteRendiconto = false;
    },
  },
};
</script>

<style scoped>
.item-menu:hover {
  background-color: #c2b3b3;
}
</style>
