<template>
  <v-container>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" v-if="capofila" />
    <v-row class="mb-4">
      <v-col>
        <h1>Dettaglio integrazioni/modifiche</h1>
        <h4 class="grey--text">
          Lista delle
          <span v-if="this.capofila === 'istituzione'"
            >integrazioni/modifiche</span
          >
          <span v-else class="font-weight-bold">
            integrazioni/modifiche in convenzione da <u>Capofila</u>
          </span>
          - Anno Scolastico
          {{ this.anno }}/{{ this.anno + 1 }}
        </h4>
      </v-col>
    </v-row>
    <div v-if="rendiconto">
      <template>
        <v-card v-if="richieste" :loading="loading">
          <v-expansion-panels focusable>
            <v-expansion-panel v-for="(item, i) in richieste" :key="i">
              <v-expansion-panel-header>
                <template>
                  <v-row>
                    <v-col cols="2">
                      <v-card-text>
                        Richiesta
                        <br />
                        <br />
                        <b>
                          {{ item.id }}
                        </b>
                      </v-card-text>
                    </v-col>
                    <v-col cols="2">
                      <v-card-text>
                        Richiedente
                        <br />
                        <br />
                        <b>
                          {{ richiedente }}
                        </b>
                      </v-card-text>
                    </v-col>
                    <v-col cols="3">
                      <v-card-text>
                        Ruolo richiedente
                        <br />
                        <br />
                        <b>
                          {{ getRuoloRichiedente(item.richiedente.ruoli) }}
                        </b>
                      </v-card-text>
                    </v-col>
                    <v-col cols="3">
                      <v-card-text>
                        Data
                        <br />
                        <br />
                        <b> {{ getDate(item.dataCreazione) }} </b>
                      </v-card-text>
                    </v-col>
                    <v-col cols="2">
                      <br />
                      <br />
                      <v-chip
                        v-if="item.conclusa === true"
                        pill
                        color="success"
                      >
                        Conclusa
                      </v-chip>
                      <v-chip v-else pill color="warning"> Nuova </v-chip>
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="mt-2">
                  <v-col cols="8">
                    <h4>Descrizione richiesta di integrazioni e modifiche:</h4>
                    <div class="pt-2">
                      <p>
                        {{ item.descrizione }}
                      </p>
                    </div>
                  </v-col>
                  <v-col class="d-flex flex-row-reverse my-auto" cols="4"
                    ><div v-if="item.fileRichiesta">
                      <a @click="getFileAllegato(item, 'richiesta')">
                        <v-icon> mdi-tray-arrow-down </v-icon> Download file
                        allegato alla Richiesta
                      </a>
                    </div>
                    <div v-else>
                      <p>
                        <v-icon> mdi-paperclip-off </v-icon>Nessun allegato
                        caricato
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-form
                      lazy-validation
                      ref="form"
                      v-model="valid"
                      :rules="validateField.rispostaSa"
                    >
                      <v-textarea
                        outlined
                        hint="Massimo 1000 caratteri"
                        name="dettagliCorso"
                        label="Risposta SA:"
                        counter
                        auto-grow
                        v-model="item.rispostaSoggettoAttuatore"
                        :rules="validateField.rispostaSa"
                        :disabled="item.conclusa === true"
                      />
                      <v-row>
                        <v-col cols="8">
                          <v-file-input
                            :placeholder="getPlaceholderInputFile(item)"
                            :disabled="item.conclusa === true"
                            @change="uploadFile($event)"
                          ></v-file-input>
                        </v-col>
                        <v-col
                          class="d-flex flex-row-reverse my-auto"
                          v-if="item.conclusa === true"
                          cols="4"
                          ><div v-if="item.fileAllegato">
                            <a @click="getFileAllegato(item, 'risposta')">
                              <v-icon> mdi-tray-arrow-down </v-icon> Download
                              file allegato
                            </a>
                          </div>
                          <div v-else>
                            <p>
                              <v-icon> mdi-paperclip-off </v-icon>Nessun
                              allegato caricato
                            </p>
                          </div>
                        </v-col>
                      </v-row>

                      <v-card-actions v-if="item.conclusa !== true">
                        <v-row>
                          <v-col cols="12" class="d-flex flex-row-reverse">
                            <v-btn
                              color="primary"
                              @click="dialogSalva = true"
                              :disabled="!valid"
                            >
                              Conferma
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-actions>
                      <dialog-conferma
                        title="Conferma risposta e concludi richiesta"
                        text-conferma="Confermando l'operazione la risposta sarà inviata e la richiesta non sarà più modificabile. Continuare?"
                        @callback="updateRichiesta(item)"
                        :dialog.sync="dialogSalva"
                        @close-dialog="dialogSalva = false"
                      />
                    </v-form>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-data-table
                      :headers="headers"
                      :items="corsi"
                      :items-per-page="5"
                      class="elevation-1"
                      :loading="loadingDettaglio"
                      loading-text="Caricamento dati in corso..."
                      ><!-- eslint-disable -->
                      <template v-slot:item.cup="{ item }">
                        <span>{{ rendiconto.programma.cup }}</span>
                      </template>
                      <template v-slot:item.nomeIstituzione="{ item }">
                        <span>{{ rendiconto.programma.nomeIstituzione }}</span>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <router-link
                            class="v-btn"
                            :to="{
                    name: 'dettaglioCorso',
                    params: {
                      capofila: capofila,
                      idCorso: item.corso.id,
                    },
                  }"
                        >
                          Dettaglio
                          <v-icon></v-icon>
                        </router-link>
                      </template>
                      <!-- eslint-enable -->
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
        <v-card v-else>
          <v-card-text class="justify-centerr">
            Non ci sono richieste di integrazioni o modifiche</v-card-text
          >
        </v-card>
        <v-snackbar
          v-model="snackbar"
          :timeout="3000"
          shaped
          :color="snackbarColor"
        >
          {{ snackbarText }}

          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </template>
    </div>
    <div v-else>
      <v-progress-linear indeterminate color="blue"></v-progress-linear>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import RendicontiRepository from "@/api/istituto/RendicontiRepository";
import DialogConferma from "@/components/DialogConferma";

export default {
  name: "dettaglioIntegrazioniModifiche",
  components: { BreadcrumbComponent, DialogConferma },
  data: () => ({
    dialogSalva: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    formRichiestaModifiche: {
      rispostaSa: null,
      disabled: false,
    },
    valid: false,
    validateField: {
      rispostaSa: [
        (v) => !!v || "Campo obbligatorio",
        (v) => (v && v.length <= 1000) || "Numero di caratteri elevato",
      ],
    },
    idUtente: 0,
    loadingDettaglio: true,
    corsi: [],
    richieste: [],
    rendiconto: null,
    richiedente: "DG",
    headers: [
      {
        text: "ID corso",
        align: "start",
        value: "corso.id",
      },
      { text: "CUP", value: "cup" },
      { text: "Soggetto responsabile", value: "nomeIstituzione" },
      { text: "Azioni", value: "actions", sortable: false },
    ],
  }),
  props: {
    capofila: {
      type: String,
      required: true,
    },
    idRendiconto: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["idStrutture", "anno", "user"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleConsulenza",
      "isRoleLegale",
    ]),
    loading: function () {
      return this.loadingDettaglio;
    },
    breadcrumbs: function () {
      if (this.capofila) {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania/${this.capofila}`,
            exact: true,
            link: true,
          },
          {
            text: "Creazione e trasmissione rendiconto di progetto",
            disabled: false,
            to: `/scrivania/${this.capofila}/rendiconti`,
            exact: true,
            link: true,
          },
          {
            text: "Dettaglio Integrazioni/modifiche",
            disabled: true,
            to: `/scrivania/${this.capofila}/rendiconti/dettaglioIntegrazioniModifiche`,
            exact: true,
            link: true,
          },
        ];
      }
      return [];
    },
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        this.getCorsiRendiconto();
        this.getRendiconto(this.idStrutture, this.idRendiconto);
      }
    },
    async getRendiconto(idStrutture, idRendiconto) {
      this.loadingDettaglio = true;
      this.rendiconto = await RendicontiRepository.getSingoloRendiconto(
        idStrutture,
        idRendiconto
      );
      this.richieste = this.rendiconto.richiesteIntegrazioniModifiche;
      this.loadingDettaglio = false;
    },
    async getCorsiRendiconto() {
      this.loadingDettaglio = true;
      this.corsi = await RendicontiRepository.getListaCorsiRendiconto(
        this.idStrutture,
        this.idRendiconto
      );
      this.loadingDettaglio = false;
    },
    getPlaceholderInputFile(item) {
      if (item.fileAllegato) {
        return item.fileAllegato.nomeOriginale;
      } else {
        return "Caricamento allegato in Pdf/p7m (facoltativo)";
      }
    },
    async getFileAllegato(richiesta, tipoAllegato) {
      let nomeFile =
        tipoAllegato === "richiesta"
          ? richiesta.fileRichiesta.nomeOriginale
          : richiesta.fileAllegato.nomeOriginale;
      await RendicontiRepository.getFileRispostaRichiesta(
        this.idStrutture,
        richiesta.id,
        nomeFile,
        tipoAllegato
      );
    },
    getDate(date) {
      return new Date(date).toLocaleDateString();
    },
    uploadFile(event) {
      this.file = event;
    },
    getRuoloRichiedente(arrayRuoli) {
      if (arrayRuoli.some((item) => item.id === 8)) {
        return "Responsabile del controllo";
      } else if (arrayRuoli.some((item) => item.id === 9)) {
        return "Incaricato del controllo";
      } else {
        return "--";
      }
    },
    async updateRichiesta(richiesta) {
      console.log(richiesta);
      if (richiesta.rispostaSoggettoAttuatore) {
        const formData = new FormData();
        formData.append("form", JSON.stringify(richiesta));
        formData.append("file", this.file);
        try {
          await RendicontiRepository.updateRichiestaIntegrazioni(
            this.idStrutture,
            parseInt(this.anno),
            richiesta.id,
            formData
          );
          this.formRichiestaModifiche.disabled = true;

          this.snackbarText = "Risposta integrazioni/modifica inserita";
          this.snackbarColor = "success";
          this.snackbar = true;
          this.initialize();
        } catch (e) {
          console.log(e);
          if (e.response.status === 415) {
            this.snackbarText = e.response.data[0];
            this.snackbarColor = "red accent-2";
            this.snackbar = true;
          } else {
            this.snackbarText =
              "Problema durante il salvataggio, contattare il supporto se il problema persiste";
            this.snackbarColor = "red accent-2";
            this.snackbar = true;
          }
        }
      }
    },
  },
};
</script>

<style scoped></style>
