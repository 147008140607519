<template>
  <v-card elevation="3" class="text-center" v-if="rendiconto">
    <h4 class="font-weight-bold text-left sottotitolo">Anagrafica progetto</h4>
    <v-row>
      <v-col class="w-25">
        <v-card-text> <b> Data Presentazione </b> </v-card-text>
      </v-col>
      <v-col class="w-25">
        <v-card-text> <b> N° Rendiconto </b> </v-card-text>
      </v-col>
      <v-col class="w-25">
        <v-card-text>
          <b> Periodo di riferimento </b>
        </v-card-text>
      </v-col>
      <v-col class="w-25">
        <v-card-text> <b> Importo Rendicontato </b> </v-card-text>
      </v-col>
      <v-col class="w-25">
        <v-card-text>
          <b> CUP </b>
        </v-card-text>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="w-25">
        <p>
          {{
            rendiconto.dataTrasmissione
              ? getDate(rendiconto.dataTrasmissione)
              : "Non ancora trasmesso."
          }}
        </p>
      </v-col>
      <v-col class="w-25">
        <p>{{ rendiconto.id }}</p>
      </v-col>
      <v-col class="w-25">
        <p>
          {{ rendiconto.quadrimestre.tipologiaQuadrimestre.descrizione }}
        </p>
      </v-col>
      <v-col class="w-25">
        <p>0 €</p>
      </v-col>
      <!--<v-col class="w-25">
        <p>
          {{
            rendiconto.ibanBancario != null
              ? rendiconto.ibanBancario
              : rendiconto.contoTesoreria
          }}
        </p>
      </v-col>-->
      <v-col class="w-25">
        <v-card-text>
          <p>{{ rendiconto.programma.cup }}</p>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  name: "riepilogoGeneraleRendiconto",
  components: {},
  props: {
    rendiconto: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getDate(date) {
      return new Date(date).toLocaleDateString();
    },
  },
};
</script>
<style scoped>
.sottotitolo {
  color: #1a237e;
  margin-left: 1%;
  font-weight: bold;
}
</style>
