<template>
  <v-container fluid>
    <StepRendicontazioni :numero-step="4" />
    <h1 class="sottotitolo">DSAN - DNSH</h1>
    <v-main v-if="!loadingPage">
      <!--    SEZIONE CON I DATI DEL RAPPRESENTANTE LEGALE  -->
      <v-card elevation="3">
        <v-card-title></v-card-title>
        <v-card-text v-if="legaleRappresentante">
          <v-row>
            <v-col><p class="testo">La/Il sottoscritta/o</p></v-col>
          </v-row>
          <v-row>
            <v-col> NOME</v-col>
            <v-col> COGNOME </v-col>
            <v-col> CODICE FISCALE </v-col>
            <v-col> DATA DI NASCITA</v-col>
            <v-col> LUOGO DI NASCITA </v-col>
          </v-row>
          <v-row>
            <v-col>
              <b> {{ legaleRappresentante.nomeRappresentante }}</b></v-col
            >
            <v-col>
              <b> {{ legaleRappresentante.cognomeRappresentante }}</b></v-col
            >
            <v-col
              ><b> {{ legaleRappresentante.codiceFiscaleRappresentante }}</b>
            </v-col>
            <v-col>
              <b>
                {{
                  legaleRappresentante.dataNascita
                    ? legaleRappresentante.dataNascita
                    : "Non Disponibile"
                }}
              </b>
            </v-col>
            <v-col>
              <b>
                {{
                  legaleRappresentante.comuneNascita
                    ? legaleRappresentante.comuneNascita
                    : "Non Disponibile"
                }}
              </b>
            </v-col>
          </v-row>
          <v-row>
            <v-col><p class="testo">Legale rappresentante del</p></v-col>
          </v-row>
          <!-- SEZIONE CON I DATI DELLA STRUTTURA  -->
          <v-row>
            <v-col> SOGGETTO ATTUATORE</v-col>
            <v-col> CODICE FISCALE/P.IVA </v-col>
            <v-col> LUOGO SEDE LEGALE</v-col>
            <v-col> COMUNE SEDE LEGALE</v-col>
            <v-col> PROVINCIA SEDE LEGALE</v-col>
          </v-row>
          <v-row>
            <v-col>
              <b> {{ rendiconto.programma.nomeIstituzione }}</b></v-col
            >
            <v-col>
              <b> {{ accordo.pIvaStruttura }}</b></v-col
            >
            <v-col
              ><b> {{ accordo.indirizzoStruttura }}</b>
            </v-col>
            <v-col
              ><b> {{ accordo.cittaStruttura }}</b>
            </v-col>
            <v-col
              ><b> {{ accordo.provinciaStruttura }}</b>
            </v-col>
          </v-row>
          <!-- SEZIONE CON I DATI DELLA STRUTTURA  SECONDA RIGA-->
          <v-row>
            <v-col> CAP SEDE LEGALE </v-col>
            <v-col> PEC </v-col>
            <v-col> </v-col>
            <v-col> </v-col>
            <v-col> </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span v-if="datiStruttura.cap"
                ><b> {{ datiStruttura.cap }}</b></span
              >
              <span v-else><b> -- </b></span></v-col
            >
            <v-col>
              <span v-if="datiStruttura.pec"
                ><b> {{ datiStruttura.pec }}</b></span
              >
              <span v-else><b> -- </b></span></v-col
            >
            <v-col> </v-col>
            <v-col> </v-col>
            <v-col> </v-col>
          </v-row>

          <!-- sezione dati struttura modificabili -->
          <!--<v-form ref="formRendiconto" lazy-validation>
            <v-row class="my-2">
              <v-col cols="4">
                <v-text-field
                  v-model="rendiconto.programma.nomeIstituzione"
                  label="Soggetto attuatore"
                  :readonly="true"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="soggettoAttuatore.cf"
                  label="Codice fiscale"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="">
                <v-text-field
                  v-model="soggettoAttuatore.comuneSede"
                  label="Comune sede legale"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field label="CAP sede legale"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field label="PEC"></v-text-field>
              </v-col>
            </v-row>
          </v-form>-->

          <p class="mt-6">
            consapevole della responsabilità penale cui può andare incontro in
            caso di dichiarazione falsa o comunque non corrispondente al vero
            (art. 76 del D.P.R. n. 445 del 28/12/2000), ai sensi del D.P.R. n.
            445 del 28/12/2000 e ss.mm.ii.
          </p>
          <v-row class="mb-4 mt-2">
            <v-col cols="12">
              <h4 class="text-center">DICHIARA CHE</h4>
            </v-col>
          </v-row>
          <ol>
            <li class="mb-2">
              Le attività svolte dall’Istituzione o dalla rete di Istituzioni
              non arrecano danno significativo a nessuno dei sei obiettivi
              ambientali indicati all’art. 17 del Reg. (UE) 2020/852, per tutto
              il ciclo di vita del progetto, e in particolare:
              <ol type="i" class="my-2">
                <li>
                  alla mitigazione dei cambiamenti climatici, in quanto le
                  attività non conducono a significative emissioni di gas a
                  effetto serra;
                </li>
                <li>
                  all’adattamento ai cambiamenti climatici, in quanto le
                  attività non conducono a un peggioramento degli effetti
                  negativi del clima attuale e del clima futuro previsto su sé
                  stessa o sulle persone, sulla natura o sugli attivi;
                </li>
                <li>
                  all’uso sostenibile e alla protezione delle acque e delle
                  risorse marine, in quanto le attività non nuocciono:
                </li>
                <ol type="a">
                  <li>
                    al buono stato o al buon potenziale ecologico di corpi
                    idrici, comprese le acque di superficie e sotterranee; o
                  </li>
                  <li>al buono stato ecologico delle acque marine;</li>
                </ol>
                <li>
                  all’economia circolare, compresi la prevenzione e il
                  riciclaggio dei rifiuti, in quanto:
                </li>
                <ol type="a">
                  <li>
                    le attività non conducono a inefficienze significative
                    nell’uso dei materiali o nell’uso diretto o indiretto di
                    risorse naturali quali le fonti energetiche non rinnovabili,
                    le materie prime, le risorse idriche e il suolo, in una o
                    più fasi del ciclo di vita dei prodotti, anche in termini di
                    durabilità, riparabilità, possibilità di miglioramento,
                    riutilizzabilità o riciclabilità dei prodotti;
                  </li>
                  <li>
                    le attività non comportano un aumento significativo della
                    produzione, dell’incenerimento o dello smaltimento dei
                    rifiuti, ad eccezione dell’incenerimento di rifiuti
                    pericolosi non riciclabili; o
                  </li>
                  <li>
                    lo smaltimento a lungo termine dei rifiuti non potrebbe
                    causare un danno significativo e a lungo termine
                    all’ambiente;
                  </li>
                </ol>
                <li>
                  alla prevenzione e alla riduzione dell’inquinamento, in quanto
                  le attività non comportano un aumento significativo delle
                  emissioni di sostanze inquinanti nell’aria, nell’acqua o nel
                  suolo rispetto alla situazione esistente prima del suo avvio;
                  o
                </li>
                <li>
                  alla protezione e al ripristino della biodiversità e degli
                  ecosistemi, in quanto le attività:
                </li>
                <ol type="a">
                  <li>
                    non nuocciono in misura significativa alla buona condizione
                    e alla resilienza degli ecosistemi; o
                  </li>
                  <li>
                    non nuocciono allo stato di conservazione degli habitat e
                    delle specie, comprese quelli di interesse per l’Unione.
                  </li>
                </ol>
              </ol>
            </li>
            <p>
              Inoltre, le attività di orientamento svolte escludono attività
              relative alla ricerca cosiddetta "brown", in conformità alla
              Comunicazione della Commissione UE al 2021/C 58/01 "Orientamento
              tecnici sull'applicazione del principio DNSH".In particolare, sono
              escluse:
            </p>
            <ol type="a">
              <li>
                attività connesse ai combustibili fossili, compreso l'uso a
                valle;
              </li>
              <li>
                attività nell'ambito del sistema di scambio quote di emissione
                dell'UE (ETS) che generano emissioni di gas a effetto serra
                previste non inferiori ai pertinento parametri di riferimento;
              </li>
              <li>
                attività connesse alle discariche di rifiuti e agli impianti di
                trattamento meccanico biologico;
              </li>
              <li>
                attività nel cui ambito lo smaltimento a lungo termine dei
                rifiuti potrebbero causare un danno all'ambiente;
              </li>
            </ol>
            <li class="my-2">
              Al fine di adempiere alle verifiche di cui al punto 1, sono state
              seguite le indicazioni contenute nell’Allegato alla Circolare MEF
              del 30 dicembre 2021, n. 32, denominato “Guida operativa per il
              rispetto del principio di non arrecare danno significativo
              all’ambiente (DNSH)” (di seguito “Guida DNSH”) e nell’Allegato
              alla Circolare MEF del 13 ottobre 2022, n.33, denominato
              “Aggiornamento Guida operativa per il rispetto del principio di
              non arrecare danno significativo all’ambiente (cd. DNSH)”, incluse
              quelle riguardanti il Regime associato alla misura a cui fa
              riferimento il progetto, le schede tecniche associate alla misura
              e le relative checklist;
            </li>
            <li class="my-2">
              Le attività svolte dall’Istituzione o dalla rete di Istituzioni
              sono conformi alla pertinente normativa ambientale dell’UE e
              nazionale.
            </li>
          </ol>
        </v-card-text>
      </v-card>
      <v-card-actions>
        <div class="d-flex my-6">
          <v-row>
            <v-col class="pa-2 mr-auto">
              <v-btn @click="goOutRendicontioSection">
                Torna al menù principale.
              </v-btn>
            </v-col>
            <v-col class="pa-2">
              <v-btn
                class="primary v-btn--outlined v-btn--tile white--text"
                v-html="'Salva Bozza e Continua'"
                @click="dialogConferma = true"
              />
              <dialog-conferma
                :dialog.sync="dialogConferma"
                @callback="salvaBozzaInserimentoRendiconto"
                @close-dialog="dialogConferma = false"
              />
            </v-col>
            <v-col>
              <v-btn v-html="'Continua senza salvare'" @click="goToNextStep" />
            </v-col>
          </v-row>
          <v-snackbar
            :color="snackbar.color"
            v-model="snackbar.value"
            max-width="200"
            max-height="200"
          >
            {{ snackbar.text }}</v-snackbar
          >
        </div>
      </v-card-actions>
    </v-main>
    <v-main v-else>
      <v-progress-linear indeterminate color="blue" />
    </v-main>
  </v-container>
</template>
<script>
import StepRendicontazioni from "@/components/Rendiconti/StepRendicontazioni";
import DialogConferma from "@/components/DialogConferma";
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import RendicontiRepository from "@/api/istituto/RendicontiRepository";
import RichiesteRepository from "@/api/istituto/RichiesteRepository";
import CodiceFiscale from "codice-fiscale-js";
import ScuoleAccordoRepository from "@/api/istituto/ScuoleAccordoRepository";

export default {
  name: "dsanDnshRendiconto",
  components: { StepRendicontazioni, DialogConferma },
  props: {
    capofila: {
      type: String,
      required: true,
    },
    operazione: {
      type: String,
      required: true,
      default: "modifica",
    },
  },
  computed: {
    ...mapState(["idStrutture", "anno", "user", "idRendiconto"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleConsulenza",
      "isRoleLegale",
    ]),
  },
  watch: {
    idStrutture() {
      return this.initialize();
    },
    anno() {
      return this.initialize();
    },
  },
  data: () => ({
    datiStruttura: null,
    datiAfam: null,
    struttura: null,
    loadingPage: false,
    rendiconto: null,
    legaleRappresentante: null,
    accordo: null,
    formDsanDnshRendiconto: {
      currentStep: null,
    },
    soggettoAttuatore: {
      id: 0,
      nominativo: null,
      cf: null,
      piva: null,
      comuneSede: null,
      capSede: null,
      pec: null,
    },
    formDichiarazioneRendiconto: {
      codiceIban: "",
      contoTesoreria: "",
      currentStep: 2,
    },
    snackbar: {
      color: null,
      value: false,
      text: null,
    },
    dialogConferma: false,
    validForm: false,
    richiestaAnticipo: null,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.idRendiconto && this.idStrutture && this.anno) {
        this.getSingoloRendiconto();
        this.getDatiStruttura();
      }
    },
    async getSingoloRendiconto() {
      this.loadingPage = true;
      this.rendiconto = await RendicontiRepository.getSingoloRendiconto(
        this.idStrutture,
        this.idRendiconto
      );
      if (this.rendiconto) {
        await this.getAccordi();
        await this.getLegaleRappresentanteAttivo();
      }
      this.loadingPage = false;
    },
    async getAccordi() {
      let accordi = await ScuoleAccordoRepository.getScuoleAccordi(
        this.idStrutture,
        this.anno,
        this.capofila
      );
      this.accordo = accordi[0];
      this.soggettoAttuatore.cf = this.accordo.pIvaStruttura;
      this.soggettoAttuatore.comuneSede = this.accordo.cittaStruttura;
    },
    async getLegaleRappresentanteAttivo() {
      const idProgrammaOrientamento = this.rendiconto.programma.id;
      const legaleRappresentante =
        await RichiesteRepository.getLegaleRappresentante(
          this.idStrutture,
          idProgrammaOrientamento
        );
      if (legaleRappresentante) {
        this.getDatiLegaleRappresentante(legaleRappresentante);
      }
    },
    getDatiLegaleRappresentante(legaleRappresentante) {
      let datiMancantiLegale = null;
      try {
        datiMancantiLegale = new CodiceFiscale(
          legaleRappresentante.codiceFiscaleRappresentante
        );
        // eslint-disable-next-line no-empty
      } catch (err) {}

      if (datiMancantiLegale) {
        let comuneNascita = datiMancantiLegale.birthplace.nome;
        let dataNascita = datiMancantiLegale.birthday;
        this.legaleRappresentante = legaleRappresentante;
        this.legaleRappresentante.comuneNascita = comuneNascita;
        this.legaleRappresentante.dataNascita = new Date(
          dataNascita
        ).toLocaleDateString();
      } else {
        this.legaleRappresentante = legaleRappresentante;
      }
    },
    async salvaBozzaInserimentoRendiconto() {
      const formRendiconto = this.formDsanDnshRendiconto;
      formRendiconto.currentStep = 4;
      const formData = new FormData();
      formData.append(
        "rendiconto",
        JSON.stringify(this.formDsanDnshRendiconto)
      );
      RendicontiRepository.modificaRendiconto(
        this.idStrutture,
        this.idRendiconto,
        4,
        formData
      ).then((data) => {
        if (data) {
          this.goToNextStep();
        }
      });
    },
    goToNextStep() {
      this.$router.push({
        name: "documentazioneAttivitaRendiconto",
        params: { operazione: this.operazione, capofila: this.capofila },
      });
    },
    goOutRendicontioSection() {
      this.$store.commit("setIdRendiconto", null);
      if (!this.idRendiconto) {
        this.$router.push({
          name: "RendicontiView",
          params: {
            capofila: this.capofila,
          },
        });
      }
    },
    setPropertySnackbar(esito, testo) {
      this.snackbar.color = esito ? "success" : "red accent-2";
      this.snackbar.text = testo;
      this.snackbar.value = true;
    },
    async getDatiStruttura() {
      let datiStruttura = await RendicontiRepository.getDatiStruttura(
        this.idStrutture
      );
      this.datiStruttura = Array.isArray(datiStruttura)
        ? datiStruttura[0]
        : datiStruttura;
    },
  },
};
</script>
<style>
.testo {
  font-size: large;
}
.sottotitolo {
  color: #1a237e;
  margin-left: 1%;
  font-weight: bold;
}
</style>
