import { render, staticRenderFns } from "./SelezionaStruttura.vue?vue&type=template&id=4e7d09a0&scoped=true&"
import script from "./SelezionaStruttura.vue?vue&type=script&lang=js&"
export * from "./SelezionaStruttura.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e7d09a0",
  null
  
)

export default component.exports