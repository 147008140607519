var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(_vm.isPublicPage())?_c('v-card',[_c('v-main',[_c('v-container',{staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('router-view',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$route.params.toString())}})])],1)],1)],1):(
      _vm.$store.getters.isRoleLegale ||
      _vm.$store.getters.isRoleReferenteProgramma ||
      _vm.$store.getters.isRoleConsulenza ||
      _vm.$store.getters.isRoleMinistero ||
      _vm.$store.getters.isRoleIstituto ||
      _vm.$store.getters.isRoleReferenteIstituto ||
      _vm.$store.getters.isRoleReferenteCorsi
    )?_c('v-card',{staticClass:"overflow-hidden o-card-drawer"},[_c('v-navigation-drawer',{attrs:{"absolute":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('NavigationDrawer')],1),_c('v-app-bar',{attrs:{"color":"#06c","dark":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',[_vm._v("Orientamento 2026 - PNRR")]),_c('v-img',{staticClass:"ml-3",attrs:{"max-width":"170px","src":require('@/assets/logo-orientamento-2026.png')}}),_c('v-spacer'),(
          _vm.$store.getters.isRoleMinistero ||
          _vm.$store.getters.isRoleConsulenza ||
          _vm.$store.getters.isRoleLegale ||
          _vm.$store.getters.isRoleLegaleProgramma ||
          _vm.$store.getters.isRoleLegaleCorso
        )?_c('SelezionaStruttura'):_vm._e(),_c('SelezionaAnnoAccademico'),_c('AbilitaScritturaConsulenza'),_c('ChipRole'),_c('InfoPersona')],1),_c('v-main',[_c('AlertIdStrutture',{staticClass:"mx-3 my-3"}),_c('v-container',{staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('router-view')],1)],1),_c('v-footer',{attrs:{"app":""}},[_c('footer-component')],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }