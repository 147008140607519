<template>
  <v-container v-if="idStrutture !== null" fluid>
    <v-row>
      <v-col>
        <h2>Proposta di Convenzione</h2>
        Gli atenei e gli istituti AFAM possono attuare congiuntamente, ai sensi
        dell'art.5, comma 3 del d.m. 934/2022, i corsi di orientamento
        attraverso la creazione di una rete e la sottoscrizione di un'apposita
        convenzione da trasmettere al Ministero attraverso il portale.
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        Non è possibile inserire cifre maggiori del target accettato.
      </v-col>
    </v-row>

    <ConvenzioniTargetComponent
      :partner="false"
      :finestra-compilazione="finestraCompilazione"
      @convenzione="convenzionePresente"
      class="mt-5"
    />

    <ConvenzioniTargetComponent
      :partner="true"
      :finestra-compilazione="finestraCompilazione"
      class="mt-5"
      @add-convenzione="refreshListaComponent"
    />

    <UploadAllegato v-if="convenzione" :convenzione="convenzione" />

    <lista-component class="mt-5" tipologia="capofila" :key="keyLista" />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import ListaComponent from "@/components/Convenzioni/ListaComponent";
import ConvenzioniTargetComponent from "@/components/Convenzioni/ConvenzioniTargetComponent";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";
import UploadAllegato from "@/components/Convenzioni/UploadAllegato";

export default {
  name: "CapofilaComponent",
  components: {
    UploadAllegato,
    ConvenzioniTargetComponent,
    ListaComponent,
  },
  props: {},
  data: () => ({
    keyLista: 0,
    finestraCompilazione: null,
    convenzione: false,
  }),
  computed: {
    ...mapState(["idStrutture", "anno"]),
  },
  created() {
    this.initialize();
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        this.getFinestraCompilazione();
      }
    },
    refreshListaComponent() {
      this.keyLista++;
    },
    async getFinestraCompilazione() {
      const finestraCompilazione =
        await IstitutoRepository.getFinestraCompilazione(
          this.idStrutture,
          this.anno,
          "convenzione-ist"
        );
      if (finestraCompilazione !== "") {
        this.finestraCompilazione = finestraCompilazione;
      }
    },
    convenzionePresente(convenzione) {
      this.convenzione = convenzione;
    },
  },
};
</script>

<style></style>
