<template>
  <v-container>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" v-if="capofila" />
    <v-row class="mb-4">
      <v-col>
        <h1>Lista delle richieste di anticipo e saldo</h1>
      </v-col>
    </v-row>
    <div class="pa-4" style="background-color: gainsboro">
      <v-row class="pb-4">
        <v-col>
          <h4>Anno Scolastico {{ this.anno }}/{{ this.anno + 1 }}</h4>
        </v-col>
      </v-row>
      <v-card
        elevation="2"
        class="px-4 pb-4"
        v-if="idStrutture !== null"
        :loading="loading"
      >
        <v-row class="mt-2">
          <v-col>
            <h4 v-if="this.capofila === 'istituzione'">Richieste</h4>
            <h4 v-else class="font-weight-bold">
              Richieste in convenzione da <u>Capofila</u>
            </h4>
          </v-col>
        </v-row>

        <div class="mb-6">
          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                :items="richieste"
                :items-per-page="5"
                class="elevation-1"
                ><!-- eslint-disable -->
                <template v-slot:item.stato="{ item }">
                  <span v-if="item.dataInvio">Inviato</span>
                </template>
                <template v-slot:item.dataInvio="{ item }">
                  <span>{{item.dataInvio | formatDate}}</span>
                </template>
                <template v-slot:item.importo="{ item }">
                  <span>{{item.importo | formatNumber}} €</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <a @click="getFileAllegato(item)">
                    <v-icon>mdi-paperclip</v-icon>
                  </a>
                </template>
                <!-- eslint-enable -->
              </v-data-table>
            </v-col>
          </v-row>
        </div>
        <div
          class="mt-6"
          v-if="inserimento && finestraCompilazione && !btnNewRequestDisabled"
        >
          <router-link
            class="v-btn primary pa-2"
            :to="`/scrivania/${capofila}/richieste-anticipo-saldo/inserisci`"
          >
            <v-icon left class="white--text"> mdi-plus </v-icon> Nuova richiesta
          </router-link>
        </div>
        <div v-else>
          <span>
            Impossibile accedere alla pagina di creazione della richiesta di
            anticipo e saldo per i seguenti motivi.</span
          >
          <ul>
            <li v-if="!inserimento">
              <v-alert type="warning" class="ma-1 pa-1">
                Non hai i permessi necessari per accedere alla creazione della
                richiesta di anticipo</v-alert
              >
            </li>
            <li v-if="!finestraCompilazione">
              <v-alert class="ma-1 pa-1" type="warning">
                Finestra di compilaizone non attiva.
              </v-alert>
            </li>
            <li v-if="btnNewRequestDisabled">
              <v-alert
                type="warning"
                class="ma-1 pa-1"
                v-if="btnNewRequestDisabled"
              >
                Una richiesta di anticipo e saldo è stata già
                presentata.</v-alert
              >
            </li>
          </ul>
        </div>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";
import RichiesteRepository from "@/api/istituto/RichiesteRepository";

export default {
  name: "RichiesteAnticipoSaldoView",
  components: { BreadcrumbComponent },
  data: () => ({
    idUtente: 0,
    loadingRichieste: false,
    loadingRichiesteDiCuiPartner: false,
    inserimento: true,
    isDisabled: true,
    isCapofila: false,
    richieste: [],
    finestraCompilazione: null,
    btnNewRequestDisabled: true,
    headers: [
      {
        text: "ID richiesta",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Data richiesta", value: "dataInvio" },
      { text: "importo richiesto", value: "importo" },
      {
        text: "Importo approvato",
        value: "",
      },
      { text: "Stato", value: "stato", sortable: false },
      { text: "Tipologia Erogazione", value: "idTipoErogazione.descrizione" },
      { text: "Data erogazione", value: "" },
      { text: "Allegato", value: "actions", sortable: false },
    ],
  }),
  props: {
    capofila: {
      type: String,
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["idStrutture", "anno", "user"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleConsulenza",
      "isRoleLegale",
    ]),
    loading: function () {
      return this.loadingRichieste;
    },
    breadcrumbs: function () {
      if (this.capofila) {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania/${this.capofila}`,
            exact: true,
            link: true,
          },
          {
            text: "Richiesta anticipo e saldo",
            disabled: true,
            to: `/scrivania/${this.capofila}/richieste-anticipo-saldo`,
            exact: true,
            link: true,
          },
        ];
      }
      return [];
    },
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        this.getRichieste();
        this.getFinestraCompilazione();
        this.inserimento = this.isRoleLegale || this.isRoleConsulenza;
      }
    },
    async getRichieste() {
      this.loadingRichieste = true;
      this.richieste = await RichiesteRepository.getListaRichieste(
        this.idStrutture,
        this.anno,
        this.capofila
      );
      this.btnNewRequestDisabled = this.richieste.length > 0;
      this.loadingRichieste = false;
    },
    async getFinestraCompilazione() {
      const finestraCompilazione =
        await IstitutoRepository.getFinestraCompilazione(
          this.idStrutture,
          this.anno,
          "richieste-ist"
        );
      if (finestraCompilazione !== "") {
        this.finestraCompilazione = finestraCompilazione;
      }
    },
    async getFileAllegato(richiesta) {
      this.loadingProgrammi = true;
      await RichiesteRepository.getFileAllegato(
        this.idStrutture,
        richiesta.id,
        richiesta.idFile.id,
        richiesta.idFile.nomeOriginale
      );
      this.loadingProgrammi = false;
    },
  },
};
</script>

<style scoped></style>
