<template>
  <v-container>
    <v-main class="pa-6" v-if="finestraCompilazione">
      <h2 class="py-6">Firma dichiarazione dei flussi finanziari</h2>
      <div class="py-6">
        <StepDichiarazioneFlussiFinanziari :numero-step="2" />
      </div>
      <v-progress-linear v-if="!loadingPage" indeterminate color="blue" />
      <v-row class="pt-6">
        <v-col>
          <h3 class="py-6">
            Dichiarazione di tracciabilità dei flussi finanziari di cui agli
            artt. 3ss. della legge n°136 del 13 Agosto 2010 e SS.MM.II
          </h3>
        </v-col>
      </v-row>
    </v-main>
    <v-form ref="form" lazy-validation>
      <v-card flat outlined>
        <div class="d-flex">
          <v-main class="pa-2 order-1">
            <v-row>
              <span>
                <v-icon size="150" color="blue-grey lighten-5">
                  mdi-check-circle
                </v-icon>
                Doc_da_firmare
              </span>
            </v-row>
            <v-row>
              <v-card-text>
                <h3 v-html="'Documento'" />
              </v-card-text>
              <v-card-text> Scarica la documentazione </v-card-text>
              <v-card-actions>
                <v-btn
                  class="primary"
                  v-html="'Scarica il Documento'"
                  @click="downloadTemplatePdf()"
                  :disabled="downloadInCorso"
                />
              </v-card-actions>
            </v-row>
            <br />
            <alert-component
              tipo-alert="warning"
              :testo="'La procedura potrebbe richiedere diversi secondi'"
              :width="400"
            />
          </v-main>
          <v-main class="pa-2 order-2">
            <v-row>
              <span>
                <v-icon
                  size="150"
                  :color="
                    dichiarazione.fileDocumentazioneFirmata
                      ? 'blue lighten-3'
                      : 'blue-grey lighten-5'
                  "
                >
                  mdi-check-circle
                </v-icon>
                {{
                  dichiarazione.fileDocumentazioneFirmata
                    ? "Documento caricato: " +
                      dichiarazione.fileDocumentazioneFirmata.nomeOriginale
                    : "Documento da caricare"
                }}
              </span>
            </v-row>
            <v-row>
              <v-card-text>
                <h3>
                  {{
                    dichiarazione.fileDocumentazioneFirmata
                      ? "Documento Caricato"
                      : "Documento Firmato"
                  }}
                </h3>
              </v-card-text>
              <v-card-text>
                Carica il documento firmato, in formato
                <b>.p7m o .pdf</b>
                per ultimare la procedura.
              </v-card-text>
              <v-card-text v-if="!dichiarazione.fileDocumentazioneFirmata">
                <v-file-input
                  v-model="form.uploadedFiles"
                  label="Documentazione dichiarazione firmata"
                  :rules="rules.fieldRequired"
                />
                <v-btn
                  class="primary v-btn--outlined v-btn--tile white--text"
                  v-html="'Carica il documento'"
                  @click="dialogConferma = true"
                />
                <dialog-conferma
                  :dialog.sync="dialogConferma"
                  @callback="caricaDocumentoDichiarazione"
                  @close-dialog="dialogConferma = false"
                />
              </v-card-text>
              <v-card-text v-else>
                <v-btn @click="dialogDeleteFile = true" class="border-danger">
                  <v-icon> mdi-delete</v-icon> Rimuovi il file</v-btn
                >
                <dialog-conferma
                  :dialog.sync="dialogDeleteFile"
                  @callback="eliminaFileCaricato"
                  @close-dialog="dialogDeleteFile = false"
                />
              </v-card-text>
            </v-row>
          </v-main>
          <v-main class="pa-2 order-4">
            <v-card-text
              v-if="dichiarazione.fileDocumentazioneFirmata"
              v-html="
                'File caricato: <br />' +
                dichiarazione.fileDocumentazioneFirmata.nomeOriginale
              "
            >
            </v-card-text>
          </v-main>
        </div>
        <div class="d-flex mt-8">
          <v-col
            v-if="!dichiarazione.fileDocumentazioneFirmata"
            cols="12"
            class="d-flex flex-row-reverse"
          >
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="grey" dark v-bind="attrs" v-on="on">
                  Trasmetti
                </v-btn>
              </template>
              <span
                >Non e' possibile procedere alla trasmissione dei dati della
                dichiarazione, <br />in quanto il documento firmato dal Legale
                Rappresentante non è stato caricato.</span
              >
            </v-tooltip>
          </v-col>
          <v-col v-else cols="12" class="d-flex flex-row-reverse">
            <v-btn
              v-html="'Trasmetti'"
              class="primary"
              @click="dialogNextStep = true"
            />
          </v-col>
          <dialog-conferma
            text-conferma="Procedere con la trasmissione definitiva della dichiarazione?"
            @callback="trasmettiDichiarazione"
            @close-dialog="dialogNextStep = false"
            :dialog.sync="dialogNextStep"
          />
          <v-snackbar
            :color="snackbar.color"
            v-model="snackbar.value"
            max-width="800"
            max-height="800"
          >
            {{ snackbar.text }}</v-snackbar
          >
        </div>
      </v-card>
    </v-form>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import DialogConferma from "@/components/DialogConferma";
import AlertComponent from "@/components/Common/AlertComponent";
import StepDichiarazioneFlussiFinanziari from "@/components/TracciabilitaFlussiFinanziari/StepDichiarazioneFlussiFinanziari.vue";
import DichiarazioneFlussiRepository from "@/api/istituto/DichiarazioneFlussiRepository";

export default {
  name: "firmaDocumentazioneDichiarazioneView",
  components: {
    StepDichiarazioneFlussiFinanziari,
    AlertComponent,
    DialogConferma,
  },
  props: {
    idDichiarazione: {
      type: Number,
      required: true,
    },
    capofila: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(["idStrutture", "anno", "user"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleConsulenza",
      "isRoleLegale",
    ]),
  },
  watch: {
    idStrutture() {
      return this.initialize();
    },
    anno() {
      return this.initialize();
    },
  },
  data: () => ({
    finestraCompilazione: true,
    loadingPage: false,
    dichiarazione: null,
    aggiornamentoFile: false,
    statoVerificaCaricamentoPdf: 0,
    form: {
      uploadedFiles: null,
    },
    rules: {
      fieldRequired: [
        (v) =>
          !!v ||
          "E' richiesto necessariamente l'upload del documento in formato .p7m e dunque firmato",
      ],
    },
    downloadedFiles: null,
    snackbar: { text: null, color: null, value: null },
    dialogConferma: false,
    dialogDeleteFile: false,
    dialogNextStep: false,
    downloadInCorso: false,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.capofila && this.idDichiarazione && this.idStrutture) {
        this.loadingPage = true;
        this.getDichiarazione();
      }
    },
    async getDichiarazione() {
      const idStrutture = this.idStrutture;
      this.dichiarazione =
        await DichiarazioneFlussiRepository.getSingolaDichiarazione(
          idStrutture,
          this.idDichiarazione
        );
      if (this.dichiarazione) {
        this.loadingPage = true;
      }
    },
    async downloadTemplatePdf() {
      this.downloadInCorso = true;
      const formBody = new FormData();
      formBody.append("dichiarazione", JSON.stringify(this.dichiarazione));
      await DichiarazioneFlussiRepository.downloadTemplatePdfDcoumentazioneDichiarazione(
        this.idStrutture,
        this.idDichiarazione,
        formBody
      )
        .then((data) => {
          this.downloadedFiles = data;
          this.downloadInCorso = false;
        })
        .catch(() => {
          this.downloadInCorso = false;
        });
    },
    async caricaDocumentoDichiarazione() {
      if (this.$refs.form.validate()) {
        const formBody = new FormData();
        formBody.append("documentazioneDichiarazione", this.form.uploadedFiles);

        try {
          await DichiarazioneFlussiRepository.uploadFile(
            this.idStrutture,
            this.idDichiarazione,
            formBody
          );
          // intercetto l'evento con cui verifico che l'utente ha caricato un template pdf
          this.aggiornamentoFile = true;
          this.loadingPage = false;
          this.initialize();
        } catch (reason) {
          if (reason.response.status === 415) {
            this.setPropertySnackbar(false, reason.response.data[0]);
          } else if (reason.response.status === 400) {
            this.setPropertySnackbar(
              false,
              "Non è stato caricato alcun file. Errore nella richiesta"
            );
          }
        }
      } else {
        this.setPropertySnackbar(
          false,
          "Verificare la compilazione dei campi richiesti"
        );
      }
    },
    async trasmettiDichiarazione() {
      try {
        await DichiarazioneFlussiRepository.trasmettiDichiarazione(
          this.idStrutture,
          this.idDichiarazione
        );
        this.setPropertySnackbar(true, "Dichiarazione trasmessa con successo");
        setTimeout(() => this.goToRecap(), 2000);
      } catch (reason) {
        this.setPropertySnackbar(
          false,
          "Errore durante l'invio della richiesta."
        );
      }
    },
    setPropertySnackbar(esito, testo) {
      this.snackbar.color = esito ? "success" : "red accent-2";
      this.snackbar.text = testo;
      this.snackbar.value = true;
    },
    async eliminaFileCaricato() {
      await DichiarazioneFlussiRepository.eliminaFileCaricato(
        this.idStrutture,
        this.idDichiarazione
      ).then(() => {
        this.form.uploadedFiles = null;
        this.initialize();
      });
    },
    goToRecap() {
      this.$router.push({
        name: "DichiarazioniTracciabilitaView",
        params: { capofila: this.capofila },
      });
    },
  },
};
</script>
<style scoped>
.v-file-input {
  width: 60%;
}
.sottotitolo {
  color: #1a237e;
  margin-left: 1%;
  font-weight: bold;
}
</style>
