<template>
  <v-alert v-if="showAlert" type="warning">
    Attenzione! È necessario selezionare un istituto.
  </v-alert>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AlertIdStrutture",
  computed: {
    ...mapState(["idStrutture"]),
  },
  data() {
    return {
      showAlert: false,
    };
  },
  watch: {
    idStrutture(newIdStrutture) {
      this.showAlert =
        newIdStrutture === null || this.idStrutture === undefined;
    },
  },
  created() {
    this.showAlert = !this.existIdStrutture;
  },
  mounted() {
    this.showAlert = !this.existIdStrutture;
  },
  methods: {
    existIdStrutture() {
      return this.idStrutture != null;
    },
  },
};
</script>

<style scoped></style>
