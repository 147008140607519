<template>
  <div class="mt-10">
    <v-card
      v-if="valoriAssegnazione"
      elevation="8"
      class="mx-auto"
      max-width="800px"
      :loading="loadingAssegnazione"
    >
      <v-container fluid>
        <v-row justify="center">
          <v-col cols="12">
            <h2>Assegnazione finanziaria definitiva</h2>
            <v-card elevation="2" class="mx-auto mt-2 pa-5" max-width="800px">
              <v-row justify="center">
                <v-col cols="12">
                  <h4 class="mb-4">
                    Importo assegnato:
                    <span v-if="valoriAssegnazione.importoAssegnato">
                      {{
                        new Intl.NumberFormat("de-DE", {
                          style: "currency",
                          currency: "EUR",
                        }).format(valoriAssegnazione.importoAssegnato)
                      }}
                    </span>
                    <span v-else> 0 </span>
                  </h4>
                  <h4 class="mb-4">
                    di cui in convenzione:
                    <span v-if="valoriAssegnazione.inConvenzione">
                      {{
                        new Intl.NumberFormat("de-DE", {
                          style: "currency",
                          currency: "EUR",
                        }).format(valoriAssegnazione.inConvenzione)
                      }}
                    </span>
                    <span v-else> 0 </span>
                  </h4>
                  <h4>
                    di cui per un programma singolo:
                    <span v-if="valoriAssegnazione.perProgrammaSingolo">
                      {{
                        new Intl.NumberFormat("de-DE", {
                          style: "currency",
                          currency: "EUR",
                        }).format(valoriAssegnazione.perProgrammaSingolo)
                      }}
                    </span>
                    <span v-else> 0 </span>
                  </h4>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";

export default {
  name: "AssegnazioneFinanziariaDefinitiva",
  data: () => ({
    loadingAssegnazione: true,
    valoriAssegnazioneDefault: null,
    valoriAssegnazione: null,
  }),
  computed: {
    ...mapState(["idStrutture", "anno"]),
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.idStrutture != null) {
        this.loadingAssegnazione = true;
        this.valoriAssegnazione = { ...this.valoriAssegnazioneDefault };
        this.getAssegnazioneFinanziariaDefinitiva();

        this.loadingAssegnazione = false;
      }
    },
    async getAssegnazioneFinanziariaDefinitiva() {
      this.valoriAssegnazione =
        await IstitutoRepository.getAssegnazioneFinanziariaDefinitiva(
          this.idStrutture,
          this.anno
        );
    },
  },
};
</script>
