<template>
  <v-container>
    <h1>Profilo Utente</h1>
    <v-row v-if="scrivaniaViewMode">
      <v-col cols="6" v-if="isCardIstitutoVisibile">
        <v-card elevation="4">
          <v-card-title>
            <v-icon class="mr-2">mdi-school</v-icon>
            Profilo Università/AFAM
          </v-card-title>
          <v-card-text class="white--text">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam eaque ipsa,
            quae ab illo inventore veritatis et quasi architecto beatae vitae
            dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas
            sit, aspernatur aut odit aut fugit, sed quia consequuntur magni
            dolores eos, qui ratione voluptatem sequi nesciunt, neque porro
            quisquam est, qui dolorem ipsum, quia dolor sit, amet, consectetur,
            adipisci velit, sed quia non numquam eius modi tempora incidunt, ut
            labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad
            minima veniam, quis nostrum exercitationem ullam corporis suscipit
            laboriosam, nisi ut aliquid ex ea commodi consequatur?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <router-link class="v-btn" to="/scrivania/istituzione">
              Accedi <v-icon>mdi-arrow-right-thin</v-icon>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="6" v-if="isCardCapofilaVisibile">
        <v-card elevation="8">
          <v-card-title>
            <v-icon class="mr-2">mdi-account-group</v-icon>
            Profilo capofila
          </v-card-title>
          <v-card-text class="white--text">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam eaque ipsa,
            quae ab illo inventore veritatis et quasi architecto beatae vitae
            dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas
            sit, aspernatur aut odit aut fugit, sed quia consequuntur magni
            dolores eos, qui ratione voluptatem sequi nesciunt, neque porro
            quisquam est, qui dolorem ipsum, quia dolor sit, amet, consectetur,
            adipisci velit, sed quia non numquam eius modi tempora incidunt, ut
            labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad
            minima veniam, quis nostrum exercitationem ullam corporis suscipit
            laboriosam, nisi ut aliquid ex ea commodi consequatur?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <router-link class="v-btn" disabled to="/scrivania/capofila">
              Accedi <v-icon>mdi-arrow-right-thin</v-icon>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ProfiloUtenteView",
  computed: {
    ...mapState(["scrivaniaViewMode"]),
    /**
     * Metodo che restituisce:
     * - 0: se non ha accettato i target;
     * - 1: se è un partner che ha assegnato tutto alla convenzione;
     * - 2: se è un partner che ha assegnato parte alla convenzione oppure non è partner di convenzioni;
     * - 3: se è un capofila che ha assegnato tutto alla convenzione;
     * - 4: se è un capofila che ha assegnato parte alla convenzione;
     */
    isCardCapofilaVisibile() {
      if (this.scrivaniaViewMode) {
        return [3, 4].includes(this.scrivaniaViewMode);
      }
      return false;
    },
    isCardIstitutoVisibile() {
      if (this.scrivaniaViewMode) {
        return [2, 4].includes(this.scrivaniaViewMode);
      }
      return false;
    },
  },
};
</script>

<style scoped></style>
