var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[(_vm.capofila)?_c('BreadcrumbComponent',{attrs:{"breadcrumbs":_vm.breadcrumbs}}):_vm._e(),_c('div',{staticClass:"mt-8"},[_c('AlertRichiesteRendiconti')],1),_c('v-row',{staticClass:"mb-4"},[_c('v-col',[_c('h1',[_vm._v("Creazione e trasmissione rendiconto di progetto")]),_c('h4',{staticClass:"grey--text"},[_vm._v(" Lista dei "),(this.capofila === 'istituzione')?_c('span',[_vm._v("Rendiconti")]):_c('span',{staticClass:"font-weight-bold"},[_vm._v(" Rendiconti in convenzione da "),_c('u',[_vm._v("Capofila")])]),_vm._v(" Anno Scolastico "+_vm._s(this.anno)+"/"+_vm._s(this.anno + 1)+" ")])])],1),_c('v-row',{staticClass:"my-4"},[_c('v-col',{attrs:{"col":"8"}},[_c('v-text-field',{attrs:{"solo":"","append-icon":"mdi-magnify","label":"Cerca per N° rendiconto","hide-details":""},model:{value:(_vm.ricerca),callback:function ($$v) {_vm.ricerca=$$v},expression:"ricerca"}})],1)],1),_c('br'),_c('alert-component',{attrs:{"tipo-alert":"info","testo":_vm.getTestoAlertRendiconto()}}),_c('v-row',{staticClass:"my-2"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"blue--text d-none",attrs:{"text":""}},[_c('v-icon',{staticClass:"mx-2"},[_vm._v(" mdi-microsoft-excel ")]),_vm._v(" Esporta Excel ")],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"blue--text d-none",attrs:{"text":""}},[_c('v-icon',{staticClass:"mx-2"},[_vm._v(" mdi-file-pdf-box ")]),_vm._v(" Esporta PDF ")],1)],1),_c('v-col',{attrs:{"cols":"4"}}),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"4"}},[(_vm.finestraCompilazione)?[(!_vm.controlloAccessoCompilazioneRendiconto(1, null))?_c('div',{staticClass:"ml-auto"},[(_vm.isRoleLegale || _vm.isRoleConsulenza)?_c('router-link',{staticClass:"v-btn primary pa-2",attrs:{"to":{
              name: 'InserimentoRendiconto',
              params: {
                capofila: _vm.capofila,
                operazione: 'inserimento',
                quadrimestre: _vm.finestraCompilazione,
              },
            }}},[_c('v-icon',{staticClass:"white--text",attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Nuovo rendiconto ")],1):_c('span',[_c('v-alert',{attrs:{"type":"info"},domProps:{"innerHTML":_vm._s('Operazione consentita solo ai legali rappresentanti.')}})],1)],1):_c('div',[_c('v-alert',{attrs:{"type":"info"},domProps:{"innerHTML":_vm._s(
              'È possibile inserire un solo rendiconto per periodo di riferimento.'
            )}})],1)]:[_c('small',{staticClass:"red--text"},[_vm._v("La compilazione dei rendinconti è attualmente chiusa")])]],2)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.rendiconti,"search":_vm.ricerca,"items-per-page":10,"loading":_vm.loadingRendiconti,"loading-text":"Caricamento dati in corso..."},scopedSlots:_vm._u([{key:"item.stato",fn:function({ item }){return [_c('v-chip',{attrs:{"outlined":"","pill":"","color":_vm.colorState(item)}},[_vm._v(" "+_vm._s(item.stato.descrizione)+" ")])]}},{key:"item.quadrimestre",fn:function({ item }){return [_vm._v(" "+_vm._s(item.quadrimestre.tipologiaQuadrimestre.descrizione)+" ")]}},{key:"item.controlli",fn:function({ item }){return [(item.avanzamentoControllo)?_c('span',[_vm._v(" "+_vm._s(item.avanzamentoControllo.id)+"/4 - "+_vm._s(item.avanzamentoControllo.descrizione))]):_vm._e()]}},{key:"item.dataTrasmissione",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.dataTrasmissione)))])]}},{key:"item.importoRichiesto",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(item.importoRichiesto))+" €")])]}},{key:"item.invioRegis",fn:function({ item }){return [(item.inviatoRegis === 1)?_c('span',[_vm._v("Si")]):_c('span',[_vm._v("No")])]}},{key:"item.importoAmmesso",fn:function({ item }){return [(item.importoAmmesso)?_c('span',[_vm._v(" "+_vm._s(item.importoAmmesso)+" €")]):_vm._e()]}},{key:"item.actions",fn:function({ item }){return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"v-icon",attrs:{"icon":true}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.menuAction),function(itemMenu,i){return _c('v-list-item',{key:i,staticClass:"item-menu",attrs:{"disabled":_vm.controlloDisabilitazione(
                    i,
                    item
                  )}},[_c('router-link',{staticClass:"v-btn",attrs:{"to":{
                        name: itemMenu.nameComponent,
                        params: {
                          capofila: _vm.capofila,
                          idRendiconto: item.id,
                        },
                      }}},[_vm._v(" "+_vm._s(itemMenu.text)+" "),_c('v-icon',[_vm._v(" "+_vm._s(itemMenu.icon))])],1)],1)}),1)],1),(_vm.isRoleLegale || _vm.isRoleConsulenza)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","disabled":_vm.controlloAccessoCompilazioneRendiconto(2, item)},on:{"click":function($event){return _vm.goToRendicontoView(item.id)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.isRoleLegale || _vm.isRoleConsulenza)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","disabled":_vm.controlloAccessoCompilazioneRendiconto(2, item)},on:{"click":function($event){return _vm.showDialogDeleteRendiconto(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])}),_c('dialog-conferma',{attrs:{"dialog":_vm.dialogDeleteRendiconto},on:{"update:dialog":function($event){_vm.dialogDeleteRendiconto=$event},"callback":function($event){return _vm.deleteRendiconto(_vm.rendicontoToDelete.id)},"close-dialog":function($event){return _vm.closeDialogDeleteRendiconto()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }