<template>
  <v-container>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" v-if="capofila" />
    <v-row class="mb-4">
      <v-col>
        <h1>Download verbale di esito</h1>
        <h4 class="grey--text">
          È in corso il download del verbale di esito del rendiconto id
          {{ idRendiconto }}
          - Anno Scolastico
          {{ this.anno }}/{{ this.anno + 1 }}
        </h4>
      </v-col>
    </v-row>
    <v-row class="my-0">
      <v-col class="my-0">
        <v-card :loading="loadingDettaglio">
          <v-row>
            <v-col cols="4" sm="4" md="4">
              <div class="ma-5">
                <a @click="getFileEistoVerifica(verifica)">
                  <v-icon>mdi-file-download-outline</v-icon> Scarica il file
                  file manualmente
                </a>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="mt-4">
          <v-btn text class="blue--text" @click="$router.go(-1)">
            <v-icon>mdi-arrow-left-thin</v-icon>Torna a trasmissione rendiconto
            di progetto
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import RendicontiRepository from "@/api/istituto/RendicontiRepository";

export default {
  name: "downloadDettaglioVerbali",
  components: { BreadcrumbComponent },
  data: () => ({
    loadingDettaglio: true,
    verifica: null,
    rendiconto: null,
  }),
  props: {
    capofila: {
      type: String,
      required: true,
    },
    idRendiconto: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["idStrutture", "anno", "user"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleConsulenza",
      "isRoleLegale",
    ]),
    loading: function () {
      return this.loadingDettaglio;
    },
    breadcrumbs: function () {
      if (this.capofila) {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania/${this.capofila}`,
            exact: true,
            link: true,
          },
          {
            text: "Creazione e trasmissione rendiconto di progetto",
            disabled: false,
            to: `/scrivania/${this.capofila}/rendiconti`,
            exact: true,
            link: true,
          },
          {
            text: "Dettaglio verbale esito",
            disabled: true,
            to: `/scrivania/${this.capofila}/rendiconti/dettaglioIntegrazioniModifiche`,
            exact: true,
            link: true,
          },
        ];
      }
      return [];
    },
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        this.getRendiconto(this.idStrutture, this.idRendiconto);
      }
    },
    async getRendiconto(idStrutture, idRendiconto) {
      this.loadingDettaglio = true;
      this.rendiconto = await RendicontiRepository.getSingoloRendiconto(
        idStrutture,
        idRendiconto
      );
      this.verifica = this.rendiconto.verificaRendiconto;
      await this.getFileEistoVerifica(this.verifica);
      this.loadingDettaglio = false;
    },
    async getFileEistoVerifica(verifica) {
      console.log(verifica);
      await RendicontiRepository.getFileEistoVerifica(
        this.idStrutture,
        verifica.id,
        verifica.fileVerificaRendiconto.nomeOriginale
      );
    },
  },
};
</script>

<style scoped></style>
