<template>
  <v-menu transition="slide-y-transition" bottom v-if="user && keycloak">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="purple" color="primary" dark v-bind="attrs" v-on="on">
        <v-icon>mdi-account</v-icon>
        <span>{{ user.name }}</span>
      </v-btn>
    </template>

    <v-list subheader>
      <v-list-item :href="keycloak.createAccountUrl()" target="_blank">
        <v-list-item-title>Account</v-list-item-title>
      </v-list-item>
      <v-list-item :href="keycloak.createLogoutUrl()">
        <v-list-item-title>Logout</v-list-item-title>
      </v-list-item>

      <template
        v-if="$store.getters.isRoleConsulenza || $store.getters.isRoleMinistero"
      >
        <v-subheader>Impersonificazione</v-subheader>

        <template v-if="$store.getters.isRoleConsulenza">
          <v-list-item :href="urlMinistero">
            <v-list-item-title>Vai a Ministero</v-list-item-title>
          </v-list-item>
          <v-list-item :href="urlConsulenza">
            <v-list-item-title>Ritorna a Consulenza</v-list-item-title>
          </v-list-item>
        </template>

        <v-list-item v-if="$store.getters.isRoleMinistero" :href="urlMinistero">
          <v-list-item-title>Ritorna a Ministero</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "InfoPersona",
  computed: {
    ...mapState(["user", "keycloak"]),
  },
  data() {
    return {
      urlMinistero: process.env.VUE_APP_MINISTERO_URL,
      urlConsulenza: process.env.VUE_APP_CONSULENZA_URL,
    };
  },
};
</script>

<style scoped></style>
