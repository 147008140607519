<template>
  <v-main>
    <div class="d-flex justify-center mb-6 bg-surface-variant">
      <v-sheet class="sheet sheetborder ma-2 pa-2">
        <v-card-text>
          <strong> N° alunni censiti </strong> <br />
          {{ infoRiepilogative.numeroStudenti }}
        </v-card-text>
      </v-sheet>
      <v-sheet class="sheet sheetborder ma-2 pa-2">
        <v-card-text>
          <strong> N° alunni con attestato </strong> <br />
          {{ infoRiepilogative.numeroStudentiConAttestato }}
        </v-card-text>
      </v-sheet>
      <v-sheet class="sheet sheetborder ma-2 pa-2">
        <v-card-text>
          <strong> N° ore totali alunni con attestato </strong> <br />
          {{
            infoRiepilogative.totaleOreLezioneStudentiConAttestato
          }}</v-card-text
        >
      </v-sheet>
      <v-sheet class="sheet sheetborder ma-2 pa-2">
        <v-card-text>
          <strong> Data prima lezione </strong> <br />
          {{ infoRiepilogative.primaLezione }}</v-card-text
        >
      </v-sheet>
      <v-sheet class="ma-2 pa-2 sheet sheetborder">
        <v-card-text>
          <strong> Data ultima lezione </strong> <br />
          {{ infoRiepilogative.ultimaLezione }}</v-card-text
        >
      </v-sheet>
      <v-sheet class="ma-2 pa-2 sheet">
        <v-card-text>
          <strong> Data Termine corso </strong> <br />
          {{ infoRiepilogative.dataTermineCorso }}
        </v-card-text>
      </v-sheet>
    </div>
  </v-main>
</template>
<script>
export default {
  name: "datiStudentiCorso",
  components: {},
  props: {
    lezioniCorso: {
      type: Array,
      required: true,
    },
    studentiCorso: {
      type: Array,
      required: true,
    },
    corso: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loadingComponent: false,
    infoRiepilogative: {
      primaLezione: null,
      ultimaLezione: null,
      dataTermineCorso: null,
      numeroStudenti: 0,
      numeroStudentiConAttestato: null,
      totaleOreLezioneStudentiConAttestato: null,
    },
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.lezioniCorso && this.lezioniCorso.length) {
        this.getInfoLezioni();
      }
      if (this.corso) {
        this.infoRiepilogative.dataTermineCorso = this.formatDate(
          this.corso.dataTermineCorso ?? null
        );
      }
      if (this.studentiCorso && this.studentiCorso.length) {
        this.getInfoStudenti();
      }
    },
    getInfoLezioni() {
      this.infoRiepilogative.primaLezione = this.formatDate(
        this.lezioniCorso[0].data ?? null
      );
      this.infoRiepilogative.ultimaLezione = this.formatDate(
        this.lezioniCorso[this.lezioniCorso.length - 1].data ?? null
      );
    },
    getInfoStudenti() {
      this.infoRiepilogative.numeroStudenti = this.studentiCorso.length;
      // recupero gli studenti con attestato
      let studentiConAttestato = this.getStudentiConAttestatoIndexed();
      let countStudentiConAttestato = Object.keys(studentiConAttestato).length;
      this.infoRiepilogative.numeroStudentiConAttestato =
        countStudentiConAttestato;
      if (countStudentiConAttestato) {
        this.infoRiepilogative.totaleOreLezioneStudentiConAttestato =
          this.calcolaOreSvolteStudentiConAttestato(studentiConAttestato);
      }
    },
    calcolaOreSvolteStudentiConAttestato(studentiConAttestato) {
      let sommaOreStudenteConAttestato = 0;
      this.lezioniCorso.forEach((lezione) => {
        if (lezione.lezioniStudenti && lezione.lezioniStudenti.length) {
          let studentiLezione = lezione.lezioniStudenti;
          studentiLezione.forEach((presenzaStudente) => {
            let cfStudente = presenzaStudente.studente.cf;
            if (studentiConAttestato[cfStudente]) {
              // lo studente ha l'attestato, calcolo la somma delle ore che ha fatto
              sommaOreStudenteConAttestato += presenzaStudente.oreSvolte ?? 0;
            }
          });
        }
      });
      return sommaOreStudenteConAttestato;
    },
    formatDate(data) {
      if (data) {
        return new Date(data).toLocaleDateString("it-IT");
      }
      return "";
    },
    getStudentiConAttestatoIndexed() {
      let studentiIndexed = {};
      let countStudentiConAttestato = 0;
      this.studentiCorso.forEach((element) => {
        if (element.idCertificato && element.dataFirmaAttestato) {
          studentiIndexed[element.cf] = element;
          countStudentiConAttestato += 1;
        }
      });
      this.infoRiepilogative.numeroStudentiConAttestato =
        countStudentiConAttestato;
      return studentiIndexed;
    },
  },
};
</script>
<style scoped>
.sheet {
  text-align: center;
}
.sheetborder {
  border-right: 2px solid black;
}
</style>
