<template>
  <v-card elevation="8" class="mx-auto mb-5 pb-6" max-width="80%">
    <v-tabs v-model="tab">
      <v-tab key="capofila">Convenzioni</v-tab>
      <v-tab key="partners">Convenzioni partners</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="capofila">
        <v-card>
          <capofila-component />
        </v-card>
      </v-tab-item>
      <v-tab-item key="parters">
        <v-card flat>
          <lista-component tipologia="partner" />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import CapofilaComponent from "@/components/Convenzioni/CapofilaComponent";
import ListaComponent from "@/components/Convenzioni/ListaComponent";

export default {
  name: "ConvenzioniView",
  components: {
    CapofilaComponent,
    ListaComponent,
  },
  data: () => ({
    tab: null,
  }),
};
</script>

<style scoped></style>
