<template>
  <v-container>
    <BreadcrumbComponent
      :breadcrumbs="breadcrumbs"
      v-if="capofila && !isPartner"
    />
    <v-btn class="primary" @click="$router.go(-1)" v-else>
      <v-icon>mdi-arrow-left-thin</v-icon>Torna indietro
    </v-btn>
    <v-card
      elevation="8"
      class="mx-auto mt-8 pb-6 px-6"
      v-if="hasProgramma && !invioBloccato"
      :loading="loadingForm"
    >
      <v-card-title class="h1"> Nuova richiesta di erogazione </v-card-title>
      <div class="pa-4">
        <h4>
          Inserisci i <b>dati</b> e i <b>file</b> necessari all'invio della
          richiesta
        </h4>
        <div class="my-4">
          <small> I campi contrassegnati con * sono obbligatori </small>
        </div>
      </div>
      <v-form ref="form">
        <div>
          <v-card elevation="8" class="mx-auto mt-8 px-6 pb-6">
            <v-card-title class="h1"> Riepilogo intervento </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Missione"
                    value="M4"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Componente"
                    value="C1"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Tipologia intervento"
                    value="A regia"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Intervento"
                    value="Orientamento attivo nella transizione scuola università"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
        <div>
          <v-card elevation="8" class="mx-auto mt-8 px-6 pb-6">
            <v-card-title class="h1">
              Riepilogo programma di orientamento
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    label="CUP"
                    v-model="programma.cup"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    label="Denominazione Università/AFAM"
                    v-model="programma.nomeIstituzione"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Data avvio del programma"
                    type="date"
                    v-model="programma.dataInizioProgramma"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Data di conclusione"
                    type="date"
                    v-model="programma.dataFineProgramma"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
        <div>
          <v-row class="mt-8">
            <v-col
              ><h5 class="text-uppercase">Tipologia erogazione *</h5></v-col
            >
          </v-row>
          <v-row>
            <v-col>
              <v-radio-group v-model="richiesta.idTipoErogazione" row disabled>
                <v-radio label="Anticipo iniziale" :value="1"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row class="mt-8">
            <v-col><h5 class="text-uppercase">inserimento importo</h5></v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-text-field
                label="Importo richiesto *"
                v-model.number="richiesta.importo"
                suffix="€"
                disabled
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Conto di tesoreria*"
                v-model="richiesta.ibanTesoreria"
                :readonly="readOnly"
                :disabled="/*disabilitaCampoBancario(2)*/ true"
              >
              </v-text-field>
              <!--<small v-if="!ibanInserito" class="mt-0 red--text"
                >Inserire almeno un numero di conto valido</small
              >-->
            </v-col>
            <v-col cols="1"> Oppure </v-col>
            <v-col cols="4">
              <v-text-field
                label="Conto corrente bancario*"
                v-model="richiesta.ibanContoBancario"
                counter="27"
                :readonly="readOnly"
                :disabled="/*disabilitaCampoBancario(1)*/ true"
              >
              </v-text-field>
              <!-- <small class="mt-0 red--text" v-if="!ibanInserito"
                >Inserire almeno un numero di conto valido</small
              >-->
            </v-col>
          </v-row>
        </div>
        <div>
          <v-card elevation="8" class="mx-auto mt-8 mb-8 px-6 pb-6">
            <v-card-title class="h1"> Dati rappresentante legale </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Nome rappresentante legale"
                    v-model="legaleRappresentante.nomeRappresentante"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Cognome rappresentante legale"
                    v-model="legaleRappresentante.cognomeRappresentante"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="CF rappresentante legale"
                    v-model="legaleRappresentante.codiceFiscaleRappresentante"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
        <div>
          <AttestazioneRichiestaAnticipo
            :legale-rappresentante="legaleRappresentante"
            :richiesta-anticipo="richiesta"
            :programma="programma"
          />
        </div>
        <div>
          <v-row class="mt-8 mb-8">
            <v-col cols="5">
              <v-row>
                <v-col
                  ><h5 class="text-uppercase">
                    Download richiesta PDF da firmare
                  </h5></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="4" sm="4" md="4">
                  <div class="mt-5">
                    <v-btn
                      outlined
                      :disabled="!controlloIbanPresente()"
                      @click="downloadPDF()"
                    >
                      <v-icon>mdi-file-download-outline</v-icon>
                      <a> Scarica il PDF </a>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="1"
              ><v-icon large>mdi-arrow-right-bold-circle-outline</v-icon>
            </v-col>
            <v-col cols="5">
              <v-row>
                <v-col
                  ><h5 class="text-uppercase">
                    Caricamento richiesta PDF firmata
                  </h5></v-col
                >
              </v-row>
              <v-row>
                <v-col>
                  <v-file-input
                    label="Carica il file in formato .pdf"
                    @change="uploadFile($event)"
                    :rules="validazioneCampoNecessario()"
                    @input="validateField"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col class="d-flex flex-row-reverse">
            <v-btn class="primary" @click="dialogSalva = true" v-if="!readOnly">
              Trasmetti richiesta
              <v-icon right class="white--text">
                mdi-email-fast-outline
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <dialog-conferma
        @callback="createRichiesta"
        :dialog.sync="dialogSalva"
        @close-dialog="dialogSalva = false"
      />

      <v-snackbar
        v-model="snackbar"
        :timeout="2000"
        shaped
        :color="snackbarColor"
      >
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
    <v-card elevation="8" class="mx-auto mt-8" v-else>
      <v-alert v-if="!hasProgramma" dense type="warning" class="mt-4">
        ATTENZIONE! per inviare una richiesta di anticipo o saldo è necessario
        avere un
        <strong>programma di orientamento attivo</strong>
      </v-alert>
      <v-alert v-if="invioBloccato" dense type="warning" class="mt-4">
        Una richiesta di anticipo e saldo è stata già presentata
      </v-alert>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DialogConferma from "@/components/DialogConferma";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";
import ProgrammiRepository from "@/api/istituto/ProgrammiRepository";
import RichiesteRepository from "@/api/istituto/RichiesteRepository";
import AttestazioneRichiestaAnticipo from "@/components/RichiesteAnticipoSaldo/AttestazioneRichiestaAnticipoSaldo.vue";
import CodiceFiscale from "codice-fiscale-js";

export default {
  name: "InserisciRichiestaAnticipoSaldoView",
  components: {
    AttestazioneRichiestaAnticipo,
    DialogConferma,
    BreadcrumbComponent,
  },
  props: {
    capofila: {
      type: String,
      required: true,
    },
    isPartner: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    invioBloccato: false,
    richiestaAnticipoPresentata: false,
    disableContoBancario: false,
    disableTesoreria: false,
    ibanInserito: false,
    hasProgramma: false,
    legaleRappresentante: {
      nomeRappresentante: null,
      cognomeRappresentante: null,
      codiceFiscaleRappresentante: null,
    },
    programma: {
      cup: null,
      idProgramma: null,
      nomeIstituzione: null,
      dataInizioProgramma: null,
      dataFineProgramma: null,
      assegnazioneFinanziaria: null,
    },
    tipiErogazione: null,
    richiesta: {
      idFile: null,
      dataInvio: null,
      ibanTesoreria: null,
      ibanContoBancario: null,
      idTipoErogazione: 1,
      importo: 0,
      legaleRappresentante: null,
    },
    richiestaPDF: {
      richiestaAnticipo: {
        dataInvio: null,
        ibanTesoreria: null,
        ibanContoBancario: null,
        idTipoErogazione: null,
        importo: 0,
      },
      programmaOrientamento: null,
      legaleRappresentante: null,
    },
    loadingForm: true,
    dialogSalva: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    validate: false,
    finestraCompilazione: null,
    datiBancari: null,
  }),
  computed: {
    ...mapState(["idStrutture", "anno"]),
    ...mapGetters(["isReadOnly"]),
    readOnly() {
      if (this.isReadOnly) return true;
      else if (!this.finestraCompilazione) {
        return true;
      } else if (!this.finestraCompilazione.attiva) {
        return true;
      }
      return false;
    },
    breadcrumbs: function () {
      if (this.capofila) {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania/${this.capofila}`,
            exact: true,
            link: true,
          },
          {
            text: "Richiesta anticipo e saldo",
            disabled: false,
            to: `/scrivania/${this.capofila}/richieste-anticipo-saldo`,
            exact: true,
            link: true,
          },
          {
            text: "Richiesta anticipo e saldo",
            disabled: true,
            to: `/scrivania/${this.capofila}/richieste-anticipo-saldo/inserisci`,
            exact: true,
            link: true,
          },
        ];
      }
      return [];
    },
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        this.loadingForm = true;
        this.getProgramma();
        this.getDatiBancariStruttura();
        this.getFinestraCompilazione();
        this.getRichiestaAnticipoPresentata();
        this.loadingForm = false;
      }
    },
    validateField() {
      this.validate = this.$refs.form.validate() && this.ibanInserito;
    },
    validazioneCampoNecessario() {
      return [(v) => !!v || "Campo richiesto"];
    },
    disabilitaCampoBancario(typeIban) {
      switch (typeIban) {
        case 1: // ibanBancario, disabilitato se l'iban tesoreria non è vuoto
          return (
            this.richiesta.ibanTesoreria != null &&
            this.richiesta.ibanTesoreria !== ""
          );

        case 2:
          // iban tesoreria disabilitato se  ibanBancario non è vuoto
          return (
            this.richiesta.ibanContoBancario != null &&
            this.richiesta.ibanContoBancario !== ""
          );
      }
    },

    controlloIbanPresente() {
      const contoTesoreria = this.richiesta.ibanTesoreria;
      const iban = this.richiesta.ibanContoBancario;

      if (contoTesoreria != null && contoTesoreria !== "") {
        this.richiesta.ibanTesoreria = contoTesoreria.toUpperCase();
        this.richiesta.ibanContoBancario = "";
        this.ibanInserito = true;
        return true;
      } else if (iban != null && iban !== "") {
        this.richiesta.ibanContoBancario = iban.toUpperCase();
        this.richiesta.ibanTesoreria = "";
        if (this.richiesta.ibanContoBancario.length !== 27) {
          return "Il campo prevede 27 caratteri totali da inserire.";
        }
        this.ibanInserito = true;
        return true;
      } else {
        this.ibanInserito = false;
        return "E' necessaria la compilazione di uno dei due conti bancari possibili";
      }
    },
    uploadFile(event) {
      this.file = event;
    },
    async createRichiesta() {
      this.validateField();
      if (this.validate) {
        let richiesta = { ...this.richiesta };

        // post file to server
        const formData = new FormData();
        formData.append("richiesta", JSON.stringify(richiesta));
        formData.append("file", this.file);

        try {
          await RichiesteRepository.createRichiestaAnticipoSaldo(
            this.idStrutture,
            this.anno,
            this.capofila,
            this.programma.id,
            formData
          );

          this.snackbarText = "Richiesta inviata con successo";

          this.snackbarColor = "success";
          this.validate = true;
          this.$router.go(-1);
        } catch (e) {
          console.log(e);
          if (e.response.status === 415) {
            this.snackbarText =
              "Errore! E' obbligatorio il caricamento di un file in formato .pdf";
            this.snackbarColor = "red accent-2";
          } else {
            this.snackbarText =
              "Problema durante il salvataggio, contattare il supporto se il problema persiste";
            this.snackbarColor = "red accent-2";
          }
        }
        this.snackbar = true;
      } else {
        this.snackbarText =
          "Errore. E' necessario assicurarsi che tutti i campi siano stati pre-compilati.";
        this.snackbarColor = "red accent-2";
        this.snackbar = true;
      }
    },
    async getProgramma() {
      const programma = await ProgrammiRepository.getProgrammi(
        this.idStrutture,
        this.anno,
        this.capofila
      );
      this.programma = programma[0];
      this.hasProgramma = !!this.programma;
      let dataInizioFormattata;
      dataInizioFormattata = this.programma.dataInizioProgramma.split("T");
      this.programma.dataInizioProgramma = dataInizioFormattata[0];
      let dataFineFormattata;
      dataFineFormattata = this.programma.dataFineProgramma.split("T");
      this.programma.dataFineProgramma = dataFineFormattata[0];
      this.richiesta.importo = parseFloat(
        (this.programma.assegnazioneFinanziaria / 100) * 10
      ).toFixed(2);
      await this.getLegaleRappresentanteAttivo();
    },
    async getLegaleRappresentanteAttivo() {
      let legaleRappresentante =
        await RichiesteRepository.getLegaleRappresentante(
          this.idStrutture,
          this.programma.id
        );
      const cfLegaleRappresentante =
        legaleRappresentante.codiceFiscaleRappresentante.trim();
      let altriDatiAnagrafici = null;
      try {
        altriDatiAnagrafici = new CodiceFiscale(cfLegaleRappresentante);
        legaleRappresentante.luogoNascita = altriDatiAnagrafici.birthplace.nome;
        legaleRappresentante.dataNascita = new Date(
          altriDatiAnagrafici.birthday
        ).toLocaleDateString();
        this.legaleRappresentante = legaleRappresentante;
      } catch (err) {
        console.log(err);
        this.legaleRappresentante = legaleRappresentante;
      }
      this.richiesta.legaleRappresentanteRichiesta = this.legaleRappresentante;
    },
    async getFinestraCompilazione() {
      const finestraCompilazione =
        await IstitutoRepository.getFinestraCompilazione(
          this.idStrutture,
          this.anno,
          "richieste-ist"
        );

      if (finestraCompilazione !== "") {
        this.finestraCompilazione = finestraCompilazione;
      }
    },
    async downloadPDF() {
      const date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      this.richiestaPDF.richiestaAnticipo = this.richiesta;
      this.richiestaPDF.richiestaAnticipo.dataInvio = `${day}/${month}/${year}`;
      this.richiestaPDF.programmaOrientamento = this.programma;
      this.richiestaPDF.legaleRappresentante = this.legaleRappresentante;

      let richiestaPDF = { ...this.richiestaPDF };
      const data = new FormData();
      data.append("richiestaPDF", JSON.stringify(richiestaPDF));
      data.append("datiBancari", JSON.stringify(this.datiBancari));
      await RichiesteRepository.downloadPdfPrecompilatoRichiesta(
        this.idStrutture,
        data
      );
    },
    async getRichiestaAnticipoPresentata() {
      const richiestaAnticipo =
        await RichiesteRepository.getRichiestaAnticipoPresentata(
          this.idStrutture,
          this.anno,
          this.capofila
        );

      if (richiestaAnticipo) {
        this.invioBloccato = true;
      }
      this.richiestaAnticipoPresentata = !!richiestaAnticipo;
    },
    async getDatiBancariStruttura() {
      const anno = this.anno;
      const idStrutture = this.idStrutture;

      const datiBancari = await RichiesteRepository.getContiBancariStruttura(
        idStrutture,
        anno
      );

      if (datiBancari) {
        this.datiBancari = datiBancari;

        this.richiesta.ibanTesoreria = datiBancari.contoTesoreria;
        this.richiesta.ibanContoBancario = !this.richiesta.ibanTesoreria
          ? datiBancari.ibanBancario
          : null;
      }
    },
  },
};
</script>

<style scoped></style>
