<template>
  <v-alert dense outlined elevation="2" v-if="showAlertErrori" type="error">
    E’ stata riscontrata la presenza di codici fiscali errati o studenti non
    ammissibili nei corsi
    {{ listaStudenti }}. Nel caso di mancata correzione dei dati non sarà
    possibile terminare il corso
  </v-alert>
</template>

<script>
import { mapState } from "vuex";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";

export default {
  name: "AlertConvalidaCf",
  computed: {
    ...mapState(["anno", "idStrutture"]),
  },
  data: () => ({
    showAlertErrori: false,
    listaStudenti: [],
  }),
  mounted() {
    this.initialize();
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      this.studentiConvalidati();
    },
    async studentiConvalidati() {
      this.listaStudenti = await IstitutoRepository.getStudentiConvalidati(
        this.idStrutture,
        this.anno
      );
      this.showAlertErrori = this.listaStudenti.length > 0;
    },
  },
};
</script>

<style scoped></style>
