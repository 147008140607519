<template>
  <v-container v-if="scrivaniaViewMode > 1">
    <v-row>
      <v-col>
        <h1>Scrivania</h1>
      </v-col>
      <v-spacer></v-spacer>
      <v-col>
        <router-link to="/profilo-utente">Cambia profilo</router-link>
        <v-divider class="mr-4 ml-4" vertical></v-divider>
        <span class="grey--text">Profilo </span>
        <span v-if="capofila === 'capofila'">Capofila</span>
        <span v-else>Università/AFAM</span>
      </v-col>
    </v-row>
    <v-row>
      <ConfermaMonitoraggio :capofila="capofila"></ConfermaMonitoraggio>
    </v-row>
    <!--<v-row align="center" justify="center">
      <AlertMonitoraggi />
    </v-row>-->
    <v-row v-if="showRiepiloghi">
      <v-col>
        <v-card class="fill-height">
          <AlunniProgrammiOrientamento
            :capofila="capofila"
            :programmaOrientamento="programma"
          ></AlunniProgrammiOrientamento>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="fill-height">
          <CorsiOrientamento
            :capofila="capofila"
            :programmaOrientamento="programma"
          ></CorsiOrientamento>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="fill-height">
          <AccordiScuolaUniversita
            :capofila="capofila"
            :programmaOrientamento="programma"
          ></AccordiScuolaUniversita>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="4">
          <v-card-title>
            <v-icon class="mr-2">mdi-beaker-check</v-icon>
            Programma di orientamento
          </v-card-title>
          <v-card-text>
            Sezione in cui il Legale Rappresentante dell’Istituzione può
            compilare il Programma di orientamento e caricare l’atto d’obbligo.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <router-link
              class="v-btn"
              :to="`/scrivania/${capofila}/programma-orientamento`"
            >
              Vai <v-icon>mdi-arrow-right-thin</v-icon>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col>
        <v-card elevation="8" height="100%" width="100%">
          <v-card-title>
            <v-icon class="mr-2">mdi-wallet</v-icon>
            Inserimento scuole e accordi
          </v-card-title>
          <v-card-text class>
            Sezione in cui il Referente per il Programma può compilare gli
            accordi sottoscritti con le scuole.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <router-link
              class="v-btn"
              :to="`/scrivania/${capofila}/scuole-accordi`"
            >
              Vai <v-icon>mdi-arrow-right-thin</v-icon>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="4">
          <v-card-title>
            <v-icon class="mr-2">mdi-lan</v-icon>
            Censimento corsi, erogazione formazione e <br />Attestati di
            frequenza
          </v-card-title>
          <v-card-text>
            Sezione in cui il Referente di Corso può inserire i dati inerenti i
            singoli corsi, può registrare le presenze degli studenti e dei
            docenti per ogni singola lezione e terminare il corso. Sezione in
            cui il Referente di Corso o il Referente per il Programma potranno
            firmare e scaricare gli Attestati di Frequenza.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <router-link class="v-btn" :to="`/scrivania/${capofila}/corsi`">
              Vai <v-icon>mdi-arrow-right-thin</v-icon>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col>
        <v-card elevation="8" height="100%" width="100%">
          <v-card-title>
            <v-icon class="mr-2">mdi-calendar-multiple-check</v-icon>
            Richiesta anticipo
          </v-card-title>
          <v-card-text>
            Sezione in cui il Legale Rappresentante dell’Istituzione può
            compilare la richiesta di anticipo e trasmetterla agli organi di
            competenza del MUR.
            <p class="white--text pt-3">
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <router-link
              class="v-btn"
              :to="`/scrivania/${capofila}/richieste-anticipo-saldo`"
            >
              Vai <v-icon>mdi-arrow-right-thin</v-icon>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="8">
          <v-card-title>
            <v-icon class="mr-2">mdi-calendar-multiple-check</v-icon>
            Indicatori Comuni UE
          </v-card-title>
          <v-card-text>
            Sezione in cui il Rappresentante Legale può aggiornare, per il
            semestre di riferimento, il valore programmato di studenti sulla
            base dei target assegnati dal Decreto definitivo di assegnazione
            delle risorse e dei target. Il valore realizzato si alimenta in
            automatico sulla base delle informazioni inserite nella card di
            censimento, erogazione formazione e attestati di frequenza.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <router-link
              class="v-btn"
              :to="`/scrivania/${capofila}/indicatori-comuni`"
            >
              Vai <v-icon>mdi-arrow-right-thin</v-icon>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card elevation="4" height="100%" width="100%">
          <v-card-title>
            <v-icon class="mr-2">mdi-lan</v-icon>
            Creazione e trasmissione rendiconto di progetto
          </v-card-title>
          <v-card-text class="mb-5">
            Sezione in cui il Legale Rappresentante dell’Istituzione può creare
            e trasmettere un nuovo rendiconto di progetto e la documentazione
            propedeutica alla domanda di rimborso agli organi di competenza del
            MUR.
          </v-card-text>
          <br />
          <v-card-actions>
            <v-spacer></v-spacer>
            <router-link
              class="v-btn"
              :to="`/scrivania/${capofila}/rendiconti`"
            >
              Vai <v-icon>mdi-arrow-right-thin</v-icon>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="8">
          <v-card-title>
            <v-icon class="mr-2">mdi-bank-check</v-icon>
            Dichiarazione di tracciabilità dei flussi finanziari <br />(art.3 ss
            L. 136/2010)
          </v-card-title>
          <v-card-text>
            Sezione in cui il Rappresentante Legale dell'istituzione può
            inserire la dichiarazione di tracciabilità dei flussi finanziari e
            indicare il/i conto/i dedicati ai movimenti finanziari
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <router-link
              class="v-btn"
              :to="`/scrivania/${capofila}/tracciabilita-flussi-finanziari`"
            >
              Vai <v-icon>mdi-arrow-right-thin</v-icon>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col></v-col>
    </v-row>
  </v-container>
</template>

<script>
import ConfermaMonitoraggio from "@/components/ConfermaMonitoraggio.vue";
import CorsiOrientamento from "@/components/CorsiOrientamento.vue";
import AccordiScuolaUniversita from "@/components/AccordiScuolaUniversita.vue";
import AlunniProgrammiOrientamento from "@/components/AlunniProgrammiOrientamento.vue";
//import AlertMonitoraggi from "@/components/AlertMonitoraggi.vue";
import { mapState } from "vuex";
import ProgrammiRepository from "@/api/istituto/ProgrammiRepository";

export default {
  name: "ScrivaniaView",
  components: {
    ConfermaMonitoraggio,
    AlunniProgrammiOrientamento,
    CorsiOrientamento,
    AccordiScuolaUniversita,
    //AlertMonitoraggi,
    //AlunniProgrammiOrientamento,
  },
  props: {
    capofila: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    programma: null,
    showRiepiloghi: false,
  }),
  computed: {
    ...mapState(["idStrutture", "anno", "scrivaniaViewMode"]),
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.showRiepiloghi = false;
      if (this.anno && this.idStrutture && this.capofila) {
        this.getProgrammaOrientamento();
      }
    },
    async getProgrammaOrientamento() {
      this.programma = await ProgrammiRepository.getProgrammi(
        this.idStrutture,
        this.anno,
        this.capofila
      );
      if (this.programma.length) {
        this.programma = this.programma[0];
        this.showRiepiloghi = true;
      }
    },
  },
};
</script>

<style scoped></style>
