<template>
  <v-container>
    <v-main>
      <StepRendicontazioni :numero-step="3" />
      <h1 class="text--primary">Compila checklist</h1>
    </v-main>
    <v-main v-if="loadingPage">
      <v-form ref="form" v-model="validForm">
        <v-expansion-panels accordion v-model="panel">
          <v-expansion-panel
            v-for="(checklist, index) in checklist"
            :key="index"
          >
            <v-expansion-panel-header class="align-center h-75">
              <v-row>
                <v-col class="align-start" cols="8">
                  <h3 class="primary--text">
                    {{ checklist.tipologiaCheckListRendiconti.descrizione }}
                  </h3>
                </v-col>
                <v-col cols="2">
                  <h3 class="align-end">
                    <v-chip
                      v-if="index !== 1"
                      outlined
                      pill
                      :color="getText(form[index]).color"
                      v-html="getText(form[index]).text"
                      class="primary--text"
                    />
                    <v-chip
                      v-else
                      outlined
                      pill
                      color="success"
                      class="primary--text"
                      >Compilato
                    </v-chip>
                  </h3>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="blue lighten-5">
              <div
                v-if="checklist.tipologiaCheckListRendiconti.numeroDomande > 1"
              >
                <div
                  v-for="(domande, index1) in testi[index].testi"
                  :key="index1"
                >
                  <span v-html="domande" />
                  <v-row class="align-items-center">
                    <v-col cols="4">
                      <!--AMSMURUNIV-3074 risp a domanda E2 -> NA e disabled per utente-->
                      <div v-if="index !== 4">
                        <v-autocomplete
                          v-model="form[index][index1].conferma"
                          :items="itemsSelect"
                          item-text="text"
                          item-value="value"
                          :rules="[
                            (v) =>
                              v !== -1 ||
                              'Bisogna selezionare una delle opzioni',
                          ]"
                          single-line
                        />
                      </div>
                      <div v-else>
                        <!--AMSMURUNIV-3074 risp a domanda E2 -> NA e disabled per utente-->
                        <v-autocomplete
                          v-if="index1 !== 1"
                          v-model="form[index][index1].conferma"
                          :items="itemsSelect"
                          item-text="text"
                          item-value="value"
                          :rules="[
                            (v) =>
                              v !== -1 ||
                              'Bisogna selezionare una delle opzioni',
                          ]"
                          single-line
                        />
                        <v-autocomplete
                          v-else
                          v-model="itemNA"
                          :items="itemsSelect"
                          item-text="text"
                          item-value="value"
                          :disabled="true"
                          single-line
                        />
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-textarea
                        outlined
                        readonly
                        disabled
                        v-model="form[index][index1].descrizioneConferma"
                        label="Elenco dei documenti a comprova"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-textarea
                        outlined
                        v-model="form[index][index1].note"
                        label="Note"
                      />
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div v-else>
                <span v-html="testi[index].testi" />
                <v-row class="align-items-center">
                  <v-col cols="4">
                    <!--PROGMINI-8632 risp a domanda B1 -> NA e disabled per utente-->
                    <v-autocomplete
                      v-if="index !== 1"
                      v-model="form[index].conferma"
                      :items="itemsSelect"
                      item-text="text"
                      item-value="value"
                      :rules="[
                        (v) =>
                          v !== -1 || 'Bisogna selezionare una delle opzioni',
                      ]"
                      single-line
                    />
                    <v-autocomplete
                      v-else
                      v-model="itemNA"
                      :items="itemsSelect"
                      item-text="text"
                      item-value="value"
                      :disabled="true"
                      single-line
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-textarea
                      outlined
                      readonly
                      disabled
                      v-model="form[index].descrizioneConferma"
                      label="Elenco dei documenti a comprova"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-textarea
                      outlined
                      v-model="form[index].note"
                      label="Note"
                    />
                  </v-col>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-card-text id="nota1">
          <sup> 1 </sup>Viene indicato, a titolo esemplificativo ma non
          esaustivo, la documentazione da prendere in esame per l’effettuazione
          del controllo; laddove necessario, integrare il campo “Note”; <br />
          <sup> 2 </sup> Tramite la compilazione del campo “Note” è possibile
          descrivere in maniera dettagliata eventuali integrazioni/punti
          attenzione/punti critici emersi durante la fase di controllo.
        </v-card-text>
        <v-card class="d-flex mb-6 mt-12" rounded>
          <v-card-actions>
            <v-row>
              <v-col class="pa-2 mr-auto">
                <v-btn @click="goOutRendicontioSection">
                  Torna al menù principale.
                </v-btn>
              </v-col>
              <v-col class="pa-2">
                <v-btn
                  class="primary v-btn--outlined v-btn--tile white--text"
                  v-html="'Salva Bozza e Continua'"
                  @click="dialogConferma = true"
                />
                <dialog-conferma
                  :dialog.sync="dialogConferma"
                  @callback="modificaChecklistRendiconto"
                  @close-dialog="dialogConferma = false"
                />
              </v-col>
              <v-col>
                <!-- disabilitato se non ho salvto le modifiche dello step corrente -->
                <v-btn
                  v-html="'Continua senza salvare'"
                  :disabled="rendiconto.step < 3"
                  @click="goToNextStep"
                />
              </v-col>
            </v-row>
            <v-snackbar
              :color="snackbar.color"
              v-model="snackbar.value"
              max-width="200"
              max-height="200"
            >
              {{ snackbar.text }}</v-snackbar
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-main>
    <v-main v-else>
      <v-progress-linear indeterminate color="blue"></v-progress-linear>
    </v-main>
  </v-container>
</template>
<script>
import StepRendicontazioni from "@/components/Rendiconti/StepRendicontazioni";
import RendicontiRepository from "@/api/istituto/RendicontiRepository";
import { mapGetters, mapState } from "vuex";
import DialogConferma from "@/components/DialogConferma";

export default {
  name: "checklistRendiconti",
  components: { StepRendicontazioni, DialogConferma },
  props: {
    capofila: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    checklist: [],
    form: [
      //checklist 1
      [
        {
          conferma: -1,
          descrizioneConferma: "Rendiconto di progetto",
          note: "",
        },
        {
          conferma: -1,
          descrizioneConferma:
            "Rendiconto di progetto; Programma di orientamento censito nella piattaforma informatica; Registri di presenza dei corsi presenti nella piattaforma informatica; Elenco dei corsi e dettaglio della tipologia di formazione erogata; Elenco degli attestati di frequenza; Atto di accettazione dei finanziamenti e degli obblighi.",
          note: "",
        },
      ],
      // checklist2:
      {
        conferma: null,
        descrizioneConferma: "",
        note: "Considerata la natura dell’Investimento che prevede l’attuazione dell'intervento a costi semplificati i dati del/dei titolari effettivi non sono previsti come documentazione a comprova delle attività rendicontate",
      },
      //checklist 3
      [
        {
          conferma: -1,
          descrizioneConferma:
            "Rendiconto di progetto;\n" +
            "Elenco dei corsi e dettaglio della tipologia di formazione erogata;\n" +
            "Elenco degli attestati di frequenza.",
          note: "In caso di risposta negativa indicare distintamente la natura delle ulteriori risorse che hanno coperto quota parte dei costi maturati nel rendiconto di progetto nonché le relative attività.",
        },
        {
          conferma: -1,
          descrizioneConferma:
            "Rendiconto di progetto;\n" +
            "Elenco dei corsi e dettaglio della tipologia di formazione erogata;\n" +
            "Elenco degli attestati di frequenza.",
          note: "",
        },
        {
          conferma: -1,
          descrizioneConferma:
            "Programma di orientamento;\n" +
            "Accordi con le scuole;\n" +
            "Attestati di frequenza.",
          note: "",
        },
      ],
      // checklist 4
      [
        {
          conferma: -1,
          descrizioneConferma:
            "Rendiconto di progetto; \n" +
            "Programma di orientamento censito nella piattaforma informatica; \n" +
            "Registri di presenza dei corsi rilevabili dalla piattaforma informatica; \n" +
            "Elenco dei corsi e dettaglio della tipologia di formazione erogata; \n" +
            "Elenco degli attestati di frequenza.",
          note: "",
        },
        {
          conferma: -1,
          descrizioneConferma:
            "Programma di orientamento censito nella piattaforma informatica; \n" +
            "Elenco dei corsi e dettaglio della tipologia di formazione erogata; \n" +
            "Elenco degli attestati di frequenza. ",
          note: "",
        },
      ],
      // checklist 5
      [
        {
          conferma: -1,
          descrizioneConferma: "",
          note: "N. Giovani di età compresa tra i 15 e i 29 anni che ricevono sostegno.",
        },
        {
          conferma: -1,
          descrizioneConferma: "",
          note: "Il/i campo/i di intervento associati alla misura non concorre/ono al conseguimento dei tagging ambientali e digitali. ",
        },
      ],
      // checklist 6
      {
        conferma: -1,
        descrizioneConferma:
          "Rendiconto di progetto; \n" +
          "Dichiarazione di conformità al principio DNSH e alla pertinente normativa ambientale dell’UE e nazionale",
        note: "",
      },
      // checklist 7
      [
        {
          conferma: -1,
          descrizioneConferma:
            "Rendiconto di progetto; \n" + "Corsi di orientamento",
          note: "",
        },
        {
          conferma: -1,
          descrizioneConferma: "",
          note: "",
        },
      ],
    ],
    validForm: false,
    rendiconto: null,
    dialogConferma: false,
    loadingPage: false,
    snackbar: {
      text: null,
      color: null,
      value: false,
    },
    testi: [
      {
        sezione: 1,
        testi: [
          "<ul> " +
            "<li>I costi standard maturati di cui al presente Rendiconto sono stati sottoposti con esito positivo alle verifiche di autocontrollo sulla regolarità amministrativo-contabile?</li></ul>",
          "<p>In relazione ai costi standard maturati in applicazione dell’Opzione di Costo Semplificato adottata e inseriti nel Rendiconto di progetto sono state condotte verifiche atte a garantire la conformità ai requisiti di ammissibilità previsti dalle Linee guida per la rendicontazione, consentendo di attestare che i medesimi:</p>" +
            "<ul>" +
            "<li>a) riferiscono alle attività previste dai programmi di orientamento finanziati, nonché presentati secondo le modalità indicate nelle Linee Guida di riferimento;</li>" +
            "<li>b) sono contenuti nei limiti dell’importo ammesso a finanziamento;</li>" +
            "<li>c) sono conformi a quanto previsto dagli specifici documenti che regolano il rapporto fra il MUR e il Soggetto Attuatore che rendiconta;</li>" +
            "<li>d) sono direttamente imputabili, congrui, coerenti, pertinenti e connessi alle attività previste nel progetto approvato e ammesso a finanziamento nel rispetto della normativa comunitaria;</li>" +
            "<li>e) rispettano il principio di addizionalità del sostegno dell’Unione europea;</li>" +
            "<li>f) sono conformi alla normativa europea e nazionale disciplinante il PNRR e alle altre norme comunitarie, nazionali e regionali pertinenti e sostenute secondo principi di economia e sana gestione finanziaria;</li>" +
            "<li>g) sono connessi a attività svolte nel periodo temporale di ammissibilità previsto nei Decreti Ministeriali di riferimento;</li>" +
            "<li>h) derivano da atti giuridicamente vincolanti (atto di accettazione dei finanziamenti e degli obblighi);</li>" +
            "<li>i) sono associati alle attività del programma di orientamento e al Codice Unico di Progetto (CUP);</li>" +
            "<li>j) sono conformi alle disposizioni delle norme contabili, fiscali e contributive;</li>" +
            "<li>k) sono registrati nella contabilità separata del Soggetto Attuatore;</li>" +
            "<li>l) sono relativi a pagamenti effettuati tramite titoli idonei a garantirne la tracciabilità (a titolo esemplificativo tramite bonifico bancario o postale, accompagnati dall’evidenza della quietanza su conto corrente);</li>" +
            "<li>m) sono relativi a pagamenti effettuati dal conto corrente dedicato intestato al Soggetto Attuatore?</li>" +
            "</ul>",
        ],
      },
      {
        sezione: 2,
        testi: `Per tutti i costi standard maturati inseriti nel presente rendiconto, ove pertinente, sono state acquisite le informazioni e i dati relativi ai titolari effettivi? \n
                La raccolta è avvenuta conformemente a quanto previsto dalle Linee Guida Controllo e Rendicontazione del MEF e ss.mm.ii con riferimento al d.lgs. n. 231/2007,
                al d.lgs. n. 125 del 2019, al Regolamento (UE) 2021/241 e alla Direttiva (UE) 2015/849 e gli atti a comprova sono stati archiviati?`,
      },
      {
        sezione: 3,
        testi: [
          "<ul> " +
            "<li>Le attività realizzate e i relativi costi standard maturati inseriti nel presente rendiconto sono stati sostenuti esclusivamente con risorse europee del PNRR? </li>",
          "<li>Le attività realizzate e i relativi costi standard maturati inseriti nel presente rendiconto sono stati imputati esclusivamente a valere del progetto finanziato e quindi è stato rispettato il divieto di duplice rimborso (stesso costo pagato due volte) a valere su fonti di finanziamento pubbliche, anche di diversa natura? </li>",
          "<li>Gli atti amministrativo/contabili relativi ai costi standard maturati di cui al presente rendiconto riportano il CUP del progetto?</li> </ul>",
        ],
      },
      {
        sezione: 4,
        testi: [
          "<ul> " +
            "<li>I costi standard maturati inseriti nel presente rendiconto sono connessi ad attività coerenti con gli obiettivi e le finalità dell’Investimento del PNRR, con particolare riferimento ai Target da conseguire previsti dai Decreti attuativi,  \n" +
            "ovvero alunni da formare, accordi da stipulare con le scuole e corsi da erogare? La relativa documentazione probatoria è stata inserita nel sistema informatico del MUR?</li>",
          "<li>La tempistica di realizzazione delle attività per il periodo di riferimento del presente Rendiconto allo stato attuale è coerente con quanto previsto dal Decreto di concessione del finanziamento?  </li> </ul>",
        ],
      },
      {
        sezione: 5,
        testi: [
          "<ul> " +
            "<li>Sono stati forniti a sistema i dati finalizzati alla valorizzazione degli indicatori Comuni?</li></ul>",
          "<li>Le attività sono coerenti con il/i campo/i di intervento della specifica Misura e concorrono al conseguimento dei tagging ambientali e digitali (ove previsto)? </li> </ul>",
        ],
      },
      {
        sezione: 6,
        testi:
          "Le attività progettuali sono state realizzate nel pieno rispetto del principio di non arrecare danno significativo " +
          "a nessuno dei seguenti obiettivi ambientali, ai sensi dell’art. 17 del Reg. (UE) 2021/852, e in particolare:<ul> " +
          "<li> alla mitigazione dei cambiamenti climatici, in quanto le attività non conducono a significative emissioni di gas a effetto serra;  </li>" +
          "<li> all’adattamento ai cambiamenti climatici, in quanto le attività non conducono a un peggioramento degli effetti negativi del clima attuale e del clima futuro previsto su sé stessa o sulle persone, sulla natura o sugli attivi;  </li>" +
          "<li>all’uso sostenibile e alla protezione delle acque e delle risorse marine, in quanto le attività non nuocciono: <ul><li>al buono stato o al buon potenziale ecologico di corpi idrici, comprese le acque di superficie e sotterranee;</li><li>al buono stato ecologico delle acque marine;</li></ul>  </li>" +
          "<li>all’economia circolare, compresi la prevenzione e il riciclaggio dei rifiuti, in quanto:  <ul>" +
          "<li> le attività non conducono a inefficienze significative nell’uso dei materiali o nell’uso diretto o indiretto di risorse naturali quali le fonti energetiche non rinnovabili, le materie prime, le risorse idriche e il suolo, in una o più fasi del ciclo di vita dei prodotti, anche in termini di durabilità, riparabilità, possibilità di miglioramento, riutilizzabilità o riciclabilità dei prodotti; </li>" +
          "<li>le attività non comportano un aumento significativo della produzione, dell’incenerimento o dello smaltimento dei rifiuti, ad eccezione dell’incenerimento di rifiuti pericolosi non riciclabili;</li>" +
          "<li>lo smaltimento a lungo termine dei rifiuti non potrebbe causare un danno significativo e a lungo termine all’ambiente;</li></ul> </li>" +
          "<li>alla prevenzione e alla riduzione dell’inquinamento, in quanto le attività non comportano un aumento significativo delle emissioni di sostanze inquinanti nell’aria, nell’acqua o nel suolo rispetto alla situazione esistente prima del suo avvio; </li>" +
          "<li>alla protezione e al ripristino della biodiversità e degli ecosistemi, in quanto le attività:<ul><li>non nuocciono in misura significativa alla buona condizione e alla resilienza degli ecosistemi;</li><li>non nuocciono allo stato di conservazione degli habitat e delle specie, comprese quelli di interesse per l’Unione?</li></ul> </li> </ul> ",
      },
      {
        sezione: 7,
        testi: [
          "<ul> " +
            "<li>Le attività progettuali sono state espletate in coerenza con i principi trasversali PNRR (pari opportunità, politiche per i giovani, quota SUD), ove applicabile?</li>",
          "<li>Ai sensi dell’articolo 34 del Regolamento (UE) n. 2021/241, è stata garantita un’adeguata visibilità al finanziamento dell’Unione Europea per il sostegno offerto in relazione all’intervento, come previsto e indicato dalle “Linee guida per le azioni di informazione e comunicazione a cura dei Soggetti Attuatori” nonché nelle Linee guida per la rendicontazione? </li> </ul>",
        ],
      },
    ],
    itemsSelect: [
      { text: "Seleziona", value: -1 },
      { text: "SI", value: true },
      { text: "NO", value: false },
      { text: "NA", value: null },
    ],
    itemNA: { text: "NA", value: null },
    panel: false,
  }),
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["idStrutture", "anno", "idRendiconto", "user"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleConsulenza",
      "isRoleLegale",
    ]),
  },
  watch: {},
  methods: {
    initialize() {
      if (this.capofila) {
        this.getSingoloRendiconto();
      }
    },
    async getSingoloRendiconto() {
      this.rendiconto = await RendicontiRepository.getSingoloRendiconto(
        this.idStrutture,
        this.idRendiconto
      );
      this.checklist = this.sortChecklist(this.rendiconto.checklist);
      let stepRendiconto = this.rendiconto.step;
      this.form.forEach((element, index) => {
        if (Array.isArray(element)) {
          if (element.length === 3) {
            this.setValueFormChecklist(
              3,
              index,
              this.checklist[index],
              stepRendiconto
            );
          } else {
            // le sezioni con più form (2 domande)
            this.setValueFormChecklist(
              1,
              index,
              this.checklist[index],
              stepRendiconto
            );
          }
        } else {
          this.setValueFormChecklist(
            2,
            index,
            this.checklist[index],
            stepRendiconto
          );
        }
      });
      this.loadingPage = true;
    },
    setValueFormChecklist(tipo, index, checkListRendiconto, stepRendiconto) {
      /** TODO anzichè l'assegnazione diretta verificare se si può inserire in modo dinamico */
      switch (tipo) {
        case 1: // sempre quando abbiamo più form nella specifica sezione
          // conferma1
          this.form[index][0].conferma = this.assignValueChecklist(
            checkListRendiconto,
            "conferma1",
            stepRendiconto
          );
          this.form[index][0].note =
            checkListRendiconto["note1"] ?? this.form[index][0].note;

          // conferma2, solo per il caso della sezione e domanda due è sempre na e non compilabile
          this.form[index][1].conferma =
            checkListRendiconto.tipologiaCheckListRendiconti.id !== 5
              ? this.assignValueChecklist(
                  checkListRendiconto,
                  "conferma2",
                  stepRendiconto
                )
              : null;
          this.form[index][1].note =
            checkListRendiconto["note2"] ?? this.form[index][1].note;
          break;
        case 2:
          // conferma1
          this.form[index].conferma = this.assignValueChecklist(
            checkListRendiconto,
            "conferma1",
            stepRendiconto
          );
          // note checklist, domanda singola
          this.form[index].note =
            checkListRendiconto["note1"] ?? this.form[index].note;
          break;
        case 3:
          // conferma1
          this.form[index][0].conferma = this.assignValueChecklist(
            checkListRendiconto,
            "conferma1",
            stepRendiconto
          );
          this.form[index][0].note =
            checkListRendiconto["note1"] ?? this.form[index][0].note;
          // conferma2
          this.form[index][1].conferma = this.assignValueChecklist(
            checkListRendiconto,
            "conferma2",
            stepRendiconto
          );
          this.form[index][1].note =
            checkListRendiconto["note2"] ?? this.form[index][1].note;
          // conferma3
          this.form[index][2].conferma = this.assignValueChecklist(
            checkListRendiconto,
            "conferma3",
            stepRendiconto
          );
          this.form[index][2].note =
            checkListRendiconto["note3"] ?? this.form[index][2].note;
      }
    },
    assignValueChecklist(checklistRendiconto, keyChecklist, stepRendiconto) {
      let valueRet = null;
      // valore-conferma
      if (stepRendiconto > 2) {
        valueRet =
          checklistRendiconto[keyChecklist] !== null &&
          typeof checklistRendiconto[keyChecklist] === "boolean"
            ? checklistRendiconto[keyChecklist]
            : null;
      } else {
        valueRet = -1;
      }
      return valueRet;
    },
    getText(valueForm) {
      const isArray = Array.isArray(valueForm);

      let color = "";
      let text = "";
      let confermeInserite = null;

      switch (isArray) {
        case true:
          confermeInserite = [];
          for (let i = 0; i < valueForm.length; i++) {
            confermeInserite.push(valueForm[i].conferma);
          }

          if (!confermeInserite.includes(-1)) {
            color = "success";
            text = " Compilato";
          } else if (
            confermeInserite.filter((element) => {
              return element !== -1;
            }).length === 0
          ) {
            color = "red darken-2";
            text = " Da compilare";
          } else {
            color = "purple-darken-1";
            text = "In corso";
          }
          break;

        case false:
          if (valueForm.conferma !== -1) {
            color = "success";
            text = " Compilato";
          } else {
            color = "red darken-2";
            text = " Da compilare";
          }
          break;
      }

      return { color: color, text: text };
    },
    getStatoCheckList(stateChecklist) {
      if (stateChecklist.conferma !== -1) {
        return 1;
      } else {
        return 2;
      }

      /*return {
        color: color,
        value: value,
        text: text,
      };*/
    },
    setPropertySnackbar(esito, testo) {
      this.snackbar.color = esito ? "success" : "red accent-2";
      this.snackbar.text = testo;
      this.snackbar.value = true;
    },
    goToNextStep() {
      // step 4 dei rendiconti
      this.$router.push({
        name: "dsanDnshRendiconto",
        params: { operazione: this.operazione, capofila: this.capofila },
      });
    },
    goOutRendicontioSection() {
      this.commitRendiconto(null);
      if (!this.idRendiconto) {
        this.$router.push({
          name: "RendicontiView",
          params: {
            capofila: this.capofila,
          },
        });
      }
    },
    commitRendiconto(idRendiconto) {
      this.$store.commit("setIdRendiconto", idRendiconto);
    },
    async modificaChecklistRendiconto() {
      //PROGMINI-8632 risp a domanda B1 -> NA e disabled per utente
      this.form[1].conferma = null;
      this.form[4][1].conferma = null;
      if (this.$refs.form.validate() && this.verificaCampiInseriti()) {
        const idStrutture = this.idStrutture;
        const idRendiconto = this.idRendiconto;
        const formChecklistRendiconto = {
          checklist: this.form,
          currentStep: 3,
        };
        const formBody = new FormData();
        formBody.append("rendiconto", JSON.stringify(formChecklistRendiconto));

        RendicontiRepository.modificaRendiconto(
          idStrutture,
          idRendiconto,
          3,
          formBody
        ).then((data) => {
          if (data) {
            this.setPropertySnackbar(
              true,
              "Le conferme in merito alle domande preposte sono state inserite con successo"
            );
            setTimeout(() => this.goToNextStep(), 2000);
          }
        });
      } else {
        this.setPropertySnackbar(
          false,
          "Verificare la compilazione dei campi richiesti. Per ogni sezione, è necessario selezionare una delle opzioni ( si, no, n/a)."
        );
      }
    },
    verificaCampiInseriti() {
      const arrRes = this.form.map((element) => {
        if (Array.isArray(element)) {
          let conferma1 = element[0].conferma;
          let conferma2 = element[1].conferma;

          return conferma1 !== -1 && conferma2 !== -1;
        } else {
          let conferma1 = element.conferma;

          return conferma1 !== -1;
        }
      });

      return !arrRes.includes(false);
    },
    sortChecklist(checklist) {
      return checklist.sort(
        (checklist1, checklist2) =>
          checklist1.tipologiaCheckListRendiconti.id -
          checklist2.tipologiaCheckListRendiconti.id
      );
    },
  },
};
</script>
