<template>
  <v-card v-if="idStrutture !== null" :loading="loading" class="my-4">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <h2 v-if="tipologia === 'capofila'">Istituzioni Partner</h2>
          <h2 v-else>Convenzioni in cui sei {{ tipologia }}:</h2>
          <v-simple-table class="mt-4 table-custom">
            <template v-slot:default>
              <thead v-if="tipologia === 'capofila'">
                <tr>
                  <th class="text-left" rowspan="2">Istituzione</th>
                  <th class="text-left">Studenti</th>
                  <th class="text-left">Corsi</th>
                  <th class="text-left">Accordi</th>
                </tr>
              </thead>
              <thead v-else>
                <tr>
                  <th class="text-left" rowspan="2">Istituzione</th>
                  <th class="text-left" colspan="2">Studenti in convezione</th>
                  <th class="text-left" colspan="2">Corsi in convezione</th>
                  <th class="text-left" colspan="2">Accordi in convezione</th>
                </tr>
                <tr>
                  <th class="text-left">Partner</th>
                  <th class="text-left">Capofila</th>
                  <th class="text-left">Partner</th>
                  <th class="text-left">Capofila</th>
                  <th class="text-left">Partner</th>
                  <th class="text-left">Capofila</th>
                </tr>
              </thead>
              <tbody v-if="tipologia === 'capofila'">
                <tr v-if="convenzioni.length === 0">
                  <td colspan="4">
                    <h4>Nessuna convenzione trovata.</h4>
                  </td>
                </tr>
                <tr v-else v-for="item in convenzioni" :key="item.id">
                  <td>
                    {{ item.strutturaPartner.nome }}
                  </td>
                  <td>{{ item.numeroStudenti }}</td>
                  <td>{{ item.numeroCorsi }}</td>
                  <td>{{ item.numeroAccordi }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-if="convenzioni.length === 0">
                  <td colspan="4">
                    <h4>Nessuna convenzione trovata.</h4>
                  </td>
                </tr>
                <tr v-else v-for="item in convenzioni" :key="item.id">
                  <td>
                    {{ item.struttura.nome }}
                  </td>
                  <td>{{ item.numeroStudentiPartner }}</td>
                  <td>{{ item.numeroStudentiCapofila }}</td>
                  <td>{{ item.numeroCorsiPartner }}</td>
                  <td>{{ item.numeroCorsiCapofila }}</td>
                  <td>{{ item.numeroAccordiPartner }}</td>
                  <td>{{ item.numeroAccordiCapofila }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import ConvenzioniRepository from "@/api/istituto/ConvenzioniRepository";

export default {
  name: "ListaCapofilaComponent",
  components: {},
  props: {
    tipologia: {
      type: String,
      required: true,
      default: "",
    },
  },
  data: () => ({
    loadingConvenzioni: false,
    convenzioni: [],
  }),
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["idStrutture", "anno"]),
    loading: function () {
      return this.loadingConvenzioni;
    },
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        this.getConvenzioni();
      }
    },
    async getConvenzioni() {
      this.loadingConvenzioni = true;
      this.convenzioni = await ConvenzioniRepository.getConvenzioni(
        this.idStrutture,
        this.anno,
        this.tipologia
      );
      this.loadingConvenzioni = false;
    },
  },
};
</script>

<style scoped>
.table-custom {
  border-collapse: collapse;
  width: 100%;
}

.table-custom td,
.table-custom th {
  border: 1px solid #ddd;
  padding: 8px;
}

.table-custom tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table-custom tr:hover {
  background-color: #ddd;
}

.table-custom th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #ccc6c6;
  color: white;
}
</style>
