<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="800px">
      <template v-slot:activator="{ on, attrs }">
        <v-row>
          <v-col cols="12" class="d-flex flex-row-reverse">
            <v-btn
              class="blue--text text-uppercase"
              text
              v-bind="attrs"
              v-on="on"
              ><v-icon class="mx-2"> mdi-loupe </v-icon> Aggiungi Titolare
              Effettivo
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <v-card>
        <v-card-title class="h1 justify-center"
          >Aggiungi Titolare Effettivo
        </v-card-title>
        <v-card-text>
          <v-container
            ><div class="my-4">
              <small> I campi contrassegnati con * sono obbligatori </small>
            </div>
            <v-form ref="form">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Codice Fiscale *"
                    v-model="titolare.cf"
                    :counter="16"
                    :rules="validazioneCF()"
                    @input="validateField"
                    @change="validateField"
                    @keyup="uppercase()"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn outlined color="blue darken-1" text @click="dialog = false">
            Annulla
          </v-btn>
          <v-btn class="primary" text @click="dialogSalva = true">
            Inserisci
          </v-btn>
        </v-card-actions>
        <dialog-conferma
          @callback="addTitolare"
          :dialog.sync="dialogSalva"
          @close-dialog="dialogSalva = false"
        />

        <v-snackbar
          v-model="snackbar"
          :timeout="4000"
          shaped
          :color="snackbarColor"
        >
          {{ snackbarText }}

          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import DialogConferma from "@/components/DialogConferma";
import { mapState } from "vuex";
import ProgrammiRepository from "@/api/istituto/ProgrammiRepository";
export default {
  name: "DialogAggiungiTitolareEffettivoComponent",
  components: {
    DialogConferma,
  },
  props: {
    idComunicazione: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState(["idStrutture", "anno"]),
  },
  data: () => ({
    dialog: false,
    dialogSalva: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    validate: false,
    titolare: {
      cf: null,
    },
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.titolare.cf = null;
    },
    uppercase() {
      this.titolare.cf = this.titolare.cf.toUpperCase();
    },
    validateField() {
      this.validate = this.$refs.form.validate();
    },
    validazioneCF() {
      return [
        (v) =>
          (!!v && v.length === 16) ||
          "Il campo è obbligatorio e il formato del codice fiscale deve essere valido",
      ];
    },

    async addTitolare() {
      this.validateField();
      if (this.validate && this.idComunicazione != null) {
        let idStrutture = this.$store.state.idStrutture;

        const titolare = { ...this.titolare };
        const formData = new FormData();
        formData.append("cf", JSON.stringify(titolare));
        try {
          await ProgrammiRepository.addTitolareEffettivo(
            idStrutture,
            this.idComunicazione,
            formData
          );
          this.titolare.cf = null;
          this.$emit("titolare-aggiunto");

          this.snackbarText = "Titolare effettivo aggiunto";
          this.snackbarColor = "success";
          this.snackbar = true;
          this.validate = true;
          this.initialize();
          this.dialog = false;
        } catch (e) {
          console.log(e);
          this.snackbarText = "Errore durante la procedura";
          this.snackbarColor = "red accent-2";
          this.snackbar = true;
        }
      } else {
        this.snackbarText = "Compilare tutti i campi richiesti";
        this.snackbarColor = "red accent-2";
        this.snackbar = true;
      }
    },
  },
};
</script>
