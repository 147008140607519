<template>
  <v-main v-if="loadingPage">
    <v-card
      class="ma-2 pa-2"
      v-for="(domanda, index) in domandeQuestionario"
      :key="index"
    >
      <h3 v-html="domanda.domandaPadre.testoDomanda"></h3>
      <div v-if="domanda.sottoDomande.length" class="">
        <!-- domande multiple -->
        <v-row>
          <v-col></v-col>
          <v-col
            v-for="(
              opzioniRisposte, indexOpzioneRisposta
            ) in colonneDomandeMultiple"
            :key="indexOpzioneRisposta"
          >
            {{ opzioniRisposte }}
          </v-col>
        </v-row>
        <v-row
          v-for="(sottoDomanda, indexSottoDomanda) in domanda.sottoDomande"
          :key="indexSottoDomanda"
          style="border-bottom: 1px solid lightgrey"
        >
          <v-col>
            <b> {{ sottoDomanda.testoDomanda }} </b>
          </v-col>
          <v-col
            v-for="(
              opzioniRisposte, indexOpzioneRisposta
            ) in sottoDomanda.opzioniRisposteDomanda"
            :key="indexOpzioneRisposta"
          >
            {{
              calcolaPercentualeRispondenti(
                sottoDomanda.id,
                opzioniRisposte.valoreRisposta
                  ? opzioniRisposte.valoreRisposta
                  : null
              )
            }}
            %
          </v-col>
          <v-col>
            <v-card-text>
              <b> {{ countRisposteDomanda(sottoDomanda.id) }}</b>
            </v-card-text>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <ul>
          <v-card-text
            v-for="(
              opzioniRisposta, indexOpzioneRisposta
            ) in getItemsSelectInput(
              parseInt(index + 1),
              domanda.domandaPadre.opzioniRisposteDomanda
            )"
            :key="indexOpzioneRisposta"
          >
            <v-row>
              <v-col cols="4">
                <li>
                  <b> {{ opzioniRisposta.text }} </b>
                </li>
              </v-col>
              <v-col cols="4">
                <span class="ml-4 pl-3">
                  {{
                    calcolaPercentualeRispondenti(
                      domanda.domandaPadre.id,
                      opzioniRisposta.value ?? null
                    )
                  }}
                  %
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </ul>

        <v-card-text>
          Totale rispondenti:
          <b>
            {{ countRisposteDomanda(domanda.domandaPadre.id) }}</b
          ></v-card-text
        >
      </div>
    </v-card>
  </v-main>
</template>
<script>
import QuestionariRepository from "@/api/istituto/QuestionariRepository";
import { mapState } from "vuex";

export default {
  name: "visualizzazioneDatiQuestionario",
  computed: {
    ...mapState(["idStrutture"]),
    loadingPage() {
      return !!(this.domandeQuestionario && this.risposteQuestionario);
    },
  },
  data: () => ({
    domandeQuestionario: [],
    classiStudente: null,
    generiStudente: null,
    colonneDomandeMultiple: [
      "del tutto",
      "molto",
      "abbastanza",
      "poco",
      "per niente",
      "non risponde",
      "Totale rispondenti",
    ],
    risposteQuestionario: null,
  }),
  created() {
    this.initialize();
  },
  props: {
    idCorso: {
      type: [Number, String],
      required: true,
    },
  },
  methods: {
    initialize() {
      this.getDomandeQuestionari();
      this.getDatiQuestionariCorso();
    },

    async getDomandeQuestionari() {
      this.domandeQuestionario =
        await QuestionariRepository.getDomandeQuestionari(this.idStrutture, 1);
    },
    getItemsSelectInput(indexDomanda, opzioniRisposta) {
      return opzioniRisposta.map((element) => {
        return {
          text: element.opzioneRispostaDomandaQuestionario.testo,
          value: element.opzioneRispostaDomandaQuestionario.valore,
        };
      });
    },
    async getDatiQuestionariCorso() {
      this.risposteQuestionario =
        await QuestionariRepository.getDatiQuestionariCorso(
          this.idStrutture,
          parseInt(this.idCorso)
        );
    },

    calcolaPercentualeRispondenti(idDomanda, valueRisposta) {
      idDomanda = parseInt(idDomanda);
      const datiStatisticiDomanda = this.getRisposteDomanda(idDomanda);

      if (
        datiStatisticiDomanda &&
        datiStatisticiDomanda.risposteDate &&
        datiStatisticiDomanda.risposteDate.length
      ) {
        const risposteDomanda = datiStatisticiDomanda.risposteDate;
        const numeroRisposte = risposteDomanda.length;
        let sum = 0;
        risposteDomanda.forEach((element) => {
          if (element.rispostaNumerica === valueRisposta) {
            sum += 1;
          }
        });

        return parseFloat((sum / numeroRisposte) * 100).toFixed(2);
      } else {
        return 0;
      }
    },
    getRisposteDomanda(idDomanda) {
      return this.risposteQuestionario.find((element) => {
        return parseInt(element.domanda.id) === idDomanda;
      });
    },
    countRisposteDomanda(idDomanda) {
      const risposteDomanda = this.getRisposteDomanda(idDomanda);
      if (
        risposteDomanda &&
        risposteDomanda.risposteDate &&
        risposteDomanda.risposteDate.length
      ) {
        // recupero le risposte che hanno un valore
        const risposte = risposteDomanda.risposteDate.filter((element) => {
          return element.rispostaNumerica != null;
        });

        return risposte.length;
      } else {
        return 0;
      }
    },
  },
};
</script>
