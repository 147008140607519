<template>
  <div style="width: 180px">
    <v-autocomplete
      :items="anni"
      v-model="annoSelezionato"
      label="Anno accademico:"
      item-text="name"
      item-value="anno"
      class="mx-4"
      flat
      hide-details
      solo-inverted
      @change="updateAnno"
    ></v-autocomplete>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SelezionaAnnoAccademico",
  computed: {
    ...mapState([""]),
  },
  data() {
    return {
      anni: [
        { name: "2022/2023", anno: 2022 },
        { name: "2023/2024", anno: 2023 },
        { name: "2024/2026", anno: 2024 },
      ], // anni disponibili nella listbox
      annoSelezionato: 2023, // quando si cambia l'anno di default va cambiato anche nello store per evitare discordanze!
    };
  },
  methods: {
    updateAnno() {
      // aggiornamento dello store di Vue con l'anno selezionato
      this.$store.commit("setAnnoSelezionato", this.annoSelezionato);
    },
  },
};
</script>

<style scoped></style>
