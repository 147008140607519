import Repository from "@/api/Repository";

export default {
  async getDomandeQuestionari(idStrutture, tipoVisualizzazione) {
    const url = `api/istituto/${idStrutture}/questionari/tipologie-domande?tipo-visualizzazione=${tipoVisualizzazione}`;
    return (await Repository.get(url)).data;
  },
  async getProvince() {
    const url = `api/questionari-studenti/domanda-province`;
    return (await Repository.get(url)).data;
  },
  async generazioneLinkQuestionario(idStrutture, idCorso) {
    const url = `api/istituto/${idStrutture}/questionari/${idCorso}/link-questionari`;
    return (await Repository.post(url)).data;
  },
  async verificaCreazioneLinkQuestionarioCorso(idStrutture, idCorso) {
    const url =
      idStrutture && true
        ? `api/istituto/${idStrutture}/questionari/${idCorso}/link-questionario`
        : `api/questionari-studenti/${idCorso}/link-questionario`;
    return (await Repository.get(url)).data;
  },
  async getDatiQuestionariCorso(idStrutture, idCorso) {
    const url = `api/istituto/${idStrutture}/questionari/${idCorso}/domande-risposte-questionario`;
    return (await Repository.get(url)).data;
  },
  async getInformazioniGeneraliQuestionari(idStrutture, idCorso) {
    const url = `api/istituto/${idStrutture}/questionari/${idCorso}/informazioni-generali`;
    return (await Repository.get(url)).data;
  },
};
