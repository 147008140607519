<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-row>
          <v-col cols="12" class="">
            <v-btn
              :disabled="!primoDelegato"
              class="blue--text"
              v-bind="attrs"
              v-on="on"
              ><v-icon class="mx-2"> mdi-loupe </v-icon> Aggiungi delegato
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <v-card>
        <v-card-title class="h1 justify-center"
          >Aggiungi Delegato
        </v-card-title>
        <v-card-text>
          <v-container
            ><div class="my-4">
              <small> I campi contrassegnati con * sono obbligatori </small>
            </div>
            <v-form ref="form">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Nome *"
                    v-model="formDelegatoAggintivo.nome"
                    :rules="validazioneCampoNecessario()"
                    @input="validateField"
                    @change="validateField"
                    @keyup="uppercasePrimaLetteraNome()"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Cognome *"
                    v-model="formDelegatoAggintivo.cognome"
                    :rules="validazioneCampoNecessario()"
                    @input="validateField"
                    @change="validateField"
                    @keyup="uppercasePrimaLetteraCognome()"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Codice Fiscale *"
                    v-model="formDelegatoAggintivo.cf"
                    :counter="16"
                    :rules="validazioneCF()"
                    @input="validateField"
                    @change="validateField"
                    @keyup="uppercase()"
                    @blur="datiFromCF(formDelegatoAggintivo.cf)"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Nella qualità di *"
                    v-model="formDelegatoAggintivo.qualifica"
                    :rules="validazioneCampoNecessario()"
                    @input="validateField"
                    @change="validateField"
                    @keyup="uppercase()"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="formDelegatoAggintivo.luogoNascita"
                    label="Comune di nascita * (autocompilato da cf)"
                    :rules="validazioneCampoNecessario()"
                    @input="validateField"
                    @change="validateField"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="formDelegatoAggintivo.dataNascita"
                    label="Data di nascita * (autocompilato da cf)"
                    type="date"
                    :rules="validazioneCampoNecessario()"
                    @input="validateField"
                    @change="validateField"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn outlined color="blue darken-1" text @click="dialog = false">
            Annulla
          </v-btn>
          <v-btn class="primary" text @click="dialogSalva = true">
            Inserisci delegato
          </v-btn>
        </v-card-actions>
        <dialog-conferma
          @callback="addDelegato"
          :dialog.sync="dialogSalva"
          @close-dialog="dialogSalva = false"
        />

        <v-snackbar
          v-model="snackbar"
          :timeout="4000"
          shaped
          :color="snackbarColor"
        >
          {{ snackbarText }}

          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import DialogConferma from "@/components/DialogConferma";
import { mapState } from "vuex";
import CorsiRepository from "@/api/istituto/CorsiRepository";
export default {
  name: "DialogAggiungiDelegato",
  components: {
    DialogConferma,
  },
  props: {
    primoDelegato: {
      type: Boolean,
      required: true,
    },
    contoAggiuntivo: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapState(["idStrutture", "anno"]),
  },
  data: () => ({
    dialog: false,
    dialogSalva: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    validate: false,
    formDelegatoAggintivo: {
      nome: "",
      cognome: "",
      qualifica: "",
      luogoNascita: "",
      dataNascita: "",
      cf: "",
    },
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.pulisciCampi();
    },
    pulisciCampi() {
      this.formDelegatoAggintivo = {
        nome: "",
        cognome: "",
        qualifica: "",
        luogoNascita: "",
        dataNascita: "",
        cf: "",
      };
    },
    uppercase() {
      this.formDelegatoAggintivo.cf =
        this.formDelegatoAggintivo.cf.toUpperCase();
    },
    uppercasePrimaLetteraNome() {
      if (this.formDelegatoAggintivo.nome) {
        this.formDelegatoAggintivo.nome =
          this.formDelegatoAggintivo.nome[0].toUpperCase() +
          this.formDelegatoAggintivo.nome.slice(1);
      }
    },
    uppercasePrimaLetteraCognome() {
      if (this.formDelegatoAggintivo.cognome) {
        this.formDelegatoAggintivo.cognome =
          this.formDelegatoAggintivo.cognome[0].toUpperCase() +
          this.formDelegatoAggintivo.cognome.slice(1);
      }
    },
    async datiFromCF(cf) {
      let anagrafica = await CorsiRepository.getAnagraficaStudente(
        this.idStrutture,
        cf
      );
      let data = anagrafica.subject.birthDate.date;
      let dataNascitaFormattata = data.split(" ");
      this.formDelegatoAggintivo.dataNascita = dataNascitaFormattata[0];
      if (anagrafica.isEstero) {
        this.formDelegatoAggintivo.luogoNascita = anagrafica.comune.name_it;
      } else {
        this.formDelegatoAggintivo.luogoNascita = anagrafica.comune.name_it;
      }
    },
    validateField() {
      this.validate = this.$refs.form.validate();
    },
    validazioneCF() {
      return [
        (v) =>
          (!!v && v.length === 16) ||
          "Il campo è obbligatorio e il formato del codice fiscale deve essere valido",
      ];
    },
    validazioneCampoNecessario() {
      return [(v) => !!v || "Campo richiesto"];
    },

    async addDelegato() {
      this.validateField();
      if (this.validate) {
        let delegato = { ...this.formDelegatoAggintivo };
        console.log(this.contoAggiuntivo);
        if (this.contoAggiuntivo != null) {
          this.$emit("ulteriore-delegato", delegato, this.contoAggiuntivo);
        } else {
          this.$emit("delegato-aggiunto", delegato);
        }
        this.snackbarText = "Delegato aggiunto";
        this.snackbarColor = "success";
        this.snackbar = true;
        this.validate = true;
        this.initialize();
        this.dialog = false;
      } else {
        this.snackbarText = "Compilare tutti i campi richiesti";
        this.snackbarColor = "red accent-2";
        this.snackbar = true;
      }
    },
  },
};
</script>
