<template>
  <v-container>
    <StepRendicontazioni :numero-step="5" />
    <h1 class="mb-4 sottotitolo">Documentazione attività svolta</h1>
    <v-main v-if="!loadingPage">
      <v-card elevation="5">
        <v-expansion-panels focusable hover v-if="corsiValidati.length">
          <v-expansion-panel
            v-for="(corso, index) in corsiValidati"
            :key="index"
          >
            <v-expansion-panel-header>
              <v-row>
                <v-col class="align-start" cols="12"
                  ><v-card-title>
                    <h3 class="">ID corso {{ corso.id }}</h3>
                  </v-card-title>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- Riepilogo corso-->
              <v-row class="pt-6">
                <v-col>
                  <h3 class="blue--text">Riepilogo corso</h3>
                </v-col>
              </v-row>
              <!-- prima riga -->
              <v-row class="">
                <v-col cols="3">CUP </v-col>
                <v-col cols="3">ID Corso </v-col>
                <v-col cols="3">Data di avvio del corso </v-col>
                <v-col cols="3">Data conclusione del corso </v-col>
              </v-row>
              <v-row class="font-weight-bold pb-8">
                <v-col cols="3"
                  >{{ corso.accordi[0].programmaOrientamento.cup }}
                </v-col>
                <v-col cols="3">{{ corso.id }} </v-col>
                <v-col cols="3">{{ corso.dataInizio | formatDate }} </v-col>
                <v-col cols="3">{{ corso.dataFine | formatDate }} </v-col>
              </v-row>
              <!-- seconda riga -->
              <v-row class="">
                <v-col cols="3">Istituzione </v-col>
                <v-col cols="3">Nome Legale rappresentante </v-col>
                <v-col cols="3">Cognome Legale rappresentante </v-col>
                <v-col cols="3">CF legale rappresentante </v-col>
              </v-row>
              <v-row class="font-weight-bold pb-8" v-if="legaleRappresentante">
                <v-col cols="3">
                  {{ corso.accordi[0].programmaOrientamento.nomeIstituzione }}
                </v-col>
                <v-col cols="3"
                  >{{ legaleRappresentante.nomeRappresentante }}
                </v-col>
                <v-col cols="3"
                  >{{ legaleRappresentante.cognomeRappresentante }}
                </v-col>
                <v-col cols="3"
                  >{{ legaleRappresentante.codiceFiscaleRappresentante }}
                </v-col>
              </v-row>
              <!-- terza riga -->
              <v-row class="">
                <v-col cols="3">Nome referente di corso Università/AFAM </v-col>
                <v-col cols="3"
                  >Cognome referente di corso Università/AFAM
                </v-col>
                <v-col cols="3"></v-col>
                <v-col cols="3"></v-col>
              </v-row>
              <v-row class="font-weight-bold pb-8">
                <v-col cols="3">{{ corso.referente.anagrafica.nome }} </v-col>
                <v-col cols="3"
                  >{{ corso.referente.anagrafica.cognome }}
                </v-col>
                <v-col cols="3"> </v-col>
                <v-col cols="3"> </v-col>
              </v-row>
              <!-- quarta riga -->
              <div v-for="(accordo, index) in corso.accordi" :key="index">
                <v-row class="">
                  <v-col cols="3">Istituto scolastico </v-col>
                  <v-col cols="3">Tipologia istituto </v-col>
                  <v-col cols="3">Regione istituto scolastico</v-col>
                  <v-col cols="3">Città istituto scolastico</v-col>
                </v-row>
                <v-row class="font-weight-bold pb-8">
                  <v-col cols="3"
                    >{{ accordo.istituto.denominazioneScuola }}
                  </v-col>
                  <v-col cols="3"
                    >{{ accordo.istituto.tipologiaGradoIstruzioneScuola }}
                  </v-col>
                  <v-col cols="3">{{ accordo.istituto.regione }} </v-col>
                  <v-col cols="3"
                    >{{ accordo.istituto.descrizioneComune }}
                  </v-col>
                </v-row>
              </div>
              <!-- Quinta riga -->
              <v-row class="">
                <v-col cols="3">Nome referente scolastico </v-col>
                <v-col cols="3">Cognome referente scolastico </v-col>
                <v-col cols="3"></v-col>
                <v-col cols="3"></v-col>
              </v-row>
              <v-row class="font-weight-bold pb-8">
                <v-col cols="3">{{ corso.nomeReferenteScolastico }} </v-col>
                <v-col cols="3">{{ corso.cognomeReferenteScolastico }} </v-col>
                <v-col cols="3"></v-col>
                <v-col cols="3"> </v-col>
              </v-row>
              <!-- Sesta riga -->
              <v-row class="">
                <v-col cols="3">N° studenti coinvolti</v-col>
                <v-col cols="3">N° studenti coinvolti con DSA </v-col>
                <v-col cols="3">N° docenti scuola</v-col>
                <v-col cols="3"></v-col>
              </v-row>
              <v-row class="font-weight-bold pb-8">
                <v-col cols="3">{{ corso.numeroStudenti }} </v-col>
                <v-col cols="3">{{ corso.numeroStudentiDsa }} </v-col>
                <v-col cols="3">{{ corso.numeroDocenti }}</v-col>
                <v-col cols="3"></v-col>
              </v-row>

              <v-divider class="my-4"></v-divider>
              <!-- Component elenco lezioni -->
              <v-row class="my-4">
                <v-col>
                  <h3 class="blue--text">Elenco lezioni</h3>
                </v-col>
              </v-row>
              <ElencoLezioniComponent
                :id-corso="corso.id"
                :capofila="capofila"
              ></ElencoLezioniComponent>
              <v-row class="my-4">
                <v-col>
                  <h3 class="blue--text">Elenco studenti con attestato</h3>
                </v-col>
              </v-row>
              <StudentiConCertificatoComponent
                :id-corso="corso.id"
              ></StudentiConCertificatoComponent>
              <!--SEZIONE ACCORDO E SOTTO ACCORDO -->
              <v-row class="my-4">
                <v-col>
                  <h3 class="blue--text">Accordo</h3>
                </v-col>
              </v-row>
              <v-card v-for="(accordo, index) in corso.accordi" :key="index">
                <v-card-text>
                  <v-row class="">
                    <v-col cols="4">ID Accordo </v-col>
                    <v-col cols="4">Data inserimento accordo </v-col>
                    <v-col cols="4">Anno scolastico </v-col>
                  </v-row>
                  <v-row class="font-weight-bold">
                    <v-col cols="4">{{ accordo.id }} </v-col>
                    <v-col cols="4"
                      >{{ accordo.fileAccordo.dataInserimento | formatDate }}
                    </v-col>
                    <v-col cols="4"
                      >{{ corso.anno }}/{{ corso.anno + 1 }}
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="4">ID Sotto accordo </v-col>
                    <v-col cols="4">Data inserimento sotto accordo </v-col>
                    <v-col cols="4"> </v-col>
                  </v-row>
                  <v-row class="font-weight-bold">
                    <v-col cols="4"
                      ><span v-if="accordo.sottoAccordo">{{
                        accordo.sottoAccordo.id
                      }}</span>
                      <span v-else> Sotto-accordo non presente </span>
                    </v-col>
                    <v-col cols="4"
                      ><span v-if="accordo.sottoAccordo">{{
                        accordo.fileAccordo.dataInserimento | formatDate
                      }}</span>
                      <span v-else> -- </span>
                    </v-col>
                    <v-col cols="4"> </v-col>
                  </v-row>
                  <v-divider class="mt-4"></v-divider>
                  <v-row class="font-weight-bold my-2">
                    <v-col cols="6"
                      >{{ accordo.istituto.denominazioneScuola }}
                    </v-col>
                    <v-col cols="6" align="end">
                      <a
                        @click="
                          downloadPDF(
                            accordo,
                            accordo.fileAccordo.nomeOriginale
                          )
                        "
                      >
                        Scarica accordo
                        <v-icon> mdi-tray-arrow-down </v-icon>
                      </a>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                </v-card-text>
              </v-card>
              <FormatCorso :corso="corso" :capofila="capofila"></FormatCorso>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
      <v-card-actions>
        <div class="d-flex my-6">
          <v-row>
            <v-col class="pa-2 mr-auto">
              <v-btn @click="goOutRendicontioSection">
                Torna al menù principale.
              </v-btn>
            </v-col>
            <v-col class="pa-2">
              <v-btn
                class="primary v-btn--outlined v-btn--tile white--text"
                v-html="'Salva Bozza e Continua'"
                @click="dialogConferma = true"
              />
              <dialog-conferma
                :dialog.sync="dialogConferma"
                @callback="salvaBozzaInserimentoRendiconto"
                @close-dialog="dialogConferma = false"
              />
            </v-col>
            <v-col>
              <v-btn v-html="'Continua senza salvare'" @click="goToNextStep" />
            </v-col>
          </v-row>
          <v-snackbar
            :color="snackbar.color"
            v-model="snackbar.value"
            max-width="200"
            max-height="200"
          >
            {{ snackbar.text }}</v-snackbar
          >
        </div>
      </v-card-actions>
    </v-main>
    <v-main v-else>
      <v-progress-linear indeterminate color="blue" />
    </v-main>
  </v-container>
</template>
<script>
import StepRendicontazioni from "@/components/Rendiconti/StepRendicontazioni";
import DialogConferma from "@/components/DialogConferma";
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import RendicontiRepository from "@/api/istituto/RendicontiRepository";
import StudentiConCertificatoComponent from "@/components/Rendiconti/StudentiConCertificato";
import FormatCorso from "@/components/Rendiconti/FormatCorso";
import ElencoLezioniComponent from "@/components/Rendiconti/ElencoLezioniComponent";
import ScuoleAccordoRepository from "@/api/istituto/ScuoleAccordoRepository";
import RichiesteRepository from "@/api/istituto/RichiesteRepository";
import CodiceFiscale from "codice-fiscale-js";
export default {
  name: "documentazioneAttivitaRendiconto",
  components: {
    ElencoLezioniComponent,
    StudentiConCertificatoComponent,
    FormatCorso,
    StepRendicontazioni,
    DialogConferma,
  },
  props: {
    capofila: {
      type: String,
      required: true,
    },
    operazione: {
      type: String,
      required: true,
      default: "modifica",
    },
  },
  computed: {
    ...mapState(["idStrutture", "anno", "user", "idRendiconto"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleConsulenza",
      "isRoleLegale",
    ]),
  },
  watch: {
    idStrutture() {
      return this.initialize();
    },
    anno() {
      return this.initialize();
    },
  },
  data: () => ({
    loadingPage: false,
    rendiconto: null,
    legaleRappresentante: null,
    corsiValidati: [],
    formRendiconto: {
      currentStep: 5,
    },
    snackbar: {
      color: null,
      value: false,
      text: null,
    },
    dialogConferma: false,
    validForm: false,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.idRendiconto && this.idStrutture && this.anno) {
        this.getRendiconto();
      }
    },
    getRendiconto() {
      RendicontiRepository.getSingoloRendiconto(
        this.idStrutture,
        this.idRendiconto
      ).then((data) => {
        if (data) {
          this.rendiconto = data;
          const corsiRendiconto = data.corsiRendiconto.map((element) => {
            return element.corso;
          });
          this.getLegaleRappresentanteAttivo();
          this.corsiValidati = corsiRendiconto;
          this.formRendiconto.corsiSelezionati = corsiRendiconto;
        }
      });
    },

    async salvaBozzaInserimentoRendiconto() {
      const formRendiconto = this.formRendiconto;
      formRendiconto.currentStep = 5;
      const formData = new FormData();
      formData.append("rendiconto", JSON.stringify(this.formRendiconto));
      RendicontiRepository.modificaRendiconto(
        this.idStrutture,
        this.idRendiconto,
        5,
        formData
      ).then((data) => {
        if (data) {
          this.goToNextStep();
        }
      });
    },
    goToNextStep() {
      this.$router.push({
        name: "firmaDocumentazioneRendiconto",
        params: { capofila: this.capofila, operazione: "modifica" },
      });
    },
    goOutRendicontioSection() {
      this.$store.commit("setIdRendiconto", null);
      if (!this.idRendiconto) {
        this.$router.push({
          name: "RendicontiView",
          params: {
            capofila: this.capofila,
          },
        });
      }
    },
    setPropertySnackbar(esito, testo) {
      this.snackbar.color = esito ? "success" : "red accent-2";
      this.snackbar.text = testo;
      this.snackbar.value = true;
    },
    async downloadPDF(scuolaAccordo, nomeFileOriginale) {
      return ScuoleAccordoRepository.downloadPDFAccordo(
        this.idStrutture,
        scuolaAccordo.id,
        nomeFileOriginale
      );
    },
    async getLegaleRappresentanteAttivo() {
      const idProgrammaOrientamento = this.rendiconto.programma.id;
      const legaleRappresentante =
        await RichiesteRepository.getLegaleRappresentante(
          this.idStrutture,
          idProgrammaOrientamento
        );
      if (legaleRappresentante) {
        this.getDatiLegaleRappresentante(legaleRappresentante);
      }
    },
    getDatiLegaleRappresentante(legaleRappresentante) {
      let datiMancantiLegale = null;
      try {
        datiMancantiLegale = new CodiceFiscale(
          legaleRappresentante.codiceFiscaleRappresentante
        );
        // eslint-disable-next-line no-empty
      } catch (err) {}

      if (datiMancantiLegale) {
        let comuneNascita = datiMancantiLegale.birthplace.nome;
        let dataNascita = datiMancantiLegale.birthday;
        this.legaleRappresentante = legaleRappresentante;
        this.legaleRappresentante.comuneNascita = comuneNascita;
        this.legaleRappresentante.dataNascita = new Date(
          dataNascita
        ).toLocaleDateString();
      } else {
        this.legaleRappresentante = legaleRappresentante;
      }
    },
  },
};
</script>
<style scoped>
.sottotitolo {
  color: #1a237e;
  margin-left: 1%;
  font-weight: bold;
}
</style>
