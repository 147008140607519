<template>
  <div>
    <v-row>
      <v-col cols="4">
        <!--Studenti censiti-->
        <v-row>
          <v-col>
            <h3 class="pb-0">Studenti immatricolati</h3>
            <span> {{ studenti.immatricolati }} </span>
          </v-col>
        </v-row>
        <!--Studenti immatricolati-->
        <v-row>
          <v-col>
            <h3 class="pb-0">Studenti censiti</h3>
            <span> {{ studenti.censiti }} </span>
          </v-col>
        </v-row>
        <!--Studenti immatricolati in altra istituzion-->
        <v-row>
          <v-col>
            <h3 class="pb-0">Studenti censiti in altra istituzione</h3>
            <span> {{ studenti.censitiAltra }} </span>
          </v-col>
        </v-row>
        <!--Studenti NON immatricolati-->
        <v-row>
          <v-col>
            <h3 class="pb-0">Studenti NON censiti</h3>
            <span> {{ studenti.nonCensiti }} </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="8">
        <Doughnut
          id="ReportStudentiCensiti"
          :options="chartOptions"
          :data="chartData"
          v-if="studenti"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Doughnut } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "ReportStudentiImmatricolatiChart",
  components: { Doughnut },
  props: {
    studenti: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context) {
              let dataset = context.dataset;
              let total = dataset.data.reduce((sum, value) => sum + value, 0);
              let currentValue = context.raw;
              let percentage = ((currentValue / total) * 100).toFixed(2);
              return percentage + "%";
            },
          },
        },
      },
    },
  }),
  computed: {
    chartData() {
      return {
        labels: [
          "Studenti censiti",
          "Studenti censiti in altra istituzione",
          "Studenti NON censiti",
        ],
        datasets: [
          {
            backgroundColor: ["#d92f7d", "#023a83", "#3acbc6"],
            data: [
              this.studenti.censiti,
              this.studenti.censitiAltra,
              this.studenti.nonCensiti,
            ],
          },
        ],
      };
    },
  },
};
</script>
