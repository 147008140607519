<template>
  <v-alert dense v-if="showAlertErrori" type="error" density="compact">
    È stata riscontrata la presenza di studenti con Codice Fiscale Errato o non
    ammissibili. Si prega di correggere. Nel caso di mancata correzione dei dati
    non sarà possibile terminare il corso
  </v-alert>
</template>

<script>
import { mapState } from "vuex";
import CorsiRepository from "@/api/istituto/CorsiRepository";

export default {
  name: "AlertConvalidaCfCorso",
  computed: {
    ...mapState(["anno", "idStrutture"]),
  },
  props: {
    idCorso: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    showAlertErrori: false,
    errori: false,
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.studentiConvalidatiCorso();
    },
    async studentiConvalidatiCorso() {
      this.errori = await CorsiRepository.getStudentiConvalidatiCorso(
        this.idStrutture,
        this.idCorso,
        this.anno
      );
      if (this.errori) this.showAlertErrori = true;
      if (this.errori) this.$emit("presenti-errori");
    },
  },
};
</script>

<style scoped></style>
