<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-container>
    <div>
      <BreadcrumbComponent :breadcrumbs="breadcrumbs" v-if="capofila" />
      <br />
      <h1>Registrazione presenze corso</h1>
      <h4
        v-html="'Hai selezionato il corso con id ' + parseInt(this.idCorso)"
      />
      <p>Seleziona la lezione direttamente sul calendario</p>
      <br />
    </div>
    <v-main>
      <v-card :loading="loadingPage" class="mx-auto mt-8 px-7 pb-7">
        <v-card-title v-html="'STATO AVANZAMENTO DEL CORSO'" />
        <v-row>
          <v-progress-linear v-model="oreRegistrate.percentuale" height="25">
            <strong>{{ Math.ceil(calcolaPercentualeAvanzamento) }}%</strong>
          </v-progress-linear>
        </v-row>
        <v-row>
          <v-col sm="2" md="2">
            <v-icon color="blue"> mdi-square</v-icon>
            <span> Ore Erogate</span>
          </v-col>
          <v-col sm="2" md="2">
            <v-icon color="blue lighten-4"> mdi-square</v-icon>
            <span> Ore Previste</span>
          </v-col>
        </v-row>
      </v-card>
    </v-main>
    <!----- -------------------------------- Card 2  ------------------------------- ----->
    <v-main v-if="studentiCorso && corso">
      <v-card :loading="loadingPage">
        <v-form
          @submit="onSubmitRegistraLezione"
          ref="formPresenza"
          v-model="valid"
          lazy-validation
          :readonly="isFormReadOnly"
        >
          <v-main>
            <v-card-title v-html="'CALENDARIO LEZIONI'" />
            <v-row justify="space-around">
              <v-col sm="6" md="6">
                <v-date-picker
                  type="date"
                  width="500"
                  v-model="formPresenza.data"
                  @change="cercaLezione"
                  :events="giorniLezioniFatte"
                  :event-color="getColorDateToShow"
                  :rules="rulesForm.valueFormData"
                  :min="corso.dataInizio"
                  :max="dataMaxCalendar"
                  locale="it-IT"
                />
              </v-col>
              <v-col sm="5" md="5">
                <v-row>
                  <v-text-field
                    label="Inserire le ore di presenza"
                    type="NUMBER"
                    v-model.number="formPresenza.oreErogate"
                    @input="setOreSvolteStudenti"
                    @change="setOreSvolteStudenti"
                    :rules="rulesForm.valueFormOre"
                    min="1"
                  />
                </v-row>
                <v-row>
                  <v-checkbox
                    v-model="formPresenza.inPresenza"
                    label="In Presenza"
                    :disabled="disabilitaCheckBoxPresenza"
                  />
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="2" md="2">
                <span>
                  <v-icon color="green lighten-1"> mdi-circle </v-icon> Lezioni
                  registrate
                </span>
              </v-col>
              <v-col sm="4" md="4">
                <span>
                  <v-icon color="amber lighten-2"> mdi-circle </v-icon>
                  Lezione non registrata correttamente
                </span>
              </v-col>
            </v-row>
          </v-main>
          <!---- CARD 4 -->
          <v-main>
            <v-card-title v-html="'ELENCO LEZIONI REGISTRATE'" />
            <v-data-table
              :headers="headersElencoLezioniRegistrate"
              :items="lezioniCorso"
              :single-select="false"
              :items-per-page="5"
              class="elevation-1 ma-1"
              :loading="loadingLezioni"
            >
              <template v-slot:item.data="{ item }">
                <span v-html="convertData(item.data)" />
              </template>
              <template v-slot:item.inPresenza="{ item }">
                {{ item.inPresenza ? "Si" : "No" }}
              </template>
              <template v-slot:item.eliminaLezione="{ item }">
                <div v-if="finestraCompilazione">
                  <v-icon
                    v-if="!corso.terminato"
                    small
                    class="mr-2"
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </div>

                <DialogConferma
                  @callback="deleteLezione"
                  :dialog.sync="dialogDelete"
                  :text.sync="dialogText"
                  @close-dialog="dialogDelete = false"
                />
              </template>
              <template v-slot:no-data> nessun risultato </template>
            </v-data-table>
          </v-main>
          <!----- -------------------------------- Card 3 ------------------------------- ----->
          <v-main>
            <v-card-title v-html="'ELENCO STUDENTI-DOCENTI'" />
            <v-alert
              v-if="operazione === 'modifica' && showAlertCardDocenti"
              type="warning"
              width="1000"
            >
              Attenzione! Per la lezione selezionata non è stata registrata la
              presenza di alcun docente.<br />
              Modifica la lezione, segnando le presenze non inserite!
            </v-alert>
            <v-alert
              v-if="
                operazione === 'modifica' &&
                showAlertCardStudenti &&
                corso.corsiAssociati &&
                corso.corsiAssociati.length === 0
              "
              type="warning"
              width="1000"
            >
              Attenzione! Per la lezione selezionata non è stata registrata la
              presenza di alcun studente.<br />
            </v-alert>
            <!-- L'inserimento senza studenti è concesso solo ai corsi associati! PROGMINI-5741: rimozione corsiAssociati -->
            <v-tabs v-model="tab" grow>
              <v-tab key="studenti" v-html="'Studenti'" />
              <v-tab key="docenti" v-html="'Docenti Ateneo / Istituto Afam'" />
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item key="studenti">
                <v-row class="my-4">
                  <v-col col="8" class="px-8">
                    <v-text-field
                      solo
                      v-model="ricerca"
                      append-icon="mdi-magnify"
                      label="Cerca per codice fiscale, nome o cognome"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-data-table
                  :headers="headersTableStudenti"
                  :items="studentiCorso"
                  :search="ricerca"
                  :single-select="false"
                  :items-per-page="-1"
                  hide-default-footer
                  item-key="cf"
                  class="elevation-1 ma-1"
                  ><!-- eslint-disable -->
                  <template v-slot:header.presenza="{ header }">
                      <v-simple-checkbox
                        color="blue"
                        v-model="checkboxStudenti"
                        @input="setPresenzeStudenti"
                        @change="setPresenzeStudenti"/>
                  </template>
                  <template v-slot:item.presenza="{ item }">
                        <v-simple-checkbox
                          v-model="item.presenza"
                          color="blue"
                          :rules="[ validateFieldOreStudentiPresenza(item) ]"
                        />
                  </template>
                  <template v-slot:item.oreSvolte=" { item }">
                    <v-text-field
                        type="number"
                        min="0"
                        label="Ore Svolte"
                        :max="formPresenza.oreErogate"
                        :disabled="!item.presenza"
                        v-model.number="item.oreSvolte"
                        :rules="[ validateFieldOreStudentiPresenza(item) ]"
                    />
                  </template>
                  <!--<template v-slot:item.nomeCognome="{ item }">
                    <span>{{ item.nome }} {{ item.cognome }}</span>
                  </template>-->
                  <template v-slot:item.dataNascita="{ item }">
                    <span v-if="item.dataNascita" v-html="convertData(item.dataNascita)"/>
                    <span v-else> Non inserita </span>
                  </template>
                  <template v-slot:item.istituto="{ item }">
                    <span>{{item.denominazioneScuola ?? corso.accordo.istituto.denominazioneScuola }}</span>
                  </template>
                  <!-- eslint-enable -->
                </v-data-table>
              </v-tab-item>
              <v-tab-item key="docenti">
                <v-main>
                  <v-data-table
                    :headers="headerstableDocenti"
                    v-model="formPresenza.docenti"
                    :items="docentiCorso"
                    :single-select="false"
                    :items-per-page="5"
                    checkbox-color="blue"
                    show-select
                    item-key="cf"
                    class="table"
                    dense
                  >
                    <!-- eslint-disable -->
                    <template v-slot:item.ssd="{ item }">
                      <span v-if="item.ssdSad" v-html="item.ssdSad" />
                      <span v-else v-html="'NON INSERITO'" class="text-warning" />
                    </template>
                    <!-- eslint enable -->
                  </v-data-table>
                </v-main>
              </v-tab-item>
            </v-tabs-items>
            <v-card-actions>
              <template v-if="!readOnly">
                <v-btn
                    v-html="'Registra lezione e presenze studenti'"
                    @click="dialogSalva = true"
                    dark
                    :disabled="!valid || settingsSnackbar.visible || isFormReadOnly || !finestraCompilazione"
                    color="#06c"
                />
              </template>
              <dialog-conferma
                  @callback="onSubmitRegistraLezione"
                  :dialog.sync="dialogSalva"
                  @close-dialog="dialogSalva = false"
                  :text-conferma="getTextConferma()"
              />
              <v-snackbar
                  v-model="settingsSnackbar.visible"
                  height="150"
                  width="600"
                  :color="settingsSnackbar.color"
              >
                {{ settingsSnackbar.textAlert }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                      color="blue"
                      text
                      v-bind="attrs"
                      @click="settingsSnackbar.visible = false"
                  >
                    <v-icon color="white">mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-snackbar>
            </v-card-actions>
          </v-main>
        </v-form>
      </v-card>
    </v-main>
    <v-main v-else>
      <v-alert type="warning">
        Non esistono studenti associati al corso. Impossibile procedere al
        registro delle lezioni</v-alert
      >
    </v-main>
  </v-container>
</template>
<script>
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import CorsiRepository from "@/api/istituto/CorsiRepository";
import DialogConferma from "@/components/DialogConferma.vue";
import { mapGetters } from "vuex";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";

export default {
  name: "registroPresenze",
  components: {
    BreadcrumbComponent,
    DialogConferma,
  },
  props: {
    capofila: {
      type: String,
      required: true,
    },
    idCorso: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleLegale",
      "isRoleReferenteProgramma",
      "isRoleLegaleCorso",
    ]),
    readOnly() {
      if (this.isRoleReferenteCorsi) return false;
      else if (
        this.isRoleReferenteProgramma ||
        this.isRoleLegale ||
        this.isRoleLegaleCorso
      ) {
        return true;
      } else if (this.isReadOnly) return true;
      return false;
    },
    breadcrumbs: function () {
      if (this.capofila) {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania/${this.capofila}`,
            exact: true,
            link: true,
          },
          {
            text: "Censimento, erogazione e validazione corsi",
            disabled: false,
            to: `/scrivania/${this.capofila}/corsi`,
            exact: true,
            link: true,
          },
          {
            text: "Erogazione corsi",
            disabled: false,
            to: `/scrivania/${this.capofila}/corsi/erogazione-corsi`,
            exact: true,
            link: true,
          },
          {
            text: "Presenze",
            disabled: true,
            to: `/scrivania/${this.capofila}/corsi/erogazione-corsi/${this.idCorso}/presenze`,
            exact: true,
            link: true,
          },
        ];
      } else {
        return [];
      }
    },
    calcolaPercentualeAvanzamento() {
      let totalSum = 0;
      if (this.lezioniCorso && this.lezioniCorso.length > 0) {
        totalSum = this.lezioniCorso.reduce((v, element) => {
          return v + element.oreErogate;
        }, 0);
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.oreRegistrate.intero = totalSum;
        totalSum = this.calcoloOreSuTotale(totalSum);
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return (this.oreRegistrate.percentuale = totalSum);
      } else {
        return 0;
      }
    },
    loading() {
      return this.loadingLezioni;
    },
  },
  data: () => ({
    ricerca: null,
    finestraCompilazione: null,
    corso: null,
    loadingPage: false,
    valid: false,
    studentiCorso: null,
    docentiCorso: null,
    labelEsitoOperazione: null,
    lezioniCorso: [],
    editedIndex: -1,
    loadingLezioni: false,
    lezioneDaAggiornare: null,
    giorniLezioniFatte: null,
    colorDateLezioni: null,
    dataMaxCalendar: null,
    showAlertCardStudenti: false,
    showAlertCardDocenti: false,
    isFormReadOnly: false,
    tab: null,
    dialogSalva: false,
    settingsSnackbar: {
      visible: false,
      textAlert: "",
      color: null,
    },
    disabilitaCheckBoxPresenza: false,
    oreRegistrate: {
      percentuale: 0,
      intero: 0,
    },
    operazione: null,
    formPresenza: {
      id: null,
      oreErogate: 1,
      data: null,
      inPresenza: false,
      studenti: [],
      docenti: [],
    },
    rulesForm: {
      valueFormData: [
        (v) => !!v || " E' obbligatoria la compilazione del campo",
      ],
      valueFormOre: [
        (v) => !!v || "E' obbligatoria la compilazione del campo",
        (v) =>
          (v && v > 0) ||
          "Il numero di ore erogate dev essere un valore positivo",
      ],
    },
    headersTableStudenti: [
      {
        text: "PRESENZA",
        align: "start",
        value: "presenza",
        sortable: false,
      },
      {
        text: "ORE SVOLTE",
        align: "start",
        value: "oreSvolte",
        sortable: false,
      },
      {
        text: "NOME E COGNOME",
        align: "start",
        value: "nomeCognome",
        sortable: false,
      },
      { text: "DATA DI NASCITA", value: "dataNascita" },
      { text: "LUOGO DI NASCITA", value: "nomeComuneNascita" },
      { text: "COMUNE DI RESIDENZA", value: "nomeComuneResidenza" },
      { text: "CODICE FISCALE", value: "cf" },
      { text: "GENERE", value: "genereStudente" },
      { text: "ISTITUTO", sortable: false, value: "istituto" },
      { text: "CLASSE", value: "classe" },
    ],
    checkboxStudenti: true,
    headerstableDocenti: [
      {
        text: "Nome",
        align: "start",
        value: "nome",
        sortable: false,
      },
      { text: "Cognome", value: "cognome" },
      /*{ text: "Data di Nascita", value: "dataNascita" },*/
      { text: "Codice fiscale", value: "cf" },
      { text: "Ruolo", value: "ruolo" },
      { text: "SSD /SAD", value: "ssd" },
    ],
    loadingTableStudenti: false,
    loadingTableDocenti: false,
    search: "",
    dialog: false,
    dialogDelete: false,
    dialogConferma: false,
    dialogText: "",
    elenco: null,
    headersElencoLezioniRegistrate: [
      { text: "Data Lezione", value: "data", sortable: false },
      {
        text: "Ore Svolte",
        value: "oreErogate",
        sortable: true,
      },
      { text: "Lezione in Presenza", value: "inPresenza", sortable: false },
      { text: "Elimina Lezione", value: "eliminaLezione" },
    ],
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      let idStrutture = this.$store.state.idStrutture;
      let anno = this.$store.state.anno;
      this.operazione = "inserimento";
      if (this.idCorso) {
        this.loadingPage = true;
      }
      this.getCorso(idStrutture, anno, this.idCorso);
      this.getLezioniCorso(idStrutture, this.idCorso);
      this.getStudentiCorso(idStrutture, this.idCorso);
      this.getDocentiCorso(idStrutture, this.idCorso);
      this.getFinestraCompilazione(idStrutture, anno);
      this.loadingPage = false;
    },
    async getFinestraCompilazione(idStrutture, anno) {
      const finestraCompilazione =
        await IstitutoRepository.getFinestraCompilazione(
          idStrutture,
          anno,
          "corsi-orientamento-ist"
        );
      if (finestraCompilazione !== "") {
        this.finestraCompilazione = finestraCompilazione;
      }
    },
    onSubmitRegistraLezione() {
      if (!this.$refs.formPresenza.validate() || !this.formPresenza.data) {
        return this.setValueSnackBar(
          true,
          "Errore nella richiesta. Verifica la compilazione dei campi richiesti!",
          "red accent-2"
        );
      }
      // controllo sul numero di ore totali erogate che non deve essere superiore a 15
      let calcoloOre =
        this.oreRegistrate.intero + parseInt(this.formPresenza.oreErogate);
      if (this.operazione === "modifica") {
        calcoloOre -= parseInt(this.lezioneDaAggiornare.oreErogate);
      }
      calcoloOre = this.calcoloOreSuTotale(calcoloOre);
      if (calcoloOre > 100) {
        this.setValueSnackBar(
          true,
          " Le ore di lezione che hai inserito hanno superato il limite consentito di 15 ore.",
          "red accent-2"
        );
        return;
      }
      // controllo se il numero di ore erogate in lezioni non in presenza sia minore di 5
      if (this.lezioniCorso && !this.calcoloOreSvolteInPresenza()) {
        return this.setValueSnackBar(
          true,
          "Impossibile procedere con l'operazione! La somma delle ore svolte non in presenza risulta essere maggiore di 5. " +
            "Si ricorda che,per i corsi in modalità mista, " +
            "il totale delle ore svolte IN PRESENZA non può essere minore di 10, ossia  dei 2/3 del totale previsto.",
          "red accent-2"
        );
      }
      let idStrutture = this.$store.state.idStrutture;
      this.formPresenza.studenti = this.studentiCorso.filter(
        (element) => element.presenza === true
      );
      this.operazione === "inserimento"
        ? this.inserisciNuovaLezione(idStrutture)
        : this.modificaLezioneEsistente(idStrutture);
    },
    async inserisciNuovaLezione(idStrutture) {
      const formBody = new FormData();
      formBody.append("formLezione", JSON.stringify(this.formPresenza));
      await CorsiRepository.registraNuovaLezione(
        idStrutture,
        this.idCorso,
        formBody
      )
        .then((data) => {
          if (data) {
            this.setValueSnackBar(
              true,
              " La lezione in data " +
                this.formPresenza.data +
                " è stata creata con successo",
              "success"
            );
            this.loadingPage = false;
            this.resetValoriForm();
            this.initialize();
          }
        })
        .catch((reason) => {
          console.log(reason);
          this.settingsSnackbar = {
            visible: true,
            textAlert:
              "Un errore è stato riscontrato nella gestione della richiesta Si prega di contattare il supporto.",
            color: "red accent-2",
          };
        });
    },
    async modificaLezioneEsistente(idStrutture) {
      const idLezione = this.lezioneDaAggiornare.id;
      const formBody = new FormData();
      formBody.append("formLezione", JSON.stringify(this.formPresenza));
      await CorsiRepository.modificaLezione(
        idStrutture,
        this.idCorso,
        idLezione,
        formBody
      )
        .then((data) => {
          if (data) {
            this.setValueSnackBar(
              true,
              "La lezione registrata in data " +
                this.formPresenza.data +
                " è stata modificata con successo",
              "success"
            );
            this.loadingPage = false;
            this.resetValoriForm();
            this.initialize();
          }
        })
        .catch((reason) => {
          console.log(reason);
          this.setValueSnackBar(
            true,
            "Un errore è stato riscontrato nella gestione della richiesta. Si prega di contattare il supporto.",
            "red accent-2"
          );
        });
    },
    async getCorso(idStrutture, anno, corso) {
      CorsiRepository.getCorsoOrientamento(idStrutture, anno, corso).then(
        (data) => {
          this.corso = data;
          if (this.corso.tipoAttivita.id === 1) {
            this.formPresenza.inPresenza = true;
            this.disabilitaCheckBoxPresenza = true;
          }
          if (this.corso.terminato) {
            this.isFormReadOnly = true;
          }
          this.dataMaxCalendar = this.getMaxDataCalendar(this.corso.dataFine);
        }
      );
    },
    async getLezioniCorso(idStrutture, idCorso) {
      this.loadingLezioni = true;
      CorsiRepository.getLezioniCorso(idStrutture, idCorso).then((data) => {
        if (data && data.length > 0) {
          this.lezioniCorso = data;
          if (this.lezioniCorso.length > 0) {
            this.getDateToShow();
          }
        } else {
          return [];
        }
      });
      this.loadingLezioni = false;
    },
    getStudentiCorso(idStrutture, idCorso) {
      CorsiRepository.getStudentiCorso(idStrutture, idCorso).then((data) => {
        if (data && data.length > 0) {
          this.studentiCorso = data.map((element) => {
            let studente = element;
            studente.oreSvolte = 1;
            studente.presenza = true;
            studente.nomeCognome = `${studente.nome} ${studente.cognome}`;
            return studente;
          });
        }
        this.loadingTableStudenti = true;
      });
    },
    getDocentiCorso(idStrutture, idCorso) {
      CorsiRepository.getDocentiCorso(idStrutture, idCorso).then((data) => {
        if (data && data.length > 0) {
          this.docentiCorso = data;
        } else {
          this.docentiCorso = [];
        }
      });
    },
    getDateToShow() {
      this.colorDateLezioni = [];
      this.giorniLezioniFatte = this.lezioniCorso.map((element) => {
        const data = element.data.split("T");
        const dataToConvert = new Date(data[0]);
        return dataToConvert.toISOString().substring(0, 10);
      });
    },
    getColorDateToShow(date) {
      let indexData = this.giorniLezioniFatte.indexOf(String(date));
      let lezioniCorso = this.lezioniCorso[indexData];
      //controllo se ci sono docenti e studenti memorizzati nella lezione nell'indice trovato
      if (lezioniCorso != null) {
        let docenti = this.lezioniCorso[indexData].docenti;
        let lezioniStudenti = this.lezioniCorso[indexData].lezioniStudenti; //PROGMINI-5117 insert lezioni senza studenti presenti
        if (
          docenti &&
          docenti.length > 0 &&
          lezioniStudenti &&
          lezioniStudenti.length > 0
        ) {
          return "green lighten-1";
        } else if (
          this.corso.corsiAssociati &&
          this.corso.corsiAssociati.length > 0 &&
          docenti &&
          docenti.length > 0
        ) {
          return "green lighten-1";
        } else {
          return "amber lighten-2";
        }
      }
    },
    convertData(data) {
      if (data) {
        return new Date(data).toLocaleDateString("nl-NL", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        });
      }
    },
    getMaxDataCalendar(dataFineCorso) {
      let dateOdierna = new Date();
      dataFineCorso = new Date(dataFineCorso);
      const dataFineCorsoToString = dataFineCorso.toLocaleDateString("it-IT", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
      return dateOdierna.getTime() >= dataFineCorso.getTime()
        ? this.convertDataToIso(dataFineCorsoToString)
        : dateOdierna.toISOString().substring(0, 10);
    },
    cercaLezione() {
      if (
        this.giorniLezioniFatte &&
        this.giorniLezioniFatte.includes(this.formPresenza.data)
      ) {
        const dataLezione = new Date(this.formPresenza.data)
          .toISOString()
          .substring(0, 10);
        const lezione = this.lezioniCorso.find(
          (item) => item.data.substring(0, 10) === dataLezione
        );
        if (lezione) {
          this.studentiCorso.map((element) => {
            let idStudente = parseInt(element.idStudente);
            let studenteGiaPresente = lezione.lezioniStudenti.find(
              (item) => item.studente.id === idStudente
            );
            if (studenteGiaPresente) {
              element.presenza = true;
              element.oreSvolte = studenteGiaPresente.oreSvolte;
            } else {
              element.presenza = false;
            }
            return element;
          });
          this.loadingTableStudenti = true;
          this.loadingTableDocenti = true;
          this.lezioneDaAggiornare = lezione;
          this.formPresenza.oreErogate = lezione.oreErogate;
          this.formPresenza.inPresenza = lezione.inPresenza;
          this.formPresenza.docenti = lezione.docenti;
          this.operazione = "modifica";
          //this.formPresenza.docenti.length < 1 ||
          this.lezioneDaAggiornare.lezioniStudenti.length < 1
            ? (this.showAlertCardStudenti = true)
            : (this.showAlertCardStudenti = false);
          this.lezioneDaAggiornare.docenti.length < 1
            ? (this.showAlertCardDocenti = true)
            : (this.showAlertCardDocenti = false);
        }
      } else {
        this.operazione = "inserimento";
        this.formPresenza.studenti = [];
        this.formPresenza.docenti = [];
      }
    },
    calcoloOreSuTotale(numeroOreSvolte) {
      return (numeroOreSvolte / 15) * 100;
    },
    resetValoriForm() {
      this.formPresenza.studenti = [];
      this.formPresenza.docenti = [];
      this.formPresenza.oreErogate = 1;
      this.formPresenza.data = null;
    },
    calcoloOreSvolteInPresenza() {
      let tipoAttivita = this.corso.tipoAttivita.id;
      let oreErogate = this.formPresenza.oreErogate;
      let inPresenza = this.formPresenza.inPresenza;

      if (tipoAttivita !== 2) {
        return true;
      } else {
        var countOreNonInPresenza = 0;
        this.lezioniCorso.forEach((item) => {
          if (!item.inPresenza) {
            countOreNonInPresenza += item.oreErogate;
          }
        });
        if (
          this.operazione === "modifica" &&
          !this.lezioneDaAggiornare.inPresenza
        ) {
          countOreNonInPresenza -= parseInt(
            this.lezioneDaAggiornare.oreErogate
          );
        }
        if (!inPresenza) {
          countOreNonInPresenza += parseInt(oreErogate);
        }
        return countOreNonInPresenza <= 5;
      }
    },
    setValueSnackBar(visible, text, color) {
      return (this.settingsSnackbar = {
        visible: visible,
        textAlert: text,
        color: color,
      });
    },
    getTextConferma() {
      let studentiSelezionati = this.studentiCorso.filter(
        (element) => element.presenza === true
      );

      let stringaAssenzeStudenti =
        studentiSelezionati.length > 0
          ? ""
          : " In questa lezione tutti gli studenti sono assenti. ";

      let stringaDialog =
        this.operazione === "inserimento"
          ? "Si sta procedendo con l'operazione di inserimento di una nuova lezione in data " +
            this.formPresenza.data +
            "."
          : "Si sta procedendo con l'operazione di modifica di una lezione già esistente in data " +
            this.formPresenza.data +
            ".";

      return (
        stringaDialog + stringaAssenzeStudenti + " Confermare l'operazione? "
      );
    },
    checkStudenteMemorizzato(item) {
      let key = this.operazione !== "inserimento" ? "id" : "idStudente";
      return (
        this.formPresenza.studenti.findIndex(
          (element) => element[key] === item.idStudente
        ) < 0
      );
    },
    // setto le ore di presenza degli studenti al valore di ore erogate
    setOreSvolteStudenti(oreErogate) {
      this.studentiCorso.forEach((element) => {
        element.oreSvolte = oreErogate;
      });
    },
    // setto le presenze degli studenti in base al valore del checkbox
    setPresenzeStudenti(value) {
      this.studentiCorso.map((element) => {
        element.presenza = value;
        return element;
      });
    },
    convertDataToIso(dataFineCorso) {
      const arrValueData = dataFineCorso.split("/");
      const anno = arrValueData[2];
      const mese = arrValueData[1];
      const day = arrValueData[0];

      return anno + "-" + mese + "-" + day;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async deleteLezione() {
      let idStrutture = this.$store.state.idStrutture;
      CorsiRepository.deleteLezione(
        idStrutture,
        parseInt(this.idCorso),
        this.editedItem.id
      ).then(() => {
        this.editedIndex = this.lezioniCorso.indexOf(this.editedItem);
        this.lezioniCorso.splice(this.editedIndex, 1);
        this.closeDelete();
        this.loadingPage = false;
        this.resetValoriForm();
        this.initialize();
      });
    },
    deleteItem(item) {
      this.editedIndex = this.lezioniCorso.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    validateFieldOreStudentiPresenza(item) {
      if (item.presenza) {
        if (!item.oreSvolte) {
          return "Ore associate allo studente non inserite.";
        }
        if (item.oreSvolte > this.formPresenza.oreErogate) {
          return "Ore inserite maggiori di quelle associate alla lezione.";
        }
        return true;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.v-container {
  margin: 0 auto;
}

.v-card {
  margin: 0 auto;
}
.v-alert {
  margin: 0 auto;
  text-align: center;
}
.v-progress-linear {
  pointer-events: none;
}
.table-studenti {
  font-size: 6px;
}
.v-snackbar {
  text-align: left;
}
</style>
