<template>
  <v-row justify="center">
    <v-dialog :value="dialog" persistent max-width="500">
      <v-card>
        <v-toolbar color="primary" dark class="text-h6">{{ title }}</v-toolbar>
        <v-card-text>
          <div class="pa-4">{{ textConferma }}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="close"> Annulla </v-btn>
          <v-btn color="green darken-1" text @click="handleOk"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ProgrammiRepository from "@/api/istituto/ProgrammiRepository";
export default {
  name: "DialogEliminaFile",
  props: {
    // editedId: Number,
    dialog: Boolean,
    title: {
      type: String,
      required: false,
      default: "Attenzione!",
    },
    textConferma: {
      type: String,
      required: false,
      default: "Desideri confermare l'operazione selezionata?",
    },
    textSuccess: {
      type: String,
      required: false,
      default: "Operazione conclusa con successo!",
    },
    textError: {
      type: String,
      required: false,
      default: "Errore durante l'operazione. Contattare il supporto.",
    },
    response: {
      type: Object,
      required: false,
      default: null,
    },
    idProgramma: {
      type: Number,
      required: false,
    },
    tipoFile: {
      type: Number,
      required: false,
    },
  },
  watch: {
    response: {
      deep: true,
      handler: function () {
        //se cambia la response devo gestire la risposta
      },
    },
  },
  computed: {},
  data() {
    return {};
  },

  methods: {
    async deleteFileAllegato4Programma() {
      let idStrutture = this.$store.state.idStrutture;
      try {
        await ProgrammiRepository.deleteFileAllegato4Programma(
          idStrutture,
          this.idProgramma
        );
        this.$emit("file-eliminato");
      } catch (e) {
        console.log(e);
      }
    },
    async deleteFileAllegato5Programma() {
      let idAssenzaDoppioFin = this.idProgramma;
      let idStrutture = this.$store.state.idStrutture;
      try {
        await ProgrammiRepository.deleteFileAllegato5Programma(
          idStrutture,
          idAssenzaDoppioFin
        );
        this.$emit("file-eliminato");
      } catch (e) {
        console.log(e);
      }
    },
    async deleteFileAllegato6Programma() {
      let idComunicazioneTitolarita = this.idProgramma;
      let idStrutture = this.$store.state.idStrutture;
      try {
        await ProgrammiRepository.deleteFileAllegato6Programma(
          idStrutture,
          idComunicazioneTitolarita
        );
        this.$emit("file-eliminato");
      } catch (e) {
        console.log(e);
      }
    },
    async deleteTitolareEffettivo() {
      let idTitolare = this.idProgramma;
      let idStrutture = this.$store.state.idStrutture;
      try {
        await ProgrammiRepository.deleteTitolareEffettivo(
          idStrutture,
          idTitolare
        );
        this.$emit("file-eliminato");
      } catch (e) {
        console.log(e);
      }
    },
    handleOk(event) {
      switch (this.tipoFile) {
        case 1:
          this.deleteFileAllegato4Programma();
          break;
        case 2:
          this.deleteFileAllegato5Programma();
          break;
        case 3:
          this.deleteFileAllegato6Programma();
          break;
        case 4:
          this.deleteTitolareEffettivo();
          break;
        default:
          console.log("Azione non riconosciuta");
      }
      // Prevent modal from closing
      event.preventDefault();
      this.$emit("close-dialog");
    },
    close() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style scoped></style>
