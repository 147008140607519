import Repository from "../Repository";

export default {
  async getStudentiCensiti(idStrutture, anno) {
    const url = `api/istituto/${idStrutture}/immatricolazioni/${anno}/studenti-censiti`;
    return (await Repository.get(url)).data;
  },
  async esportaExcel(idStrutture, anno) {
    const url = `api/istituto/${idStrutture}/immatricolazioni/${anno}/esporta-excel`;
    const res = await Repository.get(url, {
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      const extension = ".xlsx";
      fileLink.download = `Report_immatricolazioni_${idStrutture}${extension}`;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async getStudentiImmatricolati(idStrutture, anno) {
    const url = `api/istituto/${idStrutture}/immatricolazioni/${anno}/studenti-immatricolati`;
    return (await Repository.get(url)).data;
  },
};
