<template>
  <v-alert dense outlined elevation="2" v-if="showAlert" type="error">
    <h4>Attenzione:</h4>
    <p>
      il numero di studenti censito per una o più scuole selezionate all'interno
      del corso supera il numero di studenti dichiarati nell'accordo-scuola."
      inseriti nell’accordo: <br />
    </p>
    <p v-for="(accordo, index) in listaAccordi" :key="index">
      {{
        accordo.codiceScuola +
        " -- " +
        accordo.denominazioneScuola +
        " -- " +
        accordo.tipologiaGradoIstruzioneScuola +
        " -- " +
        accordo.regione +
        " -- " +
        accordo.descrizioneComune
      }}.
      <br />
      Alunni dichiarati nell'accordo: {{ accordo.numeroAlunniAccordo }} <br />
      Alunni Censiti per l'accordo: {{ accordo.numeroAlunniCensiti }}
    </p>
    <h4>Si invita il Referente per il programma ad integrare l'accordo.</h4>
  </v-alert>
</template>

<script>
import { mapState } from "vuex";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";

export default {
  name: "AlertConvalidaCf",
  computed: {
    ...mapState(["anno", "idStrutture"]),
  },
  props: {
    idCorso: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    keyStudenti: 0,
    showAlert: false,
    listaAccordi: [],
  }),
  mounted() {
    this.initialize();
  },
  watch: {
    keyStudenti() {
      this.initialize();
    },
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.anno !== 2022) {
        this.accordiStudenti();
      }
    },
    async accordiStudenti() {
      this.listaAccordi = await IstitutoRepository.getAccordiStudentiCensiti(
        this.idStrutture,
        this.anno,
        this.idCorso
      );
      console.log(this.listaAccordi);
      this.showAlert = this.listaAccordi.length > 0;
    },
  },
};
</script>

<style scoped></style>
