import Repository from "../Repository";

export default {
  async createRichiestaAnticipoSaldo(
    idStrutture,
    anno,
    capofila,
    programma,
    data
  ) {
    const url = `api/istituto/${idStrutture}/richieste/nuova-richiesta-anticipo-saldo/${anno}/${capofila}/${programma}`;
    const headers = { "Content-Type": "multipart/form-data" };
    return await Repository.post(url, data, { headers });
  },
  async getTipiErogazione() {
    const url = `api/generici/richieste-tipologie-erogazione`;
    return (await Repository.get(url)).data;
  },
  async getListaRichieste(idStrutture, anno, capofila) {
    const url = `api/istituto/${idStrutture}/richieste/anno/${anno}/${capofila}`;
    return (await Repository.get(url)).data;
  },
  async getFileAllegato(
    idStrutture,
    richiestaAnticipo,
    idFile,
    nomeFileOriginale
  ) {
    const url = `api/istituto/${idStrutture}/richieste/${richiestaAnticipo}/file/${idFile}`;
    const res = await Repository.get(url, {
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = nomeFileOriginale;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async downloadPdfPrecompilatoRichiesta(idStrutture, data) {
    const url = `api/istituto/${idStrutture}/richieste/pdf-richiesta`;
    const headers = { "Content-Type": "multipart/form-data" };
    const res = await Repository.post(url, data, {
      headers,
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = "PDF_Richiesta_anticipo_saldo.pdf";
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async getRichiestaAnticipoPresentata(idStrutture, anno, capofila) {
    const url = `api/istituto/${idStrutture}/richieste/anno/${anno}/${capofila}/richiesta-anticipo-presentata`;
    return (await Repository.get(url)).data;
  },
  async getLegaleRappresentante(idStrutture, idProgramma) {
    const url = `api/istituto/${idStrutture}/richieste/${idProgramma}/legale-rappresentante`;
    return (await Repository.get(url)).data;
  },
  async downloadFileRiepilogoLezioni(
    idStrutture,
    idCorso,
    tipoFile,
    bodyLezioniCorso
  ) {
    const url = `api/istituto/${idStrutture}/corsi/${idCorso}/riepilogo-lezioni/${tipoFile}`;
    const headers = { "Content-Type": "multipart/form-data" };
    const res = await Repository.post(url, bodyLezioniCorso, {
      headers,
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      const extension = tipoFile === 0 ? ".xlsx" : ".pdf";
      fileLink.download = "Riepilogo_corso_" + idCorso + extension;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async getContiBancariStruttura(idStrutture, anno) {
    const url = `api/istituto/${idStrutture}/richieste/conti-iban-tesoreria/anno/${anno}`;
    return (await Repository.get(url)).data;
  },
};
