<template>
  <v-container>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" v-if="capofila" />
    <v-row class="mb-4">
      <v-col>
        <h1>Lista dei programmi di orientamento</h1>
      </v-col>
    </v-row>
    <div
      v-if="scrivaniaViewMode > 1"
      class="pa-4"
      style="background-color: gainsboro"
    >
      <v-row class="pb-4">
        <v-col>
          <h4 v-if="this.anno < 2024">
            Anno Scolastico {{ this.anno }}/{{ this.anno + 1 }}
          </h4>
          <h4 v-else>Anno Scolastico {{ this.anno }}/{{ this.anno + 2 }}</h4>
        </v-col>
      </v-row>
      <v-card
        elevation="2"
        class="px-4 pb-4"
        v-if="idStrutture !== null"
        :loading="loading"
      >
        <v-row class="mt-2">
          <v-col>
            <h4 v-if="this.capofila === 'istituzione'">
              Programmi di Orientamento
            </h4>
            <h4 v-else class="font-weight-bold">
              Programmi in convenzione da <u>Capofila</u>
            </h4>
          </v-col>
        </v-row>
        <div class="mb-6">
          <v-row>
            <v-col cols="2" sm="2" md="2">
              <span class="font-weight-bold">CUP</span>
            </v-col>
            <v-col cols="2" sm="2" md="2">
              <span class="font-weight-bold">CLP</span>
            </v-col>
            <v-col cols="5" sm="5" md="5">
              <span class="font-weight-bold">Programma</span>
            </v-col>
            <v-col cols="1" sm="1" md="1">
              <span class="font-weight-bold">Anno</span>
            </v-col>
            <v-col cols="1" sm="1" md="1"> </v-col>
            <v-col cols="1" sm="1" md="1"> </v-col>
          </v-row>
          <div v-for="item in programmi" :key="item.id">
            <v-row>
              <v-col cols="2" sm="2" md="2">
                <span> {{ item.cup }} </span>
              </v-col>
              <v-col cols="2" sm="2" md="2">
                <span> {{ item.clp }} </span>
              </v-col>
              <v-col cols="5" sm="5" md="5">
                <span>
                  Programma di orientamento - {{ item.nomeIstituzione }}
                </span>
              </v-col>
              <v-col cols="1" sm="1" md="1">
                <span> {{ item.anno }} </span>
              </v-col>
              <v-col v-if="anno !== 2024" cols="1" sm="1" md="1">
                <a @click="getFileAllegato(item)">
                  <v-icon>mdi-paperclip</v-icon>
                </a>
              </v-col>
              <v-col v-else cols="1" sm="1" md="1">
                <div v-if="!item.trasmesso && finestraCompilazione">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <a v-on="on" @click="dialogTrasmetti = true">
                        <v-icon>mdi-send-outline</v-icon>
                      </a>
                    </template>
                    <span>Trasmetti</span>
                  </v-tooltip>
                </div>
              </v-col>
              <v-col cols="1" sm="1" md="1">
                <router-link
                  :to="{
                    name: 'modifica-programma',
                    params: { capofila: capofila, idProgramma: item.id },
                  }"
                >
                  <v-icon
                    v-if="
                      finestraCompilazione &&
                      (isRoleLegale || isRoleConsulenza) &&
                      !item.trasmesso
                    "
                    >mdi-square-edit-outline</v-icon
                  >
                  <v-icon v-else>mdi-eye</v-icon>
                </router-link>
              </v-col>
            </v-row>
          </div>
        </div>
        <div
          class="mt-6"
          v-if="
            this.inserimento &&
            finestraCompilazione &&
            (isRoleLegale || isRoleConsulenza)
          "
        >
          <router-link
            class="v-btn primary pa-2"
            :to="`/scrivania/${capofila}/programma-orientamento/inserisci`"
          >
            <v-icon left class="white--text"> mdi-plus </v-icon> Inserisci
            Programma di Orientamento
          </router-link>
        </div>
        <!--        <div class="mt-6" v-if="!this.inserimento">-->
        <div class="mt-6" v-if="false">
          <!-- <router-link
            class="v-btn error pa-2"
            :is="isDisabled ? 'span' : 'router-link'"
            :to="`/scrivania/${capofila}/programma-orientamento/modifica`"
          >
            <v-icon left class="white--text">
              mdi-clipboard-edit-outline
            </v-icon>
            Inserire le proposte di modifica al programma
          </router-link>-->
        </div>
      </v-card>
      <div class="my-4">
        <small>
          <i
            >Come previsto dal d.d. 1029/2024, art. 1, comma 5, è possibile
            proporre modifiche al programma per incrementare il target di alunni
            unicamente a seguito del <b>raggiungimento dell’85%</b> del target
            assegnato con il medesimo d.d. 1029/2024 entro il quarto periodo di
            rendicontazione</i
          >
        </small>
      </div>

      <v-card
        elevation="2"
        class="px-4 pb-4"
        v-if="
          this.capofila === 'istituzione' &&
          this.idStrutture !== null &&
          this.hasConvenzioni
        "
        :loading="loading"
      >
        <v-row class="mt-2">
          <v-col>
            <h4 class="font-weight-bold">
              Programmi in convenzione da <u>partner</u>
            </h4>
          </v-col>
        </v-row>
        <div class="mb-6">
          <v-row>
            <v-col cols="2" sm="2" md="2">
              <span class="font-weight-bold">CUP</span>
            </v-col>
            <v-col cols="2" sm="2" md="2">
              <span class="font-weight-bold">CLP</span>
            </v-col>
            <v-col cols="5" sm="5" md="5">
              <span class="font-weight-bold">Programma</span>
            </v-col>
            <v-col cols="1" sm="1" md="1">
              <span class="font-weight-bold">Anno</span>
            </v-col>
            <v-col cols="1" sm="1" md="1"> </v-col>
          </v-row>
          <div v-for="item in programmiPartner" :key="item.id">
            <v-row>
              <v-col cols="2" sm="2" md="2">
                <span> {{ item.cup }} </span>
              </v-col>
              <v-col cols="2" sm="2" md="2">
                <span> {{ item.clp }} </span>
              </v-col>
              <v-col cols="5" sm="5" md="5">
                <span>
                  Programma di orientamento - Convenzione con
                  {{ item.nomeIstituzione }}
                </span>
              </v-col>
              <v-col cols="1" sm="1" md="1">
                <span> {{ item.anno }} </span>
              </v-col>
              <v-col cols="1" sm="1" md="1">
                <a @click="getFileAllegato(item)">
                  <v-icon>mdi-paperclip</v-icon>
                </a>
              </v-col>
              <v-col cols="1" sm="1" md="1">
                <router-link
                  :to="{
                    name: 'modifica-programma',
                    params: {
                      capofila: 'capofila',
                      idProgramma: item.id,
                      isPartner: true,
                    },
                  }"
                >
                  <v-icon>mdi-eye</v-icon>
                </router-link>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card>
    </div>
    <AllegatiProgrammaComponet
      v-if="programmi.length && anno > 2023"
      :editable="(isRoleLegale || isRoleConsulenza) && !isTrasmesso"
      :capofila="capofila"
      :programmi="programmi"
      @refresh="initialize"
    ></AllegatiProgrammaComponet>
    <dialog-conferma
      @callback="trasmetti"
      :text-conferma="getTextConferma()"
      :dialog.sync="dialogTrasmetti"
      @close-dialog="dialogTrasmetti = false"
    />

    <v-snackbar
      v-model="snackbar"
      :timeout="4000"
      shaped
      :color="snackbarColor"
    >
      <span style="white-space: pre-line">{{ snackbarText }}</span>

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ProgrammiRepository from "@/api/istituto/ProgrammiRepository";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";
import AllegatiProgrammaComponet from "@/components/AllegatiProgrammaComponent.vue";
import DialogConferma from "@/components/DialogConferma.vue";
import DichiarazioneFlussiRepository from "@/api/istituto/DichiarazioneFlussiRepository";

export default {
  name: "ProgrammaOrientamentoView",
  components: {
    DialogConferma,
    AllegatiProgrammaComponet,
    BreadcrumbComponent,
  },
  data: () => ({
    isTrasmesso: false,
    idProgramma: null,
    loadingProgrammi: false,
    loadingProgrammiDiCuiPartner: false,
    inserimento: true,
    isDisabled: true,
    isCapofila: false,
    programmi: [],
    dichiarazioni: [],
    finestraCompilazione: null,
    programmiPartner: [],
    hasConvenzioni: false,
    dialogTrasmetti: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    erroriCompilazione: { value: false, arrayError: [] },
  }),
  props: {
    capofila: {
      type: String,
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["idStrutture", "anno", "scrivaniaViewMode"]),
    ...mapGetters(["isRoleLegale", "isRoleConsulenza", "isReadOnly"]),
    loading: function () {
      return this.loadingProgrammi;
    },
    breadcrumbs: function () {
      if (this.capofila) {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania/${this.capofila}`,
            exact: true,
            link: true,
          },
          {
            text: "Programma Orientamento",
            disabled: true,
            to: `/scrivania/${this.capofila}/programma-orientamento`,
            exact: true,
            link: true,
          },
        ];
      }
      return [];
    },
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        this.getProgrammi();
        this.getFinestraCompilazione();
      }
    },
    async getProgrammi() {
      this.loadingProgrammi = true;
      this.programmi = await ProgrammiRepository.getProgrammi(
        this.idStrutture,
        this.anno,
        this.capofila
      );
      this.loadingProgrammi = false;
      this.programmi.length
        ? (this.inserimento = false)
        : (this.inserimento = true);
      this.isTrasmesso = this.programmi[0].trasmesso;
      this.getDichiarazioneFlussiFinanziari(this.programmi[0].id ?? null);
    },
    async getFileAllegato(programma) {
      this.loadingProgrammi = true;
      await ProgrammiRepository.getFileAllegato(
        this.idStrutture,
        programma.id,
        this.anno,
        programma.fileAllegato.nomeOriginale
      );
      this.loadingProgrammi = false;
    },
    async getDichiarazioneFlussiFinanziari(idProgramma) {
      if (idProgramma) {
        this.dichiarazioni =
          await DichiarazioneFlussiRepository.getListaDichiarazioni(
            this.idStrutture,
            this.anno,
            idProgramma
          );
      }
    },
    async getFinestraCompilazione() {
      const finestraCompilazione =
        await IstitutoRepository.getFinestraCompilazione(
          this.idStrutture,
          this.anno,
          "programma-orientamento-ist"
        );
      if (finestraCompilazione !== "") {
        this.finestraCompilazione = finestraCompilazione;
      }
    },
    async getConvenzioniDaPartner() {
      this.loadingProgrammiDiCuiPartner = true;
      this.programmiPartner =
        await ProgrammiRepository.getProgrammiDiCuiPartner(
          this.idStrutture,
          this.anno
        );
      this.loadingProgrammiDiCuiPartner = false;
      this.programmiPartner.length
        ? (this.hasConvenzioni = false)
        : (this.hasConvenzioni = false);
    },
    async trasmetti() {
      this.controlloTrasmissione();
      if (!this.erroriCompilazione.value) {
        try {
          this.programmiPartner = await ProgrammiRepository.trasmettiProgramma(
            this.idStrutture,
            this.anno,
            this.programmi[0].id
          );
          this.snackbarText = "Programma trasmesso con successo";
          this.snackbarColor = "success";
        } catch (e) {
          console.log(e);
          this.snackbarText =
            "Problema durante la trasmissione del programa, contattare il supporto se il problema persiste";
          this.snackbarColor = "red accent-2";
        }
        this.snackbar = true;
        setTimeout(() => this.initialize(), 3000);
      } else {
        this.snackbarText = this.erroriCompilazione.arrayError.join("\n");
        this.snackbarColor = "red accent-2";
        this.snackbar = true;
      }
    },
    getTextConferma() {
      return `La trasmissione del programma di orientamento potrà avvenire solo se:
              <ul>
              <li>Sono stati caricati tutti i file richiesti. </li>
              <li>È stato inserito almeno un CF come titolare effettivo per ogni istituzione.</li>
              <li>È stata trasmessa la Dichiarazione di tracciabilità dei flussi finanziari nell'apposita card.</li>
              <li>È stata compilata la sezione "Programma di Orientamento" comprensiva di CUP e dati obbligatori riportati nella pagina.</li>
              </ul>
              Dopo la trasmissione non sarà più possibile modificare in alcun modo il programma di orientamento o i suoi allegati. <br><br>
              Procedere con la trasmissione?
              `;
    },
    controlloTrasmissione() {
      let programma = { ...this.programmi[0] };
      let res = {
        value: false,
        arrayError: [],
      };
      // controllo numero 1, il file allegato 4
      if (!programma.fileAllegato) {
        res.value = true;
        res.arrayError.push(
          "Non è stato caricato l'atto d'obbligo (allegato 4) "
        );
      }
      // controllo numero 2, se almeno uno dei file allegato 5 è null
      if (
        programma.assenzaDoppioFinanziamento.some(
          (elemento) => !("fileAllegato" in elemento)
        )
      ) {
        res.value = true;
        res.arrayError.push(
          "Non è stato caricato uno o più file di assenza di doppio finanziamento (allegato 5) "
        );
      }
      // controllo numero 3, se almeno uno dei file allegato 6 è null
      if (
        programma.comunicazioneTitolaritaEffettiva.some(
          (elemento) => !("fileAllegato" in elemento)
        )
      ) {
        res.value = true;
        res.arrayError.push(
          "Non è stato caricato uno o più file di comunicazione sulla titolarità effettiva (allegato 6) "
        );
      }
      let allTitolari;
      programma.comunicazioneTitolaritaEffettiva.forEach((element) => {
        allTitolari = element.titolari.length > 0;
      });
      // controllo numero 4, se almeno una delle comunicazioni è senza titolare effettivo
      if (!allTitolari) {
        res.value = true;
        res.arrayError.push(
          "Non è stato inserito almeno un titolare effettivo per ogni istituzione"
        );
      }
      // controllo numero 5, se almeno una delle comunicazioni è senza titolare effettivo
      if (this.dichiarazioni.length <= 0) {
        res.value = true;
        res.arrayError.push(
          "Non è stato inserita la dichiarazione flussi finanziari"
        );
      }
      if (res.value) {
        this.erroriCompilazione = res;
      }
    },
  },
};
</script>

<style scoped></style>
