<template>
  <v-row justify="center">
    <v-dialog :value="dialog" persistent max-width="500">
      <v-card>
        <v-toolbar color="primary" dark class="text-h6">{{ title }}</v-toolbar>
        <v-card-text>
          <div class="pa-4">{{ textConferma }}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="close"> Annulla </v-btn>
          <v-btn color="green darken-1" text @click="handleOk"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import CorsiRepository from "@/api/istituto/CorsiRepository";

export default {
  name: "DialogConferma",
  props: {
    // editedId: Number,
    dialog: Boolean,
    title: {
      type: String,
      required: false,
      default: "Attenzione!",
    },
    textConferma: {
      type: String,
      required: false,
      default:
        "Verranno eliminati DEFINITIVAMENTE i dati dello studente e TUTTE LE SUE LEZIONI salvate nel registro presenze. Desideri confermare l'operazione selezionata?",
    },
    textSuccess: {
      type: String,
      required: false,
      default: "Operazione conclusa con successo!",
    },
    textError: {
      type: String,
      required: false,
      default: "Errore durante l'operazione. Contattare il supporto.",
    },
    response: {
      type: Object,
      required: false,
      default: null,
    },
    idStudente: {
      type: Number,
      required: false,
    },
    idCorso: {
      type: Number,
      required: false,
    },
  },
  watch: {
    response: {
      deep: true,
      handler: function () {
        //se cambia la response devo gestire la risposta
      },
    },
  },
  computed: {},
  data() {
    return {};
  },

  methods: {
    async deleteStudente() {
      let idStrutture = this.$store.state.idStrutture;
      try {
        await CorsiRepository.deleteStudente(
          idStrutture,
          this.idCorso,
          this.idStudente
        );
        this.$emit("studente-eliminato");
      } catch (e) {
        console.log(e);
        if (e.response.status === 400) {
          this.$emit("corso-terminato");
        }
      }
    },
    handleOk(event) {
      this.deleteStudente();
      // Prevent modal from closing
      event.preventDefault();
      this.$emit("close-dialog");
    },
    close() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style scoped></style>
